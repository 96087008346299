// Copyright 2016-2023 Hitachi Energy. All rights reserved.

export default {
  onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      (e.target as any).blur();
    }
  }
};
