// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useAppSelector } from "store";
import Organizations from "../components/Organizations";
import getAllOrganizationsCheckedSelector from "../selectors/getAllOrganizationsCheckedSelector";
import getNotificationsEnabledSelector from "../selectors/getNotificationsEnabledSelector";
import getOrganizationsCheckedKeys from "../selectors/getOrganizationsCheckedKeys";
import getOrganizationsOptionsSelector from "../selectors/getOrganizationsOptionsSelector";

const OrganizationsContainer = () => {
  const notificationsEnabled = useAppSelector(getNotificationsEnabledSelector);
  const items = useAppSelector(getOrganizationsOptionsSelector);
  const defaultCheckedKeys = useAppSelector(getOrganizationsCheckedKeys);
  const allOrganizationsChecked = useAppSelector(
    getAllOrganizationsCheckedSelector
  );

  return (
    <Organizations
      notificationsEnabled={notificationsEnabled}
      items={items}
      defaultCheckedKeys={defaultCheckedKeys}
      allOrganizationsChecked={allOrganizationsChecked}
    />
  );
};

export default OrganizationsContainer;
