// Copyright 2016-2023 Hitachi Energy. All rights reserved.

const transformerConstructionTypes = [
  "other_core",
  "shell",
  "three_limb_core",
  "unknown"
];

export default transformerConstructionTypes;
