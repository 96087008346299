// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import Popover from "common/popover/Popover";
import {
  loadDegradationSubScoreFamilyInfoAction,
  resetFamilyInfoAction
} from "features/detailpage/features/familyinfo/actions/FamilyInfoActions";
import FamilyInfoContainer from "features/detailpage/features/familyinfo/containers/FamilyInfoContainer";
import * as React from "react";
import { connect } from "react-redux";
import { AppDispatch } from "store";
import styled from "styled-components";

const StyledDiv = styled.div`
  height: 320px;
`;

export interface IFamilyInfoDegradationSubScoreIconActions {
  loadDegradationSubScoreFamilyInfoAction: () => void;
  resetFamilyInfo: () => void;
}

export interface IFamilyInfoDegradationSubScoreIconOwnProps {
  assetId: string;
  subScoreName: string;
  subScoreTranslatedName: string;
  level: string;
}

interface IFamilyInfoDegradationSubScoreIconProps
  extends IFamilyInfoDegradationSubScoreIconActions,
    IFamilyInfoDegradationSubScoreIconOwnProps {}

const handlePopoverIconClick = (e: React.MouseEvent<HTMLSpanElement>) =>
  e.stopPropagation();

const SubScoreFamilyIcon = ({
  assetId,
  subScoreName,
  subScoreTranslatedName,
  loadDegradationSubScoreFamilyInfoAction,
  resetFamilyInfo
}: IFamilyInfoDegradationSubScoreIconProps) => {
  onclick = (e) => e.stopPropagation();
  return (
    <Popover
      placement="topRight"
      content={
        <StyledDiv>
          <FamilyInfoContainer
            assetId={assetId}
            title={subScoreTranslatedName}
            familyAnalyticsDataType={"DegradationSubScore"}
            familyAnalyticsSeriesName={subScoreName}
          />
        </StyledDiv>
      }
      onShow={loadDegradationSubScoreFamilyInfoAction}
      onHide={resetFamilyInfo}
      overlayClassName="family-info-subscore-popover family-info-popover"
    >
      <span onClick={handlePopoverIconClick}>
        <Icon
          className="family-info-popover-icon"
          dataQa="family-info-popover"
          name="speed"
        />
      </span>
    </Popover>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (
  dispatch: AppDispatch,
  ownProps: IFamilyInfoDegradationSubScoreIconOwnProps
): IFamilyInfoDegradationSubScoreIconActions => {
  return {
    loadDegradationSubScoreFamilyInfoAction: () =>
      dispatch(
        loadDegradationSubScoreFamilyInfoAction(
          ownProps.assetId,
          ownProps.subScoreName,
          ownProps.level
        )
      ),
    resetFamilyInfo: () => dispatch(resetFamilyInfoAction())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubScoreFamilyIcon);
