// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as d3 from "d3";
import * as React from "react";

import BatteryVoltageChartInfo from "./BatteryVoltageChartInfo";
import useChartConfiguration from "../hooks/useChartConfiguration";
import useChartExtensions from "../hooks/useChartExtensions";
import IVoltageData from "../models/IVoltageData";
import ResizeWrapper from "common/ResizeWrapper";
import C3Chart from "common/chart/components/C3Chart";

import "./BatteryVoltageChart.less";
import { colorBlue50 } from "styles/ColorVariables";

const minArrowSize = 12;
const maxArrowSize = 60;

interface IBatteryVoltageChartProps {
  voltageData: IVoltageData;
}

const BatteryVoltageChart = ({ voltageData }: IBatteryVoltageChartProps) => {
  const [configuration] = useChartConfiguration(voltageData, maxArrowSize);
  const [extensions] = useChartExtensions(voltageData);

  const handleRendered = (chart: c3.ChartAPI, container: HTMLDivElement) => {
    const barRects: SVGRect[] = d3
      .select(container)
      .selectAll(".c3-chart .c3-chart-bars .c3-chart-bar .c3-bars path")
      .nodes()
      .map((n: any) => n.getBBox());

    voltageData.Cells.forEach((c, i) => {
      if (c.Magnitude) {
        const barSize = barRects[i].width;
        let arrowSize = barSize * 0.75;
        if (arrowSize >= maxArrowSize) arrowSize = maxArrowSize;
        if (arrowSize <= minArrowSize) arrowSize = minArrowSize;
        const arrowSizeMap = {
          Decrease10: arrowSize,
          Decrease5: arrowSize * 0.75,
          Decrease3: arrowSize * 0.45,
          Increase10: arrowSize,
          Increase5: arrowSize * 0.75,
          Increase3: arrowSize * 0.45
        };
        const arrowDirectionMap = c.Magnitude.startsWith("Increase")
          ? "up"
          : "down";

        const computedArrowSize = arrowSizeMap[c.Magnitude];
        const x = barRects[i].x + (barSize / 2 - computedArrowSize / 2);
        const y = barRects[i].y - computedArrowSize - 10;

        const gs = d3
          .select(container)
          .selectAll(".c3-chart .c3-chart-bars .c3-chart-bar .c3-bars")
          .append("g")
          .attr("transform", `translate(${x},${y})`);

        if (arrowDirectionMap === "up") {
          gs.append("path")
            .attr(
              "d",
              `M 0 ${computedArrowSize}
                   L ${0.5 * computedArrowSize} ${0.3 * computedArrowSize}
                   L ${computedArrowSize} ${computedArrowSize}
                   Z`
            )
            .attr(
              "style",
              `fill: ${colorBlue50};
                shape-rendering: geometricPrecision;
                stroke: ${colorBlue50};`
            );
        } else {
          gs.append("path")
            .attr(
              "d",
              `M 0 ${0.3 * computedArrowSize}
                   L ${0.5 * computedArrowSize} ${computedArrowSize}
                   L ${computedArrowSize} ${0.3 * computedArrowSize}
                   Z`
            )
            .attr(
              "style",
              `fill: ${colorBlue50};
              shape-rendering: geometricPrecision;
              stroke: ${colorBlue50};`
            );
        }
      }
    });
  };

  return (
    <div className="battery-voltage-chart">
      <ResizeWrapper>
        <C3Chart
          configuration={configuration}
          extensions={extensions}
          onRendered={handleRendered}
        />
      </ResizeWrapper>
      <BatteryVoltageChartInfo></BatteryVoltageChartInfo>
    </div>
  );
};

export default BatteryVoltageChart;
