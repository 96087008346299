// Copyright 2016-2023 Hitachi Energy. All rights reserved.

const conditionOfValve = [
  "normal",
  "leaking",
  "improperly closed",
  "closed as required"
];

export default conditionOfValve;
