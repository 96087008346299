// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Reducer } from "redux";

import Data from "core/data/models/Data";
import { IAjaxAction } from "reducers/Index";
import { Actions } from "features/detailpage/features/assettree/actions/AssetTreeActions";
import IAssetTree from "../models/IAssetTree";

export interface IState {
  assetTree: Data<IAssetTree>;
}

const reducer: Reducer<IState> = (
  state = {
    assetTree: new Data()
  },
  action: IAjaxAction<IAssetTree>
) => {
  switch (action.type) {
    case Actions.DetailPage_AssetTree_SetAssetTree:
      state = Object.assign({}, state, {
        assetTree: new Data(action.request, action.data)
      });
      return state;
    default:
      return state;
  }
};

export default reducer;
