// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useCallback } from "react";
import { useAppDispatch } from "store";
import { setAllOrganizationCheckedAction } from "../actions/NotificationsActions";

const useAllOrganizationsChecked = () => {
  const dispatch = useAppDispatch();
  const setAllOrganizationChecked = useCallback(
    (value: boolean) => {
      dispatch(setAllOrganizationCheckedAction(value));
    },
    [dispatch]
  );

  return { setAllOrganizationChecked };
};

export default useAllOrganizationsChecked;
