// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";
import Data from "../../../../../core/data/models/Data";
import { IState } from "../../../../../reducers/Index";
import { getDataKey } from "../actions/loadOperatingMechanismMotor";
import IOperatingMechanismMotor from "../models/IOperatingMechanismMotor";

const getOperatingMechanismMotorSelector = createSelector(
  (state: IState) => (assetId: string) =>
    state.data[getDataKey(assetId)] as Data<IOperatingMechanismMotor>,
  (operatingMechanismMotor) => operatingMechanismMotor
);

export default getOperatingMechanismMotorSelector;
