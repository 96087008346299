// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createContext } from "react";

export interface IAssetRibbonContext {
  assetId?: string;
  sidePanelExpanded?: boolean;
}

const AssetRibbonContext = createContext<IAssetRibbonContext>({});

export default AssetRibbonContext;
