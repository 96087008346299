// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { IAjaxAction } from "reducers/Index";
import { Reducer } from "redux";
import { Actions } from "../actions/OpenIssuesActions";
import IOpenIssuesInfo from "../models/IOpenIssuesInfo";

export interface IOpenIssuesStateProps {
  openIssues: Data<IOpenIssuesInfo>;
}

export interface IOpenIssuesState {
  issues: IOpenIssuesStateProps;
}

interface IOpenIssuesAction extends IAjaxAction<IOpenIssuesInfo> {}

export const defaultState: IOpenIssuesState = {
  issues: {
    openIssues: new Data()
  }
};

const reducer: Reducer<IOpenIssuesState> = (
  state: IOpenIssuesState = defaultState,
  action: IOpenIssuesAction
) => {
  switch (action.type) {
    case Actions.OpenIssues_LoadOpenIssues: {
      return {
        ...state,
        issues: {
          ...state.issues,
          openIssues: new Data(action.request, action.data)
        }
      };
    }
    default:
      return state;
  }
};

export default reducer;
