// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Button, Col, DatePicker, Form, Row, Typography } from "antd";
import update from "immutability-helper";
import { TypedValue } from "models/ITypedValue";
import moment from "moment";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { colorIconAccent } from "styles/ColorVariables";
import {
  spacingSmall,
  spacingXSmall,
  spacingXXLarge
} from "styles/StyleVariables";
import AssetModalContext from "../contexts/AssetModalContext";
import { IFormItem } from "../hooks/useForms";
import IFormValues from "../models/IFormValues";
import disableFutureDates from "../utils/disableFutureDates";
import CustomField from "./CustomField";
import CustomParameter from "./CustomParameter";

const { Title } = Typography;

interface ICustomParametersProps {
  className?: string;
  formInfo: IFormItem;
}

const CustomParameters = ({ className, formInfo }: ICustomParametersProps) => {
  const {
    validateMessages,
    inspection,
    configuredParameterNames,
    customParameters,
    getFormInfo,
    registerForm,
    updateFormItemTouchedInfo,
    changeInspectionField,
    addParameter,
    editParameter,
    deleteParameter,
    changeInspectionDateForFields,
    isReadOnlyMode,
    modelMappedParameterNames
  } = useContext(AssetModalContext);
  const intl = useIntl();
  const inspectionDateKey = useMemo(() => {
    return `DateOfInspection_${formInfo.formKey}`;
  }, [formInfo.formKey]);

  const [parameterModalVisible, setParameterModalVisible] =
    useState<boolean>(false);
  const [editedParameterName, setEditedParameterName] = useState<string>(null);
  const [editedParameterValue, setEditedParameterValue] = useState<
    string | number | boolean | Date
  >(null);
  const [editedParameterType, setEditedParameterType] =
    useState<TypedValue>(null);
  const [forbiddenParameterNames, setForbiddenParameterNames] = useState<
    string[]
  >(configuredParameterNames);
  const [availableParameterNames, setAvailableParameterNames] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (customParameters.length > 0) {
      const newForbiddenParameterNames = update(configuredParameterNames, {
        $push: customParameters.map((el) => el.fieldKey)
      });
      setForbiddenParameterNames(newForbiddenParameterNames);
    } else {
      setForbiddenParameterNames(configuredParameterNames);
    }
  }, [configuredParameterNames, customParameters]);

  useEffect(() => {
    setAvailableParameterNames(
      modelMappedParameterNames.filter(
        (param) => !forbiddenParameterNames.includes(param)
      )
    );
  }, [forbiddenParameterNames, modelMappedParameterNames]);

  const form = Form.useForm<IFormValues>(
    getFormInfo(formInfo.formKey)?.form
  )[0];

  useEffect(() => {
    registerForm(formInfo.formKey, form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    const dateValue = form.getFieldValue(inspectionDateKey);
    form.resetFields();
    form.setFieldsValue({
      [inspectionDateKey]: dateValue
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customParameters]);

  useEffect(() => {
    if (formInfo.invalid) {
      form.validateFields();
    }
  }, [form, formInfo.invalid]);

  const handleValuesChange = useCallback(
    (changedValues: Partial<IFormValues>, values: IFormValues) => {
      Object.keys(changedValues || {}).forEach((key) => {
        if (key === inspectionDateKey) {
          changeInspectionDateForFields(
            changedValues[key] as Date,
            Object.keys(values).filter((k) => k !== inspectionDateKey)
          );
        }
        updateFormItemTouchedInfo(formInfo.formKey);
      });
    },
    [
      changeInspectionDateForFields,
      inspectionDateKey,
      updateFormItemTouchedInfo,
      formInfo.formKey
    ]
  );

  const handleParameterSave = useCallback(
    (
      previousParameterName: string,
      parameterName: string,
      parameterValue: string | number | boolean | Date,
      parameterType: TypedValue
    ) => {
      if (previousParameterName) {
        editParameter(previousParameterName, parameterName, parameterType);
      } else {
        addParameter(parameterName, parameterType);
      }
      changeInspectionField(
        parameterName,
        parameterValue,
        parameterType,
        form.getFieldValue(inspectionDateKey) as Date,
        previousParameterName
      );
      updateFormItemTouchedInfo(formInfo.formKey);
    },
    [
      changeInspectionField,
      form,
      inspectionDateKey,
      updateFormItemTouchedInfo,
      addParameter,
      editParameter,
      formInfo.formKey
    ]
  );

  const getInspectionValue = useCallback(
    (parameterName: string, parameterType: TypedValue) => {
      const parameter = inspection.find((i) => i.InputName === parameterName);
      if (parameter?.ValueType === parameterType) {
        const value = inspection.find(
          (i) => i.InputName === parameterName
        )?.Value;
        if (parameterType === "Bool") return value.toString();
        return value;
      } else {
        return null;
      }
    },
    [inspection]
  );

  useEffect(() => {
    customParameters.forEach((field) => {
      form.setFieldsValue({
        [field.fieldKey]: getInspectionValue(field.fieldKey, field.dataType)
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspection, form]);

  const handleParameterDelete = useCallback(
    (parameterName: string) => {
      deleteParameter(parameterName);
      changeInspectionField(parameterName, null, null, null);
      updateFormItemTouchedInfo(formInfo.formKey);
    },
    [
      deleteParameter,
      updateFormItemTouchedInfo,
      changeInspectionField,
      formInfo.formKey
    ]
  );

  const handleParameterModalOpen = useCallback(
    (
      parameterKey: string,
      parameterValue: string | number | boolean | Date,
      parameterType: TypedValue
    ) => {
      setEditedParameterName(parameterKey);
      setEditedParameterValue(parameterValue);
      setEditedParameterType(parameterType);
      setParameterModalVisible(true);
    },
    []
  );

  const handleModalClose = useCallback(() => {
    setEditedParameterName(null);
    setEditedParameterValue(null);
    setEditedParameterType(null);
    setParameterModalVisible(false);
  }, []);

  const fieldsNodes = useMemo(
    () =>
      customParameters.map((field) => {
        return (
          <CustomField
            configuration={field}
            key={field.fieldKey}
            editAction={() => {
              handleParameterModalOpen(
                field.fieldKey,
                getInspectionValue(field.fieldKey, field.dataType),
                field.inputType as TypedValue
              );
            }}
            editActionDisabled={isReadOnlyMode}
          />
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inspection]
  );

  return (
    <div className={className}>
      <Form
        form={form}
        layout="horizontal"
        colon={false}
        requiredMark={false}
        validateMessages={validateMessages}
        onValuesChange={handleValuesChange}
      >
        <Form.Item
          label={intl.formatMessage({
            id: "configuration_tool.label.date_of_inspection",
            defaultMessage: "Date of inspection"
          })}
          className="date-of-inspection"
          name={inspectionDateKey}
          rules={[{ required: true }]}
        >
          <DatePicker
            disabledDate={disableFutureDates}
            format={moment.defaultFormat}
            data-qa="InspectionDate"
            disabled={isReadOnlyMode}
          />
        </Form.Item>
        <div>
          <Row gutter={16} style={{ margin: 0 }}>
            <Col xs={24} sm={12} md={12} lg={8}>
              <div className="section section-list">
                <Title level={5}>
                  <FormattedMessage
                    id="configuration_tool.tab.custom_parameters"
                    defaultMessage="Custom Parameters"
                  />
                </Title>
                {fieldsNodes}
                <Button
                  type="text"
                  onClick={() => {
                    handleParameterModalOpen(null, null, null);
                  }}
                  className="add-to-list"
                  disabled={isReadOnlyMode}
                >
                  <FormattedMessage
                    defaultMessage="+ Add new parameter"
                    id="configuration_tool.action.add_new_parameter_button"
                  />
                </Button>
                <CustomParameter
                  visible={parameterModalVisible}
                  parameterName={editedParameterName}
                  parameterValue={editedParameterValue}
                  parameterType={editedParameterType}
                  onCancel={handleModalClose}
                  onSave={handleParameterSave}
                  onDelete={handleParameterDelete}
                  forbiddenParameterNames={forbiddenParameterNames}
                  availableParameterNames={availableParameterNames}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

const StyledCustomParameters = styled(CustomParameters)`
  .ant-form-item-control-input-content {
    display: flex;
  }

  .form-field {
    display: flex;

    .ant-form-item {
      flex-grow: 1;
      width: 100%;

      .ant-form-item-no-colon {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: ${spacingXSmall};
      }
    }

    .ant-row {
      width: 100%;
    }

    .field-actions {
      width: ${spacingXXLarge};
      margin: ${spacingSmall} ${spacingSmall} 0px 0px;
      color: ${colorIconAccent};
      cursor: pointer;
    }
  }
`;

export default StyledCustomParameters;
