// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { getDataKey } from "../actions/loadMotorStarts";
import IMotorStarts from "../models/IMotorStarts";

const getOperatingMechanismMotorSelector = createSelector(
  (state: IState) => (assetId: string) =>
    state.data[getDataKey(assetId)] as Data<IMotorStarts>,
  (motorStarts) => motorStarts
);

export default getOperatingMechanismMotorSelector;
