// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IValidationResult } from "@apm/widgets/build/widgets/DataGrid";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import DataIntegrationStore, {
  IModelParameter
} from "store/dashboards/dataintegration/DataIntegrationStore";
import validator from "validator";

const useModelParametersMappingValidation = () => {
  const { formatMessage } = useIntl();

  const defaultMessages = useMemo(() => {
    return {
      required: formatMessage({
        id: "data_integration.model_config.grid.field.required",
        defaultMessage: "The value is required"
      }),
      invalidCharacters: formatMessage({
        id: "data_integration.model_config.grid.input.acceptable_chars",
        defaultMessage:
          "Acceptable characters: big or small letters, digits, space, _ and ."
      }),
      unique: formatMessage({
        id: "data_integration.model_config.grid.field.unique",
        defaultMessage: "The value must be unique"
      })
    };
  }, [formatMessage]);

  const validateModelParameter = useCallback(
    (value: IModelParameter["ModelParameter"]): IValidationResult => {
      if (DataIntegrationStore.isParameterIdEmpty(value)) {
        return {
          valid: false,
          validationMessage: defaultMessages.required
        };
      } else if (DataIntegrationStore.isParameterIdDuplicated(value)) {
        return {
          valid: false,
          validationMessage: defaultMessages.unique
        };
      } else {
        return {
          valid: true
        };
      }
    },
    [defaultMessages.required, defaultMessages.unique]
  );

  const validateSource = useCallback(
    ({
      Source,
      Input
    }: Pick<IModelParameter, "Source" | "Input">): IValidationResult => {
      const isValid = DataIntegrationStore.isParameterSourceValid(
        Source,
        Input
      );

      if (isValid) {
        return {
          valid: true
        };
      }

      return {
        valid: isValid,
        validationMessage: defaultMessages.required
      };
    },
    [defaultMessages.required]
  );

  const validateInput = useCallback(
    ({
      Source,
      Input
    }: Pick<IModelParameter, "Source" | "Input">): IValidationResult => {
      if (
        !validator.isEmpty(Input || "") &&
        validator.matches(Input || "", /^[a-zA-Z0-9_.]+( [a-zA-Z0-9_.]+)*$/)
      ) {
        return {
          valid: true
        };
      } else if (
        validator.isEmpty(Source || "") &&
        validator.isEmpty(Input || "")
      ) {
        return {
          valid: true
        };
      } else if (
        !validator.isEmpty(Source || "") &&
        validator.isEmpty(Input || "")
      ) {
        return {
          valid: false,
          validationMessage: defaultMessages.required
        };
      } else {
        return {
          valid: false,
          validationMessage: defaultMessages.invalidCharacters
        };
      }
    },
    [defaultMessages.invalidCharacters, defaultMessages.required]
  );

  return {
    defaultMessages,
    validateModelParameter,
    validateSource,
    validateInput
  };
};

export default useModelParametersMappingValidation;
