// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getIsComponentSelector = createSelector(
  (state: IState) => state.detailPage.riskMatrix.isComponent,
  isComponent => isComponent
);

export default getIsComponentSelector;
