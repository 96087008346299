// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Statuses } from "core/data/models/Data";
import { loadLtcOperationsAction } from "features/detailpage/features/ltc/actions/LTCOperationsActions";
import LTCOperations from "features/detailpage/features/ltc/components/LTCOperations";
import { useLTCOperations } from "features/detailpage/features/ltc/reducers/LTCReducer";
import { isEmpty } from "lodash";
import * as React from "react";
import { useAppDispatch } from "store";

interface ILTCOperationsContainerProps {
  assetId: string;
}

export default ({ assetId }: ILTCOperationsContainerProps) => {
  const dispatch = useAppDispatch();
  const { ltcOperations, percentageUsed } = useLTCOperations();

  React.useEffect(() => {
    if (
      ltcOperations.status !== Statuses.Succeeded ||
      isEmpty(ltcOperations.data)
    ) {
      dispatch(loadLtcOperationsAction(assetId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LTCOperations
      ltcOperations={ltcOperations}
      percentageUsed={percentageUsed}
    />
  );
};
