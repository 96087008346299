// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useMemo } from "react";

import tiers from "../../../utils/tiersConfiguration";
import ClusterService from "common/AssetRiskMatrix/services/ClustersService";
import {
  colorRiskHigh,
  colorRiskLow,
  colorRiskMedium
} from "styles/ColorVariables";

interface IRiskMatrixColors {
  lowRiskColor: string;
  medRiskColor: string;
  higRiskColor: string;
  assetColor: string;
  clusterColor: string;
  prognosticColor: string;
}

const useAssetRiskMatrixColors = (grayscale?: boolean) => {
  const {
    lowRiskColor,
    medRiskColor,
    higRiskColor,
    assetColor,
    clusterColor,
    prognosticColor
  }: IRiskMatrixColors = useMemo(() => {
    return grayscale
      ? {
          lowRiskColor: "#B5B5B5",
          medRiskColor: "#B7B7B7",
          higRiskColor: "#919191",
          assetColor: "#FFFFFF",
          clusterColor: "#000000",
          prognosticColor: "#FFFFFF"
        }
      : {
          lowRiskColor: colorRiskLow,
          medRiskColor: colorRiskMedium,
          higRiskColor: colorRiskHigh,
          assetColor: "#FFFFFF",
          clusterColor: "#000000",
          prognosticColor: "#FFFFFF"
        };
  }, [grayscale]);

  const colors = useMemo(() => {
    const clustersColors: { [key: string]: string } = {};
    tiers.forEach(({ id }) => {
      clustersColors[ClusterService.getSeriesName(id)] = clusterColor;
    });

    return {
      ...{
        risk_low_stack: lowRiskColor,
        risk_med_stack: medRiskColor,
        risk_hig_stack: higRiskColor,
        other_assets: assetColor,
        current_asset: assetColor,
        prognostic: prognosticColor,
        importance_high: higRiskColor,
        importance_low: medRiskColor
      },
      ...clustersColors
    };
  }, [
    lowRiskColor,
    medRiskColor,
    higRiskColor,
    assetColor,
    clusterColor,
    prognosticColor
  ]);

  return colors;
};

export default useAssetRiskMatrixColors;
