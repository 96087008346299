// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getTenantSelector = createSelector(
  (state: IState) => state.app.user.data.CustomerId,
  (tenant) => tenant
);

export default getTenantSelector;
