// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";
import { IState } from "reducers/Index";

const getScatterPlotXDataSelector = createSelector(
  (state: IState) => state.familyAnalytics.scatterPlotXData,
  (scatterPlotXData) => scatterPlotXData
);

export default getScatterPlotXDataSelector;
