// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import moment, { max as maxDate, min as minDate, Moment } from "moment";
import IBushingLineChartDataSet from "../models/IBushingLineChartDataSet";

function getExtremeDate(series: IBushingLineChartDataSet[]) {
  const seriesValues = series.map((s) => s.values);
  const mergedSeries = [].concat(...seriesValues);

  const xDates: Set<Moment> = new Set();
  mergedSeries.forEach((s) => xDates.add(s.date));

  const momentDates: Moment[] = Array.from(xDates).map((d) => moment(d));

  const firstDate = minDate(momentDates).toDate();
  const lastDate = maxDate(momentDates).toDate();

  return {
    firstDate,
    lastDate
  };
}

export default getExtremeDate;
