// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IRow } from "common/datagrid/DataGrid";
import { useAppNavigate } from "core/app/components/RouterProvider";
import { useCallback } from "react";

interface ILicensesColumnProps {
  value: any;
  row: IRow;
}

const LicensesColumn = ({ value, row }: ILicensesColumnProps) => {
  const navigate = useAppNavigate();

  const navigateToLicenseFile = useCallback(
    (licenseType: string, fileName: string) => {
      navigate(`/Licenses/${licenseType}/${encodeURIComponent(fileName)}`);
    },
    [navigate]
  );

  return (
    <div className="grid-cell-content">
      {value && row.data["fileName"] && (
        <a
          href="#/"
          onClick={(e) => {
            e.preventDefault();
            navigateToLicenseFile(
              row.data["licenseType"],
              row.data["fileName"]
            );
          }}
        >
          {value}
        </a>
      )}
      {value && !row.data["fileName"] && row.data["licensesUrl"] && (
        <a
          href={row.data["licensesUrl"]}
          rel="noopener noreferrer"
          target="_blank"
        >
          {value}
        </a>
      )}
      {!value && null}
    </div>
  );
};

export default LicensesColumn;
