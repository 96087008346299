// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Container from "common/Container";
import { ISelectOption } from "common/form/components/Select";
import SelectInputContainer from "common/form/containers/SelectInputContainer";
import TextFieldInputContainer from "common/form/containers/TextFieldInputContainer";
import IValidator from "common/form/validators/IValidator";
import SectionName from "components/common/SectionName";
import {
  ModelIdStartsFromLetter,
  ModelIdUniqueValidator,
  ModelIdValidator,
  ModelUrlValidator
} from "features/dataintegration/ModelValidators";
import { isEmpty } from "lodash";
import * as React from "react";
import { injectIntl, IntlShape } from "react-intl";
import DataIntegrationStore, {
  ApiVersion,
  IConfigurableModel,
  ModelMode
} from "store/dashboards/dataintegration/DataIntegrationStore";
import "./ModelData.less";

const formName = "model-data";
const maxVersionNumber = 6;
const oldestSupportedVersion = "V4";

interface IModelTextInputProps {
  defaultValue: string;
  disabled?: boolean;
  title?: string;
  validators?: IValidator<any>[];
  onChange: (value: string) => void;
  onBlur: () => void;
}

const ModelIdInput = ({
  defaultValue,
  onChange,
  onBlur,
  title,
  disabled,
  validators
}: IModelTextInputProps) => (
  <div className="input-container model-id" data-qa="model-id-panel">
    <TextFieldInputContainer
      inputName="model-id"
      type="text"
      className="col3vw"
      label={{
        id: "model_integration.model_config.model_data.mode_id",
        defaultMessage: "Model Id"
      }}
      disabled={disabled}
      title={title}
      formName={formName}
      required={true}
      defaultValue={defaultValue}
      onChange={onChange}
      onBlur={onBlur}
      validators={validators}
    />
  </div>
);

const ModelUrlInput = ({
  defaultValue,
  onChange,
  onBlur,
  title,
  disabled,
  validators
}: IModelTextInputProps) => (
  <div
    className="input-container model-azure-url"
    data-qa="model-azure-url-panel"
  >
    <TextFieldInputContainer
      inputName="model-azure-url"
      type="text"
      className="col3vw"
      label={{
        id: "model_integration.model_config.model_data.mode_rest_url",
        defaultMessage: "Model Azure URL"
      }}
      disabled={disabled}
      title={title}
      formName={formName}
      required={true}
      defaultValue={defaultValue}
      onChange={onChange}
      onBlur={onBlur}
      validators={validators}
    />
  </div>
);

interface IRestApiVersionInputProps {
  defaultValue: any;
  options: ISelectOption[];
  onChange: (value: any) => void;
}

const RestApiVersionInput = ({
  defaultValue,
  options,
  onChange
}: IRestApiVersionInputProps) => (
  <div className="input-container rest-api-version" data-qa="rest-api-version">
    <SelectInputContainer
      className="col2vw"
      selectOptionClassName="version-number"
      formName={formName}
      label={{
        id: "model_integration.model_config.model_data.rest_api_label",
        defaultMessage: "REST API Version"
      }}
      inputName="model-rest-api-version"
      required={true}
      defaultValue={defaultValue}
      onChange={onChange}
      options={options}
    />
  </div>
);

interface IModelDataProps {
  intl: IntlShape;
  model: IConfigurableModel;
}

class ModelData extends React.Component<IModelDataProps> {
  private modelIdValidators: IValidator<any>[];
  private modelUrlValidators: IValidator<any>[];

  private restApiVersionOptions: ISelectOption[];

  constructor(props: IModelDataProps) {
    super(props);
    this.modelIdValidators = [
      new ModelIdStartsFromLetter(this.modelIdStartsWithLetter),
      new ModelIdUniqueValidator(this.isModelIdUnique),
      new ModelIdValidator(this.isModelIdValid)
    ];
    this.modelUrlValidators = [new ModelUrlValidator(this.isModelUrlValid)];

    this.restApiVersionOptions = this.getModelRestApiVersionOptions();
  }

  render() {
    const {
      model: { ModelId }
    } = this.props;
    const modelIdInputDisabled = this.getModelIdDisabled();
    const modelIdTitle = this.getModelIdTitle();

    return (
      <Container className="model-data" data-qa="model-data-panel">
        <SectionName
          messageId="data_integration.model_config.model_data"
          messageDefault="Model data"
        />
        <ModelIdInput
          disabled={modelIdInputDisabled}
          title={modelIdTitle}
          key={ModelId}
          defaultValue={this.props.model.ConfigurableModelConfig.data.ModelId}
          onChange={this.handleChangeOnModelId}
          onBlur={this.handleBlurOnModelId}
          validators={this.modelIdValidators}
        />
        <ModelUrlInput
          key={`${ModelId}_url`}
          defaultValue={this.props.model.ConfigurableModelConfig.data.ModelUrl}
          onChange={this.handleChangeOnModelUrl}
          onBlur={this.handleBlurOnModelUrl}
          validators={this.modelUrlValidators}
        />
        {this.restApiVersionOptions && (
          <RestApiVersionInput
            defaultValue={
              this.props.model.ConfigurableModelConfig.data.RestModelApiVersion
            }
            options={this.restApiVersionOptions}
            onChange={this.handleChangeOnRestApi}
          />
        )}
      </Container>
    );
  }

  private handleChangeOnModelId = (value: string) => {
    if (value !== this.props.model.ConfigurableModelConfig.data.ModelId) {
      DataIntegrationStore.setModelId(value);
    }
  };

  private handleBlurOnModelId = () => {
    const {
      ConfigurableModelConfig: {
        data: { ModelId }
      }
    } = DataIntegrationStore.getSelectedModel();
    DataIntegrationStore.setModelId(ModelId);
  };

  private handleChangeOnModelUrl = (value: string) => {
    if (value !== this.props.model.ConfigurableModelConfig.data.ModelUrl) {
      DataIntegrationStore.setUrl(value);
    }
  };

  private handleBlurOnModelUrl = () => {
    const {
      ConfigurableModelConfig: {
        data: { ModelUrl }
      }
    } = DataIntegrationStore.getSelectedModel();

    DataIntegrationStore.setUrl(ModelUrl);
  };

  private handleChangeOnRestApi = (value: ApiVersion) => {
    DataIntegrationStore.setApiVersion(value);
  };

  private getModelIdDisabled(): boolean {
    const {
      model: { _Mode }
    } = this.props;

    return _Mode === ModelMode.View || _Mode === ModelMode.Edit;
  }

  private getModelRestApiVersionOptions = (): ISelectOption[] => {
    let options: ISelectOption[] = [];
    options.push(this.getEmptyOption());
    options = options.concat(
      this.getModelRestApiFromVersion(oldestSupportedVersion)
    );

    return options;
  };

  private getEmptyOption(): ISelectOption {
    return {
      value: null,
      label: {
        defaultMessage: " ",
        id: "model_integration.model_config.model_data.rest_version_empty_option"
      }
    };
  }

  private getModelRestApiFromVersion(fromVersion: ApiVersion) {
    const { intl } = this.props;
    const options: ISelectOption[] = [];
    const translatedVersion = intl.formatMessage({
      id: "model_integration.model_config.model_data.rest_version",
      defaultMessage: "Version"
    });

    const fromVersionNumber = Number(fromVersion.match(/\d/));

    for (let i = fromVersionNumber; i <= maxVersionNumber; i++) {
      options.push({
        value: `V${i}`,
        label: {
          defaultMessage: `${translatedVersion} ${i}`,
          id: "model_integration.model_config.model_data.rest_version_option"
        }
      });
    }

    return options;
  }

  private getModelIdTitle(): string {
    const { intl } = this.props;
    return intl.formatMessage({
      id: "data_integration.model_config.model_data.model_id.acceptable_chars",
      defaultMessage:
        "Acceptable characters: big or small letters, digits and '.'"
    });
  }

  private isModelIdValid = (): boolean => {
    const { model } = this.props;
    if (!isEmpty(model.ConfigurableModelConfig.data.ModelId)) {
      const valid = DataIntegrationStore.isModelIdValid(
        model.ConfigurableModelConfig.data.ModelId
      );
      return valid;
    }

    return true;
  };

  private isModelIdUnique = (): boolean => {
    const { model } = this.props;
    if (!isEmpty(model.ConfigurableModelConfig.data.ModelId)) {
      const valid = DataIntegrationStore.isModelIdUnique(
        model.ConfigurableModelConfig.data.ModelId
      );
      return valid;
    }

    return true;
  };

  private modelIdStartsWithLetter = (): boolean => {
    const { model } = this.props;
    if (!isEmpty(model.ConfigurableModelConfig.data.ModelId)) {
      const valid = DataIntegrationStore.modelIdStartsWithLetter(
        model.ConfigurableModelConfig.data.ModelId
      );
      return valid;
    }

    return true;
  };

  private isModelUrlValid = (): boolean => {
    const {
      model: {
        ConfigurableModelConfig: {
          data: { ModelUrl }
        }
      }
    } = this.props;

    if (!isEmpty(ModelUrl)) {
      const valid = DataIntegrationStore.isModelUrlValid(ModelUrl);
      return valid;
    }

    return true;
  };
}

export default injectIntl(ModelData);
