// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { IntlShape } from "react-intl";

import ICluster from "../../../models/ICluster";

interface IClusterRiskMatrixTooltipProps {
  cluster: ICluster;
  intl: IntlShape;
}

const ClusterRiskMatrixTooltip = ({
  cluster: { AssetsCount },
  intl
}: IClusterRiskMatrixTooltipProps) => {
  return (
    <table className="c3-tooltip cluster">
      <tr className="c3-tooltip-name-assets-count">
        <td className="name">
          {intl.formatMessage({
            id: "asset_risk_chart.tooltip.assets_count",
            defaultMessage: "Assets in cluster"
          })}
        </td>
        <td className="value">{intl.formatNumber(AssetsCount)}</td>
      </tr>
    </table>
  );
};

export default ClusterRiskMatrixTooltip;
