// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IMapProvider } from "core/app/reducers/settings/MapProviderReducer";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IAjaxAction } from "reducers/Index";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";

export enum Actions {
  MapProvider_SetMapProvider = "MapProvider_SetMapProvider"
}

const setMapProviderAction = (
  request: JQueryXHR,
  data?: IMapProvider
): IAjaxAction<IMapProvider> => {
  const action: IAjaxAction<IMapProvider> = {
    type: Actions.MapProvider_SetMapProvider,
    request,
    data
  };
  return action;
};

export const loadMapProvider = (dispatch: AppDispatch) => {
  const url = UrlService.getApiUrl(config.api.map.config);

  const request = EndpointService.getJson<IMapProvider>(
    url,
    (request, data) => {
      dispatch(setMapProviderAction(request, data));
    },
    (request) => {
      dispatch(setMapProviderAction(request));
    }
  );

  dispatch(setMapProviderAction(request));
};
