// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { SelectedFilters } from "common/FilterBar";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IScatterPlotDataPoints } from "features/familyanalytics/components/ScatterPlot";
import { IntlShape } from "react-intl";
import { IAjaxAction } from "reducers/Index";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";
import { ScatterPlotParameters } from "../components/ScatterPlotWidget";
import { IScatterPlotFilterData } from "../reducers/FamilyAnalyticsReducer";
import ScatterPlotWidgetService from "../services/ScatterPlotWidgetService";

interface IErrorMessage {
  CurrentDataPointsNumber: number | undefined;
  DisplayLimitValue: number | undefined;
  ErrorCode: string;
}

export const Actions = {
  FamilyAnalytics_SetScatterPlotFilters:
    "FamilyAnalytics_SetScatterPlotFilters",
  ScatterPlotWidget_SetYData: "ScatterPlotWidget_SetYData",
  ScatterPlotWidget_SetXData: "ScatterPlotWidget_SetXData"
};

export const setScatterPlotFiltersAction = (
  request: JQueryXHR,
  data?: IScatterPlotFilterData
): IAjaxAction<IScatterPlotFilterData> => {
  const action: IAjaxAction<IScatterPlotFilterData> = {
    type: Actions.FamilyAnalytics_SetScatterPlotFilters,
    request,
    data
  };
  return action;
};

export const loadScatterPlotFilters = (filters: SelectedFilters) => {
  return (dispatch: AppDispatch) => {
    const url = UrlService.getApiUrl(
      config.api.familyAnalytics.scatterPlotFilterUrl
    );

    const request = EndpointService.postJson<IScatterPlotFilterData>(
      url,
      (request, data) => {
        dispatch(setScatterPlotFiltersAction(request, data));
      },
      (request) => {
        dispatch(setScatterPlotFiltersAction(request));
      },
      {
        filters: filters.selects
      }
    );

    dispatch(setScatterPlotFiltersAction(request));
  };
};

export type ScatterPlotSetDataAction = IAjaxAction<IScatterPlotDataPoints> & {
  assetId: string;
  labelX: string;
  labelY: string;
};

export const setScatterPlotDataAction = (
  actionName: string,
  request: JQueryXHR,
  assetId: string,
  labelX: string,
  labelY: string,
  data?: IScatterPlotDataPoints
): ScatterPlotSetDataAction => {
  const action: ScatterPlotSetDataAction = {
    type: actionName,
    request,
    assetId,
    labelX,
    labelY,
    data
  };
  return action;
};

export const loadScatterPlotYData = (
  parameters: ScatterPlotParameters,
  filters: SelectedFilters,
  success: () => void,
  error: (errorCode: string, dataPointsLimit: number | undefined) => void,
  intl: IntlShape
) => {
  return (dispatch: AppDispatch) => {
    const url = ScatterPlotWidgetService.resolveApiUrl(
      parameters.dataTypeY,
      parameters.seriesNameY,
      parameters
    );
    const labelX = "";
    const labelY = ScatterPlotWidgetService.resolveLabelY(parameters, intl);
    const assetId = parameters.assetId;

    const dispatchScatterPlotDataAction = (
      request?: JQuery.jqXHR<any>,
      data?: IScatterPlotDataPoints
    ) => {
      dispatch(
        setScatterPlotDataAction(
          Actions.ScatterPlotWidget_SetYData,
          request,
          assetId,
          labelX,
          labelY,
          data
        )
      );
    };

    if (url) {
      const request = EndpointService.postJson<IScatterPlotDataPoints>(
        url,
        (request, data) => {
          dispatchScatterPlotDataAction(request, data);
          success();
        },
        (request) => {
          dispatchScatterPlotDataAction(request);
          try {
            const message = request.responseJSON as IErrorMessage;
            error(message.ErrorCode, message.DisplayLimitValue);
          } catch (e) {
            console.error("Response is not a json");
          }
        },
        {
          filters: filters.selects
        }
      );
      dispatchScatterPlotDataAction(request);
    } else {
      dispatchScatterPlotDataAction();
    }
  };
};

export const loadScatterPlotXData = (
  parameters: ScatterPlotParameters,
  filters: SelectedFilters,
  success: () => void,
  error: (errorCode: string, dataPointsLimit: number | undefined) => void,
  intl: IntlShape
) => {
  return (dispatch: AppDispatch) => {
    const url = ScatterPlotWidgetService.resolveApiUrl(
      parameters.dataTypeX,
      parameters.seriesNameX,
      parameters
    );

    const labelX = ScatterPlotWidgetService.resolveLabelX(parameters, intl);
    const labelY = "";
    const assetId = parameters.assetId;

    const dispatchScatterPlotDataAction = (
      request?: JQuery.jqXHR<any>,
      data?: IScatterPlotDataPoints
    ) => {
      dispatch(
        setScatterPlotDataAction(
          Actions.ScatterPlotWidget_SetXData,
          request,
          assetId,
          labelX,
          labelY,
          data
        )
      );
    };

    if (url) {
      const request = EndpointService.postJson<IScatterPlotDataPoints>(
        url,
        (request, data) => {
          dispatchScatterPlotDataAction(request, data);
          success();
        },
        (request) => {
          dispatchScatterPlotDataAction(request);
          try {
            const message = request.responseJSON as IErrorMessage;
            error(message.ErrorCode, message.DisplayLimitValue);
          } catch (e) {
            console.error("Response is not a json");
          }
        },
        {
          filters: filters.selects
        }
      );
      dispatchScatterPlotDataAction(request);
    } else {
      dispatchScatterPlotDataAction();
    }
  };
};
