// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import React, { useMemo } from "react";

import { IRow } from "common/datagrid/DataGrid";
import Value from "./Value";
import ITypedValue from "../../../models/ITypedValue";
import IParameterInfo from "../../../models/IParameterInfo";

const ColumnLastValue = (lastValue: ITypedValue, row: IRow<IParameterInfo>) => {
  const change = useMemo(() => row.data["Change"], [row]);

  return (
    <Value value={lastValue} row={row} color={row.data.Color}>
      <Icon className={change} name="arrow_upward" size="sm" />
    </Value>
  );
};

export default ColumnLastValue;
