// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { FormattedMessage } from "react-intl";

import { IAnalyticsChartDataSet, Axis } from "./AnalyticsLineChart";

import isThreshold from "./isThreshold";
import getSeriesColor from "./getSeriesColor";

import "./AnalyticsChartLegendItem.less";

enum LegendItemState {
  Active,
  NoActive,
  Disabled
}

interface IAnalyticsChartLegendItemProps {
  item: IAnalyticsChartDataSet;
  state: LegendItemState;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  onClick?: () => void;
}

const AnalyticsChartLegendItem = ({
  item,
  onClick,
  onMouseOver,
  onMouseOut,
  state
}: IAnalyticsChartLegendItemProps) => {
  return (
    <span
      className={"analyticsLegendItem " + LegendItemState[state].toLowerCase()}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <span className="analyticsLegendItemBox">
        {item.className ? (
          <span className={"analyticsLegendItemBox_filled " + item.className} />
        ) : isThreshold(item.name) ? (
          <span
            style={{
              borderTopColor: getSeriesColor(item.name, item.axis === Axis.Left)
            }}
            className="analyticsLegendItemBox_dotted"
          />
        ) : (
          <span
            style={{
              background: getSeriesColor(item.name, item.axis === Axis.Left)
            }}
            className="analyticsLegendItemBox_filled"
          />
        )}
      </span>
      <span className="analyticsLegendItemText">
        <FormattedMessage
          defaultMessage={`${item.name} ${item.unit ? `(${item.unit})` : ""}`}
          id={`detail_page.widgets.analytics.transformers.StandardOilTests.${item.standard}.${item.name}`}
        />
      </span>
    </span>
  );
};

export default AnalyticsChartLegendItem;
export { LegendItemState };
