// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { ITrendChart } from "common/chart/components/TrendLineChart";
import Data from "core/data/models/Data";
import { isEmpty } from "lodash";
import { DielectricDataSource } from "../../../models/DielectricDataSource";
import IDielectricData from "../../../models/IDielectricData";

const filterByDataSource = (
  dielectric: Data<IDielectricData>,
  dielectricDataSource: DielectricDataSource
) => {
  const { data } = dielectric;
  const reading: ITrendChart = {
    DataSeries: {},
    Regions: [],
    Lines: null,
    Unit: ""
  };

  if (!isEmpty(data) && !isEmpty(data[dielectricDataSource])) {
    const { DataSeries, Lines, Regions, Unit } = data[dielectricDataSource];
    reading.DataSeries = DataSeries || {};
    reading.Lines = Lines || [];
    reading.Regions = Regions || [];
    reading.Unit = Unit || "";
  }

  return reading;
};

export default filterByDataSource;
