// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import getDielectricPressureSelector from "./getDielectricPressureSelector";
import filterByDataSource from "../utils/filterByDataSource";
import { DielectricDataSource } from "../../../models/DielectricDataSource";

const getDielectricPressureChartDataSelector = createSelector(
  getDielectricPressureSelector,
  (dielectric) => (dataSource: DielectricDataSource) =>
    filterByDataSource(dielectric, dataSource)
);

export default getDielectricPressureChartDataSelector;
