// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IState } from "reducers/Index";
import { createSelector } from "reselect";

export const getPbiReportsSelector = createSelector(
  (state: IState) => state.detailPage.reports.pbiReports,
  (pbiReports) => pbiReports
);

export default getPbiReportsSelector;
