// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import SearchParams from "@pg/common/build/models/SearchParams";
import { Button } from "antd";
import ColumnReplacementScore from "common/columns/ReplacementScore";
import ColumnRisk from "common/columns/Risk";
import Container from "common/Container";
import DataGrid, {
  IAction,
  IActionComponentProps,
  IColumn,
  IColumnConfig,
  IDataEndpoint,
  IRow
} from "common/datagrid/DataGrid";
import SortOrders from "common/datagrid/models/SortOrders";
import DataService, {
  ContentTypes,
  ExportTypes
} from "common/datagrid/services/DataService";
import { SelectedFilters, StatusBar } from "common/FilterBar";
import SectionName from "components/common/SectionName";
import { routes } from "core/app/components/AppRoutes";
import { useAppNavigate } from "core/app/components/RouterProvider";
import UrlService from "core/data/services/UrlService";
import getCustomerVisualizationsAssetGridSelector from "core/selectors/getCustomerVisualizationsAssetGridSelector";
import AssetModal from "features/ConfigurationTool";
import { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppSelector } from "store";
import { config } from "utils/AppConfig";
import ColumnAsset from "./columns/Asset";
import ColumnCondition from "./columns/Condition";
import ColumnEditDeleteAssetActions from "./columns/EditDeleteAssetActions";
import ColumnLatestParameterDate from "./columns/LatestParameterDate";
import ColumnMaintenancePriority from "./columns/MaintenancePriority";
import ColumnManufacturer from "./columns/Manufacturer";
import useAssetQueryGrid from "./hooks/useAssetQueryGrid";
import useConfigurationConfig from "./hooks/useConfigurationConfig";

interface IAssetQueryGridProps {
  filters: SelectedFilters;
}

const AssetQueryGrid = ({ filters }: IAssetQueryGridProps) => {
  const intl = useIntl();
  const [totalCount, setTotalCount] = useState<number>();
  const customerVisualizationsAssetGrid = useAppSelector(
    getCustomerVisualizationsAssetGridSelector
  );
  const navigate = useAppNavigate();
  const { isConfigurationVisible } = useConfigurationConfig();

  const {
    handleAssetModalOpen,
    handleNewAssetModalOpen,
    handleAssetModalCancel,
    isAssetModalVisible,
    assetIdForAssetModal,
    getRowClassName
  } = useAssetQueryGrid();

  const actions: IAction[] = useMemo(
    () => [
      {
        Component: ({ row }: IActionComponentProps) => (
          <ColumnEditDeleteAssetActions
            assetId={row.data["AssetId"]}
            onAssetModalOpen={handleAssetModalOpen}
          />
        ),
        display: (row: IRow) => row.data["AssetType"] === "Transformer",
        id: "EditAsset"
      }
    ],
    [handleAssetModalOpen]
  );

  const columns = useMemo<IColumnConfig[]>(
    () => [
      {
        component: (value, row) => (
          <ColumnAsset
            assetId={row.data["AssetId"]}
            assetName={row.data["Name"]}
            assetType={row.data["AssetType"]}
            location={row.data["AssetLocation"]}
          />
        ),
        frozen: true,
        id: "AssetId",
        message: {
          defaultMessage: "Asset",
          id: "asset_query.grid.columns.asset"
        },
        weight: 3
      },
      {
        frozen: true,
        id: "AssetDescription",
        message: {
          defaultMessage: "Description",
          id: "asset_query.grid.columns.description"
        },
        weight: 3
      },
      {
        component: (value, row) => (
          <ColumnManufacturer
            manufacturer={row.data["Manufacturer"]}
            model={row.data["ManufacturerModel"]}
          />
        ),
        frozen: true,
        id: "Manufacturer",
        message: {
          defaultMessage: "Manufacturer / Model",
          id: "asset_query.grid.columns.manufacturer"
        },
        weight: 1.5
      },
      {
        frozen: true,
        id: "AssetOrganization",
        message: {
          defaultMessage: "Organization",
          id: "asset_query.grid.columns.organization"
        },
        weight: 2
      },
      {
        frozen: true,
        id: "Voltage",
        message: {
          defaultMessage: "Voltage",
          id: "asset_query.grid.columns.asset_voltage"
        },
        HeaderTooltip: () => (
          <FormattedMessage
            defaultMessage="Asset Voltage [kV]"
            id="asset_query.grid.columns.asset_voltage.tooltip"
          />
        )
      },
      {
        frozen: true,
        id: "AssetAge",
        message: {
          defaultMessage: "Age",
          id: "asset_query.grid.columns.asset_age"
        },
        HeaderTooltip: () => (
          <FormattedMessage
            defaultMessage="Asset Age [years]"
            id="asset_query.grid.columns.asset_age.tooltip"
          />
        )
      },
      {
        component: (value) => <ColumnMaintenancePriority value={value} />,
        defaultSortOrder: SortOrders.Desc,
        id: "MaintenancePriority",
        message: {
          defaultMessage: "MP Score",
          id: "asset_query.grid.columns.maintenance_priority"
        },
        HeaderTooltip: () => (
          <FormattedMessage
            defaultMessage="Maintenance Priority"
            id="asset_query.grid.columns.maintenance_priority.tooltip"
          />
        )
      },
      {
        component: (value, row) => (
          <ColumnReplacementScore
            isWarning={row.data["IsReplacementScoreWarning"]}
            replacementScore={row.data["ReplacementScore"]}
            assetType={row.data["AssetType"]}
            rank={row.data["Rank"]}
            total={row.data["Total"]}
          />
        ),
        id: "ReplacementScore",
        message: {
          defaultMessage: "RS Rank",
          id: "asset_query.grid.columns.replacement_score"
        },
        HeaderTooltip: () => (
          <FormattedMessage
            defaultMessage="Replacement Rank"
            id="asset_query.grid.columns.replacement_score.tooltip"
          />
        )
      },
      {
        id: "AssetImportance",
        message: {
          defaultMessage: "Importance",
          id: "asset_query.grid.columns.importance"
        }
      },
      {
        component: (value) => <ColumnCondition value={value} />,
        id: "DegradationScoreValue",
        message: {
          defaultMessage: "Condition",
          id: "asset_query.grid.columns.condition"
        }
      },
      {
        id: "Status",
        message: {
          defaultMessage: "Status",
          id: "asset_query.grid.columns.status"
        },
        weight: 1.5
      },
      {
        component: (value) => <ColumnRisk risk={value} />,
        id: "AssetRisk",
        message: { defaultMessage: "Risk", id: "asset_query.grid.columns.risk" }
      },
      {
        component: (value) => <ColumnLatestParameterDate date={value} />,
        id: "LatestParameterDate",
        message: {
          defaultMessage: "Latest reading",
          id: "asset_query.grid.columns.latest_parameter_date"
        }
      }
    ],
    []
  );

  const dataEndpoint: IDataEndpoint = useMemo(
    () => ({
      url: UrlService.getApiUrl(config.api.assetQuery.assetsByRange),
      type: "POST",
      content: {
        search: filters.search,
        filters: filters.selects,
        ranges: filters.ranges
      },
      onDataLoaded: (total: number) => {
        setTotalCount(total);
      }
    }),
    [filters]
  );

  const handleExportDataClick = useCallback(
    (columns: IColumn[]) => {
      const exportDataEndpoint = Object.assign({}, dataEndpoint, {
        url: UrlService.getApiUrl(config.api.assetQuery.assetsExcelExport)
      });
      DataService.exportData(
        exportDataEndpoint,
        columns,
        ExportTypes.Excel,
        ContentTypes.Assets,
        null,
        intl
      );
    },
    [dataEndpoint, intl]
  );

  const handleExportDataCsvClick = useCallback(
    (columns: IColumn[]) => {
      const exportDataEndpoint = Object.assign({}, dataEndpoint, {
        url: UrlService.getApiUrl(config.api.assetQuery.assetsCsvExport)
      });
      DataService.exportData(
        exportDataEndpoint,
        columns,
        ExportTypes.Csv,
        ContentTypes.Assets,
        null,
        intl
      );
    },
    [dataEndpoint, intl]
  );

  const handleRowClick = useCallback(
    (row: IRow) => {
      const searchParams = new SearchParams({ assetId: row.data["AssetId"] });

      navigate({
        pathname: routes.detailPage.pathname,
        search: searchParams.toString()
      });
    },
    [navigate]
  );

  return (
    <div className="grid-container data-grid__parent">
      <div className="data-grid__scroll">
        <Container size="lg">
          <div className="row header-row">
            <div className="header" data-qa="common-filter-header">
              <SectionName
                messageId="asset_query.grid.title"
                messageDefault="Assets / {count} items"
                messageValues={{
                  count: totalCount || 0
                }}
              />
              <StatusBar />
            </div>
            {isConfigurationVisible && (
              <div className="asset-buttons">
                <Button type="primary" onClick={handleNewAssetModalOpen}>
                  <Icon name="add" className="add-icon" />
                  {intl.formatMessage({
                    id: "asset_query.grid.new_asset",
                    defaultMessage: "New asset"
                  })}
                  <Icon name="expand_more" className="expand-more-icon" />
                </Button>
              </div>
            )}
          </div>
          <div className="row">
            <DataGrid
              columns={columns}
              dataEndpoint={dataEndpoint}
              multiColumnSorting={true}
              onRowClick={handleRowClick}
              exportToExcel={{
                disabled:
                  customerVisualizationsAssetGrid.data?.ExportToExcelRowLimit &&
                  customerVisualizationsAssetGrid.data?.ExportToExcelRowLimit <
                    totalCount,
                disabledMessage: customerVisualizationsAssetGrid.data
                  ?.ExportToExcelRowLimit
                  ? intl.formatMessage(
                      {
                        id: "asset_query.grid.export_to_excel.disabled_message",
                        defaultMessage:
                          "Number of selected assets is too big. Max number of assets which can be exported to excel is {maxNumberOfAssets}. Limit number of assets using filter panel to export data."
                      },
                      {
                        maxNumberOfAssets:
                          customerVisualizationsAssetGrid.data
                            ?.ExportToExcelRowLimit
                      }
                    )
                  : undefined,
                onClick: handleExportDataClick
              }}
              exportToCsv={{
                disabled: true,
                onClick: handleExportDataCsvClick
              }}
              actions={isConfigurationVisible && actions}
              getRowClassName={getRowClassName}
            />
          </div>
          <AssetModal
            assetId={assetIdForAssetModal}
            visible={isAssetModalVisible}
            onCancel={handleAssetModalCancel}
          />
        </Container>
      </div>
    </div>
  );
};

export default AssetQueryGrid;
