// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "reducers/Index";

export const formName = "Notifications_Form";

export enum NotificationsFormFields {
  degradesRed = "degradesRed",
  degradesYellow = "degradesYellow",
  improvesGreen = "improvesGreen",
  improvesYellow = "improvesYellow",
  organizations = "organizations",
  assetTypes = "assetTypes"
}

const getNotificationsFormSelector = createSelector(
  (state: IState) =>
    state.common.forms.has(formName) ? state.common.forms.get(formName) : null,
  form => form
);

export default getNotificationsFormSelector;
