// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getImprovedToYellowSelector = createSelector(
  (state: IState) => state.notifications.modelInput.data,
  (state: IState) => state.notifications.notificationsEnabled,
  (state: IState) => state.notifications.allDegradedChecked,
  (data, notificationsEnabled, allDegradedChecked) =>
    data && notificationsEnabled
      ? allDegradedChecked || data.ImprovedToYellow
      : false
);

export default getImprovedToYellowSelector;
