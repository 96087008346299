// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Statuses } from "core/data/models/Data";
import { loadReplacementPriorityScoreCalculationAction } from "features/detailpage/features/replacementscore/actions/ReplacementScoreActions";
import ReplacementPriority from "features/detailpage/features/replacementscore/components/replacementpriority/ReplacementPriority";
import useReplacementScoreReducer from "features/detailpage/features/replacementscore/hooks/useReplacementScoreReducer";
import { isEmpty } from "lodash";
import React from "react";
import { useAppDispatch } from "store";

interface IReplacementPriorityContainerProps {
  assetId: string;
  replacementScoreModelImplementationId: string;
}

const ReplacementPriorityContainer = ({
  assetId,
  replacementScoreModelImplementationId
}: IReplacementPriorityContainerProps) => {
  const dispatch = useAppDispatch();
  const { scoreCalculation } = useReplacementScoreReducer();

  React.useEffect(() => {
    if (
      scoreCalculation.status !== Statuses.Succeeded ||
      isEmpty(scoreCalculation.data)
    ) {
      dispatch(loadReplacementPriorityScoreCalculationAction(assetId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ReplacementPriority
      scoreCalculation={scoreCalculation}
      replacementScoreModelImplementationId={
        replacementScoreModelImplementationId
      }
    />
  );
};

export default ReplacementPriorityContainer;
