// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IRowData } from "common/datagrid/DataGrid";
import LifecycleStatus from "models/LifecycleStatus";
import { useCallback, useState } from "react";
import isExactLifecycleStatus from "utils/isExactLifecycleStatus";

const useAssetQueryGrid = () => {
  const [isAssetModalVisible, setIsAssetModalVisible] = useState(false);
  const [assetIdForAssetModal, setAssetIdForAssetModal] =
    useState<string>(null);

  const handleAssetModalOpen = useCallback((assetId: string) => {
    setAssetIdForAssetModal(assetId);
    setIsAssetModalVisible(true);
  }, []);

  const handleAssetModalCancel = useCallback(() => {
    setAssetIdForAssetModal(null);
    setIsAssetModalVisible(false);
  }, []);

  const handleNewAssetModalOpen = useCallback(() => {
    handleAssetModalOpen(null);
  }, [handleAssetModalOpen]);

  const getRowClassName = useCallback(
    (data: IRowData) => {
      return isExactLifecycleStatus(
        data["LifecycleStatus"],
        LifecycleStatus.Removed
      )
        ? "removed"
        : isExactLifecycleStatus(
            data["LifecycleStatus"],
            LifecycleStatus.InReserve
          )
        ? "in-reserve"
        : undefined;
    },

    []
  );

  return {
    handleAssetModalOpen,
    handleNewAssetModalOpen,
    handleAssetModalCancel,
    isAssetModalVisible,
    assetIdForAssetModal,
    getRowClassName
  };
};

export default useAssetQueryGrid;
