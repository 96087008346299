// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Reducer } from "redux/index";

import { Actions } from "core/app/actions/TranslationsActions";
import Data from "core/data/models/Data";
import { IAjaxAction } from "reducers/Index";

export interface IMessages {
  [key: string]: string;
}

export interface ITranslations {
  locales: string[];
  messages: IMessages;
}

export interface IState {
  translations: Data<ITranslations>;
}

const translationsReducer: Reducer<IState> = (
  state = {
    translations: new Data()
  },
  action: IAjaxAction<ITranslations>
) => {
  switch (action.type) {
    case Actions.Translations_SetTranslation:
      state = Object.assign({}, state, {
        translations: new Data(action.request, action.data)
      } as IState);
      return state;
    default:
      return state;
  }
};

export default translationsReducer;
