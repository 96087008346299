// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";

import { IRow } from "common/datagrid/DataGrid";

interface IProjectWebsiteColumnProps {
  value: any;
  row: IRow;
}

const ProjectWebsiteColumn = ({ value, row }: IProjectWebsiteColumnProps) => {
  return (
    <div className="grid-cell-content">
      {value && (
        <a
          href={row.data["projectWebsite"]}
          rel="noopener noreferrer"
          target="_blank"
        >
          {value}
        </a>
      )}
      {!value && null}
    </div>
  );
};

export default ProjectWebsiteColumn;
