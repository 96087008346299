// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";
import { FormattedMessage } from "react-intl";

import "./BatteryConductanceChartInfo.less";

const BatteryConductanceChartInfo = () => (
  <div className="battery-conductance-chart-info">
    <div className="values-info">
      <div>
        <span className="pilot-cell-indicator" />
        <FormattedMessage
          defaultMessage="Pilot Cell"
          id="detail_page.parameters.battery_conductance.chart_legend.pilot_cell"
        />
      </div>
      <div>
        <span className="warning-indicator" />
        <FormattedMessage
          defaultMessage="Warning"
          id="detail_page.parameters.battery_conductance.chart_legend.warning"
        />
      </div>
      <div>
        <span className="alert-indicator" />
        <FormattedMessage
          defaultMessage="Alert"
          id="detail_page.parameters.battery_conductance.chart_legend.alert"
        />
      </div>
      <div>
        <span className="drop-magnitude-indicator">
          <div className="arrow-down large" />
          <div className="arrow-down medium" />
          <div className="arrow-down small" />
        </span>
        <FormattedMessage
          defaultMessage="Drop Magnitude"
          id="detail_page.parameters.battery_conductance.chart_legend.drop_magnitude"
        />
      </div>
    </div>
    <div className="thresholds-info">
      <div className="bold">
        <FormattedMessage
          defaultMessage="Thresholds:"
          id="detail_page.parameters.battery_conductance.chart_legend.thresholds"
        />
      </div>
      <div>
        <span className="reference-level-indicator" />
        <FormattedMessage
          defaultMessage="Reference"
          id="detail_page.parameters.battery_conductance.chart_legend.thresholds.reference"
        />
      </div>
      <div>
        <span className="warning-level-indicator" />
        <FormattedMessage
          defaultMessage="Warning"
          id="detail_page.parameters.battery_conductance.chart_legend.thresholds.warning"
        />
      </div>
      <div>
        <span className="alert-level-indicator" />
        <FormattedMessage
          defaultMessage="Alert"
          id="detail_page.parameters.battery_conductance.chart_legend.thresholds.alert"
        />
      </div>
    </div>
  </div>
);

export default BatteryConductanceChartInfo;
