// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Option, Risk } from "@apm/widgets/build/widgets/AssetsBy";
import { isEmpty, isNil, snakeCase } from "lodash";
import IAssetRiskCountsTree from "models/IAssetRiskCountsTree";
import { IntlShape } from "react-intl";

const mapDistributionToRisk = (
  option: Option,
  assetRisks: IAssetRiskCountsTree[],
  intl: IntlShape
): Risk[] => {
  const formatUnknown = () =>
    intl.formatMessage({
      defaultMessage: "Unknown",
      id: `${snakeCase(option)}.unknown`
    });

  return assetRisks
    .map<Risk>((r) => ({
      name: r.GroupKey,
      displayName:
        isNil(r.GroupKey) || isEmpty(r.GroupKey)
          ? formatUnknown()
          : r.GroupName,
      low: r.LowRiskCount,
      medium: r.MediumRiskCount,
      high: r.HighRiskCount,
      unknown: r.UnknownRiskCount,
      isLeaf: !r.HasChild
    }))
    .sort((a, b) => {
      const aName = a.displayName || a.name || "";
      const bName = b.displayName || b.name || "";
      return aName.localeCompare(bName);
    });
};

export default mapDistributionToRisk;
