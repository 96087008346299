// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { FormattedMessage } from "react-intl";

import "./RecalculateButtonTooltip.less";

const RecalculateButtonTooltip = () => {
  return (
    <div className="recalculate-button-tooltip">
      <FormattedMessage
        id="model_trigger.blocked_calculate.tooltip"
        defaultMessage="Wait 30 seconds before you can request this calculation again."
      />
    </div>
  );
};

export default RecalculateButtonTooltip;
