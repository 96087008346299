// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IVersion } from "core/app/reducers/VersionReducer";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IAjaxAction } from "reducers/Index";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";

export enum Actions {
  Version_SetUiVersion = "Version_SetUiVersion"
}

const setUiVersionAction = (
  request: JQueryXHR,
  data?: IVersion
): IAjaxAction<IVersion> => {
  const action: IAjaxAction<IVersion> = {
    type: Actions.Version_SetUiVersion,
    request,
    data
  };
  return action;
};

export const loadVersion = (dispatch: AppDispatch) => {
  loadUiVersion(dispatch);
};

const loadUiVersion = (dispatch: AppDispatch) => {
  const url = UrlService.getUrl(config.app.versionFileUrl);
  const request = EndpointService.getJson<IVersion>(
    url,
    (request, data) => {
      dispatch(setUiVersionAction(request, data));
    },
    (request) => {
      dispatch(setUiVersionAction(request));
    }
  );

  dispatch(setUiVersionAction(request));
};
