// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";
import { useAppNavigate } from "core/app/components/RouterProvider";
import { useCallback } from "react";
import { useLocation } from "react-router";

const useUrlSearchParams = () => {
  const navigate = useAppNavigate();
  const location = useLocation();

  const setUrlParam = useCallback(
    (key: string, value: string | null | undefined) => {
      const params = new SearchParams(location.search);

      if (value) {
        params.set(key, value);
      } else {
        params.delete(key);
      }

      navigate(
        {
          search: params.toString()
        },
        { replace: true }
      );
    },
    [navigate, location.search]
  );

  const getUrlParam = useCallback(
    (key: string) => {
      const params = new SearchParams(location.search);
      return params.get(key);
    },
    [location.search]
  );

  return {
    setUrlParam,
    getUrlParam
  };
};

export default useUrlSearchParams;
