// Copyright 2016-2023 Hitachi Energy. All rights reserved.

const parseUrl = (url: string) => {
  const i = url.indexOf("?");
  const hasSearch = i >= 0;
  const pathname = hasSearch ? url.substring(0, i) : url;
  const search = hasSearch ? url.substring(i + 1) : "";

  return {
    pathname,
    search
  };
};

export default parseUrl;
