// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";

import Processing from "components/common/Processing";

const ActionsProcessing = () => (
  <div className="slide-panel-content processing">
    <Processing />
  </div>
);

export default ActionsProcessing;
