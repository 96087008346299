// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Col, Row } from "antd";
import { connect } from "react-redux";

import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import getAssetDetailsSelector from "features/detailpage/selectors/getAssetDetailsSelector";
import IAssetDetails from "models/IAssetDetails";
import { IState } from "reducers/Index";
import AssetRibbonContext from "../contexts/AssetRibbonContext";
import AssetRibbonSucceded from "./AssetRibbonSucceded";

import "./AssetRibbon.less";

interface IAssetRibbonData {
  assetDetails: Data<IAssetDetails>;
}

interface IAssetRibbonOwnProps {
  assetId: string;
  sidePanelExpanded?: boolean;
}

type AssetRibbonProps = IAssetRibbonData & IAssetRibbonOwnProps;

const AssetRibbon = ({
  assetId,
  assetDetails,
  sidePanelExpanded
}: AssetRibbonProps) => {
  return (
    <AssetRibbonContext.Provider
      value={{
        assetId,
        sidePanelExpanded
      }}
    >
      <Row className="asset-ribbon" data-qa="detail-page-header">
        {assetDetails?.status === Statuses.Loading && (
          <Col className="justify-align-center">
            <Processing className="spinner small dark" />
          </Col>
        )}
        {assetDetails?.status === Statuses.Succeeded && (
          <AssetRibbonSucceded assetDetails={assetDetails?.data} />
        )}
        {assetDetails?.status === Statuses.Failed && (
          <Col className="justify-align-center">
            <WidgetErrorMessage />
          </Col>
        )}
        {assetDetails?.status === Statuses.NotFound && (
          <Col className="justify-align-center">
            <WidgetNoDataMessage />
          </Col>
        )}
      </Row>
    </AssetRibbonContext.Provider>
  );
};

const mapStateToProps = (
  state: IState,
  { assetId }: IAssetRibbonOwnProps
): IAssetRibbonData => ({
  assetDetails: getAssetDetailsSelector(state)(assetId)
});

export default connect(mapStateToProps)(AssetRibbon);
