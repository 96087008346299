// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import FilterBar, { SelectedFilters } from "common/FilterBar";
import { useState } from "react";
import "./AssetQuery.less";
import AssetQueryGrid from "./AssetQueryGrid";

const AssetQuery = () => {
  const [filters, setFilters] = useState<SelectedFilters>();
  const handleFilter = (filters: SelectedFilters) => {
    setFilters(filters);
  };

  return (
    <div className="asset-query">
      <FilterBar
        onFilter={handleFilter}
        namespace="assets"
        useSearch
        useAssetOrganizationWithRegionAndLocation
        useAssetCompanyHierarchy
        useAssetTypeWithSubType
        useAssetImportance
        useAssetImportanceRange
        useAssetRisk
        useAssetManufacturerWithModel
        useAssetVoltage
        useAssetAge
        useAssetStatus
        useDegradationScoreValueRange
      >
        {filters && <AssetQueryGrid filters={filters} />}
      </FilterBar>
    </div>
  );
};

export default AssetQuery;
