// Copyright 2016-2023 Hitachi Energy. All rights reserved.

/* eslint-disable jsx-a11y/anchor-is-valid */
import SearchParams from "@pg/common/build/models/SearchParams";
import { routes } from "core/app/components/AppRoutes";
import { useAppNavigate } from "core/app/components/RouterProvider";
import { FormattedMessage } from "react-intl";
import "./Asset.less";

interface IAssetProps {
  assetId: string;
  assetName: string;
  assetType: string | undefined;
  location: string | undefined;
}

const Asset = (props: IAssetProps) => {
  const { assetId, assetName, assetType, location } = props;
  const showLongMessage = assetType !== undefined && location !== undefined;
  const showShortMessage = !showLongMessage;
  const navigate = useAppNavigate();

  const searchParams = new SearchParams({ assetId });

  return (
    <div className="column asset">
      <a
        className="anchor id"
        onClick={() =>
          navigate({
            pathname: routes.detailPage.pathname,
            search: searchParams.toString()
          })
        }
      >
        {assetId}
        {assetName && " - "}
        {assetName}
      </a>
      <div className="details">
        {showLongMessage && (
          <FormattedMessage
            defaultMessage="{location} / {assetType}"
            id="issues_page.grid.columns.asset.location_asset_type"
            values={{
              assetType: assetType,
              location: location
            }}
          />
        )}
        {showShortMessage && (
          <FormattedMessage
            defaultMessage="{value}"
            id="issues_page.grid.columns.asset.location_asset_type_short"
            values={{
              value: assetType || location
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Asset;
