// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { ISwitchItem } from "common/form/components/Switch";
import getTenantSelector from "core/selectors/getTenantSelector";
import IAssetNameplate from "models/IAssetNameplate";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useAppSelector } from "store";
import useLoadClusters from "../../../hooks/useLoadClusters";
import useRiskMatrixConfiguration from "../../../hooks/useRiskMatrixConfiguration";
import RiskMatrixScope from "../../../models/RiskMatrixScope";
import getRiskMatrixConfigurationSelector from "../../../selectors/getRiskMatrixConfigurationSelector";
import getRiskMatrixLevelConfigurationSelector from "../../../selectors/getRiskMatrixLevelConfigurationSelector";

const fleetId = "fleet";
const locationId = "location";

const useRiskMatrixToggleButtons = (assetNameplate: IAssetNameplate) => {
  const tenant = useAppSelector(getTenantSelector);
  const riskMatrixConfiguration = useAppSelector(
    getRiskMatrixConfigurationSelector
  );
  const riskMatrixLevelConfiguration = useAppSelector(
    getRiskMatrixLevelConfigurationSelector
  );

  const isShown = useMemo(
    () => riskMatrixLevelConfiguration && riskMatrixLevelConfiguration.IsShown,
    [riskMatrixLevelConfiguration]
  );

  const loadClusters = useLoadClusters();
  const { setRiskMatrixConfigurationToLocalStorage, getDefaultScope } =
    useRiskMatrixConfiguration();

  const items: ISwitchItem[] = useMemo(
    () => [
      {
        id: fleetId,
        message: {
          id: "detail_page.widgets.risk_matrix_widget.fleet",
          defaultMessage: "Fleet"
        }
      },
      {
        id: locationId,
        message: {
          id: "detail_page.widgets.risk_matrix_widget.location",
          defaultMessage: "Location"
        }
      }
    ],
    []
  );

  const defaultSwitchSelectedId = useMemo(
    () => getDefaultScope(riskMatrixConfiguration.data, tenant),
    [getDefaultScope, riskMatrixConfiguration.data, tenant]
  );

  const switchCurrentId = useRef<string>();
  useEffect(() => {
    switchCurrentId.current = defaultSwitchSelectedId;
  }, [defaultSwitchSelectedId]);

  const updateRiskMatrixConfiguration = useCallback(
    (scope: RiskMatrixScope) => {
      setRiskMatrixConfigurationToLocalStorage(
        riskMatrixConfiguration.data,
        scope,
        tenant
      );
      if (assetNameplate) {
        loadClusters(
          scope,
          assetNameplate.ModelId,
          assetNameplate.Organization,
          assetNameplate.Region,
          assetNameplate.Location
        );
      }
    },
    [
      assetNameplate,
      loadClusters,
      riskMatrixConfiguration.data,
      setRiskMatrixConfigurationToLocalStorage,
      tenant
    ]
  );

  const handleSwitchChange = useCallback(
    (id: string) => {
      if (id === fleetId && switchCurrentId.current !== id) {
        switchCurrentId.current = id;
        updateRiskMatrixConfiguration("Fleet");
      }
      if (id === locationId && switchCurrentId.current !== id) {
        switchCurrentId.current = id;
        updateRiskMatrixConfiguration("Location");
      }
    },
    [updateRiskMatrixConfiguration]
  );

  return { items, handleSwitchChange, defaultSwitchSelectedId, isShown };
};

export default useRiskMatrixToggleButtons;
