// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import CircleVisualization from "@apm/widgets/build/widgets/CircleVisualization";
import LineVisualization from "@apm/widgets/build/widgets/LineVisualization";
import { Typography } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import {
  spacingXLarge,
  spacingXXLarge,
  spacingXXXLarge
} from "styles/StyleVariables";
import useCircleVisualization from "../hooks/useCircleVisualization";
import useLineVisualization from "../hooks/useLineVisualizations";
import ICircleChartSeries from "../models/ICircleChartSeries";
import IDCChart from "../models/IDCChart";
import { formatTwoDigitDateTime } from "common/DateTime/utils/dateFormatters";

const { Title } = Typography;

interface IDCTabChartsProps {
  assetId: string;
  className?: string;
  measuredApparentCapacitanceChart: IDCChart | undefined;
  oilTemperatureChart: IDCChart | undefined;
  oltcOilLevelAndTemperatureChart: IDCChart | undefined;
  rollingAverageOfVectorLengthChart: IDCChart | undefined;
  coreEarthingCurrent: IDCChart | undefined;
  measuredMoistureChart: IDCChart | undefined;
  sf6PressureChart: IDCChart | undefined;
  sf6PressureVectorLengthChart: IDCChart | undefined;
  circleTemperatureChart: ICircleChartSeries;
  circleBushingChart: ICircleChartSeries;
  circleOltCoilLevelChart: ICircleChartSeries;
  circleCoreEarthingCurrentChart: ICircleChartSeries;
  moistureRollingAverage: ICircleChartSeries;
  sf6PressureRollingAverageChart: ICircleChartSeries;
}

const DCTabCharts = ({
  assetId,
  className,
  measuredApparentCapacitanceChart,
  oltcOilLevelAndTemperatureChart,
  oilTemperatureChart,
  rollingAverageOfVectorLengthChart,
  coreEarthingCurrent,
  measuredMoistureChart,
  sf6PressureChart,
  sf6PressureVectorLengthChart,
  circleTemperatureChart,
  circleBushingChart,
  circleOltCoilLevelChart,
  circleCoreEarthingCurrentChart,
  moistureRollingAverage,
  sf6PressureRollingAverageChart
}: IDCTabChartsProps) => {
  const intl = useIntl();

  const {
    measuredApparentCapacitanceTrend,
    oltcOilLevelAndTemperatureTrend,
    oilTemperatureTrend,
    rollingAverageOfVectorLengthTrend,
    coreEarthingCurrentTrend,
    measuredMoistureTrend,
    sf6PressureTrend,
    sf6PressureVectorLengthTrend,
    translations
  } = useLineVisualization(
    assetId,
    measuredApparentCapacitanceChart,
    oltcOilLevelAndTemperatureChart,
    oilTemperatureChart,
    rollingAverageOfVectorLengthChart,
    coreEarthingCurrent,
    measuredMoistureChart,
    sf6PressureChart,
    sf6PressureVectorLengthChart
  );

  const {
    circleTemperatureData,
    circleBushingData,
    circleOltCoilLevelData,
    circleCoreEarthingCurrentData,
    moistureRollingAverageData,
    sf6PressureRollingAverageData,
    circleTranslations
  } = useCircleVisualization(
    circleTemperatureChart,
    circleBushingChart,
    circleOltCoilLevelChart,
    circleCoreEarthingCurrentChart,
    moistureRollingAverage,
    sf6PressureRollingAverageChart
  );

  return (
    <div className={className}>
      <div>
        <div>
          <Title level={3}>
            <FormattedMessage
              defaultMessage="Bushing Capacitance"
              id="detail_page.dc_tab.dc_tab_charts.bushing_capacitance"
            />
          </Title>
        </div>
        <div className="charts-row">
          <div>
            <CircleVisualization
              chartSize={540}
              trendData={circleBushingData}
              showSwitchLabel
              showWidgetCard
              translations={{
                title: intl.formatMessage({
                  defaultMessage: "Capacitance rolling average",
                  id: "detail_page.dc_tab.dc_tab_charts.circle.capacitance_rolling_average"
                }),
                bushing: intl.formatMessage({
                  defaultMessage: "Bushing",
                  id: "detail_page.dc_tab.dc_tab_charts.circle.bushing"
                }),
                ...circleTranslations
              }}
            />
          </div>
          <div className="line-chart">
            <LineVisualization
              height={583}
              chart={{
                name: "MeasuredApparentCapacitance",
                displayName: intl.formatMessage({
                  defaultMessage: "Measured Apparent Capacitance",
                  id: "detail_page.dc_tab.dc_tab_charts.measured_apparent_capacitance"
                }),
                optionsLabel: intl.formatMessage({
                  defaultMessage: "Bushing",
                  id: "detail_page.dc_tab.dc_tab_charts.bushing"
                }),
                options: measuredApparentCapacitanceTrend
              }}
              formatXTick={(date) => intl.formatDate(date)}
              formatTooltipTitle={(date) => formatTwoDigitDateTime(intl, date)}
              translations={translations}
              showWidgetCard
            />
          </div>
        </div>
      </div>
      <div className="charts-row-title">
        <div>
          <Title level={3}>
            <FormattedMessage
              defaultMessage="Temperature Supervision"
              id="detail_page.dc_tab.dc_tab_charts.temperature_supervision"
            />
          </Title>
        </div>
        <div className="charts-row">
          <div>
            <CircleVisualization
              chartSize={540}
              trendData={circleTemperatureData}
              showWidgetCard
              translations={{
                title: intl.formatMessage({
                  defaultMessage: "Average Temperature Rolling",
                  id: "detail_page.dc_tab.dc_tab_charts.circle.average_temperature_rolling"
                }),
                bushing: intl.formatMessage({
                  defaultMessage: "Bushing",
                  id: "detail_page.dc_tab.dc_tab_charts.circle.bushing"
                }),
                ...circleTranslations
              }}
            />
          </div>
          <div className="line-chart">
            <LineVisualization
              height={583}
              showWidgetCard
              chart={{
                name: "OilTemperature",
                displayName: intl.formatMessage({
                  defaultMessage: "Oil Temperature",
                  id: "detail_page.dc_tab.dc_tab_charts.oil_temperature"
                }),
                options: oilTemperatureTrend
              }}
              formatXTick={(date) => intl.formatDate(date)}
              formatTooltipTitle={(date) => formatTwoDigitDateTime(intl, date)}
              translations={translations}
            />
          </div>
        </div>
      </div>
      <div className="charts-row-title">
        <div>
          <Title level={3}>
            <FormattedMessage
              defaultMessage="OLTC Levels"
              id="detail_page.dc_tab.dc_tab_charts.oltc_levels"
            />
          </Title>
        </div>
        <div className="oil-temperature-line-chart">
          <LineVisualization
            height={583}
            showWidgetCard
            chart={{
              name: "OLTCOilLevelAndTemperature",
              displayName: intl.formatMessage({
                defaultMessage: "OLTC Oil Level and Temperature",
                id: "detail_page.dc_tab.dc_tab_charts.oltc_oil_level_and_temperature"
              }),
              optionsLabel: intl.formatMessage({
                defaultMessage: "Reference",
                id: "detail_page.dc_tab.dc_tab_charts.reference"
              }),
              options: oltcOilLevelAndTemperatureTrend
            }}
            formatXTick={(date) => intl.formatDate(date)}
            formatTooltipTitle={(date) => formatTwoDigitDateTime(intl, date)}
            translations={translations}
          />
        </div>
        <div className="charts-row">
          <div>
            <CircleVisualization
              chartSize={540}
              trendData={circleOltCoilLevelData}
              showWidgetCard
              translations={{
                title: intl.formatMessage({
                  defaultMessage: "Rolling Average Vector",
                  id: "detail_page.dc_tab.dc_tab_charts.circle.rolling_average_vector"
                }),
                bushing: intl.formatMessage({
                  defaultMessage: "Bushing",
                  id: "detail_page.dc_tab.dc_tab_charts.circle.bushing"
                }),
                ...circleTranslations
              }}
            />
          </div>
          <div className="line-chart">
            <LineVisualization
              height={583}
              showWidgetCard
              chart={{
                name: "RollingAverageOfVectorLength",
                displayName: intl.formatMessage({
                  defaultMessage: "Rolling Average of Vector Length",
                  id: "detail_page.dc_tab.dc_tab_charts.rolling_average_of_vector_length"
                }),
                options: rollingAverageOfVectorLengthTrend
              }}
              formatXTick={(date) => intl.formatDate(date)}
              formatTooltipTitle={(date) => formatTwoDigitDateTime(intl, date)}
              translations={translations}
            />
          </div>
        </div>
        <div className="charts-row-title">
          <div>
            <Title level={3}>
              <FormattedMessage
                defaultMessage="Core Earthing Diagnostics"
                id="detail_page.dc_tab.dc_tab_charts.core_earthing_diagnostics"
              />
            </Title>
          </div>
          <div className="charts-row">
            <div>
              <CircleVisualization
                chartSize={540}
                trendData={circleCoreEarthingCurrentData}
                showWidgetCard
                translations={{
                  title: intl.formatMessage({
                    defaultMessage: "Core Earthing Current - Rolling Average",
                    id: "detail_page.dc_tab.dc_tab_charts.circle.core_earthing_current"
                  }),
                  ...circleTranslations
                }}
              />
            </div>
            <div className="line-chart">
              <LineVisualization
                height={583}
                showWidgetCard
                chart={{
                  name: "CoreEarthingCurrent",
                  displayName: intl.formatMessage({
                    defaultMessage: "Core Earthing Current",
                    id: "detail_page.dc_tab.dc_tab_charts.core_earthing_current"
                  }),
                  options: coreEarthingCurrentTrend
                }}
                formatXTick={(date) => intl.formatDate(date)}
                formatTooltipTitle={(date) =>
                  formatTwoDigitDateTime(intl, date)
                }
                translations={translations}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="charts-row-title">
        <div>
          <Title level={3}>
            <FormattedMessage
              defaultMessage="Tap-Changer moisture content (non-vacuum)"
              id="detail_page.dc_tab.dc_tab_charts.tap_changer_moisture_content_non_vacuum"
            />
          </Title>
        </div>
        <div className="charts-row">
          <div>
            <CircleVisualization
              chartSize={540}
              trendData={moistureRollingAverageData}
              showWidgetCard
              translations={{
                title: intl.formatMessage({
                  defaultMessage: "Moisture Rolling Average",
                  id: "detail_page.dc_tab.dc_tab_charts.moisture_rolling_average"
                }),
                ...circleTranslations
              }}
            />
          </div>
          <div className="line-chart">
            <LineVisualization
              height={583}
              showWidgetCard
              chart={{
                name: "TapChangerMoistureNonVacuum",
                displayName: intl.formatMessage({
                  defaultMessage: "Measured Moisture in OLTC",
                  id: "detail_page.dc_tab.dc_tab_charts.measured_moisture_in_oltc"
                }),
                options: measuredMoistureTrend
              }}
              formatXTick={(date) => intl.formatDate(date)}
              formatTooltipTitle={(date) => formatTwoDigitDateTime(intl, date)}
              translations={translations}
            />
          </div>
        </div>
        <div className="charts-row-title">
          <div>
            <Title level={3}>
              <FormattedMessage
                defaultMessage="SF6 Pressure Supervision"
                id="detail_page.dc_tab.dc_tab_charts.sf6_pressure_supervision"
              />
            </Title>
          </div>
          <div className="sf6-bushing-pressure-line-chart">
            <div className="line-chart">
              <LineVisualization
                height={583}
                showWidgetCard
                chart={{
                  name: "SF6Pressure",
                  displayName: intl.formatMessage({
                    defaultMessage: "SF6 Bushing Pressure",
                    id: "detail_page.dc_tab.dc_tab_charts.sf6_bushing_pressure"
                  }),
                  options: sf6PressureTrend
                }}
                formatXTick={(date) => intl.formatDate(date)}
                formatTooltipTitle={(date) =>
                  formatTwoDigitDateTime(intl, date)
                }
                translations={translations}
              />
            </div>
          </div>
          <div className="charts-row">
            <div>
              <CircleVisualization
                chartSize={540}
                trendData={sf6PressureRollingAverageData}
                showWidgetCard
                translations={{
                  title: intl.formatMessage({
                    defaultMessage: "SF6 Bushing Pressure - Rolling Average",
                    id: "detail_page.dc_tab.dc_tab_charts.sf6_bushing_pressure_rolling_average"
                  }),
                  ...circleTranslations
                }}
              />
            </div>
            <div className="line-chart">
              <LineVisualization
                height={583}
                showWidgetCard
                chart={{
                  name: "SF6PressureVectorLength",
                  displayName: intl.formatMessage({
                    defaultMessage: "Rolling Average Vector Length",
                    id: "detail_page.dc_tab.dc_tab_charts.rolling_average_of_vector_length"
                  }),
                  options: sf6PressureVectorLengthTrend
                }}
                formatXTick={(date) => intl.formatDate(date)}
                formatTooltipTitle={(date) =>
                  formatTwoDigitDateTime(intl, date)
                }
                translations={translations}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default styled(DCTabCharts)`
  padding: ${spacingXLarge};

  .ant-card-body {
    min-height: 723px;
  }

  .charts-row-title {
    margin-top: ${spacingXXXLarge};
  }

  .charts-row {
    display: flex;
    width: 100%;

    .line-chart {
      flex-grow: 1;
      margin-left: ${spacingXLarge};
    }
  }

  .oil-temperature-line-chart {
    margin-bottom: ${spacingXXLarge};
  }
`;
