// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { flattenDeep, isNil, isEmpty } from "lodash";

import IDielectricData from "../../../models/IDielectricData";

const isCompensatedGasPressureNull = (data: IDielectricData) => {
  let isCompensatedGasPressureContainsNull: boolean;

  if (data) {
    const {
      CompensatedGasPressure: { DataSeries }
    } = data;

    if (isEmpty(DataSeries) || isNil(DataSeries)) {
      isCompensatedGasPressureContainsNull = true;
    } else {
      isCompensatedGasPressureContainsNull =
        data &&
        flattenDeep(
          Object.values(DataSeries).map((item) => Object.values(item))
        ).every((item) => isNil(item));
    }
  }

  return isCompensatedGasPressureContainsNull;
};

export default isCompensatedGasPressureNull;
