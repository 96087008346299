// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { ReactNode } from "react";
import { RouterContextProvider } from "../contexts/RouterContext";

interface IRouterProps {
  children?: ReactNode;
}

const RouterProvider = ({ children }: IRouterProps) => {
  return <RouterContextProvider>{children}</RouterContextProvider>;
};

export default RouterProvider;
