// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import getScoreCalculationSelector from "features/detailpage/features/replacementscore/selectors/getScoreCalculationSelector";
import { useAppSelector } from "store";

const useReplacementScoreReducer = () => {
  const scoreCalculation = useAppSelector(getScoreCalculationSelector);

  return {
    scoreCalculation
  };
};

export default useReplacementScoreReducer;
