// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { loadOpenIssuesInfo } from "../actions/OpenIssuesActions";
import getOpenIssuesSelector from "../selectors/getOpenIssuesSelector";

const useOpenIssues = () => {
  const dispatch = useAppDispatch();
  const openIssues = useAppSelector(getOpenIssuesSelector);

  useEffect(() => {
    loadOpenIssuesInfo(dispatch);
  }, [dispatch]);

  return { openIssues };
};

export default useOpenIssues;
