// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { ITrendData } from "@apm/widgets/build/widgets/CircleVisualization";
import { isNil } from "lodash";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import ICircleChartSeries from "../models/ICircleChartSeries";

const useCircleVisualization = (
  circleTemperatureChart: ICircleChartSeries,
  circleBushingChart: ICircleChartSeries,
  circleOltCoilLevelChart: ICircleChartSeries,
  circleCoreEarthingCurrentChart: ICircleChartSeries,
  moistureRollingAverage: ICircleChartSeries,
  sf6PressureRollingAverage: ICircleChartSeries
) => {
  const intl = useIntl();

  const getCircleChartData = useCallback(
    (data: ICircleChartSeries, id: string, title: string) => {
      const circleData = data[id];

      const criticalThreshold =
        circleData.RedThresholdCenter && circleData.RedThresholdSize
          ? {
              x: circleData.RedThresholdCenter.X,
              y: circleData.RedThresholdCenter.Y,
              r: circleData.RedThresholdSize
            }
          : null;

      const alertThreshold =
        circleData.YellowThresholdCenter && circleData.YellowThresholdSize
          ? {
              x: circleData.YellowThresholdCenter.X,
              y: circleData.YellowThresholdCenter.Y,
              r: circleData.YellowThresholdSize
            }
          : null;

      const mostRecentReading = circleData.LastPoint
        ? {
            x: circleData.LastPoint.X,
            y: circleData.LastPoint.Y,
            date: circleData.LastPoint.Date
          }
        : null;

      const leastRecentReading = circleData.StartingPoint
        ? {
            x: circleData.StartingPoint.X,
            y: circleData.StartingPoint.Y,
            date: circleData.StartingPoint.Date
          }
        : null;

      return {
        trendName: title,
        id: id,
        trendData: {
          criticalThreshold,
          alertThreshold,
          mostRecentReading,
          leastRecentReading,
          readings: !isNil(circleData.Readings) && [
            ...circleData.Readings.map((e) => {
              return { x: e.X, y: e.Y, date: e.Date };
            })
          ]
        }
      };
    },
    []
  );

  const circleTemperatureData: ITrendData[] = useMemo(
    () => [
      getCircleChartData(
        circleTemperatureChart,
        "average",
        intl.formatMessage({
          defaultMessage: "Average",
          id: "detail_page.dc_tab.dc_tab_charts.circle.average"
        })
      ),
      getCircleChartData(
        circleTemperatureChart,
        "topToBottom",
        intl.formatMessage({
          defaultMessage: "Top-bottom",
          id: "detail_page.dc_tab.dc_tab_charts.circle.top_bottom"
        })
      )
    ],
    [circleTemperatureChart, getCircleChartData, intl]
  );

  const circleBushingData: ITrendData[] = useMemo(
    () => [
      getCircleChartData(
        circleBushingChart,
        "ac1",
        intl.formatMessage({
          defaultMessage: "AC1",
          id: "detail_page.dc_tab.dc_tab_charts.circle.ac1"
        })
      ),
      getCircleChartData(
        circleBushingChart,
        "ac2",
        intl.formatMessage({
          defaultMessage: "AC2",
          id: "detail_page.dc_tab.dc_tab_charts.circle.ac2"
        })
      ),
      getCircleChartData(
        circleBushingChart,
        "dc1",
        intl.formatMessage({
          defaultMessage: "DC1",
          id: "detail_page.dc_tab.dc_tab_charts.circle.dc1"
        })
      ),
      getCircleChartData(
        circleBushingChart,
        "dc2",
        intl.formatMessage({
          defaultMessage: "DC2",
          id: "detail_page.dc_tab.dc_tab_charts.circle.dc2"
        })
      )
    ],
    [circleBushingChart, getCircleChartData, intl]
  );

  const circleOltCoilLevelData: ITrendData[] = useMemo(
    () =>
      Object.keys(circleOltCoilLevelChart).map((s) => {
        return getCircleChartData(circleOltCoilLevelChart, s, s);
      }),
    [circleOltCoilLevelChart, getCircleChartData]
  );

  const circleCoreEarthingCurrentData = useMemo(
    () =>
      Object.keys(circleCoreEarthingCurrentChart).map((s) => {
        return getCircleChartData(circleCoreEarthingCurrentChart, s, s);
      }),
    [circleCoreEarthingCurrentChart, getCircleChartData]
  );

  const moistureRollingAverageData: ITrendData[] = useMemo(
    () => [
      getCircleChartData(
        moistureRollingAverage,
        "OLTC1",
        intl.formatMessage({
          defaultMessage: "OLTC1",
          id: "detail_page.dc_tab.dc_tab_charts.circle.oltc1"
        })
      ),
      getCircleChartData(
        moistureRollingAverage,
        "OLTC2",
        intl.formatMessage({
          defaultMessage: "OLTC2",
          id: "detail_page.dc_tab.dc_tab_charts.circle.oltc2"
        })
      ),
      getCircleChartData(
        moistureRollingAverage,
        "OLTC3",
        intl.formatMessage({
          defaultMessage: "OLTC3",
          id: "detail_page.dc_tab.dc_tab_charts.circle.oltc3"
        })
      ),
      getCircleChartData(
        moistureRollingAverage,
        "OLTC4",
        intl.formatMessage({
          defaultMessage: "OLTC4",
          id: "detail_page.dc_tab.dc_tab_charts.circle.oltc4"
        })
      )
    ],
    [getCircleChartData, moistureRollingAverage, intl]
  );

  const sf6PressureRollingAverageData: ITrendData[] = useMemo(
    () => [
      getCircleChartData(
        sf6PressureRollingAverage,
        "AC1",
        intl.formatMessage({
          defaultMessage: "AC1",
          id: "detail_page.dc_tab.dc_tab_charts.circle.ac1"
        })
      ),
      getCircleChartData(
        sf6PressureRollingAverage,
        "AC2",
        intl.formatMessage({
          defaultMessage: "AC2",
          id: "detail_page.dc_tab.dc_tab_charts.circle.ac2"
        })
      ),
      getCircleChartData(
        sf6PressureRollingAverage,
        "DC1",
        intl.formatMessage({
          defaultMessage: "DC1",
          id: "detail_page.dc_tab.dc_tab_charts.circle.dc1"
        })
      ),
      getCircleChartData(
        sf6PressureRollingAverage,
        "DC2",
        intl.formatMessage({
          defaultMessage: "DC2",
          id: "detail_page.dc_tab.dc_tab_charts.circle.dc2"
        })
      )
    ],
    [getCircleChartData, sf6PressureRollingAverage, intl]
  );

  const circleTranslations = useMemo(
    () => ({
      mostRecentReading: intl.formatMessage({
        defaultMessage: "Most recent reading",
        id: "detail_page.dc_tab.dc_tab_charts.circle.most_recent_reading"
      }),
      leastRecentReading: intl.formatMessage({
        defaultMessage: "Least recent reading",
        id: "detail_page.dc_tab.dc_tab_charts.circle.least_recent_reading"
      }),
      critical: intl.formatMessage({
        defaultMessage: "Critical",
        id: "detail_page.dc_tab.dc_tab_charts.circle.critical"
      }),
      alert: intl.formatMessage({
        defaultMessage: "Alert",
        id: "detail_page.dc_tab.dc_tab_charts.circle.alert"
      }),
      xLabel: intl.formatMessage({
        defaultMessage: "x",
        id: "detail_page.dc_tab.dc_tab_charts.circle.x_label"
      }),
      yLabel: intl.formatMessage({
        defaultMessage: "y",
        id: "detail_page.dc_tab.dc_tab_charts.circle.y_label"
      }),
      noData: intl.formatMessage({
        defaultMessage: "No data available",
        id: "global.chart.no_data_available"
      })
    }),
    [intl]
  );

  return {
    circleTemperatureData,
    circleBushingData,
    circleOltCoilLevelData,
    circleCoreEarthingCurrentData,
    circleTranslations,
    moistureRollingAverageData,
    sf6PressureRollingAverageData
  };
};

export default useCircleVisualization;
