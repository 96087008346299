// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import IParameterValueOptions from "@apm/widgets/build/components/Parameters/models/IParameterValueOptions";
import { isNil } from "lodash";
import IParameterOptions from "../models/IParameterOptions";

const mapToParameterOptions = (
  options: IParameterOptions | undefined
): IParameterValueOptions => {
  if (isNil(options)) return undefined;

  return {
    auxFlag: options.AuxFlag,
    defined: options.Defined,
    enabled: options.Enabled,
    missingData: options.MissingData,
    percentage: options.Percentage
  };
};

export default mapToParameterOptions;
