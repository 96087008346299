// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";
import { IState } from "reducers/Index";

import NotificationsService from "features/notifications/services/NotificationsService";

const getAssetTypesOptionsSelector = createSelector(
  (state: IState) => state.notifications.modelInput.data,
  data =>
    data && data.AssetTypes
      ? NotificationsService.convertITableItemsToITreeNodes(data.AssetTypes)
      : []
);

export default getAssetTypesOptionsSelector;
