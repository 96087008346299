// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { max, isEmpty } from "lodash";

import IAssetRiskConfigurationValue from "../models/IAssetRiskConfigurationValue";
import IAssetPoint from "../models/IAssetPoint";
import IDirectionalLine from "../models/IDirectionalLine";
import IPointBase from "../models/IPointBase";
import TwoPoint from "../models/TwoPoint";
import Point from "../models/Point";
import StackedPoint from "../models/StackedPoint";
import ICluster from "../models/ICluster";
import IPrognosticPoint from "../models/IPrognosticPoint";

export interface IGetRiskAreaOptions {
  thresholds: IAssetRiskConfigurationValue;
  currentAsset?: IAssetPoint;
  otherAssets?: IAssetPoint[];
  clusters?: ICluster[];
  prognosticPoints?: IPrognosticPoint[];
  showLines?: boolean;
}

const getRiskAreas = ({
  thresholds,
  currentAsset,
  otherAssets,
  clusters,
  prognosticPoints,
  showLines
}: IGetRiskAreaOptions) => {
  const isAny =
    !isEmpty(currentAsset) ||
    !isEmpty(otherAssets) ||
    !isEmpty(thresholds) ||
    !isEmpty(thresholds.LowToMediumRiskLineThreshold) ||
    !isEmpty(thresholds.MediumToHighRiskLineThreshold);
  let yTicks: number[] = [0.0, 100.0];
  let xTicks: number[] = [0.0, 100.0];
  let crucialPoints: TwoPoint[] = [];
  let lowToMediumLine: Point[] = [];
  let mediumToHighLine: Point[] = [];
  let stackedValues: StackedPoint[] = [];
  let regionCrucialPoints: TwoPoint[] = [];
  let tp: TwoPoint = null;
  let p: Point = null;
  let maxScore = 1.0;

  maxScore = max([
    currentAsset ? currentAsset.Score : 1.0,
    ...Array.from(otherAssets ? otherAssets.map((x) => x.Score) : []),
    ...Array.from(clusters ? clusters.map((x) => x.Score) : []),
    ...Array.from(prognosticPoints ? prognosticPoints.map((x) => x.Score) : [])
  ]);

  tp = calculateBothYFromX(thresholds, 0.0);
  if (tp) {
    crucialPoints = appendUniquePoint(crucialPoints, tp);
    p = getMediumToHighPoint(tp);
    if (p && p.y >= 0.0 && p.y <= 100.0) {
      mediumToHighLine = appendUniquePoint(mediumToHighLine, p);
      yTicks = appendUniqueNumber(yTicks, p.y);
    }
    p = getLowToMediumPoint(tp);
    if (p && p.y >= 0.0 && p.y <= 100.0) {
      lowToMediumLine = appendUniquePoint(lowToMediumLine, p);
      if (p.y > 0.0 && p.y < yTicks[yTicks.length - 1]) {
        yTicks = appendUniqueNumber(yTicks, p.y);
      }
    }
  }

  tp = calculateBothYFromX(thresholds, 100.0);
  if (tp) {
    crucialPoints = appendUniquePoint(crucialPoints, tp);
    p = getLowToMediumPoint(tp);
    if (p && p.y >= 0.0 && p.y <= 100.0) {
      lowToMediumLine = appendUniquePoint(lowToMediumLine, p);
      if (
        (tp.yMediumToHigh === null || tp.yMediumToHigh >= p.y) &&
        thresholds.LowToMediumRiskLineThreshold.Slope
      ) {
        maxScore = 100.0;
      }
    }
    p = getMediumToHighPoint(tp);
    if (p && p.y >= 0.0 && p.y <= 100.0) {
      mediumToHighLine = appendUniquePoint(mediumToHighLine, p);
      if (thresholds.MediumToHighRiskLineThreshold.Slope) maxScore = 100.0;
    }
  }

  let xMediumToHigh: number = calculateXFromYMediumToHigh(thresholds, 0.0);
  if (xMediumToHigh !== null && xMediumToHigh > 0.0 && xMediumToHigh < 100.0) {
    tp = new TwoPoint();
    tp.x = xMediumToHigh;
    tp.yMediumToHigh = 0.0;
    tp.yLowToMedium = calculateYFromXLowToMedium(thresholds, tp.x);
    crucialPoints = appendUniquePoint(crucialPoints, tp);
    p = getMediumToHighPoint(tp);
    if (p) mediumToHighLine = appendUniquePoint(mediumToHighLine, p);
    xTicks = appendUniqueNumber(xTicks, xMediumToHigh);
    maxScore = Math.max(maxScore, xMediumToHigh);
  }

  let xLowToMedium: number = calculateXFromYLowToMedium(thresholds, 0.0);
  if (xLowToMedium !== null && xLowToMedium > 0.0 && xLowToMedium < 100.0) {
    tp = new TwoPoint();
    tp.x = xLowToMedium;
    tp.yLowToMedium = 0.0;
    tp.yMediumToHigh = calculateYFromXMediumToHigh(thresholds, tp.x);
    crucialPoints = appendUniquePoint(crucialPoints, tp);
    p = getLowToMediumPoint(tp);
    if (p) lowToMediumLine = appendUniquePoint(lowToMediumLine, p);
    if (xMediumToHigh === null || xLowToMedium < xMediumToHigh) {
      xTicks = appendUniqueNumber(xTicks, xLowToMedium);
      maxScore = Math.max(maxScore, xLowToMedium);
    }
  }

  xMediumToHigh = calculateXFromYMediumToHigh(thresholds, 100.0);
  if (xMediumToHigh !== null && xMediumToHigh > 0.0 && xMediumToHigh < 100.0) {
    tp = new TwoPoint();
    tp.x = xMediumToHigh;
    tp.yMediumToHigh = 100.0;
    tp.yLowToMedium = calculateYFromXLowToMedium(thresholds, tp.x);
    crucialPoints = appendUniquePoint(crucialPoints, tp);
    p = getMediumToHighPoint(tp);
    if (p) mediumToHighLine = appendUniquePoint(mediumToHighLine, p);
  }

  xLowToMedium = calculateXFromYLowToMedium(thresholds, 100.0);
  if (xLowToMedium !== null && xLowToMedium > 0.0 && xLowToMedium < 100.0) {
    tp = new TwoPoint();
    tp.x = xLowToMedium;
    tp.yLowToMedium = 100.0;
    tp.yMediumToHigh = calculateYFromXMediumToHigh(thresholds, tp.x);
    crucialPoints = appendUniquePoint(crucialPoints, tp);
    p = getLowToMediumPoint(tp);
    if (p) lowToMediumLine = appendUniquePoint(lowToMediumLine, p);
  }

  p = findCrossingPoint(thresholds);
  if (
    p &&
    p.x !== null &&
    p.y !== null &&
    p.x > 0.0 &&
    p.x < 100.0 &&
    p.y > 0.0 &&
    p.y < 100.0
  ) {
    tp = new TwoPoint();
    tp.x = p.x;
    tp.yLowToMedium = p.y;
    tp.yMediumToHigh = p.y;
    crucialPoints = appendUniquePoint(crucialPoints, tp);
    p = getLowToMediumPoint(tp);
    if (p) lowToMediumLine = appendUniquePoint(lowToMediumLine, p);
  }

  if (!isAny) {
    maxScore = 100.0;
  } else {
    maxScore = 1.1 * maxScore;
    maxScore = Math.ceil(maxScore / 5.0) * 5.0;
    maxScore = Math.min(maxScore, 100.0);
  }

  if (maxScore < 100.0) {
    tp = calculateBothYFromX(thresholds, maxScore);
    if (tp) {
      crucialPoints = appendUniquePoint(crucialPoints, tp);
      p = getLowToMediumPoint(tp);
      if (p && p.y >= 0.0 && p.y <= 100.0) {
        lowToMediumLine = appendUniquePoint(lowToMediumLine, p);
      }
      p = getMediumToHighPoint(tp);
      if (p && p.y >= 0.0 && p.y <= 100.0) {
        mediumToHighLine = appendUniquePoint(mediumToHighLine, p);
      }
    }
  }

  crucialPoints = sortPointArray(crucialPoints);

  stackedValues = getStackedValues(crucialPoints, maxScore);

  if (!showLines) {
    xTicks = [0.25 * maxScore, 0.5 * maxScore, 0.75 * maxScore];
    yTicks = [25.0, 50.0, 75.0];
  } else {
    xTicks = appendUniqueNumber(xTicks, maxScore);
  }

  xTicks = sortNumArray(
    xTicks.filter((val) => {
      return val <= maxScore;
    })
  );
  yTicks = sortNumArray(yTicks);

  lowToMediumLine = sortPointArray(
    lowToMediumLine.filter((val) => {
      return val.x <= maxScore;
    })
  );
  mediumToHighLine = sortPointArray(
    mediumToHighLine.filter((val) => {
      return val.x <= maxScore;
    })
  );

  regionCrucialPoints = getRegionCrucialPoints(
    lowToMediumLine,
    crucialPoints,
    maxScore
  );

  return {
    stackedValues,
    xTicks,
    yTicks,
    lowToMediumLine,
    mediumToHighLine,
    crucialPoints,
    regionCrucialPoints
  };
};

export default getRiskAreas;

/////////////////////////////

function getStackedValues(crucialPoints: TwoPoint[], maxScore: number) {
  let stackedValues: StackedPoint[] = [];

  for (let i = 0; i < crucialPoints.length; i++) {
    const tp = crucialPoints[i];
    if (!tp || tp.x === null || tp.x > maxScore) continue;
    const stack: StackedPoint = new StackedPoint();
    stack.x = tp.x;
    if (tp.yMediumToHigh === null || tp.yMediumToHigh > 100.0) {
      stack.yHigh = null;
      if (tp.yLowToMedium === null || tp.yLowToMedium > 100.0) {
        stack.yMedium = null;
        stack.yLow = 100.0;
      } else if (tp.yLowToMedium < 0.0) {
        stack.yMedium = 100.0;
        stack.yLow = null;
      } else {
        stack.yMedium = 100.0 - tp.yLowToMedium;
        stack.yLow = tp.yLowToMedium;
      }
    } else if (tp.yMediumToHigh < 0.0) {
      stack.yHigh = 100.0;
      stack.yMedium = null;
      stack.yLow = null;
    } else {
      if (tp.yLowToMedium === null || tp.yLowToMedium > tp.yMediumToHigh) {
        stack.yHigh = 100.0 - tp.yMediumToHigh;
        stack.yMedium = null;
        stack.yLow = tp.yMediumToHigh;
      } else if (tp.yLowToMedium < 0.0) {
        stack.yHigh = 100.0 - tp.yMediumToHigh;
        stack.yMedium = tp.yMediumToHigh;
        stack.yLow = null;
      } else {
        stack.yHigh = 100.0 - tp.yMediumToHigh;
        stack.yMedium = tp.yMediumToHigh - tp.yLowToMedium;
        stack.yLow = tp.yLowToMedium;
      }
    }
    stackedValues = stackedValues.concat(stack);
  }

  return stackedValues;
}

function getRegionCrucialPoints(
  lowToMediumLine: Point[],
  crucialPoints: TwoPoint[],
  maxScore: number
) {
  if (lowToMediumLine.length >= 2) {
    return crucialPoints.filter(
      (value) =>
        value.yLowToMedium !== null &&
        value.yMediumToHigh !== null &&
        value.yLowToMedium >= value.yMediumToHigh &&
        value.x >= 0.0 &&
        value.x <= maxScore &&
        value.yLowToMedium >= 0.0 &&
        value.yLowToMedium <= 100.0
    );
  }

  return [];
}

function calculateBothYFromX(
  thresholds: IAssetRiskConfigurationValue,
  x: number
): TwoPoint {
  const tp: TwoPoint = new TwoPoint();
  tp.x = x;
  tp.yLowToMedium = calculateYFromXLowToMedium(thresholds, tp.x);
  tp.yMediumToHigh = calculateYFromXMediumToHigh(thresholds, tp.x);
  return tp;
}

function calculateYFromXLowToMedium(
  thresholds: IAssetRiskConfigurationValue,
  x: number
): number {
  if (!thresholds) return null;
  const { LowToMediumRiskLineThreshold } = thresholds;
  return calculateYFromX(LowToMediumRiskLineThreshold, x);
}

function calculateYFromXMediumToHigh(
  thresholds: IAssetRiskConfigurationValue,
  x: number
): number {
  if (!thresholds) return null;
  const { MediumToHighRiskLineThreshold } = thresholds;
  return calculateYFromX(MediumToHighRiskLineThreshold, x);
}

function calculateXFromYLowToMedium(
  thresholds: IAssetRiskConfigurationValue,
  y: number
): number {
  if (!thresholds) return null;
  const { LowToMediumRiskLineThreshold } = thresholds;
  return calculateXFromY(LowToMediumRiskLineThreshold, y);
}

function calculateXFromYMediumToHigh(
  thresholds: IAssetRiskConfigurationValue,
  y: number
): number {
  if (!thresholds) return null;
  const { MediumToHighRiskLineThreshold } = thresholds;
  return calculateXFromY(MediumToHighRiskLineThreshold, y);
}

function calculateYFromX(line: IDirectionalLine | null, x: number): number {
  if (x === null || x === undefined || !lineIsDefined(line)) return null;
  return line.Slope * x + line.Intercept;
}

function calculateXFromY(line: IDirectionalLine | null, y: number): number {
  if (
    y === null ||
    y === undefined ||
    !lineIsDefined(line) ||
    line.Slope === 0.0
  )
    return null;
  return (y - line.Intercept) / line.Slope;
}

function findCrossingPoint(thresholds: IAssetRiskConfigurationValue): Point {
  if (
    !thresholds ||
    !lineIsDefined(thresholds.LowToMediumRiskLineThreshold) ||
    !lineIsDefined(thresholds.MediumToHighRiskLineThreshold)
  )
    return null;
  if (
    thresholds.LowToMediumRiskLineThreshold.Slope ===
    thresholds.MediumToHighRiskLineThreshold.Slope
  )
    return null;

  const result: Point = new Point();
  result.x =
    (thresholds.LowToMediumRiskLineThreshold.Intercept -
      thresholds.MediumToHighRiskLineThreshold.Intercept) /
    (thresholds.MediumToHighRiskLineThreshold.Slope -
      thresholds.LowToMediumRiskLineThreshold.Slope);
  const y = calculateYFromXMediumToHigh(thresholds, result.x);
  if (y === null) return null;
  result.y = y;
  return result;
}

function lineIsDefined(line: IDirectionalLine | null): boolean {
  return line &&
    line.Slope !== null &&
    line.Slope !== undefined &&
    line.Intercept !== null &&
    line.Intercept !== undefined
    ? true
    : false;
}

function appendUniquePoint<T extends IPointBase>(
  collection: T[],
  point: T
): T[] {
  if (collection === null || collection === undefined) collection = [];
  if (!point) return collection;
  if (collection.some((p) => p.x === point.x)) return collection;
  return collection.concat(point);
}

function appendUniqueNumber(collection: number[], point: number): number[] {
  if (collection === null || collection === undefined) collection = [];
  if (point === null || point === undefined) return collection;
  if (collection.some((p) => p === point)) return collection;
  return collection.concat(point);
}

function getMediumToHighPoint(tp: TwoPoint): Point {
  if (!tp || tp.x === null || tp.yMediumToHigh === null) return null;
  const p: Point = new Point();
  p.x = tp.x;
  p.y = tp.yMediumToHigh;
  return p;
}

function getLowToMediumPoint(tp: TwoPoint): Point {
  if (!tp || tp.x === null || tp.yLowToMedium === null) return null;
  const p: Point = new Point();
  p.x = tp.x;
  p.y = tp.yLowToMedium;
  return p;
}

function sortNumArray(collection: number[]): number[] {
  if (collection === null || collection === undefined) return [];
  return collection.sort((x, y) => x - y);
}

function sortPointArray<T extends IPointBase>(collection: T[]): T[] {
  if (collection === null || collection === undefined) return [];
  return collection.sort((p1, p2) => p1.x - p2.x);
}
