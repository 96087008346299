// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { ChartConfiguration } from "c3";

import IHistorySwitchingOperations from "features/detailpage/features/ltc/models/IHistorySwitichingOperations";
import getDefaultConfiguration from "./C3DefaultConfiguration";
import { IntlShape } from "react-intl";

export default class HistorySwitchingChartService {
  public static getChartConfiguration(
    historySwitching: IHistorySwitchingOperations,
    intl: IntlShape
  ): ChartConfiguration {
    return getDefaultConfiguration(historySwitching, intl);
  }
}
