// Copyright 2016-2023 Hitachi Energy. All rights reserved.

enum VisualizationKeys {
  AssetDetailPage_Tabs_Parameters_DC = "asset_detail_page.tabs.parameters.dc",
  AssetDetailPage_Tabs_Parameters_Parameters = "asset_detail_page.tabs.parameters.parameters",
  AssetDetailPage_Tabs_Parameters_Aging = "asset_detail_page.tabs.parameters.aging",
  AssetDetailPage_Tabs_Parameters_Dielectric = "asset_detail_page.tabs.parameters.dielectric",
  AssetDetailPage_Tabs_Parameters_Dielectric_Parameters = "asset_detail_page.tabs.parameters.dielectric_parameters",
  AssetDetailPage_Tabs_Parameters_Mechanical = "asset_detail_page.tabs.parameters.mechanical",
  AssetDetailPage_Tabs_Parameters_Wear = "asset_detail_page.tabs.parameters.wear",
  AssetDetailPage_Tabs_Parameters_Auxiliaries = "asset_detail_page.tabs.parameters.auxiliaries",
  AssetDetailPage_Tabs_Parameters_Operations = "asset_detail_page.tabs.parameters.operations",
  AssetDetailPage_Tabs_Parameters_Log = "asset_detail_page.tabs.parameters.log",
  AssetDetailPage_Tabs_Parameters_Raw_Parameters = "asset_detail_page.tabs.parameters.raw_parameters",
  AssetDetailPage_Tabs_Parameters_Subfactors = "asset_detail_page.tabs.parameters.subfactors",
  AssetDetailPage_Tabs_Parameters_Dga = "asset_detail_page.tabs.parameters.dga",
  AssetDetailPage_Tabs_Parameters_Duval = "asset_detail_page.tabs.parameters.duval",
  AssetDetailPage_Tabs_Parameters_Rogers_Ratio = "asset_detail_page.tabs.parameters.rogers_ratio",
  AssetDetailPage_Tabs_Parameters_Standard_Oil_Tests = "asset_detail_page.tabs.parameters.standard_oil_tests",
  AssetDetailPage_Tabs_Parameters_Bushing_Offline = "asset_detail_page.tabs.parameters.bushing_offline",
  AssetDetailPage_Tabs_Parameters_Bushing_Visualization = "asset_detail_page.tabs.parameters.bushing_visualization",
  AssetDetailPage_Tabs_Parameters_Thermal_Visualization = "asset_detail_page.tabs.parameters.thermal_visualization",
  AssetDetailPage_Tabs_Parameters_Ltc = "asset_detail_page.tabs.parameters.ltc",
  AssetDetailPage_Tabs_Parameters_Partial_Discharge = "asset_detail_page.tabs.parameters.partial_discharge",
  AssetDetailPage_Tabs_Parameters_Battery_Conductance = "asset_detail_page.tabs.parameters.battery_conductance",
  AssetDetailPage_Tabs_Parameters_Battery_Voltage = "asset_detail_page.tabs.parameters.battery_voltage",
  AssetDetailPage_Tabs_Parameters_Voltage_Stress = "asset_detail_page.tabs.parameters.voltage_stress",
  AssetDetailPage_Tabs_Parameters_Reports = "asset_detail_page.tabs.parameters.reports",
  Configuration_Tool_Manage_Assets = "configuration_tool.manage_assets",
  AlarmsPage_Tab = "tabs.alarms"
}

export default VisualizationKeys;
