// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import IRemoveDataAction from "../models/IRemoveDataAction";

import Actions from "../models/Actions";

const removeDataAction = (key: string): IRemoveDataAction => {
  const action: IRemoveDataAction = {
    type: Actions.RemoveData,
    key
  };
  return action;
};

export default removeDataAction;
