// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IColumn } from "common/datagrid/DataGrid";
import HeaderCell from "common/datagrid/HeaderCell";
import * as React from "react";

export interface IHeaderRowProps {
  columns: IColumn[];
  onColumnClick?: (columnId: string, modifierIsPressed: boolean) => void;
}

const HeaderRow = (props: IHeaderRowProps) => {
  const { columns, onColumnClick } = props;

  const cells = columns.map((c) => (
    <HeaderCell
      key={c.config.id}
      column={c}
      columns={columns}
      onColumnClick={onColumnClick}
    />
  ));

  return <div className="grid-row">{cells}</div>;
};

export default HeaderRow;
