// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { SelectedFilters } from "common/FilterBar";
import FamilyAnalyticsWidget from "features/familyanalytics/components/FamilyAnalyticsWidget";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useAppDispatch } from "store";
import {
  loadScatterPlotFilters,
  loadScatterPlotXData,
  loadScatterPlotYData
} from "../actions/FamilyAnalyticsActions";
import { ScatterPlotParameters } from "../components/ScatterPlotWidget";
import useFamilyAnalyticsWidgetContainer from "../hooks/useFamilyAnalyticsWidgetContainer";

interface IFamilyAnalyticsWidgetContainerProps {
  onSuccess: () => void;
  onError: (errorCode: string, dataPointsLimit: number) => void;
  selectedFilters: SelectedFilters;
  showErrorMessage: boolean;
  errorCode: string;
  dataPointsLimit: number;
}

const FamilyAnalyticsWidgetContainer = ({
  onSuccess,
  onError,
  selectedFilters,
  showErrorMessage,
  errorCode,
  dataPointsLimit
}: IFamilyAnalyticsWidgetContainerProps) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { scatterPlotFilters, scatterPlotXData, scatterPlotYData } =
    useFamilyAnalyticsWidgetContainer();

  const getScatterPlotYData = (
    scatterPlotParameters: ScatterPlotParameters
  ) => {
    dispatch(
      loadScatterPlotYData(
        scatterPlotParameters,
        selectedFilters,
        onSuccess,
        onError,
        intl
      )
    );
  };

  const getScatterPlotXData = (
    scatterPlotParameters: ScatterPlotParameters
  ) => {
    dispatch(
      loadScatterPlotXData(
        scatterPlotParameters,
        selectedFilters,
        onSuccess,
        onError,
        intl
      )
    );
  };

  useEffect(() => {
    dispatch(loadScatterPlotFilters(selectedFilters));
  }, [dispatch, selectedFilters]);

  return (
    <FamilyAnalyticsWidget
      loadScatterPlotYData={getScatterPlotYData}
      loadScatterPlotXData={getScatterPlotXData}
      scatterPlotFilters={scatterPlotFilters}
      scatterPlotXData={scatterPlotXData}
      scatterPlotYData={scatterPlotYData}
      selectedFilters={selectedFilters}
      showErrorMessage={showErrorMessage}
      errorCode={errorCode}
      dataPointsLimit={dataPointsLimit}
    />
  );
};

export default FamilyAnalyticsWidgetContainer;
