// Copyright 2016-2023 Hitachi Energy. All rights reserved.

/* eslint-disable jsx-a11y/anchor-is-valid */
import SearchParams from "@pg/common/build/models/SearchParams";
import { routes } from "core/app/components/AppRoutes";
import { useAppNavigate } from "core/app/components/RouterProvider";
import { useMemo } from "react";
import IKeyRiskChangesInfo from "../../models/IKeyRiskChangesInfo";

interface IKeyRiskItem {
  item: IKeyRiskChangesInfo;
}

const KeyRiskItem = ({ item }: IKeyRiskItem) => {
  const navigate = useAppNavigate();

  const assetDisplayedName = useMemo(
    () => [item.AssetId, item.AssetName].filter(Boolean).join(" - "),
    [item.AssetId, item.AssetName]
  );

  const searchParams = useMemo(
    () => new SearchParams({ assetId: item.AssetId }),
    [item.AssetId]
  );

  return (
    <div className="key-risk-item">
      <div
        className={
          "border-left asset-risk-border " + item.AssetRisk.toLowerCase()
        }
      >
        <div className="asset-id" title={assetDisplayedName}>
          <a
            className="explore anchor"
            data-qa="link"
            onClick={() => {
              navigate({
                pathname: routes.detailPage.pathname,
                search: searchParams.toString()
              });
            }}
          >
            {assetDisplayedName}
          </a>
        </div>
        <div className="asset-details">
          <span>{item.Location}</span>
          <span> / </span>
          <span>{item.AssetType}</span>
        </div>
      </div>
    </div>
  );
};

interface IKeyRiskItemsGroupProps {
  itemsData: IKeyRiskChangesInfo[];
}

const KeyRiskItemsGroup = ({ itemsData }: IKeyRiskItemsGroupProps) => (
  <>
    {itemsData.map((item, index) => (
      <KeyRiskItem item={item} key={index} />
    ))}
  </>
);

export default KeyRiskItemsGroup;
