// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import ElementService from "common/datagrid/services/ElementService";
import { scrollEvent } from "core/events/EventHub";

export default class EventService {
  static bindScrollListener(gridId: string, listener: (e: any) => void): void {
    setTimeout(() => {
      const scrollElement = ElementService.getScrollElement(gridId);

      if (scrollElement) {
        const eventEmitter = ElementService.getEventEmitter(scrollElement);

        if (eventEmitter) {
          eventEmitter.on(scrollEvent, listener);
        } else {
          scrollElement.addEventListener("scroll", listener);
        }
      }
    });
  }

  static bindWindowResizeListener(listener: (e: any) => void): void {
    window.addEventListener("resize", listener);
  }

  static unbindScrollListener(
    gridId: string,
    listener: (e: any) => void
  ): void {
    const scrollElement = ElementService.getScrollElement(gridId);
    const eventEmitter = ElementService.getEventEmitter(scrollElement);

    if (eventEmitter) {
      eventEmitter.removeListener(scrollEvent, listener);
    } else {
      scrollElement.removeEventListener("scroll", listener);
    }
  }

  static unbindWindowResizeListener(listener: (e: any) => void): void {
    window.removeEventListener("resize", listener);
  }
}
