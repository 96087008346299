// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { isEmpty } from "lodash";

import { Statuses } from "core/data/models/Data";
import useSF6MoistureChart from "../hooks/useSF6MoistureChart";

import Processing from "components/common/Processing";
import {
  WidgetNoDataMessage,
  WidgetErrorMessage
} from "components/common/widget/Widget";

import MoistureChart from "./MoistureChart";
import { IModelDetails } from "../../DielectricTab/DielectricTab";

interface ISF6MoistureChartProps {
  model: IModelDetails;
}

const SF6MoistureChart = ({ model }: ISF6MoistureChartProps) => {
  const { moisture, title, height } = useSF6MoistureChart();

  switch (moisture.status) {
    case Statuses.Loading:
      return <Processing className="spinner small dark" />;
    case Statuses.Succeeded:
      return isEmpty(moisture.data) ? (
        <WidgetNoDataMessage />
      ) : (
        <MoistureChart
          title={title}
          trendChart={moisture.data["Moisture"]}
          height={height}
          model={model}
        />
      );
    case Statuses.NotFound:
      return <WidgetNoDataMessage />;
    case Statuses.Failed:
      return <WidgetErrorMessage />;
    default:
      return (
        <div className="tile">
          <div className="tile-content empty" />
        </div>
      );
  }
};

export default SF6MoistureChart;
