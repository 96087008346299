// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";
import { RouterLayout } from "core/app/components/RouterProvider";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import ElectricalCharts from "./ElectricalCharts";
import UltraHighFrequencyCharts from "./UltraHighFrequencyCharts";

interface IPartialDischargeProps {
  assetId: string;
  modelId: string;
  modelImplementationId: string;
}

const PartialDischarge = ({
  assetId,
  modelId,
  modelImplementationId
}: IPartialDischargeProps) => {
  const intl = useIntl();
  const searchParams = useMemo(() => new SearchParams({ assetId }), [assetId]);

  return (
    <RouterLayout
      routes={[
        {
          id: "Assets-DetailPage-Parameters-PartialDischarge-Electrical",
          route: "Electrical",
          displayName: intl.formatMessage({
            id: "detail_page.widgets.analytics.transformers.partial_discharge.electrical",
            defaultMessage: "Electrical"
          }),
          to: {
            pathname:
              "/Assets/DetailPage/Parameters/PartialDischarge/Electrical",
            search: searchParams.toString()
          },
          element: (
            <ElectricalCharts
              assetId={assetId}
              modelId={modelId}
              modelImplementationId={modelImplementationId}
            />
          )
        },
        {
          id: "Assets-DetailPage-Parameters-PartialDischarge-UltraHighFrequency",
          route: "UltraHighFrequency",
          displayName: intl.formatMessage({
            id: "detail_page.widgets.analytics.transformers.partial_discharge.high_frequency",
            defaultMessage: "Ultra high frequency"
          }),
          to: {
            pathname:
              "/Assets/DetailPage/Parameters/PartialDischarge/UltraHighFrequency",
            search: searchParams.toString()
          },
          element: (
            <UltraHighFrequencyCharts
              assetId={assetId}
              modelId={modelId}
              modelImplementationId={modelImplementationId}
            />
          )
        }
      ]}
    />
  );
};

export default PartialDischarge;
