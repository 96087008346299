// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as d3 from "d3";
import DataSource from "../models/DataSource";
import createOctagramStar from "@apm/widgets/build/components/D3Chart/utils/createOctagramStar";
import isOnlineDataSource from "./isOnlineDataSource";
import IOctagram from "@apm/widgets/build/components/D3Chart/models/IOctagram";

interface IDuvalPointCreatorProps<T> {
  root: D3Selection<SVGElement>;
  sortedPoints: T[];
  dataSource: DataSource;
  hideTooltip: () => void;
  showTooltip: (d: T) => void;
  getCoords: (point: T) => { x: number; y: number };
}

type D3Selection<T extends d3.BaseType> = d3.Selection<
  T,
  unknown,
  null,
  undefined
>;

export const createDuvalPoints = <T extends object>({
  root,
  sortedPoints,
  dataSource,
  showTooltip,
  hideTooltip,
  getCoords
}: IDuvalPointCreatorProps<T>) => {
  const length = sortedPoints.length;
  const maxPointSize = 5;
  const minSizeThreshold = 4;
  let visiblePoints: T[] = [];
  const xScale = d3.scaleLinear().domain([minSizeThreshold, maxPointSize]);
  const yScale = d3.scaleLinear().domain([minSizeThreshold, maxPointSize]);

  if (length > 0) {
    for (let i = 0; i <= length; i++) {
      if (!sortedPoints[i]) continue;

      visiblePoints.push(sortedPoints[i]);
      const coords = getCoords(sortedPoints[i]);

      if (
        (isOnlineDataSource(dataSource) && i !== 0 && i !== length - 1) ||
        dataSource === DataSource.Offline
      ) {
        const circle = root
          .append("circle")
          .attr("cx", coords.x)
          .attr("cy", coords.y)
          .attr("r", 0)
          .attr("style", "fill:black;stroke:white;stroke-width:2;")
          .on("mouseover", (d: T) => showTooltip(d))
          .on("mouseout", (_: T) => hideTooltip());

        circle
          .transition()
          .delay(50 * i)
          .duration(200)
          .attr(
            "r",
            maxPointSize * Math.pow((i + 1) / length, 2) + minSizeThreshold
          );
      }
    }

    if (isOnlineDataSource(dataSource)) {
      visiblePoints = visiblePoints.filter((_, i) => i !== 0);
      visiblePoints = visiblePoints.filter(
        (_, i) => i !== visiblePoints.length - 1
      );
    }

    root.selectAll("circle").data(visiblePoints);

    if (length > 1 && isOnlineDataSource(dataSource)) {
      const coords = getCoords(sortedPoints[0]);
      createChartOctagram<T>(
        root,
        maxPointSize * Math.pow((0 + 1) / length, 2) + minSizeThreshold,
        xScale,
        coords,
        yScale,
        showTooltip,
        sortedPoints[0],
        hideTooltip
      );
    }

    if (length > 0 && isOnlineDataSource(dataSource)) {
      const coords = getCoords(sortedPoints[length - 1]);
      createChartOctagram<T>(
        root,
        maxPointSize * Math.pow(length / length, 2) + minSizeThreshold,
        xScale,
        coords,
        yScale,
        showTooltip,
        sortedPoints[length - 1],
        hideTooltip,
        true
      );
    }
  }
};

export const createOctagramLegends = (
  filledOctRef: React.MutableRefObject<SVGSVGElement>,
  octRef: React.MutableRefObject<SVGSVGElement>,
  octagram: IOctagram
) => {
  const filledSvg = d3
    .select(filledOctRef.current)
    .attr("width", 24)
    .attr("height", 24)
    .append("g")
    .append("path")
    .attr("class", "oct-icon-svg")
    .data([octagram.octagramPoints])
    .attr("d", (d) => octagram.lineRadial(d))
    .attr("transform", "translate(12, 12)")
    .attr("fill", "black")
    .attr("stroke", "white");
  const svg = d3
    .select(octRef.current)
    .attr("width", 24)
    .attr("height", 24)
    .append("g")
    .append("path")
    .attr("class", "oct-icon-svg")
    .data([octagram.octagramPoints])
    .attr("d", (d) => octagram.lineRadial(d))
    .attr("transform", "translate(12, 12)")
    .attr("fill", "white")
    .attr("stroke", "black");

  return () => {
    filledSvg.remove();
    svg.remove();
  };
};

function createChartOctagram<T extends object>(
  root: D3Selection<SVGElement>,
  size: number,
  xScale: d3.ScaleLinear<number, number, never>,
  coords: { x: number; y: number },
  yScale: d3.ScaleLinear<number, number, never>,
  showTooltip: (d: T) => void,
  sortedPoint: T,
  hideTooltip: () => void,
  fillOctagram?: boolean
) {
  const { octagramPoints, lineRadial } = createOctagramStar(
    size
  );
  root
    .append("g")
    .append("path")
    .data([octagramPoints])
    .attr("d", (d) => lineRadial(d))
    .attr(
      "transform",
      "translate(" + xScale(coords.x) + "," + yScale(coords.y) + ")"
    )
    .on("mouseover", (d: T) => showTooltip(sortedPoint))
    .on("mouseout", (d: T) => hideTooltip())
    .attr("fill", fillOctagram ? "black" : "white")
    .attr("stroke", fillOctagram ? "white" : "black");
}
