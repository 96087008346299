// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import update from "immutability-helper";
import { drop, first, isNil } from "lodash";
import IAssetRiskCountsTree from "models/IAssetRiskCountsTree";

const updateAssetRiskCounts = (
  parents: IAssetRiskCountsTree[] | undefined,
  childs: IAssetRiskCountsTree[] | undefined,
  id: string[]
): IAssetRiskCountsTree[] => {
  if (isNil(parents)) return parents;
  if (isNil(childs)) return parents;

  const parentIndex = parents?.findIndex((c) => c.GroupKey === first(id));
  if (parentIndex < 0) return parents;

  if (id.length === 1) {
    return update(parents, {
      [parentIndex]: {
        Childrens: {
          $apply: (x: IAssetRiskCountsTree[]) =>
            isNil(x) ? [...childs] : [...x, ...childs]
        }
      }
    });
  } else {
    const newChilds = updateAssetRiskCounts(
      parents[parentIndex].Childrens,
      childs,
      drop([...id])
    );

    return update(parents, {
      [parentIndex]: {
        Childrens: {
          $set: newChilds
        }
      }
    });
  }
};

export default updateAssetRiskCounts;
