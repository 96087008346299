// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";
import { IState } from "reducers/Index";

const getAgingVisualizationSelector = createSelector(
  (state: IState) => state.detailPage.agingVisualization,
  agingVisualization => agingVisualization
);

export default getAgingVisualizationSelector;
