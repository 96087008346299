// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import ModalWindow from "components/common/modal/ModalWindow";
import { FormattedMessage } from "react-intl";
import modalWindowStore from "store/common/modal/ModalWindowStore";
import ModelConfigurationGrid from "./ModelConfigurationGrid";

import useModelConfigurationItem from "./hooks/useModelConfigurationItem";

import "./ModelConfigurationItem.less";

const modelConfigurationModalId = "AssetDetailPageModelConfigurationModal";

interface IModelConfigurationItemProps {
  showModelConfigurationTable: boolean;
  modelImplementationId: string;
  modelId: string;
  modelConfigurationName: string;
}

const ModelConfigurationItem = ({
  modelId,
  modelImplementationId,
  modelConfigurationName,
  showModelConfigurationTable
}: IModelConfigurationItemProps) => {
  const assetId = useModelConfigurationItem();

  return (
    <div className="labeled-row model-configuration">
      <span className="label">
        <FormattedMessage
          id={"asset.nameplate.model_configuration_name"}
          defaultMessage="Model Configuration"
        />
      </span>
      {showModelConfigurationTable ? (
        <span
          className="value model-configuration-item"
          onClick={() => modalWindowStore.openModal(modelConfigurationModalId)}
        >
          {modelConfigurationName}
        </span>
      ) : (
        <span className="value">{modelConfigurationName}</span>
      )}
      <ModalWindow
        modalId={modelConfigurationModalId}
        modalTitle={assetId + " - " + modelConfigurationName}
        className="modal-window-mixed modal-window-wide"
      >
        <ModelConfigurationGrid
          modelId={modelId}
          modelImplementationId={modelImplementationId}
          configurationName={modelConfigurationName}
        />
      </ModalWindow>
    </div>
  );
};

export default ModelConfigurationItem;
