// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import WearParameters from "@apm/widgets/build/widgets/WearParameters";
import Processing from "components/common/Processing";
import ProcessingModal from "components/common/ProcessingModal";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import { getUserSelector } from "core/app/reducers/AppReducer";
import { IUser } from "core/app/reducers/settings/UserReducer";
import Data, { Statuses } from "core/data/models/Data";
import useIsReadOnlyData from "features/detailpage/hooks/useIsReadOnlyData";
import LifecycleStatus from "models/LifecycleStatus";
import { IntlShape } from "react-intl";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import IThresholdCorrection from "../../../models/IThresholdCorrection";
import useContactWearParameters from "../hooks/useContactWearParameters";
import IWearData from "../models/IWearData";

interface IWearParametersState {
  user: Data<IUser>;
}

interface IWearParametersOwnProps {
  assetId: string;
  lifecycleStatus: LifecycleStatus;
  adjustExpectedRange: (
    intl: IntlShape,
    parameterName: string,
    success?: (correction: IThresholdCorrection) => void,
    error?: () => void
  ) => void;
  acknowledge: (
    intl: IntlShape,
    parameterName: string,
    success?: (correction: IThresholdCorrection) => void,
    error?: () => void
  ) => void;
  loadWear: () => void;
  removeWear: () => void;
  wear: Data<IWearData>;
}

type IWearParametersProps = IWearParametersOwnProps & IWearParametersState;

const ContactWearParameters = ({
  acknowledge,
  adjustExpectedRange,
  assetId,
  loadWear,
  removeWear,
  wear,
  user,
  lifecycleStatus
}: IWearParametersProps) => {
  const {
    formatValue,
    handleAdjustExpectedRangeClick,
    handleAcknowledgeClick,
    processing,
    translations,
    wearMapped,
    wearStatus
  } = useContactWearParameters(
    assetId,
    wear,
    adjustExpectedRange,
    acknowledge,
    loadWear,
    removeWear
  );
  const isReadOnlyMode = useIsReadOnlyData(lifecycleStatus, user);
  return (
    <>
      {wearStatus === Statuses.Loading && <Processing />}
      {wearStatus === Statuses.Succeeded && wearMapped && (
        <WearParameters
          formatValue={formatValue}
          parametersGroups={[wearMapped]}
          onAcknowledgeClick={handleAcknowledgeClick}
          onAdjustExpectedRangeClick={handleAdjustExpectedRangeClick}
          thresholdCorrectionParams={[
            "ContactWear",
            "MainNozzleWear",
            "AuxiliaryNozzleWear"
          ]}
          translations={translations}
          readonly={isReadOnlyMode}
        />
      )}
      {wearStatus === Statuses.NotFound && <WidgetNoDataMessage />}
      {wearStatus === Statuses.Failed && <WidgetErrorMessage />}
      {processing && <ProcessingModal />}
    </>
  );
};

const mapStateToProps = (state: IState): IWearParametersState => ({
  user: getUserSelector(state)
});
const ContactWearParametersContainer = connect(mapStateToProps)(
  ContactWearParameters
);
export default ContactWearParametersContainer;
