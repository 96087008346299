// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import DataGrid, {
  IDataGridCellProps,
  IDataGridRowProps
} from "@apm/widgets/build/widgets/DataGrid/components/DataGrid";
import { createValidationSchema } from "@apm/widgets/build/widgets/DataGrid/utils/validationHelper";
import Icon from "@pg/common/build/components/Icon";
import {
  colorGray0,
  colorWidgetBorder
} from "@pg/common/build/styles/ColorVariables";
import { Button, Layout, Modal, Typography } from "antd";
import fieldsTranslationConfig from "features/ConfigurationTool/constants/importedParameterFieldsTranslationConfig";
import partitionModbusOptions from "features/ConfigurationTool/constants/partitionModbusOptions";
import AssetModalContext from "features/ConfigurationTool/contexts/AssetModalContext";
import IConfigurableConnectedDevice, {
  IConfigurableRegisterConfiguration
} from "features/ConfigurationTool/models/connectedDevices/IConfigurableConnectedDevice";
import IImportedParameter from "features/ConfigurationTool/models/connectedDevices/IImportedParameter";
import ImportedParameterKey from "features/ConfigurationTool/models/connectedDevices/ImportedParameterKey";
import IRegisterConfiguration from "features/ConfigurationTool/models/connectedDevices/IRegisterConfiguration";
import ISection from "features/ConfigurationTool/models/ISection";
import { isNil } from "lodash";
import { FC, useCallback, useContext, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import {
  spacingLarge,
  spacingMedium,
  spacingXXLarge
} from "styles/StyleVariables";

const { Footer, Content } = Layout;
const { Title } = Typography;

interface SelectedData {
  id: number;
  [ImportedParameterKey.DisplayName]: IDataGridCellProps<IImportedParameter>;
  [ImportedParameterKey.TagName]: IDataGridCellProps<IImportedParameter>;
  [ImportedParameterKey.DataType]: IDataGridCellProps<IImportedParameter>;
  [ImportedParameterKey.PartitionModbus]: IDataGridCellProps<IImportedParameter>;
  [ImportedParameterKey.IdModbus]: IDataGridCellProps<IImportedParameter>;
  [ImportedParameterKey.Scale]: IDataGridCellProps<IImportedParameter>;
  [ImportedParameterKey.Offset]: IDataGridCellProps<IImportedParameter>;
}
interface IImportParametersMappingProps {
  className?: string;
  visible: boolean;
  onCancel: () => void;
  importedParameters: IImportedParameter[];
  activeConnectedDeviceData: IConfigurableConnectedDevice;
  parameterMappingGridConfiguration: ISection;
  mapRegisterFunctionCodeToLabel: (
    registerFunctionCode: number
  ) => React.ReactNode;
  setValidationResultTab: ({
    valid,
    modified,
    tabId
  }: {
    valid: boolean;
    modified: boolean;
    tabId?: string;
  }) => void;
  setHighlightAfterUpdatedRows: (isHighlighted: boolean) => void;
}

const ImportParametersMapping: FC<IImportParametersMappingProps> = ({
  className,
  visible,
  onCancel,
  importedParameters,
  activeConnectedDeviceData,
  parameterMappingGridConfiguration,
  setValidationResultTab,
  mapRegisterFunctionCodeToLabel,
  setHighlightAfterUpdatedRows
}) => {
  const { formatMessage } = useIntl();

  const { setConnectedDevices } = useContext(AssetModalContext);

  const convertedUploadedParameters = useMemo(
    () =>
      importedParameters.map((parameter) => ({
        ...parameter,
        DataType: parameter?.DataType?.toLowerCase()
      })),
    [importedParameters]
  );

  const mapRegisterFunctionCodeToValue = useCallback(
    (registerFunctionCodeLabel: string) =>
      partitionModbusOptions.find(
        ({ label }) => label === registerFunctionCodeLabel
      )?.value,
    []
  );

  const [selectedParameters, setSelectedParameters] = useState<
    IImportedParameter[]
  >(convertedUploadedParameters || []);

  const onSelectionChanged = useCallback((selectedData: SelectedData[]) => {
    const newSelectedParameters = selectedData.map((parameter) => {
      const { id, ...rest } = parameter;

      return Object.values(rest).reduce<IImportedParameter>((acc, val) => {
        return {
          ...acc,
          [val?.cellName]: val?.cellValue
        };
      }, {} as IImportedParameter);
    });

    setSelectedParameters(newSelectedParameters);
  }, []);

  const handleOnClose = useCallback(() => {
    onCancel();
    setSelectedParameters([]);
  }, [onCancel, setSelectedParameters]);

  const mapToRegisterConfigurationSchema = useCallback(
    (key: keyof IImportedParameter): keyof IRegisterConfiguration => {
      switch (key) {
        case "DisplayName": {
          return "DisplayName";
        }
        case "DataType": {
          return "ParamType";
        }
        case "PartitionModbus": {
          return "RegisterFunctionCode";
        }
        case "IdModbus": {
          return "RegisterAddress";
        }
        case "Scale": {
          return "ParamSlopeFactor";
        }
        case "Offset": {
          return "ParamOffsetFactor";
        }
      }
    },
    []
  );

  const validateAfterAddedParameters = useCallback(
    (importedParameters: IConfigurableRegisterConfiguration[]) => {
      const promises = importedParameters.map(({ id, cells }) => {
        return cells.map((cell) => {
          const cellValidationRules =
            parameterMappingGridConfiguration.fields.find(
              ({ fieldKey }) => fieldKey === cell.cellName
            )?.validationRules;

          const cellValidationRulesWithTranslations = cellValidationRules;

          const validConfig = {
            valid: true,
            id,
            cellName: cell.cellName,
            cellValue: cell.cellValue,
            validationMessage: cell.validationMessage
          };

          if (cellValidationRulesWithTranslations) {
            const validationSchema = createValidationSchema(
              cellValidationRulesWithTranslations
            );

            return validationSchema
              .validate(cell.cellValue)
              .then(() => {
                return validConfig;
              })
              .catch((error) => {
                return {
                  valid: false,
                  id,
                  cellName: cell.cellName,
                  cellValue: cell.cellValue,
                  validationMessage: error.message
                };
              });
          }

          return validConfig;
        });
      });

      const allCellsValidationPromises = promises.reduce(
        (acc, config) => [...acc, ...config],
        []
      );

      Promise.all(allCellsValidationPromises).then((results) => {
        setValidationResultTab({
          tabId: activeConnectedDeviceData?.Guid,
          modified: true,
          valid: results.every(({ valid }) => valid)
        });

        setConnectedDevices((connectedDevices) =>
          connectedDevices.map((device) => {
            if (device.Guid === activeConnectedDeviceData?.Guid) {
              const rows = results.reduce((acc, val) => {
                const matched = acc.find(({ id }) => id === val?.id);
                const { id, ...rest } = val;
                if (!matched) {
                  return [
                    ...acc.filter(({ id }) => id !== matched?.id),
                    {
                      id: val.id,
                      cells: [rest]
                    }
                  ];
                }

                return acc.map((item) => {
                  if (item.id !== id) {
                    return item;
                  }

                  return {
                    id,
                    cells: [...item.cells, rest]
                  };
                });
              }, []);

              return {
                ...device,
                RegisterConfiguration: [
                  ...device.RegisterConfiguration.map((row) => ({
                    ...row,
                    added: false,
                    isNew: false
                  })),
                  ...rows.map((row) => ({
                    ...row,
                    added: true,
                    isNew: true
                  }))
                ]
              };
            }
            return device;
          })
        );
      });
    },
    [
      activeConnectedDeviceData?.Guid,
      parameterMappingGridConfiguration.fields,
      setConnectedDevices,
      setValidationResultTab
    ]
  );

  const mapImportedParametersToRegisterConfiguration = useCallback(
    (importedParameters: IImportedParameter[]) => {
      return importedParameters.map<IConfigurableRegisterConfiguration>(
        (parameter, idx) => ({
          id: activeConnectedDeviceData?.RegisterConfiguration.length + idx,
          added: true,
          cells: [
            ...Object.entries(parameter).map(([key, val]) => {
              return {
                cellName: mapToRegisterConfigurationSchema(
                  key as keyof IImportedParameter
                ),
                cellValue:
                  key === "PartitionModbus"
                    ? mapRegisterFunctionCodeToValue(val)
                    : (key === "Offset" || key === "Scale") && isNil(val)
                    ? undefined
                    : val,
                modified: false,
                valid: true
              };
            }),
            {
              cellName: "ParamName",
              cellValue: undefined,
              valid: false
            }
          ]
        })
      );
    },
    [
      activeConnectedDeviceData?.RegisterConfiguration.length,
      mapRegisterFunctionCodeToValue,
      mapToRegisterConfigurationSchema
    ]
  );

  const handleSelectSubmit = useCallback(() => {
    const convertedImportedParameters =
      mapImportedParametersToRegisterConfiguration(selectedParameters);

    validateAfterAddedParameters(convertedImportedParameters);

    setHighlightAfterUpdatedRows(true);

    setTimeout(() => {
      setHighlightAfterUpdatedRows(false);
    }, 1000);

    handleOnClose();
  }, [
    handleOnClose,
    mapImportedParametersToRegisterConfiguration,
    selectedParameters,
    setHighlightAfterUpdatedRows,
    validateAfterAddedParameters
  ]);

  const parametersColumns = useMemo(() => {
    return fieldsTranslationConfig.map(({ fieldKey, fieldName }) => {
      return {
        field: fieldKey,
        headerName: formatMessage({
          id: fieldName.id,
          defaultMessage: fieldName.defaultMessage
        }),
        resizable: true
      };
    });
  }, [formatMessage]);

  const parametersData = useMemo(() => {
    return convertedUploadedParameters.map<
      IDataGridRowProps<IImportedParameter>
    >((parameter, idx) => {
      return {
        id: idx + 1,
        cells: Object.entries(parameter).map(([key, value]) => {
          return {
            cellName: key as keyof IImportedParameter,
            cellValue:
              key === "PartitionModbus"
                ? mapRegisterFunctionCodeToLabel(value as number)
                : (value as string),
            modified: false,
            valid: true
          };
        })
      };
    });
  }, [convertedUploadedParameters, mapRegisterFunctionCodeToLabel]);

  return (
    <Modal
      open={visible}
      centered={true}
      width="1100px"
      destroyOnClose={true}
      onCancel={onCancel}
      footer={null}
      data-qa="ImportParametersMappingModal"
      className={className}
    >
      <Layout className="import-parameters-mapping-layout">
        <Content className="import-parameters-mapping-content">
          <DataGrid<IImportedParameter>
            columns={parametersColumns}
            data={parametersData}
            gridHeaderTitle={formatMessage({
              id: "configuration_tool.tab.connected_devices_importing_parameter_header",
              defaultMessage: "Import - select parameters"
            })}
            gridHeight="340px"
            gridWidth="100%"
            dataQa="select-imported-parameters-grid"
            noRowsMessage={formatMessage({
              id: "data_grid.no_data_found",
              defaultMessage: "No data available"
            })}
            rowCheckboxSelection={true}
            searchingFilterData={true}
            onSelectionChanged={onSelectionChanged}
          />
        </Content>

        <Footer className="import-parameters-mapping-footer">
          <Title level={5} className="registers-count">
            {formatMessage(
              {
                id: "configuration_tool.tab.connected_devices_importing_parameter.registers_selected",
                defaultMessage: "{count} registers selected"
              },
              {
                count: selectedParameters.length || 0
              }
            )}
          </Title>

          <Button type="default" className="btn-cancel" onClick={handleOnClose}>
            <Icon name="close" size="sm" />
            {formatMessage({
              defaultMessage: "Cancel",
              id: "configuration_tool.action.cancel"
            })}
          </Button>

          <Button
            type="primary"
            onClick={handleSelectSubmit}
            disabled={selectedParameters.length === 0}
          >
            <Icon name="done" size="sm" />
            {formatMessage({
              defaultMessage: "Select",
              id: "configuration_tool.action.select"
            })}
          </Button>
        </Footer>
      </Layout>
    </Modal>
  );
};

export default styled(ImportParametersMapping)`
  .import-parameters-mapping-layout,
  .import-parameters-mapping-footer {
    background-color: ${colorGray0};
  }

  .import-parameters-mapping-content {
    padding: ${spacingXXLarge} 0;
  }

  .import-parameters-mapping-footer {
    border-top: 1px solid ${colorWidgetBorder};
    display: flex;
    column-gap: ${spacingLarge};
    align-items: center;
    justify-content: space-between;
    padding-right: 0;
    padding-left: 0;

    .registers-count {
      margin-right: auto;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: ${spacingMedium};
    }
  }
`;
