// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import useClickOutside from "@pg/common/build/hooks/useClickOutside";
import { Collapse } from "antd";
import React, { useRef } from "react";

import DuvalTriangleNoteHeader from "./DuvalTriangleNoteHeader";
import BrowserService from "core/app/services/BrowserService";

const { Panel } = Collapse;

interface IDuvalTriangleCollapseProps {
  note: React.ReactNode;
}

const DuvalTriangleCollapse = ({ note }: IDuvalTriangleCollapseProps) => {
  const ref = useRef<HTMLDivElement>();
  const [isClickedOutside, setIsClickedOutside] = React.useState(false);
  useClickOutside(ref, () => setIsClickedOutside(false));

  const activeKey = isClickedOutside ? 1 : null;

  const handleCollapseChange = () => {
    setIsClickedOutside(!isClickedOutside);
  };

  const isSafari = BrowserService.isSafari();

  return (
    <div ref={ref}>
      <Collapse
        activeKey={activeKey}
        onChange={handleCollapseChange}
        className={`duval-triangle-note ${isSafari ? "safari" : ""}`}
        expandIconPosition="right"
        bordered={false}
      >
        <Panel header={<DuvalTriangleNoteHeader />} key="1">
          {note}
        </Panel>
      </Collapse>
    </div>
  );
};

export default DuvalTriangleCollapse;
