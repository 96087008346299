// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import ButtonWithIcon from "common/button/ButtonWithIcon";
import AssetModal from "features/ConfigurationTool";
import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";

interface IEditAssetButtonProps {
  assetId?: string;
}

const EditAssetButton = ({ assetId }: IEditAssetButtonProps) => {
  const [assetModalVisible, setAssetModalVisible] = useState<boolean>(false);
  const handleModalCancel = useCallback(() => {
    setAssetModalVisible(false);
  }, []);

  return (
    <>
      <ButtonWithIcon
        type="link"
        onClick={() => setAssetModalVisible(true)}
        icon={<Icon name="edit" size="sm" />}
        dataQa="config-tool-edit-button"
      >
        <FormattedMessage
          defaultMessage="Edit"
          id="configuration_tool.action.edit"
        />
      </ButtonWithIcon>
      <AssetModal
        assetId={assetId}
        visible={assetModalVisible}
        onCancel={handleModalCancel}
      />
    </>
  );
};

export default EditAssetButton;
