// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";
import Data from "../../../../../core/data/models/Data";
import { IState } from "../../../../../reducers/Index";
import { getDataKey } from "../actions/loadHeaterStatus";
import IHeaterStatus from "../models/IHeaterStatus";

const getHeaterStatusSelector = createSelector(
  (state: IState) => (assetId: string) =>
    state.data[getDataKey(assetId)] as Data<IHeaterStatus>,
  (heaterStatus) => heaterStatus
);

export default getHeaterStatusSelector;
