// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Action } from "redux";

export enum Actions {
  DetailPage_RightPanel_Expand = "DetailPage_RightPanel_Expand",
  DetailPage_RightPanel_Collapse = "DetailPage_RightPanel_Collapse"
}

export const expand = (): Action<string> => ({
  type: Actions.DetailPage_RightPanel_Expand
});

export const collapse = (): Action<string> => ({
  type: Actions.DetailPage_RightPanel_Collapse
});
