// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";

import { IColumn } from "common/datagrid/DataGrid";
import SkeletonCell from "common/datagrid/SkeletonCell";

interface ISkeletonRowProps {
  columns: IColumn[];
}

const SkeletonRow = (props: ISkeletonRowProps) => {
  const { columns } = props;

  return (
    <div className="grid-row-container skeleton-row">
      <div className="grid-row">
        {columns.map((c, i) => (
          <SkeletonCell column={c} key={i} singleLine={i > 1} />
        ))}
      </div>
    </div>
  );
};

export default SkeletonRow;
