// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { isEmpty } from "lodash";
import { IBasicFilter } from "powerbi-models";
import { Action } from "redux";

import ILinkedReport from "core/app/models/ILinkedReport";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import IReport from "features/detailpage/models/IReport";
import { IAjaxAction } from "reducers/Index";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";

export enum Actions {
  DetailPage_Reports_SetPbiReports = "DetailPage_Reports_SetPbiReports",
  DetailPage_Reports_SetLinkedReports = "DetailPage_Reports_SetLinkedReports",
  DetailPage_Reports_ClearPbiReports = "DetailPage_Reports_ClearPbiReports",
  DetailPage_Reports_ClearLinkedReports = "DetailPage_Reports_ClearLinkedReports",
  DetailPage_Reports_SetDetailPagePbiReport = "DetailPage_Reports_SetDetailPagePbiReport"
}
export interface ISetDetailPagePbiReportAction extends Action<string> {
  reportName: string;
  filters: IBasicFilter[];
}

export interface IReportsActions
  extends IAjaxAction<IReport[] | ILinkedReport[]>,
    ISetDetailPagePbiReportAction {}

const pbiReportsPrefix = "Asset";

const setPbiReports = (
  request: JQuery.jqXHR,
  data?: IReport[]
): IAjaxAction<IReport[]> => ({
  type: Actions.DetailPage_Reports_SetPbiReports,
  request,
  data
});

const setLinkedReports = (
  request: JQuery.jqXHR,
  data?: ILinkedReport[]
): IAjaxAction<ILinkedReport[]> => ({
  type: Actions.DetailPage_Reports_SetLinkedReports,
  request,
  data
});

export const setDetailPagePbiReportAction = (
  reportName: string,
  filters: IBasicFilter[]
): ISetDetailPagePbiReportAction => ({
  type: Actions.DetailPage_Reports_SetDetailPagePbiReport,
  reportName,
  filters
});

export const loadPbiReports = (
  dispatch: AppDispatch,
  assetType: string
): void => {
  const url = UrlService.getApiUrl(config.api.powerbiembed.reportsByPrefix, [
    {
      name: "prefix",
      value: `${pbiReportsPrefix}.${assetType}`
    }
  ]);

  const request = EndpointService.getJson<IReport[]>(
    url,
    (request, data) => {
      sortPbiReports(data);
      dispatch(setPbiReports(request, data));
    },
    (request) => dispatch(setPbiReports(request))
  );

  dispatch(setPbiReports(request));
};

export const loadLinkedReports = (
  dispatch: AppDispatch,
  assetId: string
): void => {
  const url = UrlService.getApiUrl(
    config.api.reports.detailPageExternalReports,
    [
      {
        name: "reportType",
        value: "Asset"
      },
      {
        name: "assetId",
        value: assetId
      }
    ]
  );

  const request = EndpointService.getJson<ILinkedReport[]>(
    url,
    (request, data) => {
      sortLinkedReports(data);
      dispatch(setLinkedReports(request, data));
    },
    (request) => dispatch(setLinkedReports(request))
  );

  dispatch(setLinkedReports(request));
};

export const clearPbiReports = (): Action => {
  return {
    type: Actions.DetailPage_Reports_ClearPbiReports
  };
};

export const clearLinkedReports = (): Action => {
  return {
    type: Actions.DetailPage_Reports_ClearLinkedReports
  };
};

// #region Helpers

function sortPbiReports(data: IReport[]): void {
  if (!isEmpty(data)) {
    data.sort((a: IReport, b: IReport) => a.Name.localeCompare(b.Name));
  }
}

function sortLinkedReports(data: ILinkedReport[]): void {
  if (!isEmpty(data)) {
    data.sort((a: ILinkedReport, b: ILinkedReport) =>
      a.DisplayName.localeCompare(b.DisplayName)
    );
  }
}

// #endregion
