// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useState } from "react";

import { IConfigurationExtensions } from "common/chart/components/C3Chart";
import IConductanceData from "../models/IConductanceData";

const useChartExtensions = (conductanceData: IConductanceData) => {
  return useState<IConfigurationExtensions>({
    data: {
      class: i => {
        return conductanceData.Cells[i].IsPilotCell ? "pilot-cell" : "";
      }
    }
  });
};

export default useChartExtensions;
