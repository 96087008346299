// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import IAssetDetails from "../../../models/IAssetDetails";
import { getDataKey } from "../actions/loadAssetDetails";

const getAssetDetailsSelector = createSelector(
  (state: IState) => (assetId: string) =>
    state.data[getDataKey(assetId)] as Data<IAssetDetails>,
  (assetDetails) => assetDetails
);

export default getAssetDetailsSelector;
