// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";

import { IAnalyticsChartDataSet } from "./AnalyticsLineChart";
import AnalyticsChartLegendItem, {
  LegendItemState
} from "./AnalyticsChartLegendItem";

import "./AnalyticsChartLegendItem.less";
import "./AnalyticsChartLegend.less";

interface IAnalyticsChartLegendProps {
  series: string;
  plots: IAnalyticsChartDataSet[];
  onFocus?: (series: string, plot: string) => void;
  onRevert?: (series: string) => void;
  onToggle?: (series: string, plot: string) => void;
}

interface IAnalyticsChartLegendState {
  itemState: { [name: string]: LegendItemState };
}

class AnalyticsChartLegend extends React.Component<
  IAnalyticsChartLegendProps,
  IAnalyticsChartLegendState
> {
  constructor(props: IAnalyticsChartLegendProps) {
    super(props);
    this.state = { itemState: {} };
    this.defaultLegendState = this.defaultLegendState.bind(this);
    this.focusLegendItem = this.focusLegendItem.bind(this);
    this.toggleLegendItem = this.toggleLegendItem.bind(this);
  }

  public render() {
    return (
      <div className="analyticsLegend">
        {(this.props.plots || []).map((p, i) => (
          <React.Fragment key={`${this.props.series}_${p.name}_${i}`}>
            {!p.isHidden && (
              <AnalyticsChartLegendItem
                item={p}
                state={this.state.itemState[p.name]}
                onMouseOver={() => {
                  this.focusLegendItem(p.name);
                  this.props.onFocus(this.props.series, p.name);
                }}
                onMouseOut={() => {
                  this.defaultLegendState();
                  this.props.onRevert(this.props.series);
                }}
                onClick={() => {
                  this.toggleLegendItem(p.name);
                  this.props.onToggle(this.props.series, p.name);
                }}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    );
  }

  public UNSAFE_componentWillMount() {
    this.defaultLegendState();
  }

  private defaultLegendState() {
    this.setState(
      (
        prevState: IAnalyticsChartLegendState,
        props: IAnalyticsChartLegendProps
      ) => {
        const _state: IAnalyticsChartLegendState = Object.assign({}, prevState);
        (props.plots || []).forEach((p: IAnalyticsChartDataSet) => {
          const itemState = _state.itemState[p.name];
          _state.itemState[p.name] =
            itemState === LegendItemState.Disabled
              ? LegendItemState.Disabled
              : LegendItemState.Active;
        });
        return _state;
      }
    );
  }

  private focusLegendItem(itemName: string) {
    this.setState(
      (
        prevState: IAnalyticsChartLegendState,
        props: IAnalyticsChartLegendProps
      ) => {
        const _state: IAnalyticsChartLegendState = Object.assign({}, prevState);
        (props.plots || []).forEach((p: IAnalyticsChartDataSet) => {
          const itemState = _state.itemState[p.name];
          _state.itemState[p.name] =
            itemState === LegendItemState.Disabled
              ? LegendItemState.Disabled
              : LegendItemState.NoActive;
        });
        _state.itemState[itemName] =
          _state.itemState[itemName] === LegendItemState.Disabled
            ? LegendItemState.Disabled
            : LegendItemState.Active;
        return _state;
      }
    );
  }

  private toggleLegendItem(itemName: string) {
    this.setState(
      (
        prevState: IAnalyticsChartLegendState,
        props: IAnalyticsChartLegendProps
      ) => {
        const _state: IAnalyticsChartLegendState = Object.assign({}, prevState);
        (props.plots || []).forEach((p: IAnalyticsChartDataSet) => {
          const itemState = _state.itemState[p.name];
          _state.itemState[p.name] =
            itemState === LegendItemState.Disabled
              ? LegendItemState.Disabled
              : LegendItemState.Active;
        });
        _state.itemState[itemName] =
          _state.itemState[itemName] === LegendItemState.Disabled
            ? LegendItemState.Active
            : LegendItemState.Disabled;
        return _state;
      }
    );
  }
}

export default AnalyticsChartLegend;
