// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IPartialDischargeChart } from "@apm/widgets/build/widgets/PartialDischarge";
import Processing from "components/common/Processing";
import { WidgetErrorMessage } from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import { kebabCase } from "lodash";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import loadUltraHighFrequency from "../actions/loadUltraHighFrequency";
import removeUltraHighFrequency from "../actions/removeUltraHighFrequency";
import IPartialDischargeResult from "../models/IPartialDischargeResult";
import getUltraHighFrequencySelector from "../selectors/getUltraHighFrequencySelector";
import mapToReadings from "../utils/mapToReadings";
import PartialDischargeCharts from "./PartialDischargeCharts";

interface IUltraHighFrequencyChartsActions {
  loadUltraHighFrequency: (assetId: string) => void;
  removeUltraHighFrequency: (assetId: string) => void;
}

interface IUltraHighFrequencyChartsData {
  ultraHighFrequency: Data<IPartialDischargeResult>;
}

interface IUltraHighFrequencyChartsOwnProps {
  assetId: string;
  modelId: string;
  modelImplementationId: string;
}

interface IUltraHighFrequencyCharts
  extends IUltraHighFrequencyChartsActions,
    IUltraHighFrequencyChartsData,
    IUltraHighFrequencyChartsOwnProps {}

const UltraHighFrequencyCharts = ({
  assetId,
  loadUltraHighFrequency,
  modelId,
  modelImplementationId,
  removeUltraHighFrequency,
  ultraHighFrequency
}: IUltraHighFrequencyCharts) => {
  useEffect(() => {
    loadUltraHighFrequency(assetId);
    return () => {
      removeUltraHighFrequency(assetId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  return (
    <>
      {ultraHighFrequency?.status === Statuses.Loading && (
        <UltraHighFrequencyChartsLoading />
      )}
      {ultraHighFrequency?.status === Statuses.Succeeded && (
        <UltraHighFrequencyChartsSucceeded
          modelId={modelId}
          modelImplementationId={modelImplementationId}
          ultraHighFrequency={ultraHighFrequency.data}
        />
      )}
      {ultraHighFrequency?.status === Statuses.Failed && <WidgetErrorMessage />}
    </>
  );
};

const UltraHighFrequencyChartsLoading = () => {
  return <Processing />;
};

interface IUltraHighFrequencyChartsSucceededProps {
  modelId: string;
  modelImplementationId: string;
  ultraHighFrequency: IPartialDischargeResult;
}

const UltraHighFrequencyChartsSucceeded = ({
  modelId,
  modelImplementationId,
  ultraHighFrequency
}: IUltraHighFrequencyChartsSucceededProps) => {
  const intl = useIntl();

  return (
    <PartialDischargeCharts
      charts={["EventCount", "Amplitude", "DischargeRate"].map((key) => {
        const data = ultraHighFrequency[key];
        const chart: IPartialDischargeChart = {
          name: key,
          displayName: intl.formatMessage({
            defaultMessage: key,
            id: `detail_page.widgets.analytics.transformers.partial_discharge.${kebabCase(
              key
            )}`
          }),
          alertThreshold: data?.AlertThreshold,
          warningThreshold: data?.WarningThreshold,
          unit: data?.Unit,
          recentReadings: data ? mapToReadings(data.RecentReadings) : null,
          historicalReadings: data
            ? mapToReadings(data.HistoricalReadings)
            : null
        };

        return chart;
      })}
      modelId={modelId}
      modelImplementationId={modelImplementationId}
    />
  );
};

const mapStateToProps = (
  state: IState,
  { assetId }: IUltraHighFrequencyChartsOwnProps
): IUltraHighFrequencyChartsData => {
  return {
    ultraHighFrequency: getUltraHighFrequencySelector(state)(assetId)
  };
};

const mapDispatchToProps = (
  dispatch: AppDispatch
): IUltraHighFrequencyChartsActions => {
  return {
    loadUltraHighFrequency: (assetId: string) =>
      dispatch(loadUltraHighFrequency(assetId)),
    removeUltraHighFrequency: (assetId: string) =>
      dispatch(removeUltraHighFrequency(assetId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UltraHighFrequencyCharts);
