// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { setCanBeSavedAction } from "../actions/NotificationsActions";
import getCanBeSavedSelector from "../selectors/getCanBeSavedSelector";

const useSetCanBeSaved = () => {
  const dispatch = useAppDispatch();
  const canBeSaved = useAppSelector(getCanBeSavedSelector);

  const setCanBeSaved = useCallback(
    (value: boolean) => {
      if (canBeSaved !== value) {
        dispatch(setCanBeSavedAction(value));
      }
    },
    [canBeSaved, dispatch]
  );

  return { setCanBeSaved };
};

export default useSetCanBeSaved;
