// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { isEmpty, findIndex, isNil } from "lodash";
import Guid from "core/guid/Guid";

import { ITreeNode } from "common/form/components/Tree";
import ITableItem from "../models/ITableItem";
import IOrganizations from "../models/IOrganizations";
import IOrganizationsModelOutput from "../models/IOrganizationsModelOutput";
import IOrganizationModelOutput from "../models/IOrganizationModelOutput";
import INotificationsModelInput from "../models/INotificationsModelInput";

export default class NotificationsService {
  // #region Properties

  private static readonly uniqueOrganizationRegionSperator = `__${Guid.getUniqGuid()}__`;
  private static readonly uniqueUnknownString = `__${Guid.getUniqGuid()}__`;

  // #endregion

  // #region Public Methods

  public static convertIOrganizationsToITreeNode(
    organizations: IOrganizations
  ) {
    const treeNodes: ITreeNode[] = [];

    organizations.Organizations.forEach((o) => {
      const treeNode: ITreeNode = {
        id: o.Organization.Name,
        message: {
          defaultMessage: o.Organization.Name,
          id: o.Organization.Name
        },
        options: []
      };
      o.Regions.forEach((r) => {
        if (!isEmpty(r.Name)) {
          treeNode.options.push({
            id: this.getRegionId(o.Organization.Name, r.Name),
            message: {
              defaultMessage: r.Name,
              id: r.Name
            },
            selected: r.IsChecked
          });
        }
      });

      if (o.ContainsUnknownRegion && o.ContainsUnknownRegion.IsExist) {
        treeNode.options.push(
          this.createUnknownOption(
            this.getRegionId(o.Organization.Name, this.uniqueUnknownString),
            o.ContainsUnknownRegion.IsChecked
          )
        );
      }

      treeNodes.push(treeNode);
    });

    if (
      organizations.ContainsUnknownOrganization &&
      organizations.ContainsUnknownOrganization.IsExist
    ) {
      treeNodes.push(
        this.createUnknownOption(
          this.getUnknownOrganizationKey(),
          organizations.ContainsUnknownOrganization.IsChecked
        )
      );
    }

    return treeNodes;
  }

  public static convertIOrganizationsToStringArray(
    organizations: IOrganizations,
    allChecked?: boolean
  ) {
    const array: string[] = [];
    if (allChecked !== false) {
      organizations.Organizations.forEach((o) => {
        if (
          allChecked ||
          o.Organization.IsChecked ||
          this.allRegionsChecked(o.Regions)
        ) {
          array.push(o.Organization.Name);
        }

        o.Regions.forEach((r) => {
          if (!isEmpty(r.Name) && (allChecked || r.IsChecked)) {
            array.push(this.getRegionId(o.Organization.Name, r.Name));
          }
        });

        if (
          o.ContainsUnknownRegion.IsExist &&
          (o.ContainsUnknownRegion.IsChecked || allChecked)
        ) {
          array.push(this.getUnknownRegionKey(o.Organization.Name));
        }
      });

      if (
        organizations.ContainsUnknownOrganization.IsExist &&
        (organizations.ContainsUnknownOrganization.IsChecked || allChecked)
      ) {
        array.push(`${this.uniqueUnknownString}`);
      }
    }
    return array;
  }

  public static convertITableItemsToITreeNodes<T extends ITableItem>(
    list: Array<T>
  ): ITreeNode[] {
    const configurationOption: ITreeNode[] = [];
    this.createITreeNodesFromITableItems<T>(configurationOption, list);
    return configurationOption;
  }

  public static convertITableItemToStringArray<T extends ITableItem>(
    list: Array<T>,
    allChecked?: boolean
  ): string[] {
    const array: string[] = [];
    if (allChecked !== false) {
      this.createStringArrayFromTableItem(array, list, allChecked);
    }
    return array;
  }

  public static createOrganizationsModelOutput(
    checkedKeys: string[]
  ): IOrganizationsModelOutput {
    let containsUnknownOrganizations = false;
    const organizations: IOrganizationModelOutput = {};

    checkedKeys.forEach((key) => {
      if (
        NotificationsService.isUnknown(key) &&
        !containsUnknownOrganizations
      ) {
        containsUnknownOrganizations = true;
      }
      const splittedOrganizationRegion = NotificationsService.splitKeyByOrganizationRegionSeparator(
        key
      );
      const [organization, region] = splittedOrganizationRegion;
      switch (splittedOrganizationRegion.length) {
        case 1:
          if (
            !NotificationsService.isUnknown(key) &&
            isNil(organizations[organization])
          ) {
            organizations[key] = {
              ContainsUnknownRegion: false,
              Regions: []
            };
          }
          break;
        case 2:
          if (NotificationsService.isUnknown(region)) {
            if (isNil(organizations[organization])) {
              organizations[organization] = {
                ContainsUnknownRegion: true,
                Regions: []
              };
            } else {
              organizations[organization].ContainsUnknownRegion = true;
            }
          } else {
            if (isNil(organizations[organization])) {
              organizations[organization] = {
                ContainsUnknownRegion: false,
                Regions: [region]
              };
            } else {
              organizations[organization].Regions.push(region);
            }
          }
          break;
        default:
          break;
      }
    });

    return {
      ContainsUnknownOrganization: containsUnknownOrganizations,
      Organizations: organizations
    };
  }

  public static getUnknownOrganizationKey(): string {
    return `${this.uniqueUnknownString}`;
  }

  public static getUnknownRegionKey(organization: string): string {
    return this.getRegionId(organization, this.uniqueUnknownString);
  }

  public static getRegionId(
    organizationName: string,
    regionName: string
  ): string {
    return `${organizationName}${this.uniqueOrganizationRegionSperator}${regionName}`;
  }

  public static getUnknownMessageDescription() {
    return {
      id: "notifications.tree.options.unknown",
      defaultMessage: "All other (undefined)"
    };
  }

  public static splitKeyByOrganizationRegionSeparator(key: string) {
    return key.split(this.uniqueOrganizationRegionSperator);
  }

  public static isUnknown(key: string) {
    return key === this.uniqueUnknownString;
  }

  public static areNotificationsEnabled(modelInput: INotificationsModelInput) {
    if (!isNil(modelInput)) {
      const {
        DegradedToRed,
        DegradedToYellow,
        ImprovedToYellow,
        ImprovedToGreen,
        AssetTypes,
        Organizations
      } = modelInput;
      return (
        (DegradedToRed ||
          DegradedToYellow ||
          ImprovedToGreen ||
          ImprovedToYellow) &&
        this.anyAssetTypeChecked(AssetTypes) &&
        this.anyOrganizationWithRegionsChecked(Organizations)
      );
    }

    return false;
  }

  // #endregion

  // #region Private Methods

  private static createUnknownOption(id: string, selected: boolean): ITreeNode {
    return {
      id,
      message: this.getUnknownMessageDescription(),
      selected
    };
  }

  private static createStringArrayFromTableItem<T extends ITableItem>(
    stringArray: string[],
    list: Array<T>,
    allChecked: boolean
  ) {
    list.forEach((value: T) => {
      const id = value.Name;
      if (findIndex(stringArray, (x) => x === id) === -1) {
        if (allChecked || value.IsChecked) {
          stringArray.push(id);
        }
      }
    });
  }

  private static createITreeNodesFromITableItems<T extends ITableItem>(
    configurationOptions: ITreeNode[],
    list: Array<T>
  ) {
    list.forEach((value: T) => {
      if (
        findIndex(configurationOptions, (x) => x.message.id === value.Name) ===
          -1 &&
        !isEmpty(value.Name)
      ) {
        const configurationOption: ITreeNode = {
          id: value.Name,
          message: {
            id: value.Name,
            defaultMessage: value.Name
          },
          selected: value.IsChecked
        };

        configurationOptions.push(configurationOption);
      }
    });
  }

  private static allRegionsChecked(regions: ITableItem[]) {
    return !isEmpty(regions) && findIndex(regions, { IsChecked: false }) === -1;
  }

  private static anyAssetTypeChecked(assetTypes: ITableItem[]) {
    return (
      !isEmpty(assetTypes) && findIndex(assetTypes, { IsChecked: true }) !== -1
    );
  }

  private static anyOrganizationWithRegionsChecked({
    ContainsUnknownOrganization,
    Organizations
  }: IOrganizations) {
    const hasAnyOrganizationByRegionSelected =
      !isEmpty(Organizations) &&
      findIndex(Organizations, (o) => {
        return (
          o.Organization.IsChecked ||
          o.ContainsUnknownRegion.IsChecked ||
          findIndex(o.Regions, { IsChecked: true }) !== -1
        );
      }) !== -1;

    return (
      ContainsUnknownOrganization.IsChecked ||
      hasAnyOrganizationByRegionSelected
    );
  }

  // #endregion
}
