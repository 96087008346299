// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React, { useCallback, useEffect, useState } from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
import { isEmpty } from "lodash";

import ShowButtons from "./ShowButtons";
import Search from "./Search";
import LogGrid from "./LogGrid";
import useUrlSearchParams from "hooks/useUrlSearchParams";

import { spacingXLarge, spacingMedium } from "styles/StyleVariables";
import LogType from "../models/LogType";

const typesSearchKey = "l_types";
const searchValueKey = "l_search";

interface ILogTabProps {
  className?: string;
  assetId: string;
}

const LogTab = ({ assetId, className }: ILogTabProps) => {
  const { getUrlParam, setUrlParam } = useUrlSearchParams();
  const [logTypes, setLogTypes] = useState<string[]>(() => {
    try {
      const params: string[] = JSON.parse(getUrlParam(typesSearchKey));
      return params || [LogType.Operations, LogType.Alarms];
    } catch (e) {}

    return [];
  });
  const [search, setSearch] = useState<string>(() => {
    const search = getUrlParam(searchValueKey);
    return search || "";
  });

  const handleButtonClicked = useCallback(
    (id: LogType) => {
      const newActiveButtons = [...logTypes];
      const index = newActiveButtons.findIndex((x) => x === id);
      if (index > -1) {
        newActiveButtons.splice(index, 1);
      } else {
        newActiveButtons.push(id);
      }
      setUrlParam(
        typesSearchKey,
        isEmpty(newActiveButtons) ? null : JSON.stringify(newActiveButtons)
      );
      setLogTypes(newActiveButtons);
    },
    [logTypes, setUrlParam]
  );

  const handleAllClicked = useCallback(() => {
    const allLogTypes = Object.keys(LogType).map((x) => x);
    setUrlParam(typesSearchKey, JSON.stringify(allLogTypes));
    setLogTypes(allLogTypes);
  }, [setUrlParam]);

  const handleOnSearch = useCallback(
    (value: string) => {
      setUrlParam(searchValueKey, value);
      setSearch(value);
    },
    [setUrlParam]
  );

  useEffect(() => {
    setTimeout(() => {
      setUrlParam(typesSearchKey, JSON.stringify(logTypes));
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className}>
      <Row align="middle" className="log-bar">
        <Col span={20}>
          <ShowButtons
            activeButtons={logTypes}
            onClick={handleButtonClicked}
            onAllClick={handleAllClicked}
          />
        </Col>
        <Col span={4}>
          <Search defaultSearchValue={search} onSearch={handleOnSearch} />
        </Col>
      </Row>
      <LogGrid assetId={assetId} logTypes={logTypes} search={search} />
    </div>
  );
};

const StyledLogTab = styled(LogTab)`
  padding: ${spacingMedium};
  .log-bar {
    margin-bottom: ${spacingXLarge};
  }
`;

export default StyledLogTab;
