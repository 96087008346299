// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useCallback, useState } from "react";

const useBoolean = (defaultValue: boolean) => {
  const [value, setValue] = useState(defaultValue);
  return {
    value,
    setValue,
    toggle: useCallback(() => setValue((v) => !v), []),
    setTrue: useCallback(() => setValue(true), []),
    setFalse: useCallback(() => setValue(false), [])
  };
};

export default useBoolean;
