// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import loadData from "core/data/actions/loadData";
import UrlService from "core/data/services/UrlService";
import ParameterSources from "models/ParameterSources";
import { config } from "utils/AppConfig";

export const dataKey = "DetailPage_AssetParameters_Histogram";

const loadParameterHistogram = (
  assetId: string,
  parameterName: string,
  parameterSource: ParameterSources
) => {
  let url: string;
  if (parameterSource === "DerivativeParameter")
    url = UrlService.getApiUrl(
      config.api.detailPage.assetDerivativeParametersHistogram,
      { assetId, parameterName }
    );
  else
    url = UrlService.getApiUrl(config.api.detailPage.assetParametersHistogram, {
      assetId,
      parameterName
    });

  return loadData({ key: dataKey, url });
};

export default loadParameterHistogram;
