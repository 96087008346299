// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import moment from "moment";
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/ja";
import "moment/locale/pl";
import "moment/locale/pt-br";
import "moment/locale/zh-cn";

const setupMoment = (locale: string) => {
  switch (locale) {
    case "zh":
      moment.locale("zh-cn");
      break;
    case "pt":
      moment.locale("pt-br");
      break;
    default:
      moment.locale(locale);
  }

  moment.defaultFormat = moment.localeData(locale).longDateFormat("l");
};

export default setupMoment;
