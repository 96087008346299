// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { getDataKey } from "features/ConfigurationTool/actions/loadAssetsStatuses";
import IAssetStatus from "models/IAssetStatus";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";

const getAssetsStatusesSelector = createSelector(
  (state: IState) => () => state.data[getDataKey()] as Data<IAssetStatus[]>,
  (assetsStatuses) => assetsStatuses
);

export default getAssetsStatusesSelector;
