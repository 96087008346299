// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import DetailPageWidgetContext from "features/detailpage/contexts/DetailPageWidgetContext";
import { ReactNode, useMemo, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";

interface IDetailPageWidgetContextProvider {
  children?: ReactNode;
}

const DetailPageWidgetContextProvider = ({
  children
}: IDetailPageWidgetContextProvider) => {
  const { pathname } = useLocation();

  const isParametersListWidgetVisible = useMemo(
    () => matchPath("/Assets/DetailPage/Parameters/*", pathname) !== null,
    [pathname]
  );

  const [isAssetNameplateWidgetVisible, setIsAssetNameplateWidgetVisible] =
    useState<boolean>(false);

  return (
    <DetailPageWidgetContext.Provider
      value={{
        isParametersListWidgetVisible,
        isAssetNameplateWidgetVisible,
        setIsAssetNameplateWidgetVisible
      }}
    >
      {children}
    </DetailPageWidgetContext.Provider>
  );
};

export default DetailPageWidgetContextProvider;
