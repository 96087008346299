// Copyright 2016-2023 Hitachi Energy. All rights reserved.

const tapChangersOltcManufacturer = [
  "ge",
  "allis chalmers",
  "mcgraw/cooper/pennsylvania",
  "waukesha/magnatek",
  "westinghouse",
  "ferranti packard",
  "federal pacific",
  "reinhausen",
  "moloney",
  "abb",
  "other (specify)"
];

export default tapChangersOltcManufacturer;
