// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import loadData from "core/data/actions/loadData";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";

export const getDataKey = (assetId: string, parameterName: string) =>
  `DetailPage_Parameters_Operations-${assetId}-${parameterName}`;

const loadOperations = (assetId: string, parameterName: string) => {
  const url = UrlService.getApiUrl(
    config.api.detailPage.parametersOperationsUrl,
    null,
    { assetId, parameterName }
  );

  return loadData({
    key: getDataKey(assetId, parameterName),
    url,
    method: "get"
  });
};

export default loadOperations;
