// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { FormattedMessage } from "react-intl";
import DuvalPentagon from "./DuvalPentagon";
import IDuvalPoint from "../models/IDuvalPoint";
import createDuvalPentagonLegend from "../utils/createDuvalPentagonLegend";
import "./DuvalPentagonChart.less";
import DataSource from "common/DuvalAnalysis/models/DataSource";
import DuvalOctagramLegend from "common/DuvalAnalysis/components/DuvalOctagramLegend";
import isOnlineDataSource from "common/DuvalAnalysis/utils/isOnlineDataSource";

interface IDuvalPentagonChartProps {
  type: number;
  points: IDuvalPoint[];
  dataSource: DataSource;
}

const DuvalPentagonChart = ({
  type,
  points,
  dataSource
}: IDuvalPentagonChartProps) => {
  let legend = createDuvalPentagonLegend({ type: type });
  return (
    <div className="duval-pentagon">
      <div className="duval-pentagon-header">
        {type === 3 ? (
          <FormattedMessage
            id="detail_page.widgets.analytics.transformers.DuvalPentagon.TypeCombined"
            values={{ type: type }}
            defaultMessage={"Type {type} (Combined)"}
          />
        ) : (
          <FormattedMessage
            id="detail_page.widgets.analytics.transformers.DuvalPentagon.Type"
            values={{ type: type }}
            defaultMessage={"Type {type}"}
          />
        )}
      </div>
      <DuvalPentagon points={points} type={type} dataSource={dataSource} />
      <table className="legend">
        {isOnlineDataSource(dataSource) ? <DuvalOctagramLegend /> : <></>}
        <tbody>
          {Object.keys(legend).map((label, index) => (
            <tr key={index}>
              <td>{label}</td>
              <td>
                ={" "}
                <FormattedMessage
                  id={`detail_page.widgets.analytics.transformers.DuvalPentagon.${label}`}
                  defaultMessage={legend[label]}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DuvalPentagonChart;
