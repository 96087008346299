// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { List, Map } from "immutable";
import { MessageDescriptor } from "react-intl";

import Guid from "core/guid/Guid";
import Form from "../models/Form";
import FormRecord from "../models/FormRecord";
import Input from "../models/Input";
import InputRecord from "../models/InputRecord";
import IState from "../models/IState";
import IValidator from "../validators/IValidator";

export default class FormService {
  static createForm(state: IState, formName: string): IState {
    if (!state.forms) state.forms = Map();
    state.forms = state.forms.setIn([formName], new FormRecord());
    return { ...state };
  }

  static createInput(
    state: IState,
    formName: string,
    inputName: string,
    defaultValue: any,
    value: any
  ): IState {
    if (!state.forms.get(formName).inputs)
      state.forms.get(formName).inputs = Map();

    state.forms = state.forms.setIn(
      [formName, "inputs", inputName],
      new InputRecord({
        defaultValue: defaultValue,
        value: value,
        valid: true,
        guid: Guid.getUniqGuid()
      })
    );

    return { ...state };
  }

  static removeForm(state: IState, formName: string): IState {
    const formExists = state.forms.has(formName);
    if (formExists) state.forms = state.forms.remove(formName);
    return { ...state };
  }

  static resetForm(state: IState, formName: string): IState {
    if (state.forms.has(formName)) {
      let form = state.forms.get(formName);
      const inputs = form.inputs.map((input) =>
        input.set("guid", Guid.getUniqGuid())
      );
      form = form.set("inputs", inputs) as Form;

      state.forms = state.forms.set(formName, form);
      return { ...state };
    }

    return state;
  }

  static setInputValue(
    state: IState,
    formName: string,
    inputName: string,
    value: any,
    validators: IValidator<any>[]
  ): IState {
    if (state.forms.get(formName).inputs.has(inputName)) {
      state.forms = state.forms.updateIn(
        [formName, "inputs", inputName],
        (input: Input) => {
          const valid = validators
            ? FormService.validate(value, validators)
            : true;

          return input
            .set("value", value)
            .set("valid", valid === true)
            .set("errorMessages", valid === true ? undefined : List(valid));
        }
      );
    }

    return { ...state };
  }

  static setFormProps(state: IState, formName: string): IState {
    state.forms = state.forms.updateIn([formName], (form: Form) => {
      const valid = form.inputs.every((v) => v.valid);
      const hasChanged = !form.inputs.every((i) => i.defaultValue === i.value);
      return form.set("valid", valid).set("hasChanged", hasChanged);
    });

    return { ...state };
  }

  private static validate(
    value: any,
    validators: IValidator<any>[]
  ): true | MessageDescriptor[] {
    let valid = true;
    const errorMessages: MessageDescriptor[] = [];
    validators.forEach((v) => {
      if (valid && !v.validate(value)) {
        valid = false;
        errorMessages.push(v.errorMessage);
      }
    });

    return valid ? true : errorMessages;
  }
}
