// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Component, ComponentType, ReactNode } from "react";
import "./TooltipWrapper.less";

export enum Themes {
  Light,
  Dark
}

export enum TooltipPosition {
  BottomRight = "bottom-right",
  BottomCenter = "bottom-center",
  BottomLeft = "bottom-left",
  TopRight = "top-right",
  TopCenter = "top-center",
  TopLeft = "top-left"
}

interface ITooltipContainerProps {
  Tooltip: ComponentType;
  theme?: Themes;
  position?: TooltipPosition;
}

interface ITooltipContainerState {
  top: number;
  left: number;
  tooltipPosition: TooltipPosition;
  arrowLeft: number;
}

class TooltipContainer extends Component<
  ITooltipContainerProps,
  ITooltipContainerState
> {
  private readonly arrowSize: number = 15;
  private readonly leftRightContainerMargin: number = 10;

  constructor(props: ITooltipContainerProps) {
    super(props);

    this.state = {
      top: 0,
      left: 0,
      tooltipPosition: TooltipPosition.TopCenter,
      arrowLeft: 0
    };
  }

  componentDidMount() {
    this.calculatePosition();
  }

  render() {
    const { Tooltip, theme } = this.props;
    const { top, left, tooltipPosition, arrowLeft } = this.state;

    return (
      <div
        className={`
          tooltip-container
          ${theme === undefined && "light"}
          ${theme == null && "light"}
          ${theme === Themes.Light && "light"}
          ${theme === Themes.Dark && "dark"}
          ${tooltipPosition}
        `}
        style={{
          top: top,
          left: left
        }}
        ref={(r) => {
          this.containerRef = r;
        }}
      >
        <span className="before-arrow" style={{ left: arrowLeft }} />
        <Tooltip />
        <span className="after-arrow" style={{ left: arrowLeft }} />
      </div>
    );
  }

  private containerRef: HTMLElement;

  private calculatePosition = () => {
    const tooltipPosition = this.props.position || this.getTooltipPosition();
    const targetBoundingRect = this.getTargetElementBoundingRect();
    const containerBoundingRect = this.getTooltipContainerBoundingRect();
    const { top, left } = this.calculateTopLeftContainer(
      targetBoundingRect,
      containerBoundingRect,
      tooltipPosition
    );

    const arrowLeft = this.calculateArrowLeft(left, targetBoundingRect);

    this.setState({
      top,
      left,
      tooltipPosition,
      arrowLeft
    });
  };

  private calculateTopLeftContainer = (
    targetBoundingRect: ClientRect | DOMRect,
    containerBoundingRect: ClientRect | DOMRect,
    tooltipPosition: TooltipPosition
  ): { top: number; left: number } => {
    let top = 0;
    let left = 0;

    switch (tooltipPosition) {
      case TooltipPosition.TopCenter: {
        top =
          targetBoundingRect.top -
          containerBoundingRect.height -
          this.arrowSize;
        left =
          targetBoundingRect.left -
          containerBoundingRect.width / 2 +
          targetBoundingRect.width / 2;
        break;
      }
      case TooltipPosition.BottomCenter: {
        top =
          targetBoundingRect.top + targetBoundingRect.height + this.arrowSize;
        left =
          targetBoundingRect.left -
          containerBoundingRect.width / 2 +
          targetBoundingRect.width / 2;
        break;
      }
      case TooltipPosition.TopRight: {
        top =
          targetBoundingRect.top -
          containerBoundingRect.height -
          this.arrowSize;
        left = targetBoundingRect.left;
        break;
      }
      case TooltipPosition.BottomRight: {
        top =
          targetBoundingRect.top + targetBoundingRect.height + this.arrowSize;
        left = targetBoundingRect.left;
        break;
      }
      case TooltipPosition.TopLeft: {
        top =
          targetBoundingRect.top -
          containerBoundingRect.height -
          this.arrowSize;
        left =
          window.innerWidth -
          containerBoundingRect.width -
          this.leftRightContainerMargin;
        break;
      }
      case TooltipPosition.BottomLeft: {
        top =
          targetBoundingRect.top + targetBoundingRect.height + this.arrowSize;
        left =
          window.innerWidth -
          containerBoundingRect.width -
          this.leftRightContainerMargin;
      }
    }

    return { top, left };
  };

  private getTooltipPosition = (): TooltipPosition => {
    const containerBoundingRect = this.getTooltipContainerBoundingRect();
    const targetBoundingRect = this.getTargetElementBoundingRect();
    let tooltipPosition = TooltipPosition.TopCenter;

    if (
      targetBoundingRect.left + containerBoundingRect.width >
      window.innerWidth
    ) {
      tooltipPosition = TooltipPosition.TopLeft;
    }
    if (targetBoundingRect.right - containerBoundingRect.width < 0) {
      tooltipPosition = TooltipPosition.TopRight;
    }
    if (
      containerBoundingRect.height + this.arrowSize >
      targetBoundingRect.top
    ) {
      if (tooltipPosition === TooltipPosition.TopCenter) {
        tooltipPosition = TooltipPosition.BottomCenter;
      } else if (tooltipPosition === TooltipPosition.TopLeft) {
        tooltipPosition = TooltipPosition.BottomLeft;
      } else if (tooltipPosition === TooltipPosition.TopRight) {
        tooltipPosition = TooltipPosition.BottomRight;
      }
    }

    return tooltipPosition;
  };

  private calculateArrowLeft = (
    containerLeft: number,
    targetBoundingRect: ClientRect | DOMRect
  ): number => {
    return (
      targetBoundingRect.left + targetBoundingRect.width / 2 - containerLeft
    );
  };

  private getTargetElementBoundingRect = () => {
    return this.containerRef.parentElement.firstElementChild.getBoundingClientRect();
  };

  private getTooltipContainerBoundingRect = () => {
    return this.containerRef.getBoundingClientRect();
  };
}

interface ITooltipWrapperProps {
  children: ReactNode;
  className?: string;
  Tooltip: ComponentType;
  theme?: Themes;
  position?: TooltipPosition;
}

interface ITooltipWrapperState {
  showTooltip: boolean;
}

class TooltipWrapper extends Component<
  ITooltipWrapperProps,
  ITooltipWrapperState
> {
  constructor(props: ITooltipWrapperProps) {
    super(props);

    this.state = {
      showTooltip: false
    };
  }

  componentDidMount() {
    this.addEventListeners();
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  render() {
    const { showTooltip } = this.state;
    const { children, className, Tooltip, theme, position } = this.props;

    return (
      <div
        className={`tooltip-wrapper ${className}`}
        ref={(r) => {
          this.tooltipRef = r;
        }}
      >
        {children}
        {showTooltip && (
          <TooltipContainer
            Tooltip={Tooltip}
            theme={theme}
            position={position}
          />
        )}
      </div>
    );
  }

  private tooltipRef: HTMLElement;

  private addEventListeners = () => {
    this.tooltipRef.firstChild.addEventListener(
      "mouseover",
      this.handleMouseOver
    );
    this.tooltipRef.firstChild.addEventListener(
      "mouseout",
      this.handleMouseOut
    );
  };

  private removeEventListeners = () => {
    if (this.tooltipRef.firstChild) {
      this.tooltipRef.firstChild.removeEventListener(
        "mouseover",
        this.handleMouseOver
      );
      this.tooltipRef.firstChild.removeEventListener(
        "mouseout",
        this.handleMouseOut
      );
    }
  };

  private handleMouseOver = (): void => {
    this.setState({ showTooltip: true });
  };

  private handleMouseOut = (): void => {
    this.setState({ showTooltip: false });
  };
}

export default TooltipWrapper;
