// Copyright 2016-2023 Hitachi Energy. All rights reserved.
import DataGrid, {
  AutoCompleteCellEditor,
  DateCellEditor,
  IDataGridCellProps,
  IDataGridProps,
  IDataGridRowProps,
  IOnCellChangedConfig,
  ISelectOption,
  IValidationResult,
  NumberCellEditor,
  SelectCellEditor,
  SuppressKeyboardEventParams,
  TextCellEditor
} from "@apm/widgets/build/widgets/DataGrid";
import {
  CellEditorSelectorResult,
  CellValueChangedEvent,
  ICellEditorParams
} from "ag-grid-community";
import { Checkbox, Form, Typography } from "antd";
import { isNil, isNull } from "lodash";
import IField from "models/IField";
import { TypedValue } from "models/ITypedValue";
import moment, { Moment } from "moment";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { colorWidgetBackground } from "styles/ColorVariables";
import { spacingXXLarge } from "styles/StyleVariables";
import AssetModalContext from "../contexts/AssetModalContext";
import FormContext from "../contexts/FormContext";
import useDataValidation from "../hooks/useDataValidation";
import IGridRow from "../models/IGridRow";
import ISection from "../models/ISection";
import isSelectOption from "../utils/isSelectOption";

const { Title } = Typography;

interface ICustomSectionTableProps {
  className?: string;
  configuration: ISection;
  getInitialValue?: (
    fieldName: string
  ) => string | number | boolean | Date | Moment;
  onParameterChange?: (
    fieldName: string,
    fieldValue: string | number | boolean | Date,
    fieldType: TypedValue
  ) => void;
  onSectionInfoChange?: (
    sectionName: string,
    modified: boolean,
    invalid: boolean
  ) => void;
  fieldIsRequired: (fieldName: string) => boolean;
}

interface ICellConfig {
  [key: string]: IField;
}

const CustomSectionTable = ({
  className,
  configuration,
  getInitialValue,
  onParameterChange,
  onSectionInfoChange,
  fieldIsRequired
}: ICustomSectionTableProps) => {
  const intl = useIntl();
  const [initialData, setInitialData] = useState<IDataGridRowProps<IGridRow>[]>(
    []
  );
  const constantColumnKey = "col0";
  const validationFieldKey = useMemo(
    () => `${configuration.sectionName.id}_componentValidation`,
    [configuration.sectionName.id]
  );

  const {
    isReadOnlyMode,
    parameters,
    pushTableSectionData,
    updateTableSectionCellInfo,
    tableSectionsData
  } = useContext(AssetModalContext);

  const form = useContext(FormContext);

  const { validateValue, defaultMessages } = useDataValidation();

  const getCellId = useCallback((columnId: string, rowId: number) => {
    return `${columnId}${rowId}`;
  }, []);

  const mapListValuesToSelectOptions = useCallback(
    (listValues: string[] | boolean[] | number[] | ISelectOption[]) => {
      if (isSelectOption(listValues)) return listValues;
      return listValues.map((item) => {
        return {
          label: item.toString(),
          value: typeof item === "boolean" ? item.toString() : item
        };
      });
    },
    []
  );

  const cellsConfig = useMemo(() => {
    const cellConfiguration: ICellConfig = {};
    const colDef = configuration.sectionConfiguration.columns;

    configuration.sectionConfiguration.rows.forEach((_, i) => {
      configuration.fields
        .slice(i * colDef.length, (i + 1) * colDef.length)
        .forEach((field, j) => {
          cellConfiguration[getCellId(colDef[j].labelName.id, i)] = field;
        });
    });

    return cellConfiguration;
  }, [
    configuration.fields,
    configuration.sectionConfiguration.columns,
    configuration.sectionConfiguration.rows,
    getCellId
  ]);

  const customValueValidation = useCallback(
    (e: CellValueChangedEvent<IGridRow>): IValidationResult => {
      if (isNil(e.value))
        return { valid: false, validationMessage: defaultMessages.required };

      const fieldConfig = cellsConfig[getCellId(e.colDef.field, e.rowIndex)];
      const isRequired = fieldIsRequired(fieldConfig.fieldKey);

      return validateValue(e.value.cellValue, fieldConfig, isRequired);
    },
    [
      cellsConfig,
      defaultMessages.required,
      fieldIsRequired,
      getCellId,
      validateValue
    ]
  );

  const suppressKeyboardEvent = useCallback(
    (params: SuppressKeyboardEventParams<IGridRow>): boolean => {
      if (params.event.code === "Enter") {
        const fieldConfig =
          cellsConfig[getCellId(params.colDef.field, params.node.rowIndex)];
        return (
          fieldConfig.inputType === "list" ||
          fieldConfig.inputType === "DateTime"
        );
      }
      return false;
    },
    [cellsConfig, getCellId]
  );

  const valueEditorSelector = useCallback(
    (params: ICellEditorParams): CellEditorSelectorResult => {
      const cellId = getCellId(params.colDef.field, params.data.id);
      const cellConfig = cellsConfig[cellId];

      if (cellConfig) {
        switch (cellConfig.inputType) {
          case "String":
            return {
              component: TextCellEditor
            };
          case "Decimal":
            return {
              component: NumberCellEditor
            };
          case "list":
            return {
              component: SelectCellEditor,
              params: {
                options: mapListValuesToSelectOptions(cellConfig.listValues)
              }
            };
          case "autoComplete":
            return {
              component: AutoCompleteCellEditor,
              params: {
                options: mapListValuesToSelectOptions(cellConfig.listValues)
              }
            };
          case "DateTime":
            return {
              component: DateCellEditor,
              params: {
                disableFutureDates: cellConfig.disableFutureDates
              }
            };
          case "Bool":
            return {
              component: SelectCellEditor,
              params: {
                options: mapListValuesToSelectOptions(cellConfig.listValues)
              }
            };

          default:
            return {
              component: TextCellEditor
            };
        }
      }
      return {
        component: TextCellEditor
      };
    },
    [cellsConfig, getCellId, mapListValuesToSelectOptions]
  );

  const columns: IDataGridProps<IGridRow>["columns"] = useMemo(() => {
    const cols: IDataGridProps<IGridRow>["columns"] = [
      {
        field: constantColumnKey,
        headerName: "",
        editable: false,
        resizable: true,
        sortable: false,
        flex: 3
      }
    ];
    configuration.sectionConfiguration.columns.forEach((columnConfig, i) =>
      cols.push({
        field: `${columnConfig.labelName.id}`,
        headerName: intl.formatMessage(columnConfig.labelName),
        flex: columnConfig.span,
        editable: true && !isReadOnlyMode,
        resizable: true,
        sortable: false,
        editType: "Text",
        cellEditorSelector: valueEditorSelector,
        customValidation: customValueValidation,
        suppressKeyboardEvent
      })
    );
    return cols;
  }, [
    configuration.sectionConfiguration.columns,
    customValueValidation,
    intl,
    isReadOnlyMode,
    suppressKeyboardEvent,
    valueEditorSelector
  ]);

  useEffect(() => {
    if (
      isNull(tableSectionsData) ||
      !Object.keys(tableSectionsData).includes(configuration.sectionName.id)
    ) {
      const colDef = configuration.sectionConfiguration.columns;

      const data = configuration.sectionConfiguration.rows.map(
        (rowConfig, i) => {
          let cells: IDataGridCellProps<IGridRow>[] = [];

          cells.push({
            cellName: constantColumnKey,
            cellValue: intl.formatMessage({ ...rowConfig.labelName })
          });

          configuration.fields
            .slice(i * colDef.length, (i + 1) * colDef.length)
            .forEach((field, j) => {
              const preloadedValue = getInitialValue
                ? getInitialValue(field.fieldKey)
                : undefined;
              const valueNormalized =
                preloadedValue instanceof Date
                  ? moment(preloadedValue)
                  : typeof preloadedValue === "boolean"
                  ? preloadedValue?.toString()
                  : preloadedValue;
              const validationResult = validateValue(
                valueNormalized,
                field,
                !isNil(preloadedValue)
              );
              cells.push({
                cellName: `${colDef[j].labelName.id}`,
                cellValue: valueNormalized,
                modified: false,
                valid: validationResult.valid,
                validationMessage: validationResult.validationMessage
              });
            });
          return { id: i, cells: cells };
        }
      );

      setInitialData(data);
      pushTableSectionData(configuration.sectionName.id, data);
    } else setInitialData(tableSectionsData[configuration.sectionName.id]);
  }, [
    configuration,
    parameters,
    getCellId,
    getInitialValue,
    validateValue,
    tableSectionsData,
    pushTableSectionData,
    intl
  ]);

  const onCellChanged = useCallback(
    ({
      cellName,
      newValue,
      rowId,
      valid,
      modified,
      validationMessage,
      warning
    }: IOnCellChangedConfig) => {
      if (modified) {
        const cellConfig = cellsConfig[getCellId(cellName, rowId)];
        onParameterChange(cellConfig.fieldKey, newValue, cellConfig.dataType);
        updateTableSectionCellInfo(configuration.sectionName.id, rowId, {
          cellName,
          cellValue: newValue,
          valid,
          modified,
          warning,
          validationMessage
        });
      }
    },
    [
      getCellId,
      cellsConfig,
      onParameterChange,
      updateTableSectionCellInfo,
      configuration.sectionName.id
    ]
  );
  useEffect(() => {
    let validData = true;
    let modified = false;
    if (
      !isNull(tableSectionsData) &&
      Object.keys(tableSectionsData).includes(configuration.sectionName.id)
    ) {
      tableSectionsData[configuration.sectionName.id].forEach((row) => {
        if (row.cells.some((cell) => cell.valid === false)) validData = false;
        if (row.cells.some((cell) => cell.modified === true)) modified = true;
      });
    }
    if (onSectionInfoChange)
      onSectionInfoChange(configuration.sectionName.id, modified, !validData);
    form.setFieldValue(validationFieldKey, validData);
  }, [
    configuration.sectionName.id,
    form,
    onSectionInfoChange,
    tableSectionsData,
    validationFieldKey
  ]);

  return (
    <div className={`${className} section`}>
      <Title className="table-title" level={5}>
        <FormattedMessage {...configuration.sectionName} />
      </Title>
      <div className="grid-container">
        {initialData?.length > 0 && (
          <>
            <DataGrid<IGridRow>
              key={configuration.sectionName.id}
              data={initialData}
              columns={columns}
              onCellChanged={onCellChanged}
              gridHeight="100%"
              noRowsMessage={intl.formatMessage({
                id: "configuration_tool.no_rows_to_show",
                defaultMessage: "No rows to show"
              })}
              rowHeight={32}
              sizeColumnsToFit={false}
              dateFormat={moment.defaultFormat}
              className={isReadOnlyMode ? "readonly-grid" : ""}
            />
            <Form.Item
              name={`${configuration.sectionName.id}_componentValidation`}
              valuePropName="checked"
              style={{ display: "none" }}
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject()
                }
              ]}
            >
              <Checkbox>
                {`${configuration.sectionName.id}_componentValidation`}
              </Checkbox>
            </Form.Item>
          </>
        )}
      </div>
    </div>
  );
};

const StyledCustomSectionTable = styled(CustomSectionTable)`
  .table-title {
    padding: ${spacingXXLarge} ${spacingXXLarge} 0px ${spacingXXLarge};
  }

  .grid-container {
    .ag-root-wrapper-body.ag-layout-normal.ag-focus-managed {
      height: 100%;
      .ag-header-cell,
      .ag-cell:first-child {
        background-color: ${colorWidgetBackground};
      }
    }
  }

  .readonly-grid {
    .ag-cell:not(:first-child) {
      background-color: ${colorWidgetBackground};
    }
  }
`;

export default StyledCustomSectionTable;
