// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createContext } from "react";
import IDegradationScoreTrend from "../../../models/IDegradationScoreTrend";
import ISimulationConfig from "../models/ISimulationConfig";
import ISimulationRequest from "../models/ISimulationRequest";
import ISimulationSummary from "../models/ISimulationSummary";

export interface ISimulationModalContext {
  assetId?: string;
  degradationScoreTrend?: IDegradationScoreTrend;
  modelId?: string;
  implementationId?: string;
  simulations?: ISimulationSummary[];
  simulationConfig?: ISimulationConfig;
  postSimulation?: (request: ISimulationRequest) => Promise<string>;
  deleteSimulation?: (simulationId: string, active: boolean) => Promise<void>;
  updateSearchParams?: (simulationId: string) => void;
  pinSimulation?: (simulationId: number) => Promise<void>;
  unpinSimulation?: (simulationId: number) => Promise<void>;
}

const SimulationModalContext = createContext<ISimulationModalContext>({});

export default SimulationModalContext;
