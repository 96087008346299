// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Button } from "antd";
import { isNil } from "lodash";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";

interface IAssetRiskChangesGridFooterProps {
  rowsTotal: number;
  showAll: boolean;
  onShowAllClick?: (value: boolean) => void;
}

const AssetRiskChangesGridFooter = ({
  rowsTotal,
  showAll,
  onShowAllClick
}: IAssetRiskChangesGridFooterProps) => {
  const handleShowAllClick = useCallback(() => {
    if (onShowAllClick) {
      onShowAllClick(!showAll);
    }
  }, [onShowAllClick, showAll]);

  return (
    <div className="grid-row-content">
      <span className="default-grey-label">
        <FormattedMessage
          defaultMessage="Number of rows: {numberOfRows, number}"
          id="data_grid.footer.number_of_rows"
          values={{
            numberOfRows: isNil(rowsTotal) ? 0 : rowsTotal
          }}
        />
      </span>
      <Button
        data-qa={`${showAll ? "all-changes" : "risk-change-only"}`}
        onClick={handleShowAllClick}
        size="small"
        type="link"
      >
        {showAll ? (
          <FormattedMessage
            id="asset_risk_changes.grid.footer.show_risk_changes"
            defaultMessage="Show risk changes"
          />
        ) : (
          <FormattedMessage
            id="asset_risk_changes.grid.footer.show_all_changes"
            defaultMessage="Show all changes"
          />
        )}
      </Button>
    </div>
  );
};

export default AssetRiskChangesGridFooter;
