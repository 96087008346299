// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";
import { FormattedMessage } from "react-intl";

import "./Asset.less";

interface IAssetProps {
  assetId: string;
  assetName: string;
  assetType: string | undefined;
  location: string | undefined;
}

const Asset = (props: IAssetProps) => {
  const { assetId, assetName, assetType, location } = props;
  const showLongMessage = assetType !== undefined && location !== undefined;
  const showShortMessage = !showLongMessage;

  return (
    <div className="column asset">
      <div className="id">
        {assetId}
        {assetName && " - "}
        {assetName}
      </div>
      <div className="details">
        {showLongMessage && (
          <FormattedMessage
            defaultMessage="{location} / {assetType}"
            id="asset_query.grid.columns.asset.location_asset_type"
            values={{
              assetType: assetType,
              location: location
            }}
          />
        )}
        {showShortMessage && (
          <FormattedMessage
            defaultMessage="{value}"
            id="asset_query.grid.columns.asset.location_asset_type_short"
            values={{
              value: assetType || location
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Asset;
