// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";
import { useLocation } from "react-router";

const useModelConfigurationItem = () => {
  const location = useLocation();
  const searchParams = new SearchParams(location.search);
  const assetId = searchParams.get("assetId");

  return assetId;
};

export default useModelConfigurationItem;
