// Copyright 2016-2023 Hitachi Energy. All rights reserved.

/* eslint-disable jsx-a11y/anchor-is-valid */
import SearchParams from "@pg/common/build/models/SearchParams";
import { routes } from "core/app/components/AppRoutes";
import { useAppNavigate } from "core/app/components/RouterProvider";
import "./SlidePanelTitle.less";

export interface ISlidePanelTitleProps {
  assetId: string;
  assetName: string;
}

const SlidePanelTitle = ({ assetId }: ISlidePanelTitleProps) => {
  const searchParams = new SearchParams({ assetId });
  const navigate = useAppNavigate();

  return (
    <a
      className="slide-panel-title bold"
      onClick={() => {
        navigate({
          pathname: routes.detailPage.pathname,
          search: searchParams.toString()
        });
      }}
      data-qa="asset-header"
    >
      {assetId}
    </a>
  );
};

export default SlidePanelTitle;
