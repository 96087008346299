// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IRow } from "common/datagrid/DataGrid";
import IParameterInfo from "../../../models/IParameterInfo";
import isClickable from "./isClickable";

const showFamilyInfo = (
  row: IRow<IParameterInfo>,
  isInFamily: boolean,
  lifecycleStatus: string
) => {
  const clickable = isClickable(row);
  return isInFamily && clickable && lifecycleStatus === "InService";
};

export default showFamilyInfo;
