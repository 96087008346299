// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { notifications } from "@pg/common/build/components/Notifications";
import { IActionConfig, IRow } from "common/datagrid/DataGrid";
import useCloseIssueAction from "features/issues/hooks/useCloseIssueAction";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

type callbackType = (
  row: IRow,
  onStarted: () => void,
  onSucceeded: () => void,
  onFailed: () => void
) => void;

const useIssuesGridActionConfig = () => {
  const intl = useIntl();
  const closeIssue = useCloseIssueAction();

  const handleCloseIssueConfirm = useCallback(
    async (
      assetIssueId: string,
      onStarted: () => void,
      onSucceeded: () => void,
      onFailed: () => void
    ) => {
      onStarted();

      try {
        await closeIssue(assetIssueId);
        notifications.success({
          message: intl.formatMessage({
            id: "issues_page.issue.close.success",
            defaultMessage: "Issue was closed"
          })
        });

        onSucceeded();
      } catch (e) {
        notifications.error({
          message: intl.formatMessage(
            {
              id: "issues_page.issue.close.failed",
              defaultMessage: "Cannot close issue. Response code: {code}"
            },
            { code: e.status }
          )
        });

        onFailed();
      }
    },
    [closeIssue, intl]
  );

  const callback: callbackType = useCallback(
    (row, onStarted, onSucceeded, onFailed) => {
      const assetIssueId = row.data["AssetIssueId"];
      const numberOfActions: number = row.data["NumberOfActions"];

      notifications.confirm({
        title: intl.formatMessage({
          id: numberOfActions
            ? "issues_page.issue.close.opened_actions_confirmation"
            : "issues_page.issue.close.confirmation",
          defaultMessage: numberOfActions
            ? "This issue has opened actions, are you sure you want to close it?"
            : "Are you sure you want to close the issue?"
        }),
        onOk: () => {
          handleCloseIssueConfirm(
            assetIssueId,
            onStarted,
            onSucceeded,
            onFailed
          );
        }
      });
    },
    [handleCloseIssueConfirm, intl]
  );

  const actions: IActionConfig[] = useMemo(
    () => [
      {
        callback,
        iconName: "clear",
        id: "close"
      }
    ],
    [callback]
  );

  return actions;
};

export default useIssuesGridActionConfig;
