// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { createSelector } from "reselect";
import { IState } from "../../../reducers/Index";
import { dataKey } from "../actions/loadTemplates";
import { ITemplateInfo } from "../models/ITemplate";

const getTemplatesSelector = createSelector(
  (state: IState) => state.data[dataKey] as Data<ITemplateInfo[]>,
  (templates) => templates
);

export default getTemplatesSelector;
