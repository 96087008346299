// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useAppNavigate } from "core/app/components/RouterProvider";
import { IScatterPlotDataPoints } from "features/familyanalytics/components/ScatterPlot";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import ScatterPlot from "./ScatterPlot";
import "./ScatterPlotChart.less";

export interface IScatterPlotChartProps {
  assetId: string;
  labelX: string;
  labelY: string;
  scatterPlotYData?: IScatterPlotDataPoints;
  scatterPlotXData?: IScatterPlotDataPoints;
  showCorrelation: boolean;
}

const ScatterPlotChart = ({
  assetId,
  labelX,
  labelY,
  scatterPlotYData,
  scatterPlotXData,
  showCorrelation
}: IScatterPlotChartProps) => {
  const navigate = useAppNavigate();
  const intl = useIntl();
  const htmlContainerRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const scatterPlot = new ScatterPlot(
      htmlContainerRef.current,
      assetId,
      labelX,
      labelY,
      scatterPlotYData,
      scatterPlotXData,
      showCorrelation
    );
    scatterPlot.draw(intl, navigate);

    return () => {
      scatterPlot.destroy();
    };
  }, [
    assetId,
    labelX,
    labelY,
    scatterPlotYData,
    scatterPlotXData,
    showCorrelation,
    htmlContainerRef,
    intl,
    navigate
  ]);

  return (
    <div
      className="scatter-plot-chart"
      ref={htmlContainerRef}
      data-qa="scatter-plot-chart"
    />
  );
};

export default ScatterPlotChart;
