// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Col, Popover, Row, Typography } from "antd";
import ReplacementScoreFormatter from "common/formatters/ReplacementScore";
import { ReplacementScoreTooltip } from "common/ReplacementScore";
import { isNil } from "lodash";
import { memo, useContext } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import AssetRibbonContext from "../contexts/AssetRibbonContext";
import Label from "./Label";
import NumberValue from "./NumberValue";
import "./ReplacementScore.less";
import ReplacementScoreFamilyIcon from "./ReplacementScoreFamilyIcon";

const { Text } = Typography;

interface IReplacementScoreContextProps {
  assetId: string;
}

interface IReplacementScoreOwnProps {
  rank: number;
  total: number;
  replacementScore: number;
  isWarning: boolean;
  isInFamily: boolean;
  assetType: string;
  lifecycleStatus: string;
}

type ReplacementScoreProps = IReplacementScoreContextProps &
  IReplacementScoreOwnProps;

const ReplacementScore = memo(
  ({
    rank,
    total,
    isWarning,
    assetType,
    replacementScore,
    assetId,
    isInFamily,
    lifecycleStatus
  }: ReplacementScoreProps) => {
    const showRank = !isNil(rank) && !isNil(total);

    return (
      <div className="asset-detail-replacement-score asset-ribbon-section">
        <div className="asset-ribbon-column">
          <div data-qa="header-replacement-label">
            <Row justify="space-between">
              <Col>
                <Label>
                  <FormattedMessage
                    id="asset_detail_page.header.replacement_score"
                    defaultMessage="Replacement Rank"
                  />
                </Label>
              </Col>
              <Col>
                {isInFamily && lifecycleStatus === "InService" && (
                  <ReplacementScoreFamilyIcon assetId={assetId} />
                )}
              </Col>
            </Row>
          </div>
          <div>
            {showRank ? (
              <Popover
                content={
                  <ReplacementScoreTooltip
                    assetType={assetType}
                    rank={rank}
                    scoreValue={replacementScore}
                    total={total}
                  />
                }
              >
                <div data-qa="header-replacement-value">
                  <Row className="asset-detail-replacement-score-grid-row">
                    <Col
                      className={`replacement-score ${
                        isWarning ? "warning" : ""
                      }`}
                    >
                      <NumberValue>
                        #<FormattedNumber value={rank} />
                      </NumberValue>
                    </Col>
                    <Col className="asset-detail-replacement-score-out-of-column">
                      <Text>
                        <FormattedMessage
                          id="asset_detail_page.replacement_score.rank.out_of"
                          defaultMessage="out of"
                        />
                      </Text>
                      <div className="asset-detail-replacement-score-total">
                        <Text strong={true}>
                          <FormattedNumber value={total} />
                        </Text>
                        <Text>{assetType}</Text>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Popover>
            ) : (
              <NumberValue>
                <ReplacementScoreFormatter value={replacementScore} />
              </NumberValue>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default (props: IReplacementScoreOwnProps) => {
  const { assetId } = useContext(AssetRibbonContext);
  return <ReplacementScore {...props} assetId={assetId} />;
};
