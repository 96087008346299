// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { AppDispatch } from "store";
import removeDataAction from "./removeDataAction";

const removeData = (key: string) => (dispatch: AppDispatch) =>
  new Promise<void>((resolve) => {
    dispatch(removeDataAction(key));
    resolve();
  });

export default removeData;
