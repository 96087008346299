// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

import Label from "./Label";
import NumberValue from "./NumberValue";

interface IParametersProps {
  parametersCount: number;
}

const Parameters = ({ parametersCount }: IParametersProps) => {
  return (
    <div
      className="asset-detail-parameters asset-ribbon-section"
    >
      <div className="asset-ribbon-column">
        <div data-qa="header-parameters-label">
          <Label>
            <FormattedMessage
              id="asset_detail_page.header.parameters"
              defaultMessage="Parameters"
            />
          </Label>
        </div>
        <div data-qa="header-parameters-count">
          <NumberValue className="blue-accent">
            <FormattedNumber value={parametersCount} />
          </NumberValue>
        </div>
      </div>
    </div>
  );
};

export default Parameters;
