// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Button } from "antd";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { IColumn, IExportToExcel } from "./DataGrid";


interface IExportToExcelButtonProps {
  exportToExcel?: IExportToExcel;
  columns: IColumn[];
}

const ExportToExcelButton = ({
  exportToExcel,
  columns
}: IExportToExcelButtonProps) => {
  const handleExportDataClick = useCallback(() => {
    exportToExcel?.onClick(columns);
  }, [columns, exportToExcel]);

  return (
    <Button
      className="light"
      disabled={exportToExcel.disabled}
      type="link"
      onClick={handleExportDataClick}
      data-qa="export-excel-button"
    >
      <FormattedMessage
        defaultMessage="Export to Excel"
        id="data_grid.footer.export.excel"
      />
    </Button>
  );
};

export default ExportToExcelButton;
