// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useCallback, useMemo } from "react";

import IAssetPoint from "common/AssetRiskMatrix/models/IAssetPoint";
import ICluster from "common/AssetRiskMatrix/models/ICluster";
import ClusterService from "common/AssetRiskMatrix/services/ClustersService";
import IPrognosticPoint from "common/AssetRiskMatrix/models/IPrognosticPoint";

interface IUseAssetRiskMatrixScatterData {
  currentAsset: IAssetPoint;
  otherAssets?: IAssetPoint[];
  clusters?: ICluster[];
  prognosticPoints?: IPrognosticPoint[];
}

const useAssetRiskMatrixScatterData = ({
  currentAsset,
  otherAssets,
  clusters,
  prognosticPoints
}: IUseAssetRiskMatrixScatterData) => {
  const sortOtherAssetPredicate = useCallback(
    (p1: IAssetPoint, p2: IAssetPoint) =>
      p1.Score !== p2.Score
        ? p1.Score - p2.Score
        : p1.Importance !== p2.Importance
        ? p1.Importance - p2.Importance
        : p1.AssetId !== p2.AssetId
        ? p1.AssetId < p2.AssetId
          ? -1
          : 1
        : 0,
    []
  );

  const sortedOtherAssets = useMemo(() => {
    return otherAssets ? otherAssets.sort(sortOtherAssetPredicate) : null;
  }, [otherAssets, sortOtherAssetPredicate]);

  const sortGroupedClustersPredicate = useCallback(
    (c1: ICluster, c2: ICluster) =>
      c1.Score !== c2.Score
        ? (c1.Score as number) - (c2.Score as number)
        : c1.Importance !== c2.Importance
        ? c1.Importance - c2.Importance
          ? -1
          : 1
        : 0,
    []
  );

  const groupedClusters = useMemo(
    () =>
      clusters
        ? ClusterService.getGroupedClusters(
            clusters,
            sortGroupedClustersPredicate
          )
        : null,
    [clusters, sortGroupedClustersPredicate]
  );

  const sortPrognosticPointsPredicate = useCallback(
    (p1: IPrognosticPoint, p2: IPrognosticPoint) =>
      p1.Score !== p2.Score
        ? p1.Score - p2.Score
        : p1.Importance !== p2.Importance
        ? p1.Importance - p2.Importance
          ? -1
          : 1
        : 0,
    []
  );

  const sortedPrognosticPoints = useMemo(
    () =>
      prognosticPoints
        ? prognosticPoints.sort(sortPrognosticPointsPredicate)
        : null,
    [prognosticPoints, sortPrognosticPointsPredicate]
  );

  const getAssetData = useCallback(
    (seriesName: string, idx: number) => {
      if (seriesName === "current_asset") {
        return currentAsset;
      } else if (seriesName === "other_assets" && sortedOtherAssets) {
        return sortedOtherAssets[idx];
      }

      return null;
    },
    [currentAsset, sortedOtherAssets]
  );

  const getClusterData = useCallback(
    (seriesName: string, idx: number) => {
      const id = ClusterService.getTierIdBySeriesName(seriesName);

      if (groupedClusters && groupedClusters[id]) {
        return groupedClusters[id][idx];
      }

      return null;
    },
    [groupedClusters]
  );

  const getPrognosticPointData = useCallback(
    (seriesName: string, idx: number) => {
      return sortedPrognosticPoints && sortedPrognosticPoints[idx];
    },
    [sortedPrognosticPoints]
  );

  return {
    groupedClusters,
    getAssetData,
    getClusterData,
    getPrognosticPointData
  };
};

export default useAssetRiskMatrixScatterData;
