// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import AssetRiskMatrix, {
  IAssetRiskConfigurationValue
} from "common/AssetRiskMatrix";
import Container from "common/Container";
import SectionName from "components/common/SectionName";
import Guid from "core/guid/Guid";
import React from "react";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { ModelMode } from "store/dashboards/dataintegration/DataIntegrationStore";
import "./AssetRiskConfiguration.less";
import preventEnterPressedInputMixin from "./PreventEnterPressed";

interface IAssetRiskConfigurationProps {
  modelId: string;
  thresholds: IAssetRiskConfigurationValue;
  onChange: (value: IAssetRiskConfigurationValue) => void;
  mode: ModelMode;
  isThresholdEditable: boolean;
  intl: IntlShape;
}

interface IAssetRiskConfigurationState {
  mth_s: string | null;
  mth_i: string | null;
  ltm_s: string | null;
  ltm_i: string | null;
}

function localizedParseFloat(
  text: string | null | undefined,
  intl: IntlShape
): number | null {
  if (text === null || text === undefined) return null;
  let s = intl.formatNumber(5.5, {
    style: "decimal",
    useGrouping: false,
    minimumIntegerDigits: 1,
    maximumFractionDigits: 1,
    minimumFractionDigits: 1
  });
  s = s.replace(/\+|\(|\)|5/g, "");
  const decSep = s;

  text = text.replace(new RegExp("\\" + decSep), ".");

  let result = parseFloat(text);
  if (
    result === undefined ||
    result === null ||
    isNaN(result) ||
    !isFinite(result)
  )
    result = null;
  return result;
}

class AssetRiskConfiguration extends React.PureComponent<
  IAssetRiskConfigurationProps,
  IAssetRiskConfigurationState
> {
  private resetKey: string;

  constructor(props: IAssetRiskConfigurationProps) {
    super(props);
    this.resetKey = Guid.getUniqGuid();
    this.state = {
      mth_s: null,
      mth_i: null,
      ltm_s: null,
      ltm_i: null
    };

    this.inputChangeEventHandler = this.inputChangeEventHandler.bind(this);
  }

  public render(): JSX.Element {
    const s: IAssetRiskConfigurationValue = {
      LowToMediumRiskLineThreshold: {
        Slope:
          this.props.thresholds &&
          this.props.thresholds.LowToMediumRiskLineThreshold
            ? this.props.thresholds.LowToMediumRiskLineThreshold.Slope
            : null,
        Intercept:
          this.props.thresholds &&
          this.props.thresholds.LowToMediumRiskLineThreshold
            ? this.props.thresholds.LowToMediumRiskLineThreshold.Intercept
            : null
      },
      MediumToHighRiskLineThreshold: {
        Slope:
          this.props.thresholds &&
          this.props.thresholds.MediumToHighRiskLineThreshold
            ? this.props.thresholds.MediumToHighRiskLineThreshold.Slope
            : null,
        Intercept:
          this.props.thresholds &&
          this.props.thresholds.MediumToHighRiskLineThreshold
            ? this.props.thresholds.MediumToHighRiskLineThreshold.Intercept
            : null
      }
    };

    const ltm_s_ev = (e: React.FormEvent<HTMLInputElement>): void => {
      this.inputChangeEventHandler(
        e,
        (sNum: string | null, nNum: number | null): void => {
          if (this.state.ltm_s !== sNum) {
            this.setState((prevState, props) => {
              return Object.assign({}, prevState, {
                ltm_s: sNum
              });
            });
          }
          if (nNum !== null) nNum = -nNum; // Slope is positive from user, but has to be negative in store/props
          if (s.LowToMediumRiskLineThreshold.Slope !== nNum) {
            const ns: IAssetRiskConfigurationValue = {
              LowToMediumRiskLineThreshold: {
                Slope: nNum,
                Intercept: s.LowToMediumRiskLineThreshold.Intercept
              },
              MediumToHighRiskLineThreshold: s.MediumToHighRiskLineThreshold
            };
            this.props.onChange(ns);
          }
        }
      );
    };

    const ltm_i_ev = (e: React.FormEvent<HTMLInputElement>): void => {
      this.inputChangeEventHandler(
        e,
        (sNum: string | null, nNum: number | null): void => {
          if (this.state.ltm_i !== sNum) {
            this.setState((prevState, props) => {
              return Object.assign({}, prevState, {
                ltm_i: sNum
              });
            });
          }
          if (s.LowToMediumRiskLineThreshold.Intercept !== nNum) {
            const ns: IAssetRiskConfigurationValue = {
              LowToMediumRiskLineThreshold: {
                Slope: s.LowToMediumRiskLineThreshold.Slope,
                Intercept: nNum
              },
              MediumToHighRiskLineThreshold: s.MediumToHighRiskLineThreshold
            };
            this.props.onChange(ns);
          }
        }
      );
    };

    const mth_s_ev = (e: React.FormEvent<HTMLInputElement>): void => {
      this.inputChangeEventHandler(
        e,
        (sNum: string | null, nNum: number | null): void => {
          if (this.state.mth_s !== sNum) {
            this.setState((prevState, props) => {
              return Object.assign({}, prevState, {
                mth_s: sNum
              });
            });
          }
          if (nNum !== null) nNum = -nNum; // Slope is positive from user, but has to be negative in store/props
          if (s.MediumToHighRiskLineThreshold.Slope !== nNum) {
            const ns: IAssetRiskConfigurationValue = {
              LowToMediumRiskLineThreshold: s.LowToMediumRiskLineThreshold,
              MediumToHighRiskLineThreshold: {
                Slope: nNum,
                Intercept: s.MediumToHighRiskLineThreshold.Intercept
              }
            };
            this.props.onChange(ns);
          }
        }
      );
    };

    const mth_i_ev = (e: React.FormEvent<HTMLInputElement>): void => {
      this.inputChangeEventHandler(
        e,
        (sNum: string | null, nNum: number | null): void => {
          if (this.state.mth_i !== sNum) {
            this.setState((prevState, props) => {
              return Object.assign({}, prevState, {
                mth_i: sNum
              });
            });
          }
          if (s.MediumToHighRiskLineThreshold.Intercept !== nNum) {
            const ns: IAssetRiskConfigurationValue = {
              LowToMediumRiskLineThreshold: s.LowToMediumRiskLineThreshold,
              MediumToHighRiskLineThreshold: {
                Slope: s.MediumToHighRiskLineThreshold.Slope,
                Intercept: nNum
              }
            };
            this.props.onChange(ns);
          }
        }
      );
    };

    const slopePlaceholder = this.props.intl.formatMessage({
      id: "data_integration.model_config.thresholds.slope_placeholder",
      defaultMessage: "slope"
    });
    const interceptPlaceholder = this.props.intl.formatMessage({
      id: "data_integration.model_config.thresholds.intercept_placeholder",
      defaultMessage: "intercept"
    });

    return (
      <Container className="asset-risk-config">
        <SectionName
          messageId="data_integration.model_config.asset_risk_config"
          messageDefault="Risk Configuration"
        />
        <div className="content">
          <div className="asset-risk-chart-wrapper">
            <AssetRiskMatrix
              thresholds={this.props.thresholds}
              className="chart"
              showLines={true}
              chartSize={{
                width: 350,
                height: 300
              }}
            />
          </div>
          <div className="thresholdConfig">
            <div className="threshold-config-title">
              <FormattedMessage
                id="data_integration.model_config.thresholds_title"
                defaultMessage="Configure risk threshold lines"
              />
            </div>
            <div className="threshold-line-label" data-qa="ltm-label">
              <FormattedMessage
                id="data_integration.model_config.thresholds.low_medium_title"
                defaultMessage="Low / Medium"
              />
            </div>

            <div
              className={
                this.props.isThresholdEditable
                  ? "threshold-line-formula"
                  : "threshold-line-formula-disabled"
              }
              data-qa="ltm-line"
            >
              <FormattedMessage
                id="data_integration.model_config.threshold_formula"
                defaultMessage="Importance = - {slope} · condition + {intercept}"
                values={{
                  slope: (
                    <input
                      id={"ahc_ltm_s_" + this.resetKey}
                      className="form-input"
                      min={0.0}
                      max={9999999999999999999.99999}
                      type="number"
                      step="any"
                      required={true}
                      placeholder={slopePlaceholder}
                      spellCheck={false}
                      size={5}
                      name="ltmSlope"
                      data-qa="ltmSlope"
                      autoComplete="off"
                      value={
                        this.state.ltm_s !== null
                          ? this.state.ltm_s
                          : -s.LowToMediumRiskLineThreshold.Slope
                      }
                      onChange={ltm_s_ev}
                      onInput={ltm_s_ev}
                      {...preventEnterPressedInputMixin}
                    />
                  ),
                  intercept: (
                    <input
                      id={"ahc_ltm_i_" + this.resetKey}
                      className="form-input"
                      min={0.0}
                      max={9999999999999999999.99999}
                      type="number"
                      step="any"
                      required={true}
                      placeholder={interceptPlaceholder}
                      spellCheck={false}
                      size={5}
                      name="ltmIntercept"
                      data-qa="ltmIntercept"
                      autoComplete="off"
                      value={
                        this.state.ltm_i !== null
                          ? this.state.ltm_i
                          : s.LowToMediumRiskLineThreshold.Intercept
                      }
                      onChange={ltm_i_ev}
                      onInput={ltm_i_ev}
                      {...preventEnterPressedInputMixin}
                    />
                  )
                }}
              />
            </div>
            <div className="threshold-line-label" data-qa="mth-label">
              <FormattedMessage
                id="data_integration.model_config.thresholds.medium_high_title"
                defaultMessage="Medium / High"
              />
            </div>
            <div
              className={
                this.props.isThresholdEditable
                  ? "threshold-line-formula"
                  : "threshold-line-formula-disabled"
              }
              data-qa="mth-line"
            >
              <FormattedMessage
                id="data_integration.model_config.threshold_formula"
                defaultMessage="Importance = - {slope} · condition + {intercept}"
                values={{
                  slope: (
                    <input
                      id={"ahc_mth_s_" + this.resetKey}
                      className="form-input"
                      min={0.0}
                      max={9999999999999999999.99999}
                      type="number"
                      step="any"
                      required={true}
                      placeholder={slopePlaceholder}
                      spellCheck={false}
                      size={5}
                      name="mthSlope"
                      data-qa="mthSlope"
                      autoComplete="off"
                      value={
                        this.state.mth_s !== null
                          ? this.state.mth_s
                          : -s.MediumToHighRiskLineThreshold.Slope
                      }
                      onChange={mth_s_ev}
                      onInput={mth_s_ev}
                      {...preventEnterPressedInputMixin}
                    />
                  ),
                  intercept: (
                    <input
                      id={"ahc_mth_i_" + this.resetKey}
                      className="form-input"
                      min={0.0}
                      max={9999999999999999999.99999}
                      type="number"
                      step="any"
                      required={true}
                      placeholder={interceptPlaceholder}
                      spellCheck={false}
                      size={5}
                      name="mthIntercept"
                      data-qa="mthIntercept"
                      autoComplete="off"
                      value={
                        this.state.mth_i !== null
                          ? this.state.mth_i
                          : s.MediumToHighRiskLineThreshold.Intercept
                      }
                      onChange={mth_i_ev}
                      onInput={mth_i_ev}
                      {...preventEnterPressedInputMixin}
                    />
                  )
                }}
              />
            </div>
          </div>
        </div>
      </Container>
    );
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: IAssetRiskConfigurationProps
  ): void {
    if (
      nextProps.mode === ModelMode.View ||
      nextProps.modelId !== this.props.modelId
    ) {
      this.resetKey = Guid.getUniqGuid();
      const state: IAssetRiskConfigurationState = {
        mth_s: null,
        mth_i: null,
        ltm_s: null,
        ltm_i: null
      };
      this.setState(state);
    }
  }

  private inputChangeEventHandler(
    e: React.FormEvent<HTMLInputElement>,
    handleVals: (sNum: string | null, nNum: number | null) => void
  ): void {
    const t = e.currentTarget;
    let valid = true;
    if (
      t.validity &&
      t.validity.valid !== undefined &&
      t.validity.valid !== null
    ) {
      valid = t.validity.valid ? true : false;
    }
    let sNum: string | null | undefined = t.value;
    if (sNum === undefined) sNum = null;

    let nNum: number | null | undefined = t.valueAsNumber;

    if (
      valid &&
      sNum !== null &&
      (nNum === undefined || nNum === null || isNaN(nNum) || !isFinite(nNum))
    ) {
      nNum = localizedParseFloat(sNum, this.props.intl);
    }

    if (
      !valid ||
      nNum === undefined ||
      nNum === null ||
      isNaN(nNum) ||
      !isFinite(nNum)
    )
      nNum = null;
    handleVals(sNum, nNum);
  }
}

export default injectIntl(AssetRiskConfiguration, { forwardRef: true });
