// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import React from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";

import "./Toggle.less";

export enum ToggleModes {
  LabelOnTop,
  LabelOnLeft,
  LabelOnRight
}

export interface IToggleProps {
  className?: string;
  defaultValue?: boolean;
  disabled?: boolean;
  label?: MessageDescriptor;
  mode?: ToggleModes;
  onChange?: (value: boolean) => void;
  onInit?: (value: boolean) => void;
}

export interface IToggleState {
  animate: boolean;
  value: boolean;
}

export default class Toggle extends React.Component<
  IToggleProps,
  IToggleState
> {
  constructor(props: IToggleProps) {
    super(props);

    this.state = {
      animate: false,
      value: Toggle.getDefaultValue(props)
    };
  }

  componentDidMount() {
    const { onInit } = this.props;
    const { value } = this.state;
    if (onInit) onInit(value);
  }

  render() {
    const { className, disabled, label, mode, onChange } = this.props;
    const { animate, value } = this.state;

    const isOn = value === true;

    return (
      <div
        className={`
          toggle-control
          ${animate ? "animate" : ""}
          ${isOn ? "on" : "off"}
          ${disabled ? "disabled" : ""}
          ${mode === undefined ? "label-on-top" : ""}
          ${mode === ToggleModes.LabelOnTop ? "label-on-top" : ""}
          ${mode === ToggleModes.LabelOnLeft ? "label-on-left" : ""}
          ${mode === ToggleModes.LabelOnRight ? "label-on-right" : ""}
          ${className}
        `}
      >
        {label && (
          <div className="control-label default-grey-label">
            <FormattedMessage {...label} />
          </div>
        )}
        <div
          className="switch"
          onClick={() => {
            if (disabled) return;
            this.setState({ animate: true, value: !isOn });
            if (onChange) onChange(!isOn);
          }}
        >
          {isOn ? (
            <Icon name="check" size="sm" />
          ) : (
            <Icon name="close" size="sm" />
          )}
        </div>
      </div>
    );
  }

  private static getDefaultValue(props: IToggleProps): boolean {
    return props.defaultValue || false;
  }
}
