// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import * as React from "react";

import TooltipWrapper, { Themes } from "common/tooltipwrapper/TooltipWrapper";
import IIssue from "features/detailpage/features/issues/models/IIssue";
import IssueChangesTooltip from "./IssueChangesTooltip";

export interface IIssueIconProps {
  issue: IIssue;
}

const IssueIcon = ({ issue }: IIssueIconProps) => {
  return (
    <div className="issue-icon">
      {issue.IsManualIssue && <ManuallyCreatedIssueIcon issue={issue} />}
    </div>
  );
};

export default IssueIcon;

interface IManuallyCreatedIssueIconProps {
  issue: IIssue;
}

const ManuallyCreatedIssueIcon = ({
  issue
}: IManuallyCreatedIssueIconProps) => (
  <TooltipWrapper
    className="manually-created"
    theme={Themes.Dark}
    Tooltip={() => <IssueChangesTooltip issue={issue} />}
  >
    <Icon name="account_circle" />
  </TooltipWrapper>
);
