// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { closeIssue as closeIssueAction } from "features/issues/actions/IssuesActions";
import { useCallback } from "react";
import { useAppDispatch } from "store";

const useCloseIssueAction = () => {
  const dispatch = useAppDispatch();

  const closeIssue = useCallback(
    (issueId: string) => closeIssueAction(dispatch, issueId),
    [dispatch]
  );

  return closeIssue;
};

export default useCloseIssueAction;
