// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";
import { IScatterPlotData } from "../reducers/FamilyAnalyticsReducer";
import useFamilyContents from "../hooks/useFamilyContents";
import SectionName from "components/common/SectionName";
import { FormattedNumber } from "react-intl";
import DataGrid from "common/datagrid/DataGrid";

interface IFamilyContents {
  scatterPlotXData: IScatterPlotData;
  scatterPlotYData: IScatterPlotData;
}

const FamilyContents = ({
  scatterPlotXData,
  scatterPlotYData
}: IFamilyContents) => {
  const { handleRowClick, data, dataPoints, columns } = useFamilyContents(
    scatterPlotXData,
    scatterPlotYData
  );
  return (
    <div className="family-contents">
      <SectionName
        messageId="family_analytics.family_contents.label"
        messageDefault="Family Contents - {count} assets"
        messageValues={{
          count: <FormattedNumber value={dataPoints.length} />
        }}
      />
      <DataGrid
        showFooter={false}
        columns={columns}
        data={data}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

export default FamilyContents;
