// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useCallback, useState } from "react";

import { SelectedFilters } from "common/FilterBar";

const useAssetRiskChanges = () => {
  const [filters, setFilters] = useState<SelectedFilters>();

  const handleFilter = useCallback((filters: SelectedFilters) => {
    setFilters(filters);
  }, []);

  return { filters, handleFilter };
};

export default useAssetRiskChanges;
