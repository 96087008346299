// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useAppSelector } from "store";
import ActiveDirectoryEmailStatus from "../components/ActiveDirectoryEmailStatus";
import getAdSupportContactUrlSelector from "../selectors/getAdSupportContactUrlSelector";
import getUserHasEmailSelector from "../selectors/getUserHasEmailSelector";

const ActiveDirectoryEmailStatusContainer = () => {
  const adSupportContactUrl = useAppSelector(getAdSupportContactUrlSelector);
  const userHasEmail = useAppSelector(getUserHasEmailSelector);

  return (
    <ActiveDirectoryEmailStatus
      adSupportContactUrl={adSupportContactUrl}
      userHasEmail={userHasEmail}
    />
  );
};

export default ActiveDirectoryEmailStatusContainer;
