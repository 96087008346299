// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

const messages = defineMessages({
  message_1: {
    id: `notifications.noemail.message.part1`,
    defaultMessage: `Sorry, you won't get any notification e-mails.`
  },
  message_2: {
    id: `notifications.noemail.message.part2`,
    defaultMessage: `You don't have an e-mail address set up in your Active Directory profile.`
  }
});

const BeginningOfMessages = () => {
  const formattedMessages: JSX.Element[] = [];
  for (const msg of Object.keys(messages)) {
    formattedMessages.push(
      <div key={(messages as any)[msg].id}>
        <FormattedMessage
          id={(messages as any)[msg].id}
          defaultMessage={(messages as any)[msg].defaultMessage}
        />
      </div>
    );
  }
  return <>{formattedMessages}</>;
};

export default BeginningOfMessages;
