// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { NotificationsProvider as PgNotificationsProvider } from "@pg/common";
import { ReactNode } from "react";
import { useIntl } from "react-intl";

interface INotificationsProviderProps {
  children?: ReactNode;
}

const NotificationsProvider = ({ children }: INotificationsProviderProps) => {
  const intl = useIntl();

  return (
    <PgNotificationsProvider
      translations={{
        ok: intl.formatMessage({
          id: "global.messages.yes",
          defaultMessage: "Yes"
        }),
        cancel: intl.formatMessage({
          id: "global.messages.no",
          defaultMessage: "No"
        })
      }}
    >
      {children}
    </PgNotificationsProvider>
  );
};

export default NotificationsProvider;
