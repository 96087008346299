// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "../../../../../reducers/Index";

const batteryVoltageSelector = createSelector(
  (state: IState) => state.detailPage.voltageData,
  voltageData => voltageData
);

export default batteryVoltageSelector;
