// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { ChartConfiguration } from "c3";
import { IntlShape } from "react-intl";

export default (intl: IntlShape) => {
  const configuration: ChartConfiguration = {
    data: {
      xFormat: "%Y-%m-%d",
      empty: {
        label: {
          text: ""
        }
      }
    },
    grid: {
      y: {
        show: true
      }
    },
    axis: {
      x: {
        type: "timeseries",
        tick: {
          format: (x: Date): string => intl.formatDate(x),
          count: 5,
          culling: {
            max: 5
          }
        }
      },
      y: {
        min: 0,
        show: true,
        tick: {
          format: function (x: number): string {
            return `${x}${"%"}`;
          }
        }
      },
      y2: {
        show: true,
        tick: {
          format: function (x: number): string {
            return `${x}`;
          }
        },
        label: {
          text: intl.formatMessage({
            defaultMessage: "pF",
            id:
              "detail_page.widgets.analytics.transformers.bushings_offline.y2_axis_label"
          }),
          position: "inner-top"
        }
      }
    },
    legend: {
      show: false
    },
    line: {
      connectNull: false,
      step: {
        type: "step-after"
      }
    }
  };

  return configuration;
};
