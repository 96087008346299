// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import loadData from "core/data/actions/loadData";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";
import dataKey from "../constants/dgaDataKey";

export type DataSource = "Online" | "Offline" | "Advanced";
export interface ILoadDGADataPayload {
  assetId: string;
  dataSource: DataSource;
}

const loadDGAData = ({ assetId, dataSource }: ILoadDGADataPayload) => {
  const url = UrlService.getApiUrl(
    config.api.detailPage.dissolvedDataSourceGasAnalysisUrl,
    [
      {
        name: "assetId",
        value: assetId
      },
      {
        name: "dataSource",
        value: dataSource
      }
    ]
  );

  return loadData({ key: dataKey(assetId), url });
};

export default loadDGAData;
