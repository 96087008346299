// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getMessagesSelector = createSelector(
  (state: IState) => state.detailPage.messages,
  (messages) => messages
);

export default getMessagesSelector;
