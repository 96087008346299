// Copyright 2016-2023 Hitachi Energy. All rights reserved.

enum LogType {
  Operations = "Operations",
  Alarms = "Alarms",
  SensorChange = "SensorChange",
  Exceptions = "Exceptions",
  Notifications = "Notifications",
  Interventions = "Interventions",
  Other = "Other"
}

export default LogType;
