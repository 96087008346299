// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { ChartConfiguration } from "c3";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import IParameterHistogram from "../models/IParameterHistogram";

const useChartConfiguration = (
  parameterHistogramData: IParameterHistogram[],
  unit: string
) => {
  const intl = useIntl();
  const count = parameterHistogramData.map((c) => c.Count);
  const bucketStart = parameterHistogramData.map((s) => s.BucketStart);
  const bucketEnd = parameterHistogramData.map((e) => e.BucketEnd);

  const xTicks = bucketStart.map(
    (s, i) => `${s.toString()} - ${bucketEnd[i].toString()}`
  );

  const columns: [string, ...c3.Primitive[]][] = [["quantity", ...count]];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const configuration: ChartConfiguration = {
    size: {
      height: 265,
      width: 700
    },
    padding: { left: 50, right: 20, top: 35, bottom: -20 },
    data: {
      columns: columns,
      type: "bar"
    },
    axis: {
      y: {
        tick: {
          format: (x) => (x % 1 === 0 ? x : "")
        },
        min: 0,
        padding: { top: 30, bottom: 0 },
        label: {
          text: intl.formatMessage({
            defaultMessage: "Quantity",
            id: "detail_page.widgets.parameters_list.table.parameter_histogram.y_axis_label"
          }),
          position: "outer-top"
        }
      },
      x: {
        label: {
          text: unit,
          position: "outer-right"
        },
        type: "category",
        categories: xTicks,
        tick: {
          rotate: -25,
          multiline: false
        }
      }
    },
    legend: {
      hide: true
    },
    grid: {
      y: {
        show: true
      },
      x: {
        show: false
      }
    },
    tooltip: {
      format: {
        name: (name) => {
          return intl.formatMessage({
            defaultMessage: name,
            id: `detail_page.widgets.parameters_list.table.parameter_histogram.${name}_tooltip`
          });
        }
      }
    }
  };

  return useMemo(() => configuration, [configuration]);
};

export default useChartConfiguration;
