// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { SelectedFilters } from "common/FilterBar";
import { useEffect } from "react";
import { ScatterPlotParameters } from "../components/ScatterPlotWidget";

const useFamilyAnalyticsWidget = (
  scatterPlotParameters: ScatterPlotParameters,
  loadScatterPlotYData: (scatterPlotParameters: ScatterPlotParameters) => void,
  loadScatterPlotXData: (scatterPlotParameters: ScatterPlotParameters) => void,
  selectedFilters: SelectedFilters
) => {
  useEffect(() => {
    loadScatterPlotYData(scatterPlotParameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    scatterPlotParameters.dataTypeY,
    scatterPlotParameters.assetId,
    scatterPlotParameters.seriesNameY,
    selectedFilters
  ]);

  useEffect(() => {
    loadScatterPlotXData(scatterPlotParameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    scatterPlotParameters.dataTypeX,
    scatterPlotParameters.assetId,
    scatterPlotParameters.seriesNameX,
    selectedFilters
  ]);

  return scatterPlotParameters;
};

export default useFamilyAnalyticsWidget;
