// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Action, Reducer } from "redux";

import { Actions } from "features/detailpage/features/rightpanel/actions/RightPanelActions";
import { IState as IRootState } from "reducers/Index";

export interface IState {
  rightPanel: {
    expanded: boolean;
  };
}

export const defaultState: IState = {
  rightPanel: {
    expanded: false
  }
};

const reducer: Reducer<IState> = (
  state = defaultState,
  action: Action<string>
): IState => {
  switch (action.type) {
    case Actions.DetailPage_RightPanel_Collapse: {
      return {
        ...state,
        rightPanel: {
          ...state.rightPanel,
          expanded: false
        }
      };
    }
    case Actions.DetailPage_RightPanel_Expand: {
      return {
        ...state,
        rightPanel: {
          ...state.rightPanel,
          expanded: true
        }
      };
    }

    default:
      return state;
  }
};

export default reducer;

// #region Selectors

export const getExpandedSelector = (state: IRootState) =>
  state.detailPage.rightPanel.expanded;

// #endregion
