// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";
import { FormattedMessage } from "react-intl";

const BatteryVoltageTitle = () => (
  <div className="chart-name">
    <FormattedMessage
      defaultMessage="{voltage} - {recent}"
      id="detail_page.parameters.battery_voltage.chart_title"
      values={{
        voltage: (
          <span className="bold">
            <FormattedMessage
              defaultMessage="Voltage"
              id="detail_page.parameters.battery_voltage.voltage"
            />
          </span>
        ),
        recent: (
          <FormattedMessage
            defaultMessage="Recent"
            id="detail_page.parameters.battery_voltage.recent"
          />
        )
      }}
    />
  </div>
);

export default BatteryVoltageTitle;
