// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";
import { FormattedNumber } from "react-intl";

import "./MaintenancePriority.less";

interface IMaintenancePriorityProps {
    value: number;
}

const MaintenancePriority = (props: IMaintenancePriorityProps) => {
    const { value } = props;
    const roundedValue = value !== undefined && value != null ?
        Math.round(value * 10) / 10 : undefined;

    return (
        <div className="column maintenance-priority">
            {
                roundedValue !== undefined && roundedValue != null ?
                    <FormattedNumber value={ roundedValue } /> :
                    "-"
            }
        </div>
    );
};

export default MaintenancePriority;