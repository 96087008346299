// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import AgingOperationsBarChart from "features/detailpage/features/aging/components/AgingOperationsBarChart";
import { IAgingOperations } from "features/detailpage/features/aging/models/IAgingOperations";
import { isEmpty } from "lodash";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import "./AgingOperations.less";

interface IAgingOperationsComponent {
  agingOperations: IAgingOperations;
}

const AgingOperationsComponent = ({
  agingOperations: { Total, Fault }
}: IAgingOperationsComponent) => (
  <div className="container aging-operations-widget" data-qa="aging-operations">
    <div className="bootstrap-row align-items-center">
      <div className="col-12">
        <div className="aging-operations-chart__wrapper">
          <h2 className="aging-operations-chart__title">
            <FormattedMessage
              id="detail_page.widgets.circuit_breaker.aging.total_operations_chart_title"
              defaultMessage="Total Operations"
            />
          </h2>
          <AgingOperationsBarChart
            agingOperations={Total.Count}
            warningThreshold={Total.WarningThreshold}
            alarmThreshold={Total.AlarmThreshold}
          />
        </div>
      </div>
      <div className="col-12">
        <div className="aging-operations-chart__wrapper">
          <h2 className="aging-operations-chart__title">
            <FormattedMessage
              id="detail_page.widgets.circuit_breaker.aging.fault_operations_chart_title"
              defaultMessage="Fault Operations"
            />
          </h2>
          <AgingOperationsBarChart
            isFaultOperations={true}
            agingOperations={Fault.Count}
            warningThreshold={Fault.WarningThreshold}
            alarmThreshold={Fault.AlarmThreshold}
          />
        </div>
      </div>
    </div>
  </div>
);

interface IOperationsStateProps {
  agingOperations: Data<IAgingOperations>;
}

interface IOperationsProps extends IOperationsStateProps {}

export default ({ agingOperations }: IOperationsProps) => {
  switch (agingOperations.status) {
    case Statuses.Loading:
      return <Processing className="spinner small dark" />;
    case Statuses.Succeeded:
      return isEmpty(agingOperations.data) ? (
        <WidgetNoDataMessage />
      ) : (
        <AgingOperationsComponent agingOperations={agingOperations.data} />
      );
    case Statuses.NotFound:
      return <WidgetNoDataMessage />;
    case Statuses.Failed:
      return <WidgetErrorMessage />;
    default:
      return (
        <div className="tile">
          <div className="tile-content empty" />
        </div>
      );
  }
};
