// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import MechanicalParameters from "@apm/widgets/build/widgets/MechanicalParameters";
import ProcessingModal from "components/common/ProcessingModal";
import { getUserSelector } from "core/app/reducers/AppReducer";
import { IUser } from "core/app/reducers/settings/UserReducer";
import Data from "core/data/models/Data";
import useIsReadOnlyData from "features/detailpage/hooks/useIsReadOnlyData";
import LifecycleStatus from "models/LifecycleStatus";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import useMechanical from "../hooks/useMechanical";
import IMechanicalPoles from "../models/IMechanicalPoles";

interface IMechanicalState {
  user: Data<IUser>;
}

interface IMechanicalOwnProps {
  mechanical: IMechanicalPoles;
  lifecycleStatus: LifecycleStatus;
  onAdjustExpectedRangeClick?: (
    parameterName: string,
    success?: () => void,
    error?: () => void
  ) => void;
  onAcknowledgeClick?: (
    parameterName: string,
    success?: () => void,
    error?: () => void
  ) => void;
}
type IMechanicalProps = IMechanicalOwnProps & IMechanicalState;

const Mechanical = ({
  mechanical,
  onAdjustExpectedRangeClick,
  onAcknowledgeClick,
  user,
  lifecycleStatus
}: IMechanicalProps) => {
  const {
    formatValue,
    handleAdjustExpectedRangeClick,
    handleAcknowledgeClick,
    pole1,
    pole2,
    pole3,
    processing,
    translations
  } = useMechanical(mechanical, onAdjustExpectedRangeClick, onAcknowledgeClick);
  const isReadOnlyMode = useIsReadOnlyData(lifecycleStatus, user);

  return (
    <>
      <MechanicalParameters
        formatValue={formatValue}
        onAcknowledgeClick={handleAcknowledgeClick}
        onAdjustExpectedRangeClick={handleAdjustExpectedRangeClick}
        poles={[pole1, pole2, pole3]}
        thresholdCorrectionParams={[
          "TravelDistance",
          "ContactSpeed",
          "ReactionTime",
          "MechanismTime"
        ]}
        translations={translations}
        readonly={isReadOnlyMode}
      />
      {processing && <ProcessingModal />}
    </>
  );
};

const mapStateToProps = (state: IState): IMechanicalState => ({
  user: getUserSelector(state)
});
const MechanicalContainer = connect(mapStateToProps)(Mechanical);
export default MechanicalContainer;
