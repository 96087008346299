// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IntlShape } from "react-intl";

export const formatDate = (intl: IntlShape, date: Date | string | number) =>
  intl.formatDate(date, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

export const formatDateTime = (intl: IntlShape, date: Date | string | number) =>
  intl.formatDate(date, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  });

export const formatTwoDigitDateTime = (
  intl: IntlShape,
  date: Date | string | number
) =>
  intl.formatDate(date, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  });
