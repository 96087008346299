// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as d3 from "d3";
import { flatten } from "lodash";

import PhaseAngleService from "./PhaseAngleService";
import ISeries from "../models/ISeries";
import ITick from "../models/ITick";
import Scale from "../models/Scale";
import Svg from "../models/Svg";
import TickTypes from "../models/TickTypes";

const colorBackground = "#fafafa";

export default class ChartService {
  static drawBackground = (
    svg: Svg,
    width: number,
    height: number,
    ticks: ITick[],
    scale: Scale,
    phaseAColor: string,
    phaseBColor: string,
    phaseCColor: string
  ) => {
    const range = Math.max(...ticks.map(t => t.value));

    svg
      .append("circle")
      .attr("cx", width / 2)
      .attr("cy", height / 2)
      .attr("fill", colorBackground)
      .attr("r", scale(range * 1.03));

    PhaseAngleService.drawPhaseAngles(
      svg,
      width,
      height,
      phaseAColor,
      phaseBColor,
      phaseCColor,
      scale,
      range
    );
  };

  static getScale = (width: number, height: number, ticks: ITick[]): Scale => {
    const rangeBase = Math.min(height, width) / 2;
    const rangeMax = 0.75 * rangeBase;
    const domainMax = Math.max(...ticks.map(t => t.value));
    return d3
      .scaleLinear()
      .domain([0, domainMax])
      .range([0, rangeMax]);
  };

  static getTicks = (
    series: ISeries[],
    alertValue: number,
    warningValue: number
  ): ITick[] => {
    if (!series) return [];

    const getValueMax = () =>
      Math.max(
        ...flatten(
          series.map(s => {
            if (!s.points) return [];
            return s.points.map(p => p.value);
          })
        )
      );

    const getNormalTicks = () => {
      const n = 4;
      const valueMax = getValueMax();
      const valueMaxWithMargin = valueMax + valueMax * 0.05;
      const tickValue = Math.ceil(valueMaxWithMargin / n);
      const ticks = [];
      for (let i = 0; i <= n; i++) {
        const a = i || 0.5;
        ticks.push({
          value: a * tickValue,
          type: TickTypes.Normal
        });
      }

      return ticks;
    };

    const addCustomTick = (ticks: ITick[], value: number, type: TickTypes) => {
      if (value !== undefined && value != null) {
        ticks.push({
          value,
          type
        });
      }
    };

    const ticks = getNormalTicks();
    addCustomTick(ticks, alertValue, TickTypes.Alert);
    addCustomTick(ticks, warningValue, TickTypes.Warning);
    return ticks;
  };
}
