// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";
import {
  useFeature,
  VisualizationKeys
} from "core/app/components/FeatureToggle";
import { LayoutRoute } from "core/app/components/RouterProvider";
import DGA from "features/detailpage/features/DGA";
import SOT from "features/detailpage/features/SOT";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import DuvalTabs from "../../duval/components/DuvalTabs";
import DataSource from "common/DuvalAnalysis/models/DataSource";

interface IUseTransformerKNRoutesProps {
  assetId: string;
  implementationId: string;
}

const useTransformerKNRoutes = ({
  assetId,
  implementationId
}: IUseTransformerKNRoutesProps) => {
  const { isAvailable } = useFeature();
  const intl = useIntl();
  const searchParams = useMemo(() => new SearchParams({ assetId }), [assetId]);

  const routes = useMemo<LayoutRoute[]>(() => {
    return [
      isAvailable(VisualizationKeys.AssetDetailPage_Tabs_Parameters_Dga)
        ? {
            id: "Assets-DetailPage-Parameters-DGA",
            route: "DGA/*",
            displayName: intl.formatMessage({
              defaultMessage: "DGA",
              id: "detail_page.widgets.analytics.transformers.dga"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/DGA",
              search: searchParams.toString()
            },
            element: (
              <DGA
                assetId={assetId}
                dataSources={["offline"]}
                implementationId={implementationId}
              />
            )
          }
        : undefined,
      isAvailable(VisualizationKeys.AssetDetailPage_Tabs_Parameters_Duval)
        ? {
            id: "Assets-DetailPage-Parameters-Duval",
            route: "Duval/*",
            displayName: intl.formatMessage({
              defaultMessage: "Duval",
              id: "detail_page.widgets.analytics.transformers.duval"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/Duval",
              search: searchParams.toString()
            },
            element: (
              <DuvalTabs
                className="duval-tabs"
                assetId={assetId}
                defaultDataSource={DataSource.Offline}
                dataSources={[DataSource.Offline]}
                implementationId={implementationId}
              />
            )
          }
        : undefined,
      isAvailable(
        VisualizationKeys.AssetDetailPage_Tabs_Parameters_Standard_Oil_Tests
      )
        ? {
            id: "Assets-DetailPage-Parameters-StandardOilTests",
            route: "StandardOilTests/*",
            displayName: intl.formatMessage({
              defaultMessage: "Standard Oil Tests",
              id: "detail_page.widgets.analytics.transformers.standard_oil_tests"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/StandardOilTests",
              search: searchParams.toString()
            },
            element: <SOT assetId={assetId} dataSources={["offline"]} />
          }
        : undefined
    ].filter(Boolean);
  }, [isAvailable, intl, searchParams, assetId, implementationId]);

  return routes;
};

export default useTransformerKNRoutes;
