// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Processing from "components/common/Processing";
import Widget from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import { isNil } from "lodash";
import IAssetStatus from "models/IAssetStatus";
import { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import { loadDegradationScore } from "../../../actions/RiskSimulationSummaryActions";
import IDegradationScoreTrend from "../../../models/IDegradationScoreTrend";
import ScoreType from "../../../models/ScoreType";
import getDegradationScoreTrendSelector from "../../../selectors/getDegradationScoreTrendSelector";
import ConditionTrend from "../../ConditionTrend";
import PrognosticWidget from "../../PrognosticWidget";

interface IRiskTrendWidgetsDataProps {
  degradationScore: Data<IDegradationScoreTrend>;
}

interface IRiskTrendWidgetsActionsProps {
  loadDegradationScore: (assetId: string) => void;
}

interface IRiskTrendWidgetsOwnProps {
  expanded: boolean;
  widgetId: string;
  assetId: string;
  modelId: string;
  implementationId: string;
  lifecycleStatus: IAssetStatus["LifecycleStatus"];
}

type RiskTrendWidgetsProps = IRiskTrendWidgetsDataProps &
  IRiskTrendWidgetsOwnProps &
  IRiskTrendWidgetsActionsProps;

const RiskTrendWidgets = ({
  expanded,
  widgetId,
  assetId,
  modelId,
  implementationId,
  lifecycleStatus,
  degradationScore,
  loadDegradationScore
}: RiskTrendWidgetsProps) => {
  useEffect(() => {
    if (degradationScore.status !== Statuses.Succeeded) {
      loadDegradationScore(assetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAssetDataFailed = useMemo(() => {
    const statusFailed =
      degradationScore.status === Statuses.Failed ||
      degradationScore.status === Statuses.NotFound;

    const hasEmptySubscores = isNil(
      degradationScore?.data?.LatestDegradationScore?.SubScores
    );

    return statusFailed || hasEmptySubscores;
  }, [degradationScore]);

  return (
    <>
      {degradationScore.status === Statuses.Succeeded &&
        degradationScore.data.ScoreType === ScoreType.Additive && (
          <Widget
            element={
              <ConditionTrend
                assetId={assetId}
                modelId={modelId}
                implementationId={implementationId}
                expanded={expanded}
                series={degradationScore?.data?.Series}
                latestDegradationScores={
                  degradationScore?.data?.LatestDegradationScore
                }
                isInFamily={degradationScore?.data?.IsInFamily}
                isFailed={isAssetDataFailed}
                lifecycleStatus={lifecycleStatus}
              />
            }
            hideHeader={true}
            size={null}
            widgetId={widgetId}
          />
        )}
      {degradationScore.status === Statuses.Succeeded &&
        (degradationScore.data.ScoreType === ScoreType.Other ||
          degradationScore.data.ScoreType === ScoreType.Maximum) && (
          <Widget
            element={
              <PrognosticWidget
                expanded={expanded}
                modelId={modelId}
                implementationId={implementationId}
                lifecycleStatus={lifecycleStatus}
                degradationScoreTrend={degradationScore.data}
                assetId={assetId}
              />
            }
            hideHeader={true}
            size={null}
            widgetId={widgetId}
          />
        )}
      {degradationScore.status === Statuses.Loading && (
        <Widget
          element={<Processing />}
          hideHeader={true}
          size={null}
          widgetId={widgetId}
        />
      )}
      {(degradationScore.status === Statuses.Failed ||
        degradationScore.status === Statuses.NotFound) && (
        <Widget
          element={
            <ConditionTrend
              isFailed={isAssetDataFailed}
              expanded={expanded}
              lifecycleStatus={lifecycleStatus}
            />
          }
          hideHeader={true}
          size={null}
          widgetId={widgetId}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: IState): IRiskTrendWidgetsDataProps => ({
  degradationScore: getDegradationScoreTrendSelector(state)
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): IRiskTrendWidgetsActionsProps => ({
  loadDegradationScore: (assetId: string) =>
    loadDegradationScore(dispatch, assetId)
});

export default connect(mapStateToProps, mapDispatchToProps)(RiskTrendWidgets);
