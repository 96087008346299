// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { isEmpty } from "lodash";
import { FormattedMessage } from "react-intl";

import { Typography } from "antd";
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import ReplacementPriorityGrid from "features/detailpage/features/replacementscore/components/grid/ReplacementPriorityGrid";
import IReplacementPriorityScore from "features/detailpage/features/replacementscore/models/IReplacementPriorityScore";

const { Title } = Typography;

interface IReplacementPriorityComponentProps {
  scoreCalculation: IReplacementPriorityScore[];
  replacementScoreModelImplementationId: string;
}

const ReplacementPriorityComponent = ({
  scoreCalculation,
  replacementScoreModelImplementationId
}: IReplacementPriorityComponentProps) => {
  return (
    <div>
      <Title level={4}>
        <FormattedMessage
          id="detail_page.replacement_priority.grid.title"
          defaultMessage="Score Calculation"
        />
      </Title>
      <ReplacementPriorityGrid
        scoreCalculation={scoreCalculation}
        replacementScoreModelImplementationId={
          replacementScoreModelImplementationId
        }
      />
    </div>
  );
};

interface IReplacementPriorityProps {
  scoreCalculation: Data<IReplacementPriorityScore[]>;
  replacementScoreModelImplementationId: string;
}

const ReplacementPriority = ({
  scoreCalculation,
  replacementScoreModelImplementationId
}: IReplacementPriorityProps) => {
  switch (scoreCalculation.status) {
    case Statuses.Loading:
      return <Processing className="spinner small dark" />;
    case Statuses.Succeeded:
      return isEmpty(scoreCalculation.data) ? (
        <WidgetNoDataMessage />
      ) : (
        <ReplacementPriorityComponent
          scoreCalculation={scoreCalculation.data}
          replacementScoreModelImplementationId={
            replacementScoreModelImplementationId
          }
        />
      );
    case Statuses.NotFound:
      return <WidgetNoDataMessage />;
    case Statuses.Failed:
      return <WidgetErrorMessage />;
    default:
      return (
        <div className="tile">
          <div className="tile-content empty" />
        </div>
      );
  }
};

export default ReplacementPriority;
