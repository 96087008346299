// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import loadData from "core/data/actions/loadData";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";

export const getDataKey = (assetId: string) =>
  `ConfigurationTool_ConnectedDevices-${assetId}`;

const loadConnectedDevices = (assetId: string) => {
  const url = UrlService.getApiUrl(
    config.api.configurationTool.assetDeviceUrl,
    { assetId }
  );

  return loadData({
    key: getDataKey(assetId),
    url,
    method: "get"
  });
};

export default loadConnectedDevices;
