// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IMessage } from "common/form/models/IMessage";
import React from "react";
import { MessageDescriptor } from "react-intl";
import Input, {
  IInputActions,
  IInputData,
  IInputOwnProps,
  IInputState
} from "./Input";
import TextField, { ITextFieldProps } from "./TextField";

export interface ITextFieldInputActions extends IInputActions {}

export interface ITextFieldInputData extends IInputData {
  guid: string;
  validationMessages: MessageDescriptor[];
}

export interface ITextFieldInputOwnProps
  extends IInputOwnProps<string>,
    ITextFieldProps {}

export interface ITextFieldInputProps
  extends ITextFieldInputActions,
    ITextFieldInputData,
    ITextFieldInputOwnProps {}

export interface ITextFieldInputState extends IInputState {
  showValidationMessages: boolean;
}

class TextFieldInput extends Input<
  ITextFieldInputProps,
  ITextFieldInputState,
  string
> {
  public static getComputedMessages = (
    messages: IMessage[],
    validationMessages: MessageDescriptor[]
  ): IMessage[] => {
    if (messages || validationMessages) {
      if (!messages) messages = [];
      if (validationMessages)
        messages = messages.concat(
          validationMessages.map<IMessage>((m) => ({
            type: "error",
            descriptor: m
          }))
        );
    }

    return messages;
  };

  constructor(props: ITextFieldInputProps) {
    super(props);

    this.state = {
      showValidationMessages: false
    };
  }

  componentDidUpdate(prevProps: ITextFieldInputProps) {
    super.componentDidUpdate(prevProps);

    if (prevProps.guid !== this.props.guid)
      this.setShowValidationMessages(false);
  }

  render() {
    const { validationMessages, messages, guid } = this.props;
    const { showValidationMessages } = this.state;
    const computedMessages = showValidationMessages
      ? TextFieldInput.getComputedMessages(messages, validationMessages)
      : messages;

    return (
      <div className="input-text-field">
        <TextField
          key={guid}
          {...this.props}
          onInit={this.handleInit.bind(this)}
          onBlur={this.handleBlur.bind(this)}
          onChange={this.handleChange.bind(this)}
          messages={computedMessages}
        />
      </div>
    );
  }

  protected handleChange(value: string) {
    this.setShowValidationMessages(false);
    super.handleChange(value);
  }

  private handleBlur = () => {
    const { onBlur } = this.props;
    this.setShowValidationMessages(true);
    if (onBlur) onBlur();
  };

  private setShowValidationMessages = (value: boolean) => {
    const { showValidationMessages } = this.state;
    if (showValidationMessages !== value) {
      this.setState((prevState) =>
        Object.assign({}, prevState, {
          showValidationMessages: value
        })
      );
    }
  };
}

export default TextFieldInput;
