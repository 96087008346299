// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState as RootState } from "reducers/Index";

const getOpenIssuesSelector = createSelector(
  (state: RootState) => state.mainDashboard.issues.openIssues,
  (openIssues) => openIssues
);

export default getOpenIssuesSelector;
