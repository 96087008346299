// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { dataKey } from "../actions/StationRiskActions";
import ILocationRisk from "../models/ILocationRisk";

const getStationRiskSelector = createSelector(
  (state: IState) => state.data[dataKey] as Data<ILocationRisk[]>,
  (stationRisk) => stationRisk
);

export default getStationRiskSelector;
