// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { Row, Col } from "antd";

import IOperation from "../models/IOperation";
import Parameters from "./Parameters";
import Charts from "./Charts";

import useOperationsSucceded from "../hooks/useOperationsSucceded";

interface IOperationsSucceededPropsOwnProps {
  operations: IOperation;
}

const OperationsSucceeded = ({
  operations
}: IOperationsSucceededPropsOwnProps) => {
  const {
    chartData,
    group,
    sliderTimes,
    parametersState,
    handleOnParametersChange,
    handleOnTimesLinesAfterChange
  } = useOperationsSucceded({ operations });

  return (
    <Row>
      <Col span={15}>
        <Charts
          data={chartData}
          defaultSlopeTime={sliderTimes.slopeTime}
          defaultValueTime={sliderTimes.valueTime}
          onTimesLinesAfterChange={handleOnTimesLinesAfterChange}
        />
      </Col>
      <Col span={9}>
        <Parameters
          group={group}
          onChange={handleOnParametersChange}
          parametersDefaultState={parametersState}
        />
      </Col>
    </Row>
  );
};

export default OperationsSucceeded;
