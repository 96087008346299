// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { Menu, Typography } from "antd";
import { MenuItemType, SubMenuType } from "antd/lib/menu/hooks/useItems";
import { MenuInfo } from "rc-menu/lib/interface";
import { useCallback } from "react";
import styled from "styled-components";
import { colorStatusRed, colorTeal100 } from "styles/ColorVariables";
import { spacingLarge } from "styles/StyleVariables";

const { Text } = Typography;
export interface IMenuItem {
  key: string;
  title: string;
  iconName?: string;
  children?: Array<IMenuItem>;
  invalid?: boolean;
  touched?: boolean;
}

interface IAssetModalMenuProps {
  menuItems: Array<IMenuItem>;
  onMenuChange: (key: string, keyPath: string[]) => void;
  selectedMenu?: string;
  className?: string;
}

const AssetModalMenu = ({
  menuItems,
  onMenuChange,
  selectedMenu,
  className
}: IAssetModalMenuProps) => {
  const handleMenuClick = useCallback(
    (info: MenuInfo) => {
      onMenuChange(info.key, info.keyPath);
    },
    [onMenuChange]
  );

  return (
    <Menu
      style={{ width: 200 }}
      defaultOpenKeys={menuItems.map((item) => item.key)}
      selectedKeys={[selectedMenu]}
      mode="inline"
      onClick={handleMenuClick}
      inlineIndent={0}
      className={className}
      items={menuItems.map<SubMenuType>(({ key, title, children }) => {
        return {
          key,
          title,
          label: title,
          className,
          children: children.map<MenuItemType>(
            ({ key, title, iconName, invalid, touched }) => {
              return {
                key,
                title,
                icon: iconName ? (
                  <Icon className="menu-custom-icon" name={iconName} />
                ) : null,
                label: (
                  <>
                    <Text title={title}>{title}</Text>
                    {invalid ? (
                      <Icon
                        className="menu-validation-icon"
                        name="circle"
                        size="xs"
                      />
                    ) : touched ? (
                      <Icon
                        className="menu-touched-icon"
                        name="circle"
                        size="xs"
                      />
                    ) : null}
                  </>
                )
              };
            }
          )
        };
      })}
    />
  );
};

const StyledAssetModalMenu = styled(AssetModalMenu)`
  .menu-custom-icon {
    svg {
      display: flex;
    }
  }
  .svg-icon.md {
    width: auto;
  }

  .ant-menu-title-content {
    display: flex;
    .ant-typography {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .menu-touched-icon {
      color: ${colorTeal100};
      margin: auto ${spacingLarge};
    }

    .menu-validation-icon {
      color: ${colorStatusRed};
      margin: auto ${spacingLarge};
    }
  }
`;

export default StyledAssetModalMenu;
