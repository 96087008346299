// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { SearchParams } from "@pg/common";
import Icon from "@pg/common/build/components/Icon";
import Text from "antd/lib/typography/Text";
import { routes } from "core/app/components/AppRoutes";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { colorTeal90 } from "styles/ColorVariables";
import { spacingLarge, spacingXSmall } from "styles/StyleVariables";

interface IViewInIssuesProps {
  className?: string;
  organization: string;
  region: string;
  location: string;
}

const Wrapper = styled.div`
  border-top: ${spacingXSmall} solid ${colorTeal90};
  width: 100%;
  padding-top: ${spacingLarge};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: ${colorTeal90};
  }
`;

const ViewInIssues: FC<IViewInIssuesProps> = ({
  organization,
  region,
  location
}) => {
  const intl = useIntl();

  const viewInIssuesSearchParams = useMemo(() => {
    const fao = [organization, region, location].map((v) => v || null);
    const searchParams = new SearchParams();
    searchParams.append("f_ao", JSON.stringify(fao));
    searchParams.append("f_is", JSON.stringify(["New"]));
    searchParams.append("f_is", JSON.stringify(["InProgress"]));
    searchParams.append("f_is", JSON.stringify(["Monitor"]));
    searchParams.append("f_as", JSON.stringify(["InService"]));
    return searchParams.toString();
  }, [organization, region, location]);

  return (
    <Wrapper>
      <StyledNavLink
        to={{
          pathname: routes.issues.pathname,
          search: viewInIssuesSearchParams
        }}
        data-qa="view-in-button"
      >
        <Icon name="open_in_new" />
        <Text>
          {intl.formatMessage({
            defaultMessage: "View in Issues",
            id: "locations_page.side_panel.view_in_issues"
          })}
        </Text>
      </StyledNavLink>
    </Wrapper>
  );
};

export default ViewInIssues;
