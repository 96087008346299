// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { isEmpty } from "lodash";

import Data, { Statuses } from "core/data/models/Data";

import { IPrognosticPoint } from "common/AssetRiskMatrix";

import IDegradationScoreTrend from "../../risktrend/models/IDegradationScoreTrend";
import IScoreTrend from "../../risktrend/models/IScoreTrend";

const getPrognosticPoints = (
  degradationScoreTrend: Data<IDegradationScoreTrend>,
  importance: number,
  date?: Date
) => {
  if (hasData(degradationScoreTrend)) {
    const { Prognosis } = degradationScoreTrend.data;
    const scoreTrend = getConditionScore(Prognosis);
    const prognosticPoints = findPrognosticPoints(scoreTrend, importance, date);
    return prognosticPoints;
  }

  return [];
};

export default getPrognosticPoints;

///////////////////////////////////////

function hasData(degradationScoreTrend: Data<IDegradationScoreTrend>) {
  return (
    degradationScoreTrend.status === Statuses.Succeeded &&
    !isEmpty(degradationScoreTrend.data) &&
    !isEmpty(degradationScoreTrend.data.Prognosis)
  );
}

function getConditionScore(prognosis: IScoreTrend[]) {
  return prognosis[0];
}

function findPrognosticPoints(
  scoreTrend: IScoreTrend,
  importance: number,
  date?: Date
) {
  const prognosticPoints: IPrognosticPoint[] = [];
  const datePeriods = getDatePeriods(date);
  const length = scoreTrend.Dates.length;
  let k = 0;
  const usedIndex: number[] = [];

  for (let i = 0; i < length; i++) {
    const nextDate = new Date(scoreTrend.Dates[i]);
    const previousDate =
      i - 1 > -1 ? new Date(scoreTrend.Dates[i - 1]) : undefined;
    const periodDate = datePeriods[k];
    const isLastDate = i === length - 1;
    const index = getDateIndex(
      i,
      periodDate,
      nextDate,
      previousDate,
      isLastDate
    );

    if (index > -1 && !usedIndex.some((x) => x === index)) {
      prognosticPoints.push({
        Date: new Date(scoreTrend.Dates[index]),
        Score: scoreTrend.Values[index],
        Importance: importance
      });
      usedIndex.push(index);
      k++;

      if (k === datePeriods.length) {
        break;
      }
    }
  }

  return prognosticPoints;
}

function getDateIndex(
  i: number,
  periodDate: number,
  nextDate: Date,
  previousDate: Date,
  isLastDate: boolean
) {
  if (nextDate.valueOf() >= periodDate) {
    const nextDateDiff = nextDate.valueOf() - periodDate;
    const prevDateDiff = previousDate
      ? Math.abs(previousDate.valueOf() - periodDate)
      : Number.MIN_VALUE;

    return nextDateDiff <= prevDateDiff ? i : i - 1;
  } else if (isLastDate) {
    return i;
  }

  return -1;
}

function getDatePeriods(date?: Date) {
  const current = getCurrentDateWithoutTime(date);
  const datePeriods = [
    new Date(current).setMonth(current.getMonth() + 3),
    new Date(current).setMonth(current.getMonth() + 6),
    new Date(current).setFullYear(current.getFullYear() + 1),
    new Date(current).setFullYear(current.getFullYear() + 2),
    new Date(current).setFullYear(current.getFullYear() + 3)
  ];
  return datePeriods;
}

function getCurrentDateWithoutTime(date = new Date()) {
  date.setHours(0, 0, 0, 0);
  return date;
}
