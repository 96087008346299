// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getAllDegradedCheckedSelector = createSelector(
  (state: IState) => state.notifications.allDegradedChecked,
  allDegradedChecked => allDegradedChecked
);

export default getAllDegradedCheckedSelector;
