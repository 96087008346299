// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import setDataAction from "core/data/actions/setDataAction";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";
import IDielectricData from "../../../models/IDielectricData";

export const dataKey = "DetailPage_Dielectric_Pressure_Chart";

const loadDielectricPressure = (dispatch: AppDispatch, assetId: string) => {
  return new Promise<IDielectricData>((resolve, reject) => {
    const url = UrlService.getApiUrl(
      config.api.detailPage.dielectricPressureUrl,
      { assetId }
    );

    const request = EndpointService.getJson<IDielectricData>(
      url,
      (request, data) => {
        dispatch(setDataAction(dataKey, request, data));
        resolve(data);
      },
      (request) => {
        dispatch(setDataAction(dataKey, request));
        reject();
      }
    );

    dispatch(setDataAction(dataKey, request));
  });
};

export default loadDielectricPressure;
