// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useCallback, ChangeEvent, useState, useRef, useEffect } from "react";

const postponeTime = 1000;

const useParametersSearch = (
  onSearch?: (value: string) => void,
  onClear?: () => void,
  defaultSearch?: string
) => {
  const timerRef = useRef<number>();
  const [internalSearch, setInternalSearch] = useState<string>(defaultSearch);

  const clearTimer = useCallback(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
  }, [timerRef]);

  const setTimer = useCallback(
    (func: () => void) => {
      clearTimer();
      timerRef.current = window.setTimeout(func, postponeTime);
    },
    [clearTimer, timerRef]
  );

  const handleClearClick = useCallback(() => {
    clearTimer();
    setInternalSearch(null);
    if (onSearch) onSearch(null);
    if (onClear) onClear();
  }, [clearTimer, onSearch, onClear]);

  const handleSearch = useCallback(
    (value: string) => {
      clearTimer();
      if (onSearch) onSearch(value);
    },
    [clearTimer, onSearch]
  );

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setInternalSearch(value);
      setTimer(() => {
        if (onSearch) onSearch(value);
      });
    },
    [onSearch, setTimer]
  );

  useEffect(() => {
    return () => {
      clearTimer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    handleClearClick,
    handleSearch,
    handleSearchChange,
    internalSearch
  };
};

export default useParametersSearch;
