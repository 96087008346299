// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IUser } from "core/app/reducers/settings/UserReducer";
import Data from "core/data/models/Data";
import IReport from "features/detailpage/models/IReport";
import LifecycleStatus from "models/LifecycleStatus";
import { useMemo } from "react";
import useBatteryRoutes from "./useBatteryRoutes";
import useCircuitBreakerRoutes from "./useCircuitBreakerRoutes";
import useDefaultRoutes from "./useDefaultRoutes";
import useReportsRoutes from "./useReportsRoutes";
import useTransformerDCRoutes from "./useTransformerDCRoutes";
import useKinectricsNetworkTransformerTabs from "./useTransformerKNRoutes";
import useTransformerRoutes from "./useTransformerRoutes";

interface IUseParametersTabsProps {
  assetId: string;
  modelId: string;
  implementationId: string;
  assetType: string;
  cbkAvailable: boolean;
  analyticsAvailable: boolean;
  dcTransformerAvailable: boolean;
  kinectricsNetworkTransformerAvailable: boolean;
  batteryAvailable: boolean;
  pbiReports: Data<IReport[]>;
  loadPbiReports: (assetType: string) => void;
  user: IUser;
  lifecycleStatus: LifecycleStatus;
}

const useParametersTabs = ({
  assetId,
  assetType,
  cbkAvailable,
  analyticsAvailable,
  dcTransformerAvailable,
  kinectricsNetworkTransformerAvailable,
  batteryAvailable,
  modelId,
  implementationId,
  pbiReports,
  loadPbiReports,
  user,
  lifecycleStatus
}: IUseParametersTabsProps) => {
  const defaultRoutes = useDefaultRoutes({
    assetId,
    implementationId,
    modelId
  });

  const circuitBreakerRoutes = useCircuitBreakerRoutes({
    assetId,
    implementationId,
    lifecycleStatus,
    modelId
  });

  const batteryRoutes = useBatteryRoutes({
    assetId
  });

  const transformerRoutes = useTransformerRoutes({
    assetId,
    implementationId,
    modelId
  });

  const transformerDCRoutes = useTransformerDCRoutes({
    assetId
  });

  const transformerKNRoutes = useKinectricsNetworkTransformerTabs({
    assetId,
    implementationId
  });

  const reportsRoutes = useReportsRoutes({
    assetType,
    assetId,
    pbiReports,
    user,
    loadPbiReports
  });

  const routes = useMemo(() => {
    const routes = [...defaultRoutes];

    if (cbkAvailable) routes.push(...circuitBreakerRoutes);
    if (analyticsAvailable) routes.push(...transformerRoutes);
    if (dcTransformerAvailable) routes.push(...transformerDCRoutes);
    if (kinectricsNetworkTransformerAvailable)
      routes.push(...transformerKNRoutes);
    if (batteryAvailable) routes.push(...batteryRoutes);

    routes.push(...reportsRoutes);

    return routes;
  }, [
    analyticsAvailable,
    batteryAvailable,
    batteryRoutes,
    cbkAvailable,
    circuitBreakerRoutes,
    dcTransformerAvailable,
    defaultRoutes,
    kinectricsNetworkTransformerAvailable,
    reportsRoutes,
    transformerDCRoutes,
    transformerKNRoutes,
    transformerRoutes
  ]);

  return routes;
};

export default useParametersTabs;
