// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";

import ResizeWrapper from "common/ResizeWrapper";
import C3Chart from "common/chart/components/C3Chart";
import useAgingVisualizationChartConfiguration from "../../hooks/useAgingVisualizationChartConfiguration";

import "./AgingVisualizationChart.less";
import AgingVisualizationChartLegend from "./AgingVisualizationChartLegend";
import IAgingVisualization from "../../models/IAgingVisualization";

interface IAgingVisualizationChartProps {
  agingData: IAgingVisualization;
}

const AgingVisualizationChart = ({
  agingData
}: IAgingVisualizationChartProps) => {
  const [configuration] = useAgingVisualizationChartConfiguration(agingData);

  return (
    <div className="aging-visualization-chart">
      <ResizeWrapper>
        <C3Chart configuration={configuration}></C3Chart>
      </ResizeWrapper>
      <AgingVisualizationChartLegend />
    </div>
  );
};

export default AgingVisualizationChart;
