// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import styled from "styled-components";

export default styled.span`
  &:after {
    content: "";
    width: 0;
    height: 100%;
    position: absolute;
    border-left: 1px solid #e4e4e4;
  }
`;
