// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";

interface IFractionProps {
  numerator: string;
  denominator: string;
}

const Fraction = ({ numerator, denominator }: IFractionProps) => (
  <span className="fraction">
    <span className="fup">{numerator}</span>
    <span className="bar">/</span>
    <span className="fdn">{denominator}</span>
  </span>
);

export default Fraction;
