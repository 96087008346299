// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import DataSource from "common/DuvalAnalysis/models/DataSource";
import { createDuvalPoints } from "common/DuvalAnalysis/utils/duvalAnalysisHelper";
import * as d3 from "d3";
import { IntlShape } from "react-intl";
import { getStaticMarkup } from "../../DuvalAnalysis/components/DuvalAnalysisTooltip";
import IDuvalPoint from "../models/IDuvalPoint";
import createDuvalPentagonConvertedCoordinates from "./createDuvalPentagonConvertedCoordinates";

interface IDuvalPentagonPoints {
  root: D3Selection<SVGElement>;
  tooltip: D3Selection<HTMLElement>;
  points: IDuvalPoint[];
  intl: IntlShape;
  dataSource: DataSource;
}

type D3Selection<T extends d3.BaseType> = d3.Selection<
  T,
  unknown,
  null,
  undefined
>;

const createDuvalPentagonPoints = ({
  root,
  tooltip,
  points,
  intl,
  dataSource
}: IDuvalPentagonPoints) => {
  createDuvalPoints({
    root,
    dataSource,
    sortedPoints: points.sort((a, b) =>
      a?.Date < b?.Date ? -1 : a?.Date > b?.Date ? 1 : 0
    ),
    showTooltip: (d: IDuvalPoint) => showTooltip(tooltip, d, intl, dataSource),
    hideTooltip: () => hideTooltip(tooltip),
    getCoords: (duvalPoint: IDuvalPoint) =>
      createDuvalPentagonConvertedCoordinates({
        point: getPointCoordinates(duvalPoint)
      })
  });
};

function getPointCoordinates(point: IDuvalPoint) {
  const eighteenDegrees = (18.0 * Math.PI) / 180.0; // 18 degrees in radians.
  const fiftyFourDegrees = (54.0 * Math.PI) / 180.0; // 54 degrees in radians.

  let x1 = 0;
  let y1 = getHydrogenPercent(point);
  let x2 = getEthanePercent(point) * Math.cos(eighteenDegrees) * -1;
  let y2 = getEthanePercent(point) * Math.sin(eighteenDegrees);
  let x3 = getMethanePercent(point) * Math.cos(fiftyFourDegrees) * -1;
  let y3 = getMethanePercent(point) * Math.sin(fiftyFourDegrees) * -1;
  let x4 = getEthylenePercent(point) * Math.cos(-fiftyFourDegrees);
  let y4 = getEthylenePercent(point) * Math.sin(-fiftyFourDegrees);
  let x5 = getAcetylenePercent(point) * Math.cos(eighteenDegrees);
  let y5 = getAcetylenePercent(point) * Math.sin(eighteenDegrees);

  // Area
  let area1 = x1 * y2 - x2 * y1;
  let area2 = x2 * y3 - x3 * y2;
  let area3 = x3 * y4 - x4 * y3;
  let area4 = x4 * y5 - x5 * y4;
  let area5 = x5 * y1 - x1 * y5;
  let totalArea = (area1 + area2 + area3 + area4 + area5) / 2;

  // Centroipoint X
  let centroidX1 = (x1 + x2) * area1;
  let centroidX2 = (x2 + x3) * area2;
  let centroidX3 = (x3 + x4) * area3;
  let centroidX4 = (x4 + x5) * area4;
  let centroidX5 = (x5 + x1) * area5;
  let centroidX =
    (centroidX1 + centroidX2 + centroidX3 + centroidX4 + centroidX5) /
    (6 * totalArea);

  // Centroipoint Y
  let centroidY1 = (y1 + y2) * area1;
  let centroidY2 = (y2 + y3) * area2;
  let centroidY3 = (y3 + y4) * area3;
  let centroidY4 = (y4 + y5) * area4;
  let centroidY5 = (y5 + y1) * area5;
  let centroidY =
    (centroidY1 + centroidY2 + centroidY3 + centroidY4 + centroidY5) /
    (6 * totalArea);

  return {
    x: centroidX,
    y: centroidY
  };
}

function getHydrogenPercent(point: IDuvalPoint) {
  return getRelativePercent(point.Hydrogen, point);
}

function getAcetylenePercent(point: IDuvalPoint) {
  return getRelativePercent(point.Acetylene, point);
}

function getEthylenePercent(point: IDuvalPoint) {
  return getRelativePercent(point.Ethylene, point);
}

function getMethanePercent(point: IDuvalPoint) {
  return getRelativePercent(point.Methane, point);
}

function getEthanePercent(point: IDuvalPoint) {
  return getRelativePercent(point.Ethane, point);
}

function getRelativePercent(value: number, point: IDuvalPoint) {
  return (value / getTotal(point)) * 100;
}

function getTotal(point: IDuvalPoint) {
  return (
    point.Hydrogen +
    point.Acetylene +
    point.Ethylene +
    point.Methane +
    point.Ethane
  );
}

function showTooltip(
  tooltip: D3Selection<HTMLElement>,
  point: IDuvalPoint,
  intl: IntlShape,
  dataSource: DataSource
) {
  const labels = {
    hydrogen: "H2",
    acetylene: "C2H2",
    ethylene: "C2H4",
    methane: "CH4",
    ethane: "C2H6"
  };
  const html = getStaticMarkup({
    intl: intl,
    date: new Date(point.Date),
    dataSource: dataSource,
    gases: [
      {
        label: labels.hydrogen,
        ppm: point.Hydrogen,
        percent: Math.round(getHydrogenPercent(point))
      },
      {
        label: labels.acetylene,
        ppm: point.Acetylene,
        percent: Math.round(getAcetylenePercent(point))
      },
      {
        label: labels.ethylene,
        ppm: point.Ethylene,
        percent: Math.round(getEthylenePercent(point))
      },
      {
        label: labels.methane,
        ppm: point.Methane,
        percent: Math.round(getMethanePercent(point))
      },
      {
        label: labels.ethane,
        ppm: point.Ethane,
        percent: Math.round(getEthanePercent(point))
      }
    ]
  });

  tooltip
    .transition()
    .duration(200)
    .style("opacity", 0.95)
    .style("display", "block");
  tooltip
    .html(html)
    .style("left", (d3 as any).event.pageX + 10 + "px")
    .style("top", (d3 as any).event.pageY + 10 + "px");
}

function hideTooltip(tooltip: D3Selection<HTMLElement>) {
  tooltip
    .transition()
    .duration(500)
    .style("opacity", 0)
    .style("display", "none");
}

export default createDuvalPentagonPoints;
