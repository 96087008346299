// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { find, findIndex, startsWith } from "lodash";
import { supportedLocales } from "../constants/supportedLocales";

export interface ILocalizedText {
  TextId: string;
  TextValues: Record<string, string | number | boolean | Date>;
}

export default class IntlService {
  static getSupportedLocale(locales: string[]): string {
    let locale = find(
      locales,
      (locale) =>
        findIndex(
          supportedLocales,
          (supportedLocale) =>
            locale === supportedLocale ||
            startsWith(locale, supportedLocale + "-")
        ) !== -1
    );

    if (locale === undefined) {
      locale = "en";
    }

    return locale;
  }
}
