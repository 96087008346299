// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { ITenant } from "core/app/reducers/settings/TenantReducer";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IAjaxAction } from "reducers/Index";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";

export enum Actions {
  Settings_SetTenantList = "Settings_SetTenantList"
}

const setTenantListAction = (
  request: JQuery.jqXHR,
  data?: ITenant[]
): IAjaxAction<ITenant[]> => {
  const action: IAjaxAction<ITenant[]> = {
    type: Actions.Settings_SetTenantList,
    request,
    data
  };
  return action;
};

export const loadTenantList = (dispatch: AppDispatch) => {
  const url = UrlService.getApiUrl(config.api.user.getTenantsListUrl);
  const request = EndpointService.getJson<ITenant[]>(
    url,
    (request, data) => {
      dispatch(setTenantListAction(request, data));
    },
    (request) => {
      dispatch(setTenantListAction(request));
    }
  );

  dispatch(setTenantListAction(request));
};
