// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React, { useCallback } from "react";
import { Button as AntdButton } from "antd";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import styled from "styled-components";

import { colorPrimary } from "styles/ColorVariables";

interface IButtonProps {
  className?: string;
  messageDescription: MessageDescriptor;
  onClick?: () => void;
  active?: boolean;
}

const Button = ({
  className,
  messageDescription: { defaultMessage, id },
  active,
  onClick
}: IButtonProps) => {
  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.currentTarget.blur();
      if (onClick) onClick();
    },
    [onClick]
  );

  return (
    <AntdButton
      className={`${className}${active ? " active" : ""}`}
      onClick={handleOnClick}
    >
      <FormattedMessage defaultMessage={defaultMessage} id={id} />
    </AntdButton>
  );
};

const StyledButton = styled(Button)`
  &.active,
  &:hover {
    color: ${colorPrimary};
    border-color: ${colorPrimary};
    z-index: 2;
  }
`;

export default StyledButton;
