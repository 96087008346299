// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { FC, ReactNode, useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { setLocale } from "yup";

interface IYupLocaleProvider {
  children: ReactNode;
}

const YupLocaleProvider: FC<IYupLocaleProvider> = ({ children }) => {
  const { formatMessage } = useIntl();

  const buildYupLocaleObject = useCallback(() => {
    setLocale({
      mixed: {
        required: formatMessage({
          id: "validation.yup.mixed.required",
          defaultMessage: "Value is required"
        }),
        oneOf: formatMessage({
          id: "validation.yup.mixed.oneOf",
          // eslint-disable-next-line no-template-curly-in-string
          defaultMessage: "This must be one of the following values: ${values}"
        }),
        notType: formatMessage({
          id: "validation.yup.mixed.notType",
          defaultMessage: "Value has incorrect type"
        })
      },
      string: {
        max: formatMessage({
          id: "validation.yup.string.max",
          // eslint-disable-next-line no-template-curly-in-string
          defaultMessage: "Max length: ${max}"
        })
      },
      number: {
        min: formatMessage({
          id: "validation.yup.number.min",
          // eslint-disable-next-line no-template-curly-in-string
          defaultMessage: "Value must be greater or equal to ${min}"
        }),
        max: formatMessage({
          id: "validation.yup.number.max",
          // eslint-disable-next-line no-template-curly-in-string
          defaultMessage: "Value must be lower or equal to ${max}"
        })
      }
    });
  }, [formatMessage]);

  useEffect(() => {
    buildYupLocaleObject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>{children}</div>;
};

export default YupLocaleProvider;
