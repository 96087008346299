// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IRow } from "common/datagrid/DataGrid";
import IParameterInfo from "../../../models/IParameterInfo";

const isClickable = (row: IRow<IParameterInfo>) => {
  return (
    row.data.LastValue.Type === "Decimal" &&
    (row.data.ParameterSource === "AssetParameter" ||
      row.data.ParameterSource === "DerivativeParameter")
  );
};

export default isClickable;
