// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";
import { FormattedMessage } from "react-intl";

import "./Message.less";

const types = {
  DEFAULT: 0,
  INFO: 1,
  WARNING: 2,
  ERROR: 3,
  CONFIRMATION: 4
};

interface IMessageProps {
  type: number;
  messageId?: string;
  messageDefault?: string;
  messageValues?: any;
  message?: string;
}

class Message extends React.Component<IMessageProps, {}> {
  public render() {
    let className: string;

    switch (this.props.type) {
      case types.DEFAULT:
        className = "message";
        break;
      case types.INFO:
        className = "message message-info";
        break;
      case types.WARNING:
        className = "message message-warning";
        break;
      case types.ERROR:
        className = "message message-error";
        break;
      case types.CONFIRMATION:
        className = "message message-confirmation";
        break;
      default:
        className = "message";
        break;
    }

    return (
      <div className={className}>
        {this.props.messageId !== undefined && this.props.messageId != null ? (
          <FormattedMessage
            id={this.props.messageId}
            defaultMessage={this.props.messageDefault}
            values={this.props.messageValues}
          />
        ) : (
          this.props.message
        )}
      </div>
    );
  }
}

export default Message;
export { types };
