// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Typography } from "antd";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import {
  chartToGroupMapper,
  chartToPhaseTypeGroupMapper
} from "../constants/voltageStressSettings";
import ChartType from "../models/ChartType";

const { Title } = Typography;

interface IChartTitleProps {
  chartType: ChartType;
  isModalTitle: boolean;
}

const ChartTitle = ({ chartType, isModalTitle }: IChartTitleProps) => {
  const intl = useIntl();
  const phaseType = useMemo(
    () => chartToPhaseTypeGroupMapper[chartType],
    [chartType]
  );
  const groupType = useMemo(() => chartToGroupMapper[chartType], [chartType]);

  return (
    <div className="chart-title">
      <Title level={4} className="group-title">
        {isModalTitle &&
          intl.formatMessage({
            defaultMessage: groupType.toString(),
            id: `detail_page.widgets.analytics.transformers.voltage_stress.tab.${groupType}`
          })}
      </Title>
      <Title level={4} className="chart-subtitle">
        {intl.formatMessage({
          defaultMessage: `${phaseType} -  ${chartType}`,
          id: `detail_page.widgets.analytics.transformers.voltage_stress.chart.${phaseType}.${chartType}`
        })}
      </Title>
    </div>
  );
};

export default ChartTitle;
