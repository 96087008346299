// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IRegion } from "@apm/widgets/build/widgets/ParameterCharts";
import { colorStatusOrange } from "@pg/common/build/styles/ColorVariables";
import { isNil } from "lodash";
import IThresholds from "../models/IThresholds";

const mapToRegions = (thresholds: IThresholds): IRegion[] => {
  if (thresholds) {
    const thresholdValues = Object.values(thresholds)
      .flat()
      .map((x) => x.Value);
    if (thresholdValues.length > 0) {
      const minValue = Math.min(...thresholdValues);
      const maxValue = Math.max(...thresholdValues);

      if (!isNil(minValue) && !isNil(maxValue))
        return [
          {
            name: "ratioThreshold1",
            color: colorStatusOrange,
            start: null,
            end: minValue
          },
          {
            name: "ratioThreshold2",
            color: colorStatusOrange,
            start: maxValue,
            end: null
          }
        ];
    }
  }
};

export default mapToRegions;
