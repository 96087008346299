// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IPartialDischargeChart } from "@apm/widgets/build/widgets/PartialDischarge";
import Processing from "components/common/Processing";
import { WidgetErrorMessage } from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import { kebabCase } from "lodash";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import loadElectrical from "../actions/loadElectrical";
import removeElectrical from "../actions/removeElectrical";
import IPartialDischargeResult from "../models/IPartialDischargeResult";
import getElectricalSelector from "../selectors/getElectricalSelector";
import mapToReadings from "../utils/mapToReadings";
import PartialDischargeCharts from "./PartialDischargeCharts";

interface IElectricalChartsActions {
  loadElectrical: (assetId: string) => void;
  removeElectrical: (assetId: string) => void;
}

interface IElectricalChartsData {
  electrical: Data<IPartialDischargeResult>;
}

interface IElectricalChartsOwnProps {
  assetId: string;
  modelId: string;
  modelImplementationId: string;
}

interface IElectricalChartsProps
  extends IElectricalChartsActions,
    IElectricalChartsData,
    IElectricalChartsOwnProps {}

const ElectricalCharts = ({
  assetId,
  electrical,
  loadElectrical,
  modelId,
  modelImplementationId,
  removeElectrical
}: IElectricalChartsProps) => {
  useEffect(() => {
    loadElectrical(assetId);
    return () => {
      removeElectrical(assetId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  return (
    <>
      {electrical?.status === Statuses.Loading && <ElectricalChartsLoading />}
      {electrical?.status === Statuses.Succeeded && (
        <ElectricalChartsSucceeded
          electrical={electrical.data}
          modelId={modelId}
          modelImplementationId={modelImplementationId}
        />
      )}
      {electrical?.status === Statuses.Failed && <WidgetErrorMessage />}
    </>
  );
};

const ElectricalChartsLoading = () => {
  return <Processing />;
};

interface IElectricalChartsSucceededProps {
  electrical: IPartialDischargeResult;
  modelId: string;
  modelImplementationId: string;
}

const ElectricalChartsSucceeded = ({
  electrical,
  modelId,
  modelImplementationId
}: IElectricalChartsSucceededProps) => {
  const intl = useIntl();

  return (
    <PartialDischargeCharts
      charts={["Intensity", "PulseMagnitude"].map((key) => {
        const data = electrical[key];
        const chart: IPartialDischargeChart = {
          name: key,
          displayName: intl.formatMessage({
            defaultMessage: key,
            id: `detail_page.widgets.analytics.transformers.partial_discharge.${kebabCase(
              key
            )}`
          }),
          alertThreshold: data?.AlertThreshold,
          warningThreshold: data?.WarningThreshold,
          unit: data?.Unit,
          recentReadings: data ? mapToReadings(data.RecentReadings) : null,
          historicalReadings: data
            ? mapToReadings(data.HistoricalReadings)
            : null
        };

        return chart;
      })}
      modelId={modelId}
      modelImplementationId={modelImplementationId}
    />
  );
};

const mapStateToProps = (
  state: IState,
  { assetId }: IElectricalChartsOwnProps
): IElectricalChartsData => {
  return {
    electrical: getElectricalSelector(state)(assetId)
  };
};

const mapDispatchToProps = (
  dispatch: AppDispatch
): IElectricalChartsActions => {
  return {
    loadElectrical: (assetId: string) => dispatch(loadElectrical(assetId)),
    removeElectrical: (assetId: string) => dispatch(removeElectrical(assetId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ElectricalCharts);
