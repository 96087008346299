// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React, { ReactNode } from "react";

export interface IVersionProviderActions {
  loadVersion: () => void;
}

export interface IVersionProviderProps extends IVersionProviderActions {
  children?: ReactNode;
}

class VersionProvider extends React.Component<IVersionProviderProps> {
  componentDidMount() {
    const { loadVersion } = this.props;
    loadVersion();
  }

  render() {
    const { children } = this.props;
    const childArray = React.Children.toArray(children);
    return <div>{childArray}</div>;
  }
}

export default VersionProvider;
