// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import BodyCell from "common/datagrid/BodyCell";
import BodyRowActions from "common/datagrid/BodyRowActions";
import { IAction, IColumn, IRow } from "common/datagrid/DataGrid";
import React, { useRef } from "react";

export const dragOverlapMargin = 2;

export interface IBodyRowProps {
  actions: IAction[];
  columns: IColumn[];
  gridId: string;
  onRowClick?: (e: React.MouseEvent<HTMLDivElement>, row: IRow) => void;
  row: IRow;
  rows: IRow[];
}

interface IPosition {
  x: number;
  y: number;
}

const BodyRow = (props: IBodyRowProps) => {
  const { actions, columns, gridId, onRowClick, row, rows } = props;

  const mouseDownPositionRef = useRef<IPosition>();
  const mouseUpPositionRef = useRef<IPosition>();

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    mouseDownPositionRef.current = {
      x: e.clientX,
      y: e.clientY
    };
  };

  const handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    mouseUpPositionRef.current = {
      x: e.clientX,
      y: e.clientY
    };
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (
      onRowClick &&
      Math.abs(mouseDownPositionRef.current.x - mouseUpPositionRef.current.x) <
        dragOverlapMargin &&
      Math.abs(mouseDownPositionRef.current.y - mouseUpPositionRef.current.y) <
        dragOverlapMargin
    ) {
      onRowClick(e, row);
    }
  };

  const handleActionsClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const cells = columns.map((c, i) => (
    <BodyCell
      column={c}
      gridId={gridId}
      key={c.config.id}
      row={row}
      rows={rows}
    />
  ));

  return (
    <div className="grid-row-container">
      <div
        className={`
          grid-row
          ${onRowClick && "clickable"}
          ${row.state.selected && "selected"}
          ${row.state.className}
        `}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        {cells}
        <BodyRowActions
          actions={actions}
          row={row}
          onClick={handleActionsClick}
        />
      </div>
    </div>
  );
};

export default BodyRow;
