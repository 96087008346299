// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getCustomerVisualizationsIssueGridSelector = createSelector(
  (state: IState) => state.app.customerVisualizationsIssueGrid,
  (customer) => customer
);

export default getCustomerVisualizationsIssueGridSelector;
