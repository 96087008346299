// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { isEmpty } from "lodash";
import Data, { Statuses } from "core/data/models/Data";

const shouldLoadDielectricData = ({ status, data }: Data<any>) => {
  return status !== Statuses.Succeeded || isEmpty(data);
};

export default shouldLoadDielectricData;
