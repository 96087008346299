// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { getDataKey } from "../actions/loadEvents";

const getEventsNameSelector = createSelector(
  (state: IState) => (assetId: string) =>
    state.data[getDataKey(assetId)] as Data<string[]>,
  (eventNames) => eventNames
);

export default getEventsNameSelector;
