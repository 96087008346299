// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { isNil } from "lodash";
import * as React from "react";
import { injectIntl, IntlShape } from "react-intl";
import { connect } from "react-redux";

import LabeledRow from "components/common/LabeledRow";
import Processing from "components/common/Processing";
import {
  WidgetEmpty,
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import getAssetDetailsSelector from "features/detailpage/selectors/getAssetDetailsSelector";
import IAssetDetails from "models/IAssetDetails";
import { IState } from "reducers/Index";
import AssetNameplateItems, { INameplateItem } from "./AssetNameplateItems";

import "./AssetNameplate.less";

interface IAssetNameplateDataProps {
  assetDetails: Data<IAssetDetails>;
}

interface IAssetNameplateOwnProps {
  assetId: string;
}

interface IAssetNameplateIntlProps {
  intl: IntlShape;
}

type AssetNameplateProps = IAssetNameplateOwnProps &
  IAssetNameplateDataProps &
  IAssetNameplateIntlProps;

class AssetNameplate extends React.Component<AssetNameplateProps> {
  public render() {
    const { assetDetails } = this.props;
    let component: JSX.Element = null;

    if (assetDetails?.status === Statuses.Loading) {
      component = this.getLoadingComponent();
    } else if (assetDetails?.status === Statuses.Failed) {
      component = this.getErrorComponent();
    } else if (assetDetails?.status === Statuses.Succeeded) {
      if (isNil(assetDetails?.data?.NameplateWithModelInfo))
        component = this.getNoDataComponent();
      else component = this.getNameplateComponent();
    } else {
      component = this.getEmptyComponent();
    }

    return component;
  }

  private getLargeContentItem(attribute: INameplateItem): JSX.Element {
    return (
      <div
        key={attribute.id ? attribute.id : attribute.key}
        className="attribute large-content"
      >
        <LabeledRow
          label={
            attribute.id
              ? {
                  id: attribute.id,
                  defaultMessage: attribute.defaultMessage
                }
              : attribute.key
          }
          value={attribute.value}
          defaultValue={"-"}
        />
      </div>
    );
  }

  private getSmallContentItem(attribute: INameplateItem): JSX.Element {
    const value = this.getFormattedAttributeValue(attribute);
    return (
      <div
        key={attribute.id ? attribute.id : attribute.key}
        className="attribute"
      >
        <LabeledRow
          label={
            attribute.id
              ? {
                  id: attribute.id,
                  defaultMessage: attribute.defaultMessage
                }
              : attribute.key
          }
          value={value}
          defaultValue={"-"}
          truncate={attribute.truncate}
          unit={attribute.unit}
          additionalData={attribute.additionalData}
        />
      </div>
    );
  }

  private getComponentContentItem(attribute: INameplateItem): JSX.Element {
    return (
      <div
        key={attribute.id ? attribute.id : attribute.key}
        className="attribute"
      >
        <attribute.value />
      </div>
    );
  }

  private getLoadingComponent(): JSX.Element {
    return <Processing />;
  }

  private getErrorComponent(): JSX.Element {
    const { assetDetails } = this.props;
    return (
      <WidgetErrorMessage
        messageId="global.empty"
        messageDefault={assetDetails?.message}
        messageValues={{ message: assetDetails?.message }}
      />
    );
  }

  private getNameplateComponent(): JSX.Element {
    const { assetDetails } = this.props;
    const nameplateItems = AssetNameplateItems.getNameplateItems(
      assetDetails?.data
    );
    return (
      <div className="asset-nameplate-attributes-container">
        {nameplateItems?.map((attribute, i, a) => {
          return typeof attribute.value === "function"
            ? this.getComponentContentItem(attribute)
            : attribute.isLargeContent
            ? this.getLargeContentItem(attribute)
            : this.getSmallContentItem(attribute);
        })}
      </div>
    );
  }

  private getEmptyComponent(): JSX.Element {
    return <WidgetEmpty />;
  }

  private getNoDataComponent(): JSX.Element {
    return <WidgetNoDataMessage />;
  }

  private getFormattedAttributeValue(
    attribute: INameplateItem
  ): string | number {
    if (attribute.isBoolValue) {
      const yesNoMsgId = attribute.value
        ? "global.messages.yes"
        : "global.messages.no";
      const defaultMsg = attribute.value ? "YES" : "NO";
      return this.props.intl.formatMessage({
        id: yesNoMsgId,
        defaultMessage: defaultMsg
      });
    }

    if (typeof attribute.value === "number") {
      return attribute.value;
    }

    if (attribute.value && String(attribute.value).trim() !== "") {
      const { assetDetails } = this.props;
      const attributeId = attribute.id !== null ? attribute.id : attribute.key;
      return this.props.intl.formatMessage({
        id: `${assetDetails?.data?.NameplateWithModelInfo?.ModelId}.${attributeId}.${attribute.value}`,
        defaultMessage: String(attribute.value)
      });
    }

    return "-";
  }
}

const mapStateToProps = (
  state: IState,
  { assetId }: IAssetNameplateOwnProps
): IAssetNameplateDataProps => ({
  assetDetails: getAssetDetailsSelector(state)(assetId)
});

export default connect(mapStateToProps)(injectIntl(AssetNameplate));
