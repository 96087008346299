// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { getDataKey } from "features/ConfigurationTool/actions/loadConnectedDevices";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import IConnectedDevicesData from "../models/connectedDevices/IConnectedDevicesData";

const getConnectedDevicesDataSelector = createSelector(
  (state: IState) => (assetId: string) =>
    state.data[getDataKey(assetId)] as Data<IConnectedDevicesData>,
  (connectedDevices) => connectedDevices
);

export default getConnectedDevicesDataSelector;
