// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import MaintenanceScoreFormatted from "common/formatters/MaintenanceScore";
import React from "react";
import styled from "styled-components";

interface IMaintenancePriorityProps {
  className?: string;
  value: number;
}

const MaintenancePriority = ({
  className,
  value
}: IMaintenancePriorityProps) => (
  <div className={className}>
    <MaintenanceScoreFormatted value={value} />
  </div>
);

const defaultGridPadding = "10px";

export default styled(MaintenancePriority)`
  padding: ${defaultGridPadding};
`;
