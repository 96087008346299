// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import ISelectOption from "../../../models/ISelectOption";

const partitionModbusOptions: ISelectOption[] = [
  {
    value: 1,
    label: "Coil (1)"
  },
  {
    value: 3,
    label: "Holding-Reg (3)"
  },
  {
    value: 4,
    label: "Input-Reg (4)"
  }
];

export default partitionModbusOptions;
