// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useCallback } from "react";
import { ChartAPI, DataPoint } from "c3";

interface ITarget {
  id: string;
  id_org: string;
  values: DataPoint[];
}

const excludedTargets = [
  "score_stack",
  "risk_low_stack",
  "risk_med_stack",
  "risk_hig_stack",
  "score_low",
  "importance_low",
  "score_high",
  "importance_high"
];

const filterTargets = (targets: ITarget[]) => {
  return targets.filter(
    (x) => !excludedTargets.some((excluded) => excluded === x.id)
  );
};

const findClosestFromTargets = (chart: ChartAPI) => {
  const original = chart.internal.findClosestFromTargets;
  return (targets: ITarget[], pos: any) => {
    targets = filterTargets(targets);
    return original.call(chart.internal, targets, pos);
  };
};

const useChartInternalOverwrite = () => {
  const decorateChartInternal = useCallback((chart: ChartAPI) => {
    chart.internal.findClosestFromTargets = findClosestFromTargets(chart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { decorateChartInternal };
};

export default useChartInternalOverwrite;
