// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import loadData from "../../../core/data/actions/loadData";
import UrlService from "../../../core/data/services/UrlService";
import { config } from "../../../utils/AppConfig";

export const dataKey = `ReportsConfiguration_Reports`;

const loadReports = () => {
  const url = UrlService.getApiUrl(config.api.powerbiembed.reports);

  return loadData({ key: dataKey, url, method: "get" });
};

export default loadReports;
