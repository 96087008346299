// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React, { useMemo } from "react";

import SlidePanelTitle from "features/issues/components/SlidePanelTitle";
import SlidePanelMaintenancePriority from "features/issues/components/SlidePanelMaintenacePriority";
import SlidePanelActions from "features/issues/components/SlidePanelActions";

export enum IssueSlidePanelMode {
  MaintenancePriority,
  NumberOfActions
}

export interface ISlidePanelData {
  assetId?: string;
  assetIssueId?: string;
  assetName?: string;
  maintenancePriority?: number;
  maintenanceScore?: number;
  normalizedScore?: number;
  importance?: number;
  costLevel?: string;
  costFactor?: number;
  urgencyLevel?: string;
  urgencyFactor?: number;
  impactLevel?: string;
  impactFactor?: number;
  assetType?: string;
  assetLocation?: string;
}

const useIssuesSlidePanel = (
  mode: IssueSlidePanelMode,
  options: ISlidePanelData
) => {
  const PanelComponent = useMemo(() => {
    return () =>
      options ? (
        <SlidePanelTitle
          assetId={options.assetId}
          assetName={options.assetName}
          key={options.assetIssueId}
        />
      ) : null;
  }, [options]);

  const ContentComponent = useMemo(() => {
    switch (mode) {
      case IssueSlidePanelMode.MaintenancePriority:
        return () => (
          <SlidePanelMaintenancePriority
            key={options.assetIssueId}
            score={options.maintenancePriority}
            rawScore={options.maintenanceScore}
            normalizedScore={options.normalizedScore}
            importance={options.importance}
            costLevel={options.costLevel}
            costFactor={options.costFactor}
            urgencyLevel={options.urgencyLevel}
            urgencyFactor={options.urgencyFactor}
            impactLevel={options.impactLevel}
            impactFactor={options.impactFactor}
          />
        );
      case IssueSlidePanelMode.NumberOfActions:
        return () => (
          <SlidePanelActions
            assetIssueId={options.assetIssueId}
            assetType={options.assetType}
            assetLocation={options.assetLocation}
            key={options.assetIssueId}
          />
        );
      default:
        return null;
    }
  }, [mode, options]);

  return { PanelComponent, ContentComponent };
};

export default useIssuesSlidePanel;
