// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { loadAssetSubfactors } from "features/detailpage/features/subfactorsgrid/actions/SubfactorsGridActions";
import SubfactorsGrid, {
  ISubfactorsGridActions,
  ISubfactorsGridStateProps
} from "features/detailpage/features/subfactorsgrid/components";
import {
  getAssetSubfactorsCountSelector,
  getAssetSubfactorsSelector,
  getCurrentValueDateSelector,
  getPreviousValueDateSelector
} from "features/detailpage/features/subfactorsgrid/reducers/SubfactorsGridReducer";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";

const mapStateToProps = (state: IState): ISubfactorsGridStateProps => ({
  assetSubfactors: getAssetSubfactorsSelector(state),
  assetSubfactorsCount: getAssetSubfactorsCountSelector(state),
  previousDate: getPreviousValueDateSelector(state),
  currentDate: getCurrentValueDateSelector(state)
});

const mapDispatchToProps = (dispatch: AppDispatch): ISubfactorsGridActions => ({
  loadAssetSubfactors: (assetId: string) =>
    dispatch(loadAssetSubfactors(assetId))
});

export default connect(mapStateToProps, mapDispatchToProps)(SubfactorsGrid);
