// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Guid from "core/guid/Guid";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import { createInput, setInputValue } from "../actions/FormActions";
import ToggleInput, {
  IToggleInputActions,
  IToggleInputOwnProps
} from "../components/ToggleInput";

export function mapStateToProps(state: IState, ownProps: IToggleInputOwnProps) {
  const form = state.common.forms && state.common.forms.get(ownProps.formName);
  const input = form && form.inputs && form.inputs.get(ownProps.inputName);
  const guid = input ? input.guid : Guid.getUniqGuid();
  return {
    guid
  };
}

export function mapDispatchToProps(
  dispatch: AppDispatch,
  ownProps: IToggleInputOwnProps
): IToggleInputActions {
  return {
    createInput: () => {
      createInput(
        dispatch,
        ownProps.formName,
        ownProps.inputName,
        ownProps.defaultValue,
        ownProps.defaultValue
      );
    },
    setInputValue: (value: any) => {
      setInputValue(
        dispatch,
        ownProps.formName,
        ownProps.inputName,
        value,
        ownProps.validators
      );
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ToggleInput);
