// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import dataKey from "../constants/voltageStressDataKey";
import IVoltageStressData from "../models/IVoltageStressData";

const getVoltageStressDataSelector = createSelector(
  (state: IState) => (assetId: string) =>
    state.data[dataKey(assetId)] as Data<IVoltageStressData>,
  (voltageStressData) => voltageStressData
);

export default getVoltageStressDataSelector;
