import createOctagramStar from "@apm/widgets/build/components/D3Chart/utils/createOctagramStar";
import { useLayoutEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { createOctagramLegends } from "../utils/duvalAnalysisHelper";

const DuvalOctagramLegend = () => {
  const filledOctRef = useRef();
  const octRef = useRef();

  useLayoutEffect(
    () => createOctagramLegends(filledOctRef, octRef, createOctagramStar(8)),
    []
  );

  return (
    <>
      <tr>
        <td>
          <svg ref={filledOctRef}></svg>
        </td>
        <td>
          ={" "}
          <FormattedMessage
            id={`detail_page.widgets.analytics.transformers.duval.Latest`}
            defaultMessage={"Latest reading"}
          />
        </td>
      </tr>
      <tr>
        <td>
          <svg ref={octRef}></svg>
        </td>
        <td>
          ={" "}
          <FormattedMessage
            id={`detail_page.widgets.analytics.transformers.duval.Oldest`}
            defaultMessage={"Oldest reading"}
          />
        </td>
      </tr>
    </>
  );
};

export default DuvalOctagramLegend;
