// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import loadData from "core/data/actions/loadData";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";

export const getDataKey = () => `ConfigurationTool_GetAssetsStatuses`;

const loadAssetsStatuses = () => {
  const url = UrlService.getApiUrl(
    config.api.configurationTool.assetsStatusesUrl,
    { effective: "true" }
  );

  return loadData({
    key: getDataKey(),
    url,
    method: "get"
  });
};

export default loadAssetsStatuses;
