// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import BoxPlot, { BoxPlotData } from "./BoxPlot";

interface IBoxPlotChartProps {
  data: BoxPlotData;
}

class BoxPlotChart extends React.Component<IBoxPlotChartProps, {}> {
  private d3Root: SVGElement = null;
  private boxPlot: BoxPlot = null;

  componentDidMount(): void {
    this.boxPlot = new BoxPlot(this.d3Root);
    this.drawBoxPlot();
  }

  componentDidUpdate(): void {
    this.drawBoxPlot();
  }

  private drawBoxPlot() {
    this.boxPlot.draw(this.props.data);
  }

  render() {
    return (
      <div className="box-plot">
        <svg
          ref={(ref: SVGElement) => {
            this.d3Root = ref;
          }}
        />
      </div>
    );
  }
}

export default BoxPlotChart;
