// Copyright 2016-2023 Hitachi Energy. All rights reserved.
const getSotType = (sotType: string) => {
  let shortSotType: string;

  switch (sotType) {
    case "MineralOilIECNetworkTransformer":
      shortSotType = "IEC";
      break;
    case "SiliconeIEEENetworkTransformer":
      shortSotType = "IEEE";
      break;
    default:
      shortSotType = sotType;
  }

  return shortSotType;
};

export default getSotType;
