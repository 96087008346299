// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Icon } from "@pg/common";
import { Modal } from "antd";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import styled from "styled-components";
import { setSimulationModalVisibleAction } from "../../../actions/RiskSimulationSummaryActions";
import IDegradationScoreTrend from "../../../models/IDegradationScoreTrend";
import getSimulationModalVisibleSelector from "../../../selectors/getSimulationModalVisibleSelector";
import useSimulationModal from "../hooks/useSimulationModal";
import SimulationModalContextProvider from "../providers/SimulationModalContextProvider";
import SimulationModalContent from "./SimulationModalContent";

interface ISimulationModalActions {
  setModalVisible: (value: boolean) => void;
}

interface ISimulationModalData {
  modalVisible: boolean;
}

interface ISimulationModalProps
  extends ISimulationModalActions,
    ISimulationModalData {
  className?: string;
  assetId: string;
  modelId: string;
  implementationId: string;
  degradationScoreTrend: IDegradationScoreTrend;
}

const SimulationModal = ({
  className,
  assetId,
  modelId,
  implementationId,
  degradationScoreTrend,
  modalVisible,
  setModalVisible
}: ISimulationModalProps) => {
  const { handleCancel } = useSimulationModal(setModalVisible);
  return (
    <Modal
      centered
      width="85%"
      bodyStyle={{
        height: "70vh"
      }}
      open={modalVisible}
      footer={null}
      destroyOnClose={true}
      onCancel={handleCancel}
      className={className}
      closeIcon={<Icon name="close" />}
      data-qa="simulation-modal"
    >
      <SimulationModalContextProvider
        assetId={assetId}
        degradationScoreTrend={degradationScoreTrend}
        modelId={modelId}
        implementationId={implementationId}
      >
        <SimulationModalContent />
      </SimulationModalContextProvider>
    </Modal>
  );
};

const mapStateToProps = (state: IState): ISimulationModalData => ({
  modalVisible: getSimulationModalVisibleSelector(state)
});

const mapDispatchToProps = (dispatch: AppDispatch): ISimulationModalActions => {
  const setModalVisible = (value: boolean) => {
    dispatch(setSimulationModalVisibleAction(value));
  };
  return {
    setModalVisible
  };
};

const StyledSimulationModal = styled(SimulationModal)`
  .ant-modal-close {
    outline: none;
  }

  .ant-modal-body {
    padding: 0px;
  }
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledSimulationModal);
