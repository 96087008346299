// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";
import Widget from "components/common/widget/Widget";
import { Route, RouterSwitch } from "core/app/components/RouterProvider";
import DetailPageWidgetContext from "features/detailpage/contexts/DetailPageWidgetContext";
import RiskMatrixWidget from "features/detailpage/features/riskmatrix/components/RiskMatrixWidget";
import { first } from "lodash";
import LifecycleStatus from "models/LifecycleStatus";
import { memo, useContext, useMemo } from "react";
import ParametersTabs from "../features/ParametersTabs";
import ReplacementScoreTabs from "../features/replacementscore/components/tabs/ReplacementScoreTabs";
import RiskTrendWidgets from "../features/risktrend/components/RiskTrendWidgets";
import AssetNameplate from "./AssetNameplate";
import "./DetailPageWidgets.less";
import MessagesWidget from "./MessagesWidget";

class WidgetsIds {
  static readonly HealthScoreTrendWidgetId = "DetailPageHealthScoreWidget";
  static readonly RiskMatrixWidgetId = "DetailPageRiskMatrixWidget";
  static readonly ParametersListWidgetId = "DetailPageParametersList";
  static readonly AssetNameplateWidgetId = "DetailPageAssetNameplateWidget";
  static readonly MessagesWidgetId = "DetailPageMessagesWidget";
  static readonly ReplacementScoreWidgetId = "ReplacementScoreWidget";
}

interface IDetailPageWidgetsContextProps {
  isParametersListWidgetVisible: boolean;
  isAssetNameplateWidgetVisible: boolean;
}

interface IDetailPageWidgetsOwnProps {
  assetId: string;
  modelId: string;
  implementationId: string;
  replacementScoreModelImplementationId: string;
  assetType: string;
  analyticsAvailable: boolean;
  dcTransformerAvailable: boolean;
  kinectricsNTAvailable: boolean;
  batteryAvailable: boolean;
  cbkAvailable: boolean;
  expanded: boolean;
  lifecycleStatus: LifecycleStatus;
}

type DetailPageWidgetsProps = IDetailPageWidgetsOwnProps &
  IDetailPageWidgetsContextProps;

const DetailPageWidgets = memo(
  ({
    assetId,
    modelId,
    implementationId,
    replacementScoreModelImplementationId,
    assetType,
    analyticsAvailable,
    dcTransformerAvailable,
    kinectricsNTAvailable,
    batteryAvailable,
    cbkAvailable,
    expanded,
    isAssetNameplateWidgetVisible,
    isParametersListWidgetVisible,
    lifecycleStatus
  }: DetailPageWidgetsProps) => {
    const searchParams = useMemo(
      () => new SearchParams({ assetId }),
      [assetId]
    );

    const routes = useMemo<Route[]>(() => {
      return [
        {
          id: "Assets-DetailPage-Risk",
          route: "Risk/*",
          to: {
            pathname: "/Assets/DetailPage/Risk",
            search: searchParams.toString()
          },
          element: (
            <div className="charts-wrapper" data-qa="risk-tab">
              <Widget
                element={<RiskMatrixWidget assetId={assetId} />}
                titleId="detail_page.widgets.risk_matrix_widget.title"
                titleDefault="Risk Matrix"
                size={null}
                widgetId={WidgetsIds.RiskMatrixWidgetId}
                className="risk-matrix-widget"
              />
              <RiskTrendWidgets
                expanded={expanded}
                widgetId={WidgetsIds.HealthScoreTrendWidgetId}
                assetId={assetId}
                modelId={modelId}
                implementationId={implementationId}
                lifecycleStatus={lifecycleStatus}
              />
            </div>
          )
        },
        {
          id: "Assets-DetailPage-Parameters",
          route: "Parameters/*",
          to: {
            pathname: "/Assets/DetailPage/Parameters",
            search: searchParams.toString()
          },
          element: (
            <ParametersTabs
              modelId={modelId}
              implementationId={implementationId}
              assetId={assetId}
              assetType={assetType}
              analyticsAvailable={analyticsAvailable}
              dcTransformerAvailable={dcTransformerAvailable}
              kinectricsNetworkTransformerAvailable={kinectricsNTAvailable}
              batteryAvailable={batteryAvailable}
              cbkAvailable={cbkAvailable}
              lifecycleStatus={lifecycleStatus}
            />
          )
        },
        {
          id: "Assets-DetailPage-Messages",
          route: "Messages/*",
          to: {
            pathname: "/Assets/DetailPage/Messages",
            search: searchParams.toString()
          },
          element: (
            <Widget
              element={<MessagesWidget assetId={assetId} />}
              titleId="detail_page.widgets.messages.title"
              titleDefault="Messages"
              size={null}
              widgetId={WidgetsIds.MessagesWidgetId}
            />
          )
        },
        {
          id: "Assets-DetailPage-RPS",
          route: "RPS/*",
          to: {
            pathname: "/Assets/DetailPage/RPS",
            search: searchParams.toString()
          },
          element: (
            <div className="replacement-score-widget-wrapper">
              <Widget
                element={
                  <ReplacementScoreTabs
                    assetId={assetId}
                    replacementScoreModelImplementationId={
                      replacementScoreModelImplementationId
                    }
                  />
                }
                titleId="detail_page.widgets.replacement_score.title"
                titleDefault="Replacement Score"
                hideHeader={true}
                size={null}
                widgetId={WidgetsIds.ReplacementScoreWidgetId}
              />
            </div>
          )
        }
      ];
    }, [
      analyticsAvailable,
      assetId,
      assetType,
      batteryAvailable,
      cbkAvailable,
      dcTransformerAvailable,
      expanded,
      implementationId,
      kinectricsNTAvailable,
      lifecycleStatus,
      modelId,
      replacementScoreModelImplementationId,
      searchParams
    ]);

    const defaultRoute = useMemo(() => first(routes), [routes]);

    return (
      <div
        className={`detail-page-widgets scroll-area ${
          isParametersListWidgetVisible && "horizontal"
        }`}
      >
        <div
          className={
            isParametersListWidgetVisible ? "widgets" : "widgets max-col12vw"
          }
        >
          {isAssetNameplateWidgetVisible && (
            <Widget
              element={<AssetNameplate assetId={assetId} />}
              titleId="detail_page.widgets.asset_nameplate_widget.title"
              titleDefault="Nameplate"
              size={null}
              widgetId={WidgetsIds.AssetNameplateWidgetId}
            />
          )}
          <RouterSwitch
            defaultRoute={{ route: defaultRoute }}
            routes={routes}
          />
        </div>
      </div>
    );
  }
);

export default (props: IDetailPageWidgetsOwnProps) => {
  const { isAssetNameplateWidgetVisible, isParametersListWidgetVisible } =
    useContext(DetailPageWidgetContext);

  return (
    <DetailPageWidgets
      {...props}
      isAssetNameplateWidgetVisible={isAssetNameplateWidgetVisible}
      isParametersListWidgetVisible={isParametersListWidgetVisible}
    />
  );
};
