// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { ISeries } from "@apm/widgets/build/widgets/PrognosticTrend";
import { SubscoreService } from "common/formatters/SubscoreName";
import { head, isEmpty, last } from "lodash";
import { IntlShape } from "react-intl";
import IScoreTrend from "../../../models/IScoreTrend";

interface ICreateResult {
  series: ISeries[];
  prognosisSeries: ISeries[];
  simulationSeries: ISeries[];
}

export default class PrognosticChartSeriesCreator {
  constructor(
    private intl: IntlShape,
    private modelId: string,
    private implementationId: string
  ) {}

  create(
    degradationScoreSeries: IScoreTrend[],
    degradationScorePrognosisSeries: IScoreTrend[],
    degradationScoreSimulationSeries: IScoreTrend[]
  ) {
    return new Promise<ICreateResult>((resolve) => {
      setTimeout(async () => {
        const [series, prognosisSeries, simulationSeries] = await Promise.all([
          this.createSeries(degradationScoreSeries),
          this.createSeries(
            degradationScorePrognosisSeries,
            degradationScoreSeries
          ),
          this.createSeries(
            degradationScoreSimulationSeries,
            degradationScoreSeries,
            true
          )
        ]);
        resolve({
          series,
          prognosisSeries,
          simulationSeries
        });
      });
    });
  }

  private async createSeries(
    scoreSeries: IScoreTrend[] = [],
    degradationScores?: IScoreTrend[],
    isSimulation?: boolean
  ) {
    if (isEmpty(scoreSeries)) return Promise.resolve([]);
    const series: ISeries[] = [];
    for (let i = 0; i < scoreSeries?.length; i++) {
      const { Dates, Name, Values } = scoreSeries[i];
      const dates = [...Dates];
      const values = [...Values];

      if (degradationScores) {
        const lastDates = last(degradationScores[i].Dates);
        const lastValue = last(degradationScores[i].Values);
        const firstDateScoreSeries = head(scoreSeries[i].Dates);

        if (
          new Date(lastDates).valueOf() <
          new Date(firstDateScoreSeries).valueOf()
        ) {
          dates.unshift(lastDates);
          values.unshift(lastValue);
        }
      }

      series.push({
        displayName: this.getSeriesName(Name, isSimulation),
        name: Name,
        values: this.createValues(dates, values)
      });
    }
    return Promise.resolve(series);
  }

  private getSeriesName(seriesName: string, isSimulation: boolean) {
    const name =
      seriesName === "Score"
        ? this.intl.formatMessage({
            id: "detail_page.risk_trend.prognostic_legend.condition",
            defaultMessage: "Condition"
          })
        : SubscoreService.format(
            seriesName,
            this.modelId,
            this.implementationId,
            this.intl
          );

    return isSimulation
      ? this.intl.formatMessage(
          {
            id: "detail_page.risk_trend.simulation_modal.tooltip.subscore_simulation",
            defaultMessage: "(Sim) {value}"
          },
          {
            value: name
          }
        )
      : name;
  }

  private createValues(dates: string[], values: number[]) {
    const result: { [key: string]: number } = {};
    for (let i = 0; i < dates?.length; i++) {
      result[dates[i]] = values[i];
    }
    return result;
  }
}
