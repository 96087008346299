// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import SearchParams, {
  ISearchParams
} from "@pg/common/build/models/SearchParams";
import GridBody from "common/datagrid/GridBody";
import GridFooter from "common/datagrid/GridFooter";
import GridHeader from "common/datagrid/GridHeader";
import { IEndpoint } from "common/datagrid/models/IEndpoint";
import SortOrders from "common/datagrid/models/SortOrders";
import DataService, {
  ContentTypes
} from "common/datagrid/services/DataService";
import ElementService from "common/datagrid/services/ElementService";
import EventService from "common/datagrid/services/EventService";
import PropertyService, {
  IProperties
} from "common/datagrid/services/PropertyService";
import { NavigateFn, useAppNavigate } from "core/app/components/RouterProvider";
import Guid from "core/guid/Guid";
import Sequence from "core/sequence/Sequence";
import { findIndex, maxBy } from "lodash";
import React, { ComponentType } from "react";
import { MessageDescriptor } from "react-intl";
import { Location, useLocation } from "react-router";
import "./DataGrid.less";

export enum GridStatuses {
  Processing,
  Loading,
  Updating,
  Succeeded,
  Failed
}

export interface IColumnConfig {
  compareFunction?: (r1: IRowData, r2: IRowData) => number;
  compareFunctionWithSortOrder?: (
    r1: IRowData,
    r2: IRowData,
    order: SortOrders
  ) => number;
  component?: (value: any, row: IRow, rows: IRow[]) => JSX.Element;
  defaultSortOrder?: SortOrders;
  defaultGroupOrder?: number;
  frozen?: boolean;
  id: string;
  message?: MessageDescriptor;
  sortable?: boolean;
  weight?: number;
  width?: number;
  HeaderTooltip?: ComponentType;
}

export interface IColumnState {
  calculatedWidth: string;
  groupOrder?: number;
  sortOrder: SortOrders;
}

export interface IColumn {
  config: IColumnConfig;
  state: IColumnState;
}

export interface IDataEndpoint extends IEndpoint {
  onDataLoaded?: (rowsTotal: number, rowsNew: number | null) => void;
}

export interface IActionConfig {
  callback?: (
    row: IRow,
    onStarted: () => void,
    onSucceeded: () => void,
    onFailed: () => void
  ) => void;
  Component?: ActionComponent;
  display?: (row: IRow) => boolean;
  iconName?: string;
  id: string;
}

export interface IAction extends IActionConfig {
  callback?: (row: IRow) => void;
}

export interface IActionComponentProps<T = IRowData> {
  row: IRow<T>;
}

export type ActionComponent = (props: IActionComponentProps) => JSX.Element;

export interface IRowData {
  [columnId: string]: any;
}

export interface IRowState {
  selected?: boolean;
  className?: string;
}

export interface IRow<T = IRowData> {
  rowId: number;
  data: T;
  state: IRowState;
}

export interface IExportToExcel {
  disabled?: boolean;
  disabledMessage?: string;
  onClick?: (columns: IColumn[]) => void;
}

export interface IExportToCsv {
  disabled?: boolean;
  disabledMessage?: string;
  dataType?: ContentTypes;
  onClick?: (columns: IColumn[]) => void;
}

export interface IDataGridProps {
  actions?: IActionConfig[];
  columns: IColumnConfig[];
  data?: IRowData[];
  dataEndpoint?: IDataEndpoint;
  filterRows?: (row: IRow) => boolean;
  getDefaultRowSelected?: (data: IRowData) => boolean;
  getRowClassName?: (data: IRowData) => string;
  footerComponent?: (rows: IRow[], rowsTotal: number) => JSX.Element;
  multiColumnSorting?: boolean;
  exportToExcel?: IExportToExcel;
  exportToCsv?: IExportToCsv;
  onRowClick?: (row: IRow, e: React.MouseEvent<HTMLDivElement>) => void;
  onSelectionChanged?: (affectedRow: IRow, selectedRows: IRow[]) => void;
  selectableRow?: boolean | ((row: IRow) => boolean);
  showColumnBorder?: boolean;
  showFooter?: boolean;
}

export interface IDataGridNavProps {
  location: Location;
  navigate: NavigateFn;
}

export interface IDataGridData {
  actions: IAction[];
  columns: IColumn[];
  rows: IRow[];
}

export interface IDataGridState extends IDataGridData {
  footerWidth: number;
  gridId: string;
  gridProperties: IProperties;
  gridStatus: GridStatuses;
  filteredRows: IRow[];
  headerWidth: number;
  rowsTotal: number;
  showFixedFooter: boolean;
  showFixedHeader: boolean;
}

export const parentClassName = "data-grid__parent";
export const scrollClassName = "data-grid__scroll";

let groupingSequence: Sequence;

class DataGrid extends React.Component<
  IDataGridProps & IDataGridNavProps,
  IDataGridState
> {
  static defaultProps = {
    frozen: false,
    multiColumnSorting: true,
    selectableRow: false,
    showColumnBorder: true,
    showFooter: true
  };

  private dataNext: () => void;
  private dataProcessing = false;
  private dataXhr: JQueryXHR;
  private propertiesNext: () => void;
  private propertiesTimer: NodeJS.Timeout;

  constructor(props: IDataGridProps & IDataGridNavProps) {
    super(props);

    const { handleActionStarted, handleActionSucceeded, handleActionFailed } =
      this;

    const isConfigValid = DataGrid.isConfigValid(props);
    if (!isConfigValid) return;

    const actions = props.actions
      ? props.actions.map((a) => {
          return {
            callback: (row: IRow) => {
              a.callback(
                row,
                handleActionStarted,
                handleActionSucceeded,
                handleActionFailed
              );
            },
            iconName: a.iconName,
            id: a.id,
            Component: a.Component,
            display: a.display
          } as IAction;
        })
      : undefined;

    const columns = DataService.processColumns(props.columns);

    this.state = {
      actions: actions,
      filteredRows: undefined,
      footerWidth: undefined,
      gridId: `data-grid-${Guid.getUniqGuid()}`,
      gridProperties: undefined,
      gridStatus: GridStatuses.Loading,
      columns: columns,
      headerWidth: undefined,
      rows: undefined,
      rowsTotal: undefined,
      showFixedFooter: false,
      showFixedHeader: false
    };
  }

  componentDidUpdate(prevProps: IDataGridProps, prevState: IDataGridState) {
    const {
      mapSortOrderToUrl,
      processOnClient,
      processOnServer,
      processProperties
    } = this;

    const { getRowClassName } = this.props;

    const isConfigValid = DataGrid.isConfigValid(this.props);
    if (!isConfigValid) return;

    const isClientMode = DataGrid.isClientMode(this.props);
    const isServerMode = DataGrid.isServerMode(this.props);

    const isColumnsChanged = DataGrid.isColumnsChanged(
      prevProps.columns,
      this.props.columns
    );
    const isDataChanged = DataGrid.isDataChanged(
      prevProps.data,
      this.props.data
    );
    const isFilterRowsChanged = prevProps.filterRows !== this.props.filterRows;
    const isDataEndpointChanged = DataGrid.isDataEndpointChanged(
      prevProps.dataEndpoint,
      this.props.dataEndpoint
    );

    let columns: IColumn[];
    if (isColumnsChanged) {
      columns = DataService.processColumns(this.props.columns);
      mapSortOrderToUrl(columns, this.props.navigate, this.props.location);
    } else {
      columns = this.state.columns;
    }

    if (isClientMode && isDataChanged) {
      this.setState((prevState) =>
        Object.assign({}, prevState, {
          filteredRows: undefined,
          gridStatus: GridStatuses.Loading,
          rows: undefined,
          rowsTotal: undefined,
          ...(isColumnsChanged && columns)
        })
      );

      processOnClient(columns, this.props.data);
    } else if (isServerMode && isDataEndpointChanged) {
      this.setState((prevState) =>
        Object.assign({}, prevState, {
          filteredRows: undefined,
          gridStatus: GridStatuses.Loading,
          rows: undefined,
          rowsTotal: undefined,
          ...(isColumnsChanged && columns)
        })
      );

      processOnServer(
        columns,
        this.props.dataEndpoint,
        undefined,
        getRowClassName,
        true
      );
    } else if (isClientMode && isFilterRowsChanged) {
      this.setState((prevState) =>
        Object.assign({}, prevState, {
          filteredRows: this.props.filterRows
            ? this.state.rows.filter(this.props.filterRows)
            : this.state.rows
        })
      );
    } else if (isColumnsChanged) {
      this.setState((prevState) =>
        Object.assign({}, prevState, {
          columns
        })
      );
    }

    processProperties((newState) => {
      this.setState((prevState) => Object.assign({}, prevState, newState));
    });
  }

  componentDidMount() {
    const {
      handleScroll,
      handleWindowResize,
      mapSortOrderToUrl,
      mapUrlToSortOrder,
      processProperties
    } = this;

    const { data, dataEndpoint, location, navigate, getRowClassName } =
      this.props;
    const { gridId } = this.state;

    (this as any)._mounted = true;

    const columns = mapUrlToSortOrder(this.state.columns, location);
    this.initializeGroupingSequence(columns);
    this.setState((prevState) =>
      Object.assign({}, prevState, {
        columns: columns
      })
    );

    const isServerMode = DataGrid.isServerMode(this.props);
    if (!isServerMode) {
      this.processOnClient(columns, data);
    } else {
      this.processOnServer(
        columns,
        dataEndpoint,
        undefined,
        getRowClassName,
        true
      );
    }

    mapSortOrderToUrl(columns, navigate, location);
    processProperties((newState) => {
      this.setState((prevState) => Object.assign({}, prevState, newState));
    });

    EventService.bindScrollListener(gridId, handleScroll);
    EventService.bindWindowResizeListener(handleWindowResize);
  }

  componentWillUnmount() {
    const { gridId } = this.state;
    const { handleScroll, handleWindowResize } = this;

    (this as any)._mounted = false;

    EventService.unbindScrollListener(gridId, handleScroll);
    EventService.unbindWindowResizeListener(handleWindowResize);
  }

  render() {
    const { changeSortOrder, handleReloadClick } = this;
    const {
      exportToExcel,
      exportToCsv,
      footerComponent,
      showColumnBorder,
      showFooter
    } = this.props;

    const {
      actions,
      filteredRows,
      footerWidth,
      gridId,
      gridStatus,
      headerWidth,
      columns,
      rowsTotal,
      showFixedFooter,
      showFixedHeader
    } = this.state;

    const isConfigValid = DataGrid.isConfigValid(this.props);
    if (!isConfigValid) return null;

    return (
      <div
        className={`common data-grid ${showColumnBorder && "column-border"}`}
        id={gridId}
        key={gridId}
      >
        <GridHeader
          columns={columns}
          gridId={gridId}
          onColumnClick={changeSortOrder}
        />
        <GridHeader
          columns={columns}
          fixed={true}
          gridId={gridId}
          onColumnClick={changeSortOrder}
          visible={showFixedHeader}
          width={headerWidth}
        />
        <GridBody
          actions={actions}
          columns={columns}
          gridId={gridId}
          gridStatus={gridStatus}
          onReloadClick={handleReloadClick}
          onRowClick={this.handleRowClick}
          rows={filteredRows}
        />
        {showFooter && (
          <GridFooter
            columns={columns}
            component={footerComponent}
            exportToExcel={exportToExcel}
            exportToCsv={exportToCsv}
            gridId={gridId}
            rows={filteredRows}
            rowsTotal={rowsTotal}
          />
        )}
        {showFooter && (
          <GridFooter
            columns={columns}
            component={footerComponent}
            exportToExcel={exportToExcel}
            exportToCsv={exportToCsv}
            fixed={true}
            gridId={gridId}
            rows={filteredRows}
            rowsTotal={rowsTotal}
            visible={showFixedFooter}
            width={footerWidth}
          />
        )}
      </div>
    );
  }

  handleRowClick = (e: React.MouseEvent<HTMLDivElement>, row: IRow) => {
    const { onRowClick, onSelectionChanged, selectableRow } = this.props;

    if (
      (typeof selectableRow === "boolean" && selectableRow) ||
      (typeof selectableRow !== "boolean" &&
        selectableRow &&
        selectableRow(row))
    ) {
      const { affectedRow, selectedRows } = this.toggleRow(e, row);
      if (onSelectionChanged) onSelectionChanged(affectedRow, selectedRows);
    }

    if (onRowClick) onRowClick(row, e);
  };

  private toggleRow = (
    e: React.MouseEvent<HTMLDivElement>,
    row: IRow
  ): {
    affectedRow: IRow;
    selectedRows: IRow[];
  } => {
    const { filterRows } = this.props;
    const { rows } = this.state;
    const index = rows.findIndex((r) => r.rowId === row.rowId);

    let newRows = rows;
    if (!e.ctrlKey && !e.metaKey) {
      rows
        .filter((r, i) => i !== index && r.state.selected)
        .forEach((r) => {
          newRows = DataGrid.setSelectToRow(newRows, r, false);
        });
    }

    newRows = DataGrid.setSelectToRow(
      newRows,
      row,
      !newRows[index].state.selected
    );

    this.setState((prevState) => ({
      ...prevState,
      filteredRows: filterRows ? newRows.filter(filterRows) : newRows,
      rows: newRows
    }));

    return {
      affectedRow: newRows[index],
      selectedRows: newRows.filter((r) => r.state.selected)
    };
  };

  private changeSortOrder = (
    columnId: string,
    modifierIsPressed: boolean
  ): void => {
    const { mapSortOrderToUrl, processOnServer, sortOnClient } = this;
    const {
      dataEndpoint,
      location,
      multiColumnSorting,
      navigate,
      getRowClassName
    } = this.props;
    const { columns, rows } = this.state;

    const currentColumn = columns.filter((c) => c.config.id === columnId)[0];
    if (currentColumn.config.sortable === false) return;

    const isClientMode = DataGrid.isClientMode(this.props);
    const isMultiColumnSorting =
      columns.filter((c) => c.state.sortOrder !== SortOrders.None).length > 1;

    const newColumns = [...columns];
    for (const c of newColumns) {
      if (c.config.sortable !== false) {
        const newColumn = DataGrid.cloneColumn(c);

        if (newColumn.config.id === columnId) {
          if (!multiColumnSorting || !modifierIsPressed) {
            if (isMultiColumnSorting) {
              newColumn.state.groupOrder = groupingSequence.next();
              newColumn.state.sortOrder = SortOrders.Asc;
              DataGrid.updateColumn(newColumn, newColumns);
            } else {
              DataGrid.toggleSortOrder(newColumn, newColumns);
            }
          } else {
            DataGrid.toggleSortOrder(newColumn, newColumns);
          }
        } else {
          if (!multiColumnSorting || !modifierIsPressed) {
            DataGrid.cleanSortOrder(newColumn, newColumns);
          }
        }
      }
    }

    if (isClientMode) {
      sortOnClient(newColumns, rows);
    } else {
      this.setState((prevState) =>
        Object.assign({}, prevState, {
          columns: newColumns,
          filteredRows: undefined,
          gridStatus: GridStatuses.Loading,
          rows: undefined,
          rowsTotal: undefined
        })
      );

      processOnServer(
        newColumns,
        dataEndpoint,
        undefined,
        getRowClassName,
        true
      );
    }

    mapSortOrderToUrl(newColumns, navigate, location);
  };

  private handleScroll = (e: any): void => {
    const { shouldLoadNext, processOnServer, processProperties } = this;

    processProperties((newState) => {
      const shouldUpdate = shouldLoadNext();

      if (shouldUpdate) {
        newState = Object.assign(newState, {
          gridStatus: GridStatuses.Updating
        });
      }

      this.setState((prevState) => Object.assign({}, prevState, newState));

      if (shouldUpdate) {
        processOnServer(
          this.state.columns,
          this.props.dataEndpoint,
          this.state.rows,
          this.props.getRowClassName
        );
      }
    });
  };

  private handleReloadClick = () => {
    const { processOnServer } = this;
    const { dataEndpoint, getRowClassName } = this.props;
    const { columns, rows } = this.state;

    const isServerMode = DataGrid.isServerMode(this.props);
    if (isServerMode) {
      this.setState((prevState) =>
        Object.assign({}, prevState, {
          gridStatus:
            rows && rows.length ? GridStatuses.Updating : GridStatuses.Loading
        })
      );

      processOnServer(columns, dataEndpoint, rows, getRowClassName, true);
    }
  };

  private handleActionStarted = () => {
    this.setState((prevState) =>
      Object.assign({}, prevState, {
        gridStatus: GridStatuses.Processing
      })
    );
  };

  private handleActionSucceeded = () => {
    const { processOnServer } = this;
    const { getRowClassName } = this.props;

    const isServerMode = DataGrid.isServerMode(this.props);
    if (isServerMode) {
      this.setState((prevState) =>
        Object.assign({}, prevState, {
          filteredRows: undefined,
          gridStatus: GridStatuses.Loading,
          rows: undefined,
          rowsTotal: undefined
        })
      );

      processOnServer(
        this.state.columns,
        this.props.dataEndpoint,
        undefined,
        getRowClassName,
        true
      );
    } else {
      this.setState((prevState) =>
        Object.assign({}, prevState, {
          gridStatus: GridStatuses.Succeeded
        })
      );
    }
  };

  private handleActionFailed = () => {
    this.setState((prevState) =>
      Object.assign({}, prevState, {
        gridStatus: GridStatuses.Succeeded
      })
    );
  };

  private handleWindowResize = (e: any): void => {
    const { processProperties } = this;

    processProperties((newState) => {
      this.setState((prevState) => Object.assign({}, prevState, newState));
    });
  };

  private mapSortOrderToUrl = (
    columns: IColumn[],
    navigate: NavigateFn,
    location: Location
  ) => {
    const searchParams = new SearchParams(location.search);
    const sortedColumns = [...columns].sort(this.sortColumnsByGroupOrder);
    sortedColumns.forEach((c) => {
      if (c.state.sortOrder === SortOrders.None) {
        searchParams.delete(`c_${c.config.id}`);
      } else {
        searchParams.set(
          `c_${c.config.id}`,
          DataService.sortOrderToString(c.state.sortOrder)
        );
      }
    });

    setTimeout(() =>
      navigate({ search: searchParams.toString() }, { replace: true })
    );
  };

  private sortColumnsByGroupOrder(column1: IColumn, column2: IColumn) {
    const groupOrder1 = isNaN(column1.state.groupOrder)
      ? 0
      : column1.state.groupOrder;
    const groupOrder2 = isNaN(column2.state.groupOrder)
      ? 0
      : column2.state.groupOrder;
    return groupOrder1 - groupOrder2;
  }

  private initializeGroupingSequence(columns: IColumn[]): void {
    const groupingSequenceStartValue = maxBy(columns, (c) =>
      isNaN(c.state.groupOrder) ? 0 : c.state.groupOrder
    ).state.groupOrder;
    groupingSequence = new Sequence(groupingSequenceStartValue);
  }

  private mapUrlToSortOrder = (
    columns: IColumn[],
    location: Location
  ): IColumn[] => {
    const searchParams = new SearchParams(location.search);
    const columnParamNames = columns.map((c) => `c_${c.config.id}`);
    const hasColumnParam =
      columnParamNames.filter((p) => searchParams.has(p)).length > 0;
    if (hasColumnParam) {
      const searchParamsArray = searchParams.toArray();
      columns = [...columns];
      columns.forEach((c, i) => {
        const paramKey = `c_${c.config.id}`;
        const paramValue = searchParams.get(paramKey);
        const sortOrder = DataService.stringToSortOrder(paramValue);
        const newColumnState = Object.assign({}, c.state, {
          sortOrder: sortOrder,
          groupOrder: this.getGroupOrder(paramKey, searchParamsArray, columns)
        });
        const newColumn = Object.assign({}, c, {
          state: newColumnState
        });

        columns[i] = newColumn;
      });
    }

    return columns;
  };

  private getGroupOrder(
    searchParamName: string,
    searchParams: ISearchParams[],
    columns: IColumn[]
  ) {
    const index = findIndex(
      searchParams.filter((x) =>
        columns.some((c) => `c_${c.config.id}` === x.name)
      ),
      { name: searchParamName }
    );

    if (index > -1) {
      return index + 1;
    }

    return undefined;
  }

  private processOnClient = (columns: IColumn[], data: IRowData[]): void => {
    const { processProperties } = this;
    const { filterRows, getDefaultRowSelected, getRowClassName } = this.props;

    new Promise<IRow[]>((resolve) => {
      EventService.unbindScrollListener(this.state.gridId, this.handleScroll);

      const sortedRows = DataService.processRows(
        data,
        columns,
        getDefaultRowSelected,
        getRowClassName
      );
      resolve(sortedRows);
    }).then((rows) => {
      this.setState((prevState) =>
        Object.assign({}, prevState, {
          filteredRows: filterRows ? rows.filter(filterRows) : rows,
          gridStatus: GridStatuses.Succeeded,
          rows: rows,
          rowsTotal: rows.length
        })
      );

      processProperties((newState) => {
        this.setState((prevState) => Object.assign({}, prevState, newState));
      });

      EventService.bindScrollListener(this.state.gridId, this.handleScroll);
    });
  };

  private processOnServer = (
    columns: IColumn[],
    dataEndpoint: IDataEndpoint,
    rows: IRow[],
    getRowClassName?: (data: IRowData) => string,
    abort = false
  ): void => {
    const { processProperties } = this;
    const { filterRows } = this.props;

    const callbackFunc = (
      resolve: (value: {
        rows: IRow[];
        rowsTotal: number;
        rowsNew: number | null;
      }) => void,
      reject: () => void
    ) => {
      EventService.unbindScrollListener(this.state.gridId, this.handleScroll);

      this.dataXhr = DataService.loadData(
        dataEndpoint,
        columns,
        rows,
        (rows, rowsTotal, rowsNew, response) => {
          if (response.statusText !== "abort") {
            resolve({
              rows,
              rowsTotal,
              rowsNew
            });

            if (dataEndpoint.onDataLoaded)
              dataEndpoint.onDataLoaded(rowsTotal, rowsNew);
          }

          if (this.dataNext) this.dataNext();
        },
        (response) => {
          if (response.statusText !== "abort") reject();
          if (this.dataNext) this.dataNext();
        },
        getRowClassName
      );
    };

    const thenFunc = (data: { rows: IRow[]; rowsTotal: number }) => {
      this.setState((prevState) =>
        Object.assign({}, prevState, {
          filteredRows: filterRows ? data.rows.filter(filterRows) : data.rows,
          gridStatus: GridStatuses.Succeeded,
          rows: data.rows,
          rowsTotal: data.rowsTotal
        })
      );

      this.dataProcessing = false;

      processProperties((newState) => {
        this.setState((prevState) => Object.assign({}, prevState, newState));
      });

      EventService.bindScrollListener(this.state.gridId, this.handleScroll);
    };

    const catchFunc = () => {
      this.setState((prevState) =>
        Object.assign({}, prevState, {
          gridStatus: GridStatuses.Failed
        })
      );

      this.dataProcessing = false;

      processProperties((newState) => {
        this.setState((prevState) => Object.assign({}, prevState, newState));
      });

      EventService.bindScrollListener(this.state.gridId, this.handleScroll);
    };

    if (this.dataProcessing && abort) {
      this.dataNext = () => {
        this.setState((prevState) =>
          Object.assign({}, prevState, {
            gridStatus:
              rows && rows.length ? GridStatuses.Updating : GridStatuses.Loading
          })
        );

        this.dataProcessing = true;

        new Promise<{ rows: IRow[]; rowsTotal: number }>(callbackFunc)
          .then(thenFunc)
          .catch(catchFunc);

        this.dataNext = undefined;
      };

      if (this.dataXhr) this.dataXhr.abort();
    } else if (!this.dataProcessing) {
      this.dataProcessing = true;

      new Promise<{ rows: IRow[]; rowsTotal: number }>(callbackFunc)
        .then(thenFunc)
        .catch(catchFunc);
    }
  };

  private processProperties = (callback: (state?: IDataGridState) => void) => {
    const handler = () => {
      const properties = PropertyService.getProperties(this);
      if (properties === null || properties === undefined) return;

      const showFixedHeader = GridHeader.showFixedHeader(properties);
      const showFixedFooter = GridFooter.showFixedFooter(properties);
      const headerWidth = properties.headerWidth;
      const footerWidth = properties.footerWidth;

      let newState: any;
      if (
        this.state.showFixedHeader !== showFixedHeader ||
        this.state.showFixedFooter !== showFixedFooter ||
        this.state.headerWidth !== headerWidth
      ) {
        if (!newState) newState = {};
        newState = Object.assign(newState, {
          showFixedHeader: showFixedHeader,
          showFixedFooter: showFixedFooter,
          headerWidth: headerWidth,
          footerWidth: footerWidth
        });
      }

      if (newState) {
        callback(newState);
      }

      if (this.propertiesNext) {
        this.propertiesTimer = setTimeout(this.propertiesNext);
        this.propertiesNext = undefined;
      } else {
        this.propertiesTimer = undefined;
      }
    };

    if (this.propertiesTimer) {
      this.propertiesNext = handler;
    } else {
      this.propertiesTimer = setTimeout(handler);
    }
  };

  private sortOnClient = (columns: IColumn[], rows: IRow[]) => {
    const { filterRows } = this.props;
    const sortedRows = DataService.sortRows(columns, rows);

    this.setState((prevState) =>
      Object.assign({}, prevState, {
        filteredRows: filterRows ? sortedRows.filter(filterRows) : rows,
        columns: columns,
        rows: sortedRows
      })
    );
  };

  private shouldLoadNext = (): boolean => {
    const { gridId, rows, rowsTotal } = this.state;

    const isServerMode = DataGrid.isServerMode(this.props);
    if (isServerMode) {
      if (rows.length < rowsTotal) {
        const isLastRowVisible = ElementService.isLastRowsVisible(gridId);
        return isLastRowVisible;
      }
    }

    return false;
  };

  private static cleanSortOrder(column: IColumn, columns: IColumn[]): void {
    column.state.groupOrder = undefined;
    column.state.sortOrder = SortOrders.None;
    DataGrid.updateColumn(column, columns);
  }

  private static cloneColumn(column: IColumn): IColumn {
    const newColumnState = Object.assign({}, column.state);
    const newColumn = Object.assign({}, column, { state: newColumnState });
    return newColumn;
  }

  private static isClientMode(props: IDataGridProps): boolean {
    const { data } = props;
    return data !== undefined;
  }

  private static isColumnsChanged(
    prevColumns: IColumnConfig[],
    nextColumns: IColumnConfig[]
  ): boolean {
    return JSON.stringify(prevColumns) !== JSON.stringify(nextColumns);
  }

  private static isConfigValid(props: IDataGridProps): boolean {
    const { data, dataEndpoint } = props;

    if (data !== undefined && dataEndpoint !== undefined) {
      console.warn(
        "Only one of the properties (data, dataEndpoint) can be set."
      );
      return false;
    }

    if (data === undefined && dataEndpoint === undefined) {
      console.warn(
        "Exactly one of the properties (data, dataEndpoint) has to be set."
      );
      return false;
    }

    return true;
  }

  private static isDataChanged(
    prevData: IRowData[],
    nextData: IRowData
  ): boolean {
    return JSON.stringify(prevData) !== JSON.stringify(nextData);
  }

  private static isDataEndpointChanged(
    prevEndpoint: IDataEndpoint,
    nextEndpoint: IDataEndpoint
  ): boolean {
    return JSON.stringify(prevEndpoint) !== JSON.stringify(nextEndpoint);
  }

  private static isServerMode(props: IDataGridProps): boolean {
    const { data, dataEndpoint } = props;
    return data === undefined && dataEndpoint !== undefined;
  }

  private static toggleSortOrder(column: IColumn, columns: IColumn[]): void {
    if (column.state.sortOrder === SortOrders.None) {
      column.state.groupOrder = groupingSequence.next();
      column.state.sortOrder += 1;
    } else if (column.state.sortOrder === SortOrders.Desc) {
      column.state.groupOrder = undefined;
      column.state.sortOrder = SortOrders.None;
    } else {
      column.state.sortOrder += 1;
    }

    DataGrid.updateColumn(column, columns);
  }

  private static updateColumn(column: IColumn, columns: IColumn[]): IColumn[] {
    const i = columns.findIndex((c) => c.config.id === column.config.id);
    columns[i] = column;
    return columns;
  }

  private static setSelectToRow(rows: IRow[], row: IRow, value: boolean) {
    const i = rows.findIndex((r) => r.rowId === row.rowId);
    if (i < 0) return rows;

    const newRows = [...rows];
    const newRow = {
      ...newRows[i],
      state: {
        ...newRows[i].state,
        selected: value
      }
    };
    newRows[i] = newRow;

    return newRows;
  }
}

export default (props: IDataGridProps) => {
  const navigate = useAppNavigate();
  const location = useLocation();

  return <DataGrid {...props} location={location} navigate={navigate} />;
};
