// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { DuvalPoint } from "../../../components/analytics/DuvalTriangle/DuvalTriangle";
import IDuvalRegion from "../../../components/analytics/DuvalTriangle/models/IDuvalRegion";

const type5Default: IDuvalRegion[] = [
  {
    Name: "PD",
    Color: "#4386FB",
    LabelCenter: new DuvalPoint(90, 0, 10),
    LabelXOffset: -22,
    LabelYOffset: 0,
    Points: [
      new DuvalPoint(98, 0, 2),
      new DuvalPoint(97, 1, 2),
      new DuvalPoint(84, 1, 15),
      new DuvalPoint(85, 0, 15)
    ]
  },
  {
    Name: "O", //upper
    Color: "#57C676",
    LabelCenter: new DuvalPoint(90, 10, 0),
    LabelXOffset: -25,
    LabelYOffset: 10,
    Points: [
      new DuvalPoint(84, 1, 15),
      new DuvalPoint(75, 10, 15),
      new DuvalPoint(90, 10, 0),
      new DuvalPoint(100, 0, 0),
      new DuvalPoint(98, 0, 2),
      new DuvalPoint(97, 1, 2)
    ]
  },
  {
    Name: "T2",
    Color: "#8EB7FD",
    LabelCenter: new DuvalPoint(75, 25, 0),
    LabelXOffset: -25,
    LabelYOffset: 5,
    Points: [
      new DuvalPoint(90, 10, 0),
      new DuvalPoint(78, 10, 12),
      new DuvalPoint(53, 35, 12),
      new DuvalPoint(65, 35, 0)
    ]
  },
  {
    Name: "S",
    Color: "#9AEEE1",
    LabelCenter: new DuvalPoint(65, 0, 35),
    LabelXOffset: 15,
    LabelYOffset: 0,
    Points: [
      new DuvalPoint(85, 0, 15),
      new DuvalPoint(75, 10, 15),
      new DuvalPoint(36, 10, 54),
      new DuvalPoint(46, 0, 54)
    ]
  },
  {
    Name: "O", //bottom
    Color: "#57C676",
    LabelCenter: new DuvalPoint(25, 0, 75),
    LabelXOffset: 15,
    LabelYOffset: 0,
    Points: [
      new DuvalPoint(46, 0, 54),
      new DuvalPoint(36, 10, 54),
      new DuvalPoint(0, 10, 90),
      new DuvalPoint(0, 0, 100)
    ]
  },
  {
    Name: "ND",
    Color: "#83D3AF",
    LabelCenter: new DuvalPoint(0, 20, 80),
    LabelXOffset: 0,
    LabelYOffset: -10,
    Points: [
      new DuvalPoint(0, 10, 90),
      new DuvalPoint(60, 10, 30),
      new DuvalPoint(35, 35, 30),
      new DuvalPoint(0, 35, 65)
    ]
  },
  {
    Name: "T3", //middle
    Color: "#DAE7FE",
    LabelCenter: new DuvalPoint(0, 50, 50),
    LabelXOffset: 0,
    LabelYOffset: -10,
    Points: [
      new DuvalPoint(0, 35, 65),
      new DuvalPoint(35, 35, 30),
      new DuvalPoint(0, 70, 30)
    ]
  },
  {
    Name: "C",
    Color: "#54B3AA",
    LabelCenter: new DuvalPoint(40, 35, 25),
    LabelXOffset: 0,
    LabelYOffset: 0,
    Points: [
      new DuvalPoint(60, 10, 30),
      new DuvalPoint(78, 10, 12),
      new DuvalPoint(41, 47, 12),
      new DuvalPoint(39, 47, 14),
      new DuvalPoint(16, 70, 14),
      new DuvalPoint(0, 70, 30)
    ]
  },
  {
    Name: "T3", //right
    Color: "#DAE7FE",
    LabelCenter: new DuvalPoint(30, 70, 0),
    LabelXOffset: -35,
    LabelYOffset: 0,
    Points: [
      new DuvalPoint(65, 35, 0),
      new DuvalPoint(0, 100, 0),
      new DuvalPoint(0, 70, 30),
      new DuvalPoint(16, 70, 14),
      new DuvalPoint(39, 47, 14),
      new DuvalPoint(41, 47, 12),
      new DuvalPoint(53, 35, 12),
      new DuvalPoint(65, 35, 0)
    ]
  }
];

export default type5Default;
