// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { Button } from "antd";
import { Map } from "immutable";
import React from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";

import ErrorMessage from "common/messages/ErrorMessage";
import Processing from "components/common/Processing";
import { IUser } from "core/app/reducers/settings/UserReducer";
import AuthorizationService from "core/app/services/AuthorizationService";
import Data, { Statuses } from "core/data/models/Data";
import { issuesLimit } from "features/detailpage/features/issues/containers/IssuesContainer";
import IIssue from "features/detailpage/features/issues/models/IIssue";
import IssueMeta from "features/detailpage/features/issues/models/IssueMeta";
import IssueModes from "features/detailpage/features/issues/models/IssueModes";
import { IssueStatuses } from "features/detailpage/features/issues/models/IssueStatuses";
import { PageModes } from "features/detailpage/models/PagesModes";
import Issue from "./Issue";
import IssueList from "./IssueList";
import IssueTypeSwitch, { IssueSwitchType } from "./IssueTypeSwitch";

import { UserRoles } from "core/app/components/auth/Authorization";
import LifecycleStatus from "models/LifecycleStatus";
import "./Issues.less";

enum IssueTypes {
  Active,
  Closed
}

export interface IIssuesActions {
  createNewIssue: (
    userName: string,
    assetId: string,
    userSurname: string
  ) => string;
  expandPanel: () => void;
  loadClosedIssues: () => void;
  loadClosedIssuesWithLimit: () => void;
  removeNewIssue: () => void;
  switchIssueModeToView: (issueId: string) => void;
  startProcessingIssues: () => void;
}

export interface IIssuesData {
  newIssue: IIssue;
  newMeta: IssueMeta;
  activeIssues: Data<IIssue[]>;
  activeMetas: Map<string, IssueMeta>;
  closedIssues: Data<IIssue[]>;
  closedMetas: Map<string, IssueMeta>;
  statuses: Data<IssueStatuses[]>;
  urgencies: Data<string[]>;
  user: IUser;
}

export interface IIssuesOwnProps {
  assetId: string;
  pageMode: number;
  lifecycleStatus: LifecycleStatus;
}

export interface IIssuesProps
  extends IIssuesActions,
    IIssuesData,
    IIssuesOwnProps {}

export interface IIssuesState {
  currentIssueId: string;
  issueType: IssueTypes;
  user: IUser;
}

class Issues extends React.Component<IIssuesProps, IIssuesState> {
  constructor(props: IIssuesProps) {
    super(props);

    this.state = {
      currentIssueId: null,
      issueType: IssueTypes.Active,
      user: props.user
    };
  }

  componentDidUpdate() {
    const { currentIssueId } = this.state;
    const { pageMode } = this.props;

    if (pageMode === PageModes.Left4Right0 && currentIssueId !== null) {
      this.handleIssueCollapsed();
    }
  }

  render() {
    const {
      handleActiveSelected,
      handleHistorySelected,
      handleCreateClick,
      handleIssueClick,
      handleIssueSaved,
      handleIssueStatusSaved,
      handleShowMoreClick
    } = this;

    const {
      newIssue,
      newMeta,
      activeIssues,
      activeMetas,
      closedIssues,
      closedMetas,
      statuses,
      urgencies
    } = this.props;

    const { currentIssueId, issueType } = this.state;

    let className: string;
    let issues: IIssue[];
    let metas: Map<string, IssueMeta>;

    if (issueType === IssueTypes.Active) {
      className = "active";
      issues = activeIssues.data;
      metas = activeMetas;
    } else {
      className = "closed";
      issues = closedIssues.data;
      metas = closedMetas;
    }

    const showNewIssueLoading =
      !!newIssue &&
      !!newMeta &&
      (statuses.status === Statuses.Loading ||
        urgencies.status === Statuses.Loading);

    const showNewIssueSucceeded =
      !!newIssue &&
      !!newMeta &&
      statuses.status === Statuses.Succeeded &&
      urgencies.status === Statuses.Succeeded;

    const showNewIssueFailed =
      !!newIssue &&
      !!newMeta &&
      (statuses.status === Statuses.Failed ||
        urgencies.status === Statuses.Failed);

    const showIssueListLoading =
      (issueType === IssueTypes.Active &&
        activeIssues.status === Statuses.Loading) ||
      (issueType === IssueTypes.Closed &&
        closedIssues.status === Statuses.Loading) ||
      statuses.status === Statuses.Loading ||
      urgencies.status === Statuses.Loading;

    const showIssueListSucceeded =
      ((issueType === IssueTypes.Active &&
        activeIssues.status === Statuses.Succeeded) ||
        (issueType === IssueTypes.Closed &&
          closedIssues.status === Statuses.Succeeded)) &&
      statuses.status === Statuses.Succeeded &&
      urgencies.status === Statuses.Succeeded;

    const showIssueListFailed =
      (issueType === IssueTypes.Active &&
        activeIssues.status === Statuses.Failed) ||
      (issueType === IssueTypes.Closed &&
        closedIssues.status === Statuses.Failed) ||
      statuses.status === Statuses.Failed ||
      urgencies.status === Statuses.Failed;

    const showShowMoreButton =
      issueType === IssueTypes.Closed &&
      closedIssues.status === Statuses.Succeeded &&
      closedIssues.data.length === issuesLimit;

    const isClosedSelected = issueType === IssueTypes.Closed;
    const isAnyIssueInCreateOrEdit =
      (!!newIssue && !!newMeta && newMeta.mode === IssueModes.Create) ||
      activeMetas.some((m) => m.mode === IssueModes.Edit);

    const isReadOnly = !AuthorizationService.isAuthorized(this.state.user, [
      UserRoles.Administrator,
      UserRoles.Engineer,
      UserRoles.LimitedEngineer
    ]);
    return (
      <div className="details-page-issues">
        {!isReadOnly && (
          <Button
            type="primary"
            shape="circle"
            size="large"
            className="create-issue"
            disabled={
              !showIssueListSucceeded ||
              isAnyIssueInCreateOrEdit ||
              issueType !== IssueTypes.Active ||
              this.props.lifecycleStatus === LifecycleStatus.Removed
            }
            onClick={handleCreateClick}
          >
            <Icon name="add" />
          </Button>
        )}
        <IssueTypeSwitch
          type={
            this.props.lifecycleStatus === LifecycleStatus.Removed
              ? IssueSwitchType.History
              : IssueSwitchType.Active
          }
          disabled={
            isAnyIssueInCreateOrEdit ||
            this.props.lifecycleStatus === LifecycleStatus.Removed
          }
          onActiveSelected={handleActiveSelected}
          onHistorySelected={handleHistorySelected}
        />
        <div
          className="scrollarea"
          ref={(r) => {
            this.scrollareaRef = r;
          }}
        >
          {showNewIssueSucceeded && !isClosedSelected && (
            <Issue
              currentIssueId={currentIssueId}
              issue={newIssue}
              meta={newMeta}
              key={newIssue.Id}
              onClick={(e) => {
                handleIssueClick(e, newIssue.Id);
              }}
              onSaved={handleIssueSaved}
              onStatusSaved={handleIssueStatusSaved}
              urgencies={urgencies.data}
              readonly={isReadOnly}
            />
          )}
          {showIssueListSucceeded && (
            <IssueList
              currentIssueId={currentIssueId}
              className={className}
              disabledIfView={isAnyIssueInCreateOrEdit}
              issues={issues}
              metas={metas}
              onIssueClick={handleIssueClick}
              onIssueSaved={handleIssueSaved}
              onIssueStatusSaved={handleIssueStatusSaved}
              readonly={isClosedSelected || isReadOnly}
              urgencies={urgencies.data}
            />
          )}
          {(showNewIssueLoading || showIssueListLoading) && (
            <IssuesProcessing />
          )}
          {showNewIssueFailed && (
            <IssueErrorMessage
              message={{
                defaultMessage: "Create new issue failed.",
                id: "detail_page.issues.create_issue_failed"
              }}
            />
          )}
          {showIssueListFailed && (
            <IssueErrorMessage
              message={{
                defaultMessage: "Loading issue list failed.",
                id: "detail_page.issues.loading_issues_failed"
              }}
            />
          )}
          {showShowMoreButton && (
            <div className="show-more-container" onClick={handleShowMoreClick}>
              <Button type="link" className="light">
                <FormattedMessage
                  defaultMessage="Show more"
                  id="detail_page.issues.show_more"
                />
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }

  reset() {
    const { switchIssueModeToView, newIssue, removeNewIssue } = this.props;
    const { currentIssueId } = this.state;

    if (newIssue) {
      removeNewIssue();
    } else {
      switchIssueModeToView(currentIssueId);
    }

    this.setState({
      currentIssueId: null
    });
  }

  private scrollareaRef: HTMLElement;

  private handleActiveSelected = () => {
    this.setState({
      currentIssueId: null,
      issueType: IssueTypes.Active
    });
  };

  private handleHistorySelected = () => {
    const { loadClosedIssuesWithLimit } = this.props;

    this.setState({
      currentIssueId: null,
      issueType: IssueTypes.Closed
    });
    loadClosedIssuesWithLimit();
  };

  private handleCreateClick = () => {
    const {
      assetId,
      createNewIssue,
      expandPanel,
      user,
      startProcessingIssues
    } = this.props;

    const issueId = createNewIssue(user.Name, assetId, user.Surname);
    this.setState({ currentIssueId: issueId });
    expandPanel();
    startProcessingIssues();

    this.scrollareaRef.scrollTo(0, 0);
  };

  private handleIssueClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    issueId: string
  ) => {
    const { scrollToElement } = this;
    const { expandPanel } = this.props;
    const { currentIssueId } = this.state;

    if (issueId !== currentIssueId) {
      this.setState({ currentIssueId: issueId });
      expandPanel();
      scrollToElement(e.currentTarget);
    }
  };

  private handleIssueCollapsed = () => {
    this.setState({ currentIssueId: null });
  };

  private handleIssueSaved = () => {
    this.setState({ currentIssueId: null });
  };

  private handleIssueStatusSaved = () => {
    this.setState({ currentIssueId: null });
  };

  private handleShowMoreClick = () => {
    const { loadClosedIssues } = this.props;

    loadClosedIssues();
  };

  private scrollToElement = (element: HTMLDivElement) => {
    setTimeout(() => {
      const y =
        element.getBoundingClientRect().top -
        element.parentElement.getBoundingClientRect().top;

      this.scrollareaRef.scrollTo(0, y);
    }, 100);
  };
}

const IssuesProcessing = () => (
  <div>
    <Processing />
  </div>
);

interface IIssueErrorMessageProps {
  message: MessageDescriptor;
}

const IssueErrorMessage = ({ message }: IIssueErrorMessageProps) => (
  <ErrorMessage message={message} />
);

export default Issues;
