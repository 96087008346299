// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IState } from "core/app/reducers/AppReducer";
import IntlService from "core/app/services/IntlService";

export default class AppService {
  static updateLocale(state: IState): IState {
    const locales =
      (state.translations.data && state.translations.data.locales) || [];
    
    const supportedLocale = IntlService.getSupportedLocale(locales);
    
    if (supportedLocale !== state.locale) {
      state = Object.assign({}, state, {
        locale: supportedLocale
      });
    }

    return state;
  }
}
