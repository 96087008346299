// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import {
  getDataKey,
  IParametersData
} from "features/ConfigurationTool/actions/loadParameters";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";

const getParameterSelector = createSelector(
  (state: IState) => (assetId: string) =>
    state.data[getDataKey(assetId)] as Data<IParametersData>,
  (assetDetails) => assetDetails
);

export default getParameterSelector;
