// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Data from "../models/Data";
import ISetDataAction from "../models/ISetDataAction";
import IRemoveDataAction from "../models/IRemoveDataAction";
import Actions from "../models/Actions";

export interface IState {
  [key: string]: Data<any>;
}

export const defaultState: IState = {};

const reducer = (
  state: IState = defaultState,
  action: ISetDataAction<any> | IRemoveDataAction
): IState => {
  switch (action.type) {
    case Actions.SetData: {
      const { key, data } = action as ISetDataAction<any>;
      return { ...state, [key]: data };
    }
    case Actions.RemoveData: {
      const { key } = action as IRemoveDataAction;
      delete state[key];
      return { ...state };
    }
    default:
      return state;
  }
};

export default reducer;
