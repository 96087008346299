// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import AntdPopover, { PopoverProps } from "antd/lib/popover";
import { TooltipPlacement } from "antd/lib/tooltip";
import { MouseEvent, ReactNode, useCallback, useMemo } from "react";

type TooltipTrigger =
  | "hover"
  | "focus"
  | "click"
  | "contextMenu"
  | Array<string>;

interface IPopoverProps {
  children?: JSX.Element | string;
  className?: string;
  content?: ReactNode;
  onShow?: () => void;
  onHide?: () => void;
  overlayClassName?: string;
  placement?: TooltipPlacement;
  stopPropagation?: boolean;
  title?: string;
  trigger?: TooltipTrigger;
  visible?: boolean;
}

const Popover = ({
  children,
  className,
  content,
  onHide,
  onShow,
  overlayClassName,
  placement = "right",
  stopPropagation,
  title,
  trigger = "click",
  visible
}: IPopoverProps) => {
  const handleVisibleChange = useCallback(
    (visible: boolean) => {
      if (visible && onShow) {
        onShow();
      } else if (!visible && onHide) {
        onHide();
      }
    },
    [onShow, onHide]
  );

  const handleContainerClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (stopPropagation) e.stopPropagation();
    },
    [stopPropagation]
  );

  const popoverProps = useMemo(() => {
    const props: PopoverProps = {
      content,
      title,
      trigger,
      placement,
      onOpenChange: handleVisibleChange,
      overlayClassName: overlayClassName
    };

    // If visible prop is used directly in Antd Popover component, popover is not displayed.
    if (visible !== undefined) props.open = visible;

    return props;
  }, [
    content,
    title,
    trigger,
    placement,
    handleVisibleChange,
    overlayClassName,
    visible
  ]);

  return (
    <span
      className={`popover-container ${className}`}
      onClick={handleContainerClick}
    >
      <AntdPopover {...popoverProps}>{children}</AntdPopover>
    </span>
  );
};

export default Popover;
