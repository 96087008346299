// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { FormattedDate, FormattedMessage } from "react-intl";
import styled from "styled-components";

interface IRiskChangeTooltipProps {
  className?: string;
  level: string;
  value: number;
  date: string;
}

const RiskChangeTooltip = ({
  className,
  level,
  value,
  date
}: IRiskChangeTooltipProps) => (
  <div className={`${className} risk-change-tooltip`}>
    <div className="date">
      <FormattedDate value={new Date(date)} />
    </div>
    <div className="border" />
    <div className="risk-data">
      <div className="risk-value">
        <FormattedMessage
          id="asset_risk_changes.grid.columns.change.risk"
          defaultMessage="{value, number, decimal}"
          values={{
            value
          }}
        />
      </div>
      <div
        className={`risk-dot asset-risk-bg ${level.toLowerCase()} dot-tooltip`}
      />
    </div>
  </div>
);

const StyledRiskChangeTooltip = styled(RiskChangeTooltip)`
  &.risk-change-tooltip {
    display: flex;
    flex-direction: column;

    .border {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }

    .risk-data {
      align-items: center;
      display: flex;
      flex-direction: row;

      .risk-dot {
        border-radius: 50%;
        height: 16px;
        margin-left: 10px;
        width: 16px;

        &.asset-risk-bg.dot-tooltip {
          position: relative;
          top: inherit;
          left: inherit;
        }
      }
    }
  }
`;

export default StyledRiskChangeTooltip;
