// Copyright 2016-2023 Hitachi Energy. All rights reserved.

const componentsWhichRequiresSupressingEnterKeyWhenEditing = [
  "list",
  "autoComplete",
  "DateTime",
  "Bool"
];

export default componentsWhichRequiresSupressingEnterKeyWhenEditing;
