// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import ParameterCharts, {
  TimeFrames
} from "@apm/widgets/build/widgets/ParameterCharts";
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import { Statuses } from "core/data/models/Data";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import loadDGAData, { ILoadDGADataPayload } from "../actions/loadDGAData";
import removeDGAData from "../actions/removeDGAData";
import dataSourceKey from "../constants/dataSourceKey";
import getDGADataSelector from "../selectors/getDGADataSelector";
import getChart from "../utils/getChart";
import getChartGroups from "../utils/getChartGroups";
import getLegend from "../utils/getLegend";
import { formatDateTime } from "common/DateTime/utils/dateFormatters";

interface IDGAOwnProps {
  assetId: string;
  implementationId?: string;
  dataSources: string[];
  defaultDataSource?: string;
}

type DGAProps = IDGAOwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const DGA = ({
  assetId,
  implementationId,
  dataSources,
  defaultDataSource,
  dgaData,
  loadDGAData,
  removeDGAData
}: DGAProps) => {
  const intl = useIntl();

  const [dataSource, setLoadDataTrigger] = useState<string>(defaultDataSource);

  const { search } = useLocation();

  const mapToDataSourceKey =
    useCallback((): ILoadDGADataPayload["dataSource"] => {
      switch (dataSource) {
        case "offline": {
          return "Offline";
        }
        case "advanced": {
          return "Advanced";
        }

        case "default": {
          return "Online";
        }

        default: {
          return "Offline";
        }
      }
    }, [dataSource]);

  useEffect(() => {
    loadDGAData({
      assetId,
      dataSource: mapToDataSourceKey()
    });
    return () => {
      removeDGAData(assetId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId, dataSource]);

  const chartGroups = useMemo(
    () => getChartGroups(intl, dgaData?.data),
    [dgaData?.data, intl]
  );

  const dataSourceConfig = useMemo(
    () => ({
      dataSources,
      defaultDataSource,
      showLabel: true,
      dataSourceSessionKey: dataSourceKey
    }),
    [dataSources, defaultDataSource]
  );

  const handleDataSourceActiveChanged = useCallback((value: string) => {
    setLoadDataTrigger(value);
  }, []);

  return (
    <>
      {dgaData?.status === Statuses.Loading && <Processing />}
      {dgaData?.status === Statuses.Succeeded && (
        <ParameterCharts
          onDataSourceChanged={handleDataSourceActiveChanged}
          chartGroups={chartGroups}
          getChart={(chartName: string, timeframe: TimeFrames) =>
            getChart(
              intl,
              chartName,
              dgaData?.data.GasStandard,
              dgaData?.data.GasStandardRevision,
              timeframe,
              search
            )
          }
          getLegend={getLegend(
            intl,
            implementationId,
            dgaData?.data.GasStandard,
            dgaData?.data.GasStandardRevision
          )}
          formatTooltipTitle={(date) => formatDateTime(intl, date)}
          formatXTick={(date) => intl.formatDate(date)}
          dataSourceConfig={dataSourceConfig}
          translations={{
            getDataSourceOptionTranslation: (dataSource) =>
              intl.formatMessage({
                defaultMessage: dataSource,
                id: `detail_page.widgets.analytics.transformers.dga.data_source.${dataSource}`
              }),
            series: (seriesName) =>
              intl.formatMessage({
                defaultMessage: seriesName,
                id: `detail_page.widgets.analytics.transformers.dga.${seriesName}`
              }),
            dataSourceLabel: intl.formatMessage({
              defaultMessage: "Data source",
              id: `global.data_source`
            }),
            empty: intl.formatMessage({
              defaultMessage: "No data available",
              id: "global.chart.no_data_available"
            }),
            timeFrame: (timeFrame) =>
              intl.formatMessage({
                defaultMessage: timeFrame,
                id: `detail_page.widgets.analytics.transformers.dga.${timeFrame}`
              }),
            switchRatio: intl.formatMessage({
              defaultMessage: "Switch ratio",
              id: "detail_page.widgets.analytics.transformers.dga.switch_ratio"
            })
          }}
        />
      )}
      {dgaData?.status === Statuses.NotFound && (
        <div>
          <WidgetNoDataMessage />
        </div>
      )}
      {dgaData?.status === Statuses.Failed && (
        <div>
          <WidgetErrorMessage />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: IState, ownProps: IDGAOwnProps) => ({
  dgaData: getDGADataSelector(state)(ownProps.assetId)
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  loadDGAData: (payload: ILoadDGADataPayload) => dispatch(loadDGAData(payload)),
  removeDGAData: (assetId: string) => dispatch(removeDGAData(assetId))
});

export default connect(mapStateToProps, mapDispatchToProps)(DGA);
