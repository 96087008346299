// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";
import { FormattedMessage } from "react-intl";

import "./RecentReadingInfo.less";

const RecentReadingInfo = () => (
  <div className="recent-reading-info chart-legend__item-text">
    <svg height={15} width={15}>
      <polygon
        points="5,2.5 7.5,0 10,2.5 12.5,2.5 12.5,5 15,7.5 12.5,10 12.5,12.5 10,12.5 7.5,15 4,12.5 2.5,12.5 2.5,10 0,7.5 2.5,5 2.5,2.5"
        fill="black"
      />
    </svg>
    <FormattedMessage
      defaultMessage="Most recent reading"
      id="detail_page.parameters.bushing_visualization.most_recent_readings"
    />
  </div>
);

export default RecentReadingInfo;
