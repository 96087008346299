// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { colorBlue60, colorStatusOrange } from "styles/ColorVariables";
import Axis from "../models/Axis";

function getSeriesColor(axis: Axis) {
  switch (axis) {
    case 0:
      return colorBlue60;
    case 1:
      return colorStatusOrange;
  }
}

export default getSeriesColor;
