// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { isNil } from "lodash";
import AssetRiskTypes from "models/AssetRiskTypes";
import IAssetRiskCountsTree from "models/IAssetRiskCountsTree";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { dataKey } from "../actions/loadAssetRiskCounts";
import updateAssetRiskCounts from "../utils/updateAssetRiskCounts";

const getAssetRiskCountsData = createSelector(
  (state: IState) => (type: AssetRiskTypes, ids: string[][]) => {
    const result = state.data[dataKey(type)];
    let data: IAssetRiskCountsTree[] = result?.data;

    if (isNil(result?.data)) return;

    ids
      .sort((a, b) => a.length - b.length)
      .forEach((id) => {
        const childs: IAssetRiskCountsTree[] =
          state.data[dataKey(type, id)]?.data;
        data = updateAssetRiskCounts(data, childs, id);
      });

    return data;
  },
  (data) => data
);

export default getAssetRiskCountsData;
