// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useState, useEffect } from "react";

export type LastDataSource = null | string;

export type TracePoint = {
  date: string;
  x: number;
  y: number;
  z: number;
};

export type RogerRatioCoords = {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
  minZ: number;
  maxZ: number;
};

export type ZonesLimits = {
  ArchingHighEnergyDischarge: RogerRatioCoords;
  LowEnergyDensityArchingPd: RogerRatioCoords;
  LowTemperatureThermal: RogerRatioCoords;
  ThermalGreaterThan700: RogerRatioCoords;
  ThermalLessThan700: RogerRatioCoords;
  UnitNormal: RogerRatioCoords;
};

export type RogersRatioDataFetched = {
  LastDataSource: LastDataSource;
  OfflinePoints: TracePoint[];
  OnlinePoints: TracePoint[];
  ZonesLimits: ZonesLimits;
};

export type RogerRatioZone = {
  limits: RogerRatioCoords;
};

export type RogersRatioDataProcessed = {
  rogersRatioZones: RogerRatioZone[];
  tracePoints: TracePoint[];
};

export type TracePointsData = {
  OfflinePoints: TracePoint[];
  OnlinePoints: TracePoint[];
};

type UseProcessRogersRatioData = {
  rogersRatioData: RogersRatioDataFetched;
};

const useProcessRogersRatioData = ({
  rogersRatioData
}: UseProcessRogersRatioData) => {
  const [rogersRatioZones, setRogersRatioZones] = useState<
    ZonesLimits[] & RogerRatioZone[]
  >([]);
  const [lastDataSource, setLastDataSource] = useState<LastDataSource>(
    rogersRatioData.LastDataSource
  );
  const [tracePoints] = useState<TracePointsData>({
    OfflinePoints: rogersRatioData.OfflinePoints,
    OnlinePoints: rogersRatioData.OnlinePoints
  });

  const processRogersRatioData = (): void => {
    const rogersRatioZonesEntries = Object.entries(rogersRatioData.ZonesLimits);
    const rogersRatioZonesArray: RogerRatioZone[] & ZonesLimits[] = [];

    for (const [, values] of rogersRatioZonesEntries) {
      rogersRatioZonesArray.push({
        limits: values
      });
    }
    setRogersRatioZones(rogersRatioZonesArray);
  };

  useEffect(() => {
    processRogersRatioData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    rogersRatioZones,
    tracePoints,
    lastDataSource,
    setLastDataSource
  };
};

export default useProcessRogersRatioData;
