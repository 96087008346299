// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { models } from "powerbi-client";
import React from "react";

import PbiEmbeddedReport from "common/powerbi/PbiEmbeddedReport";
import Message, { types as msgTypes } from "components/common/Message";
import Processing from "components/common/Processing";
import Data, { Statuses } from "core/data/models/Data";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";

import "./PbiReport.less";

export const removeReportPrefix = (name: string) => {
  const i = name.indexOf(".");
  return name.substr(i + 1);
};

interface IReport {
  Report: {
    Id: string;
    Name: string;
    WebUrl: string;
    EmbedUrl: string;
  };
  AccessToken: string;
}

export interface IPbiReportProps {
  reportName: string;
  filters?: models.IBasicFilter[];
  className?: string;
  styleHeight?: string;
}

export interface IPbiReportState {
  data: Data<IReport>;
}

export default class PbiReport extends React.Component<
  IPbiReportProps,
  IPbiReportState
> {
  constructor(props: IPbiReportProps) {
    super(props);

    this.state = {
      data: new Data(null)
    };
  }

  render() {
    const { filters, styleHeight } = this.props;
    const { data } = this.state;

    return (
      <div className="pbi-report">
        {data.status === Statuses.Loading && <PbiReportLoading />}
        {data.status === Statuses.Succeeded && (
          <PbiReportSucceeded
            accessToken={data.data.AccessToken}
            embedUrl={data.data.Report.EmbedUrl}
            filters={filters}
            reportId={data.data.Report.Id}
            styleHeight={styleHeight}
          />
        )}
        {data.status === Statuses.Failed && <PbiReportFailed />}
      </div>
    );
  }

  componentDidMount() {
    this.getReport(this.props.reportName);
  }

  UNSAFE_componentWillReceiveProps(nextProps: IPbiReportProps) {
    const prevProps = this.props;
    if (prevProps.reportName !== nextProps.reportName)
      this.getReport(nextProps.reportName);
  }

  private assignData = (data: Data<IReport>) => {
    this.setState((prevState) =>
      Object.assign({}, prevState, {
        data
      })
    );
  };

  private getReport(name: string) {
    if (name) {
      const url = UrlService.getApiUrl(config.api.powerbiembed.report, [
        {
          name: "name",
          value: name
        }
      ]);

      const request = EndpointService.getJson<IReport>(
        url,
        (request, data) => {
          this.assignData(new Data(request, data));
        },
        (request) => {
          this.assignData(new Data(request));
        }
      );

      this.assignData(new Data(request));
    }
  }
}

const PbiReportLoading = () => (
  <div className="loading">
    <Processing />
  </div>
);

interface IPbiReportSucceededProps {
  accessToken: string;
  embedUrl: string;
  filters?: models.IBasicFilter[];
  styleHeight?: string;
  reportId: string;
}

const PbiReportSucceeded = ({
  accessToken,
  embedUrl,
  filters,
  reportId,
  styleHeight
}: IPbiReportSucceededProps) => (
  <PbiEmbeddedReport
    accessToken={accessToken}
    embedUrl={embedUrl}
    filters={filters}
    reportId={reportId}
    styleHeight={styleHeight}
  />
);

const PbiReportFailed = () => (
  <Message
    type={msgTypes.ERROR}
    messageId="pbi_embedded_report.get-report.failed"
    messageDefault="Report can not be displayed."
  />
);
