// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IBasicFilter } from "powerbi-models";
import { Reducer } from "redux";

import ILinkedReport from "core/app/models/ILinkedReport";
import Data from "core/data/models/Data";
import {
  Actions,
  IReportsActions,
  ISetDetailPagePbiReportAction
} from "features/detailpage/features/reports/actions/ReportsActions";
import { IAjaxAction } from "reducers/Index";
import IReport from "../../../models/IReport";

export interface IState {
  reports: {
    pbiReports: Data<IReport[]>;
    linkedReports: Data<ILinkedReport[]>;
    pbiDetailPageReportName?: string;
    pbiDetailPageFilters?: IBasicFilter[];
  };
}

export const defaultState: IState = {
  reports: {
    pbiReports: new Data(),
    linkedReports: new Data()
  }
};

const reducer: Reducer<IState> = (
  state = defaultState,
  action: IReportsActions
): IState => {
  switch (action.type) {
    case Actions.DetailPage_Reports_SetPbiReports:
      return {
        ...state,
        reports: {
          ...state.reports,
          pbiReports: new Data(
            action.request,
            (action as IAjaxAction<IReport[]>).data
          )
        }
      };
    case Actions.DetailPage_Reports_SetLinkedReports:
      return {
        ...state,
        reports: {
          ...state.reports,
          linkedReports: new Data(
            action.request,
            (action as IAjaxAction<ILinkedReport[]>).data
          )
        }
      };
    case Actions.DetailPage_Reports_ClearPbiReports:
      return {
        ...state,
        reports: {
          ...state.reports,
          pbiReports: new Data()
        }
      };
    case Actions.DetailPage_Reports_ClearLinkedReports:
      return {
        ...state,
        reports: {
          ...state.reports,
          linkedReports: new Data()
        }
      };
    case Actions.DetailPage_Reports_SetDetailPagePbiReport:
      return {
        ...state,
        reports: {
          ...state.reports,
          pbiDetailPageReportName: (action as ISetDetailPagePbiReportAction)
            .reportName,
          pbiDetailPageFilters: (action as ISetDetailPagePbiReportAction)
            .filters
        }
      };
    default:
      return state;
  }
};

export default reducer;
