// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { dataKey } from "../actions/loadTrends";
import IAssetRiskTrend from "../models/IAssetRiskTrend";

const getTrendsSelector = createSelector(
  (state: IState) => state.data[dataKey] as Data<IAssetRiskTrend[]>,
  (trends) => trends
);

export default getTrendsSelector;
