// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import IAssetStatus from "models/IAssetStatus";
import LifecycleStatus from "models/LifecycleStatus";
import { ReactNode, useEffect } from "react";
import { useAppDispatch } from "store";
import isExactLifecycleStatus from "utils/isExactLifecycleStatus";
import { loadSimulationConfig } from "../../../actions/RiskSimulationSummaryActions";
import OpenButton from "./OpenButton";
import "./RiskSimulationSummary.less";
import SimulationLabel from "./SimulationLabel";

export interface IRiskSimulationSummaryProps {
  assetId: string;
  modelId: string;
  implementationId: string;
  lifecycleStatus: IAssetStatus["LifecycleStatus"];
  children?: ReactNode;
}

const RiskSimulationSummary = ({
  assetId,
  modelId,
  implementationId,
  lifecycleStatus,
  children
}: IRiskSimulationSummaryProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    loadSimulationConfig(dispatch, assetId).catch(() => {});
  }, [assetId, dispatch]);

  return (
    <div className="risk-simulation-summary">
      <SimulationLabel modelId={modelId} implementationId={implementationId} />
      <div className="risk-simulation-summary__buttons">
        <div>
          {!isExactLifecycleStatus(
            lifecycleStatus,
            LifecycleStatus.Removed
          ) && <OpenButton />}
        </div>
        {children}
      </div>
    </div>
  );
};

export default RiskSimulationSummary;
