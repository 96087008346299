// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IRisk } from "@apm/widgets/build/widgets/PrognosticRiskGrid";
import IIndicativeParameter from "@apm/widgets/build/widgets/PrognosticRiskGrid/models/IIndicativeParameter";
import { SubscoreService } from "common/formatters/SubscoreName";
import IDegradationScoreTrend from "features/detailpage/features/risktrend/models/IDegradationScoreTrend";
import IParameter from "features/detailpage/models/IParameter";
import { first, isEmpty, isNaN } from "lodash";
import { IntlShape } from "react-intl";
import IDegradationScore from "../../../models/IDegradationScore";
import IScore from "../../../models/IScore";
import IScoreTrend from "../../../models/IScoreTrend";
import IPrognosticGridRow from "../models/IPrognosticGridRow";
import PrognosticRiskGridParametersMapper from "../services/PrognosticRiskGridParametersMapper";

export default class PrognosticGridRowCreator {
  private leftIndex: number;
  private rightIndex: number;

  constructor(
    private modelId: string,
    private implementationId: string,
    private intl: IntlShape
  ) {}

  createRows(
    degradationScoreTrend: IDegradationScoreTrend,
    currentDate = new Date()
  ): Promise<IPrognosticGridRow[]> {
    const self = this;
    return new Promise((resolve) => {
      setTimeout(async () => {
        const rows = await this.create(degradationScoreTrend, currentDate);
        rows.sort(this.sortSubscoreRowsByPofValue.bind(self));
        resolve(rows);
      });
    });
  }

  private async create(
    degradationScoreTrend: IDegradationScoreTrend,
    currentDate: Date
  ) {
    let rows: IPrognosticGridRow[] = [];

    if (degradationScoreTrend) {
      const { Prognosis, LatestDegradationScore } = degradationScoreTrend;
      if (!isEmpty(Prognosis)) {
        this.calculateDateIndexes(currentDate, first(Prognosis)?.Dates);
        rows = await Promise.all(
          Prognosis?.map((score) =>
            this.createPrognosticRow(score, currentDate, LatestDegradationScore)
          )
        );
      }
    }

    return rows;
  }

  private async createPrognosticRow(
    {
      Dates,
      Name,
      Values,
      Risks,
      IndicativeParameters,
      IndicativeCustomModelOutputs
    }: IScoreTrend,
    currentDate: Date,
    LatestDegradationScore: IDegradationScore
  ): Promise<IPrognosticGridRow> {
    const pofValueToday = this.calculatePofToday(
      Dates,
      Values,
      currentDate,
      LatestDegradationScore,
      Name
    );
    const name = this.formatName(Name);
    const risks = this.createRisks(
      Dates,
      Values,
      Risks,
      IndicativeParameters,
      IndicativeCustomModelOutputs,
      LatestDegradationScore?.SubScores,
      Name
    );

    return {
      pof: isNaN(pofValueToday)
        ? "-"
        : this.intl.formatNumber(pofValueToday / 100, {
            style: "percent"
          }),
      pofValueToday,
      name,
      risks
    };
  }

  private sortSubscoreRowsByPofValue(
    r1: IPrognosticGridRow,
    r2: IPrognosticGridRow
  ) {
    const mainScoreName = this.intl.formatMessage({
      id: "detail_page.risk_trend.prognostic_legend.condition",
      defaultMessage: "Condition"
    });

    return r1.name === mainScoreName
      ? -1
      : r2.name === mainScoreName
      ? 1
      : r2.pofValueToday - r1.pofValueToday;
  }

  private calculateDateIndexes(date: Date, dates: string[]) {
    const dateValue = date.valueOf();
    for (let i = 0; i < dates?.length; i++) {
      const leftDate = new Date(dates[i]).valueOf();
      const rightDate = new Date(dates[i + 1]).valueOf();

      if (dateValue >= leftDate && dateValue < rightDate) {
        this.leftIndex = i;
        this.rightIndex = i + 1;
      } else if (dateValue >= rightDate) {
        this.leftIndex = i;
        this.rightIndex = i;
      } else if (dateValue <= leftDate && i === 0) {
        this.leftIndex = -1;
        this.rightIndex = i;
      }
    }
  }

  private calculatePofToday(
    dates: string[],
    values: number[],
    currentDate: Date,
    LatestDegradationScore: IDegradationScore,
    name: string
  ): number {
    const { x1, x2, y1, y2 } = this.getCoordinates(
      dates,
      values,
      LatestDegradationScore,
      name
    );

    const lastPrognoseDate = new Date(dates[this.rightIndex]).valueOf();

    const result = this.interpolatePofValue(
      x1,
      x2,
      y1,
      y2,
      x1 === y1 ? lastPrognoseDate : currentDate.valueOf()
    );
    return result;
  }
  private getCoordinates(
    dates: string[],
    values: number[],
    LatestDegradationScore: IDegradationScore,
    name: string
  ) {
    const score =
      LatestDegradationScore?.SubScores.find((s) => s.NameId === name) ??
      LatestDegradationScore;

    const todayIsBetweenPrognosis = this.leftIndex !== this.rightIndex;
    const todayIsBeforeFirstPrognose = this.leftIndex === -1;

    const x1 = todayIsBetweenPrognosis
      ? todayIsBeforeFirstPrognose
        ? new Date(score?.Date).valueOf()
        : new Date(dates[this.leftIndex]).valueOf()
      : 0;
    const x2 = new Date(dates[this.rightIndex]).valueOf();

    const y1 = todayIsBetweenPrognosis
      ? todayIsBeforeFirstPrognose
        ? score?.Value
        : values[this.leftIndex]
      : 0;
    const y2 = values[this.rightIndex];

    return { x1, x2, y1, y2 };
  }

  private createRisks(
    dates: string[],
    values: number[],
    riskValues: string[],
    IndicativeParameters: IParameter[][],
    IndicativeCustomModelOutputs: IParameter[][],
    SubScores: IScore[],
    name: string
  ): IRisk {
    const risks: IRisk = {};
    const riskGridParametersMapper = new PrognosticRiskGridParametersMapper();
    for (let i = 0; i < dates.length; i++) {
      const date = this.intl.formatDate(dates[i]);
      const pof = this.intl.formatNumber(values[i] / 100, {
        style: "percent"
      });

      const indicativeParameters =
        IndicativeParameters && IndicativeParameters[i]
          ? riskGridParametersMapper.map([...IndicativeParameters[i]])
          : undefined;

      const modelOutputParameters =
        IndicativeCustomModelOutputs && IndicativeCustomModelOutputs[i]
          ? riskGridParametersMapper.map([...IndicativeCustomModelOutputs[i]])
          : undefined;

      const impactingParameters = this.getImpactingParameters(
        SubScores,
        indicativeParameters,
        modelOutputParameters,
        name,
        riskGridParametersMapper
      );

      const risk =
        riskValues && riskValues[i]
          ? this.getRiskValue(riskValues[i])
          : undefined;
      risks[date] = {
        pof,
        risk,
        indicativeParameters,
        modelOutputParameters,
        impactingParameters
      };
    }
    return risks;
  }

  private getRiskValue(riskValue: string) {
    switch (riskValue) {
      case "High":
        return "high";
      case "high":
        return "high";
      case "Low":
        return "low";
      case "low":
        return "low";
      case "Medium":
        return "medium";
      case "medium":
        return "medium";
    }
  }

  private formatName(name: string): string {
    const result =
      name === "Score"
        ? this.intl.formatMessage({
            id: "detail_page.risk_trend.prognostic_legend.condition",
            defaultMessage: "Condition"
          })
        : SubscoreService.format(
            name,
            this.modelId,
            this.implementationId,
            this.intl
          );

    return result;
  }

  private interpolatePofValue(
    x1: number,
    x2: number,
    y1: number,
    y2: number,
    date: number
  ) {
    const a = (y2 - y1) / (x2 - x1);
    const b = y1 - a * x1;

    return a * date + b;
  }

  private getImpactingParameters(
    subScores: IScore[],
    indicativeParameters: IIndicativeParameter[],
    indicativeCustomModelOutputs: IIndicativeParameter[],
    name: string,
    riskGridParametersMapper: PrognosticRiskGridParametersMapper
  ) {
    const impactingParameters: IIndicativeParameter[] = [];
    const index = subScores?.findIndex((s) => s.NameId === name);

    index !== -1 &&
      index !== undefined &&
      subScores[index] !== undefined &&
      subScores[index]?.ImpactingInputParameters?.forEach((impacting) => {
        if (
          this.checkIfIndicativesContainImpactingParameter(
            indicativeParameters,
            impacting
          ) &&
          this.checkIfIndicativesContainImpactingParameter(
            indicativeCustomModelOutputs,
            impacting
          )
        ) {
          const mappedParameter = riskGridParametersMapper.map([impacting])[0];
          impactingParameters.push(mappedParameter);
        }
      });
    return impactingParameters;
  }

  private checkIfIndicativesContainImpactingParameter(
    parametersArray: IIndicativeParameter[],
    impactingParameter: IParameter
  ) {
    return (
      parametersArray === undefined ||
      parametersArray?.findIndex((p) => p.name === impactingParameter?.Name) ===
        -1
    );
  }
}
