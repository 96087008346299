// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { isEmpty } from "lodash";
import IAssetNameplate from "models/IAssetNameplate";
import { useCallback } from "react";
import { useAppDispatch } from "store";
import { setIsComponentAction } from "../actions/RiskMatrixActions";

const useSetIsComponentAction = (assetNameplate: IAssetNameplate) => {
  const dispatch = useAppDispatch();
  const isComponent = !isEmpty(assetNameplate && assetNameplate.ParentId);

  return useCallback(
    () => dispatch(setIsComponentAction(isComponent)),
    [dispatch, isComponent]
  );
};

export default useSetIsComponentAction;
