// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import DataSource from "common/DuvalAnalysis/models/DataSource";
import useDuvalPentagon from "../hooks/useDuvalPentagon";
import IDuvalPoint from "../models/IDuvalPoint";
import "./DuvalPentagon.less";

interface IDuvalPentagonProps {
  points: IDuvalPoint[];
  type: number;
  dataSource: DataSource;
}

export const svgWidth = 500;
export const svgHeight = 400;

const DuvalPentagon = ({ points, type, dataSource }: IDuvalPentagonProps) => {
  const { svgRef, tooltipRef } = useDuvalPentagon({ points, type, dataSource });

  return (
    <div className="duval-pentagon-container">
      <div className="c-tooltip" ref={tooltipRef}></div>
      <svg
        ref={svgRef}
        className="duval-pentagon-svg"
        viewBox={`-45 45 ${svgWidth} ${svgHeight}`}
      ></svg>
    </div>
  );
};

export default DuvalPentagon;
