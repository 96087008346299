// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";
import { RouterLayout } from "core/app/components/RouterProvider";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import ReplacementPriority from "../../containers/ReplacementPriorityContainer";
import AgingVisualization from "../aging/AgingVisualization";
import "./ReplacementScoreTabs.less";

interface IReplacementScoreTabsProps {
  assetId: string;
  replacementScoreModelImplementationId: string;
}

const ReplacementScoreTabs = ({
  assetId,
  replacementScoreModelImplementationId
}: IReplacementScoreTabsProps) => {
  const intl = useIntl();
  const searchParams = useMemo(() => new SearchParams({ assetId }), [assetId]);

  return (
    <RouterLayout
      routes={[
        {
          id: "Assets-DetailPage-RPS-ReplacementPriority",
          route: "ReplacementPriority",
          displayName: intl.formatMessage({
            id: "detail_page.replacement_score.tabs.replacement_priority",
            defaultMessage: "Replacement Priority"
          }),
          to: {
            pathname: "/Assets/DetailPage/RPS/ReplacementPriority",
            search: searchParams.toString()
          },
          element: (
            <ReplacementPriority
              assetId={assetId}
              replacementScoreModelImplementationId={
                replacementScoreModelImplementationId
              }
            />
          )
        },
        {
          id: "Assets-DetailPage-RPS-Aging",
          route: "Aging",
          displayName: intl.formatMessage({
            id: "detail_page.replacement_score.tabs.aging",
            defaultMessage: "Aging"
          }),
          to: {
            pathname: "/Assets/DetailPage/RPS/Aging",
            search: searchParams.toString()
          },
          element: <AgingVisualization assetId={assetId} />
        }
      ]}
    />
  );
};

export default ReplacementScoreTabs;
