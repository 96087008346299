// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { kebabCase, camelCase, upperFirst } from "lodash";
import * as React from "react";
import { FormattedMessage } from "react-intl";

// hooks
import { LegendItem, Statuses } from "./hooks/useLegend";

import "./ChartLegendItem.less";

interface IChartLegendItemProps {
  className?: string;
  index: number;
  item: LegendItem;
  messageIdPrefix: string;
  messageIdToPascalCase: boolean;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  onClick?: () => void;
  status: Statuses;
}

const ChartLegendItem: React.FC<IChartLegendItemProps> = ({
  className = "",
  index,
  item,
  messageIdPrefix,
  messageIdToPascalCase,
  onClick,
  onMouseOver,
  onMouseOut,
  status
}) => {
  const pascalCase = (text: string) => upperFirst(camelCase(text));

  return (
    <span
      className={
        "chart-legend__item " +
        (Statuses[status] && Statuses[status].toLowerCase())
      }
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <span className="chart-legend__item-box">
        <span
          style={{ background: item.color }}
          className="chart-legend__item-box--filled"
        />
      </span>
      <span className={`chart-legend__item-text ${className}`}>
        {messageIdToPascalCase ? (
          <FormattedMessage
            defaultMessage={item.name}
            id={`${messageIdPrefix}.${pascalCase(item.name)}`}
          />
        ) : (
          <FormattedMessage
            defaultMessage={item.name}
            id={`${messageIdPrefix}.legend_item_${index}.${kebabCase(
              item.name
            )}`}
          />
        )}
      </span>
    </span>
  );
};

export default ChartLegendItem;
