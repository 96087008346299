// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Col, Row } from "antd";
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import { Statuses } from "core/data/models/Data";
import UrlService from "core/data/services/UrlService";
import $ from "jquery";
import React from "react";
import { injectIntl, IntlShape } from "react-intl";
import { config } from "utils/AppConfig";
import { IData } from "utils/WebService";
import { DuvalDataPoint } from "./DuvalTriangle";
import DuvalTriangleChart from "./DuvalTriangleChart";
import DuvalTriangleType4Note from "./DuvalTriangleType4Note";
import DuvalTriangleType5Note from "./DuvalTriangleType5Note";
import DuvalType from "./models/DuvalType";
import IDuvalRegion from "./models/IDuvalRegion";
import IDuvalTabConfiguration from "./models/IDuvalTabConfiguration";
import DataSource from "common/DuvalAnalysis/models/DataSource";

interface ITriangleData {
  type1: IDuvalPoint[];
  type3: IDuvalPoint[];
  type4: IDuvalPoint[];
  type5: IDuvalPoint[];
}

interface IDuvalPoint {
  date: Date;
  left: number;
  right: number;
  base: number;
}

interface IDuvalTrianglesTabState {
  triangleData: IData<ITriangleData>;
}

interface IDuvalTrianglesTabProps {
  assetId: string;
  dataSource: DataSource;
  duvalConfiguration: IDuvalTabConfiguration;
  intl: IntlShape;
}

class DuvalTrianglesTab extends React.Component<
  IDuvalTrianglesTabProps,
  IDuvalTrianglesTabState
> {
  constructor(props: IDuvalTrianglesTabProps) {
    super(props);
    this.getAnalyticsWidget = this.getAnalyticsWidget.bind(this);

    this.state = {
      triangleData: {
        data: {
          type1: [],
          type3: [],
          type4: [],
          type5: []
        },
        message: "",
        status: Statuses.Loading
      }
    };
  }

  componentWillReceiveProps = (nextProps: IDuvalTrianglesTabProps) => {
    if (
      this.props.dataSource !== nextProps.dataSource ||
      this.props.assetId !== nextProps.assetId
    )
      this.setState({
        triangleData: {
          data: {
            type1: [],
            type3: [],
            type4: [],
            type5: []
          },
          message: "",
          status: Statuses.Loading
        }
      });
    queryForParameters(
      nextProps.assetId,
      nextProps.dataSource,
      this.onSuccess,
      this.onError
    );
  };

  componentDidMount() {
    queryForParameters(
      this.props.assetId,
      this.props.dataSource,
      this.onSuccess,
      this.onError
    );
  }

  private static convertToDuvalDataPoints(
    points: IDuvalPoint[]
  ): DuvalDataPoint[] {
    return (points || []).map((p) =>
      p ? new DuvalDataPoint(p.left, p.right, p.base, p.date) : null
    );
  }

  private static duvalConfigurationExists(configuration: IDuvalRegion[]) {
    return configuration?.length > 0;
  }

  private static showDuvalTriangle(
    duvalType: DuvalType,
    duvalConfiguration: IDuvalTabConfiguration
  ): boolean {
    switch (duvalType) {
      case "Type1":
        return DuvalTrianglesTab.duvalConfigurationExists(
          duvalConfiguration?.type1
        );
      case "Type3":
        return DuvalTrianglesTab.duvalConfigurationExists(
          duvalConfiguration?.type3
        );
      case "Type4":
        return DuvalTrianglesTab.duvalConfigurationExists(
          duvalConfiguration?.type4
        );
      case "Type5":
        return DuvalTrianglesTab.duvalConfigurationExists(
          duvalConfiguration?.type5
        );
    }
  }

  getAnalyticsWidget(): JSX.Element {
    return (
      <Row>
        {DuvalTrianglesTab.showDuvalTriangle(
          "Type1",
          this.props.duvalConfiguration
        ) && (
          <Col span={8}>
            <DuvalTriangleChart
              dataSource={this.props.dataSource}
              points={DuvalTrianglesTab.convertToDuvalDataPoints(
                this.state.triangleData.data.type1
              )}
              type={1}
              regions={this.props.duvalConfiguration.type1}
              labels={{ left: "CH4", right: "C2H4", base: "C2H2" }}
            />
          </Col>
        )}
        {DuvalTrianglesTab.showDuvalTriangle(
          "Type3",
          this.props.duvalConfiguration
        ) && (
          <Col span={8}>
            <DuvalTriangleChart
              dataSource={this.props.dataSource}
              points={DuvalTrianglesTab.convertToDuvalDataPoints(
                this.state.triangleData.data.type3
              )}
              type={3}
              regions={this.props.duvalConfiguration.type3}
              labels={{ left: "CH4", right: "C2H4", base: "C2H2" }}
            />
          </Col>
        )}
        {DuvalTrianglesTab.showDuvalTriangle(
          "Type4",
          this.props.duvalConfiguration
        ) && (
          <Col span={8}>
            <DuvalTriangleChart
              dataSource={this.props.dataSource}
              points={DuvalTrianglesTab.convertToDuvalDataPoints(
                this.state.triangleData.data.type4
              )}
              type={4}
              regions={this.props.duvalConfiguration.type4}
              note={<DuvalTriangleType4Note />}
              labels={{ left: "H2", right: "CH4", base: "C2H6" }}
            />
          </Col>
        )}
        {DuvalTrianglesTab.showDuvalTriangle(
          "Type5",
          this.props.duvalConfiguration
        ) && (
          <Col span={8}>
            <DuvalTriangleChart
              dataSource={this.props.dataSource}
              points={DuvalTrianglesTab.convertToDuvalDataPoints(
                this.state.triangleData.data.type5
              )}
              type={5}
              regions={this.props.duvalConfiguration.type5}
              note={<DuvalTriangleType5Note />}
              labels={{ left: "CH4", right: "C2H4", base: "C2H6" }}
            />
          </Col>
        )}
      </Row>
    );
  }

  private getNoDataComponent(): JSX.Element {
    return <WidgetNoDataMessage />;
  }

  private getLoadingComponent(): JSX.Element {
    return <Processing />;
  }

  private getErrorComponent(): JSX.Element {
    return (
      <WidgetErrorMessage
        messageId="global.empty"
        messageDefault={""}
        messageValues={""}
      />
    );
  }

  private onSuccess = (data: ITriangleData): void => {
    this.setState(
      (
        prevState: IDuvalTrianglesTabState,
        props: IDuvalTrianglesTabProps
      ): IDuvalTrianglesTabState => {
        return {
          triangleData: {
            data: data,
            status: Statuses.Succeeded,
            message: ""
          }
        };
      }
    );
  };

  private onError = (xhr: JQueryXHR, status: any, err: any): void => {
    this.setState(
      (
        prevState: IDuvalTrianglesTabState,
        props: IDuvalTrianglesTabProps
      ): IDuvalTrianglesTabState => {
        return {
          triangleData: {
            data: {
              type1: [],
              type3: [],
              type4: [],
              type5: []
            },
            status: xhr.status === 404 ? Statuses.NotFound : Statuses.Failed,
            message: status.concat(" ").concat(err.toString())
          }
        };
      }
    );
  };

  private getComponentByStatus(): JSX.Element {
    switch (this.state.triangleData.status) {
      case Statuses.Loading:
        return this.getLoadingComponent();
      case Statuses.Succeeded:
        return this.getAnalyticsWidget();
      case Statuses.NotFound:
        return this.getNoDataComponent();
      default:
        return this.getErrorComponent();
    }
  }

  render() {
    return (
      <div className="analytics duval-triangles" data-qa="duval-triangles">
        {this.getComponentByStatus()}
      </div>
    );
  }
}

function queryForParameters(
  assetId: string,
  dataSource: string,
  onSuccess: (data: ITriangleData | null | undefined) => void,
  onError: (xhr: JQueryXHR, status: any, err: any) => void
): void {
  const url = UrlService.getApiUrl(
    config.api.detailPage.assetDuvalTrianglesGasesConcentrationUrl,
    [
      {
        name: "assetId",
        value: assetId
      },
      {
        name: "dataSource",
        value: dataSource
      }
    ]
  );
  $.ajax({
    url: url,
    type: "GET",
    dataType: "json",
    cache: false,
    success: onSuccess,
    error: onError,
    contentType: "application/json; charset=UTF-8",
    processData: false
  });
}

export default injectIntl(DuvalTrianglesTab, {
  forwardRef: true
});
