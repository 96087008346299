// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";
import { FormattedNumber } from "react-intl";

import "./Actions.less";

interface IActionsProps {
  onClick: () => void;
  value: number;
}

const Actions = ({ onClick, value }: IActionsProps) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onClick();
  };

  return (
    <a className="column actions anchor" href="#/" onClick={handleClick}>
      <FormattedNumber value={value} />
    </a>
  );
};

export default Actions;
