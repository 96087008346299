// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { loadTranslations } from "core/app/actions/TranslationsActions";
import TranslationsProvider, {
  ITranslationsProviderActions
} from "core/app/components/TranslationsProvider";
import { connect } from "react-redux";
import { AppDispatch } from "store";

function mapDispatchToProps(
  dispatch: AppDispatch
): ITranslationsProviderActions {
  return {
    loadTranslations: (locale: string) => {
      loadTranslations(dispatch, locale);
    }
  };
}

const TranslationsProviderContainer = connect(
  () => ({}),
  mapDispatchToProps
)(TranslationsProvider);

export default TranslationsProviderContainer;
