// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import StorageService from "core/app/services/StorageService";

interface useRecalculateButtonBlockTimestampOptions {
  modelId: string;
  assetId: string;
}

const sessionStorageKey = "apm-recalculate-button-timestamps";

type RecalculatingTimestamps = { [key: string]: Date };

const useRecalculateButtonBlockTimestamp = ({
  modelId,
  assetId
}: useRecalculateButtonBlockTimestampOptions) => {
  const parseTimestamps = (timestamps: string): RecalculatingTimestamps => {
    try {
      return JSON.parse(timestamps);
    } catch (e) {
      return null;
    }
  };

  const getPrefix = () => {
    if (assetId) return `apm-asset-id-${assetId}`;
    else if (modelId) return `apm-model-id-${modelId}`;

    throw new Error(
      "Cannot set recalculate button timestamp prefix because none of assetId or modelId is provided"
    );
  };

  const setTimestamp = () => {
    const timestamps = StorageService.getSessionStorageItem(sessionStorageKey);
    if (timestamps) {
      const parsed = parseTimestamps(timestamps);
      parsed[getPrefix()] = new Date();
      StorageService.setSessionStorageItem(
        sessionStorageKey,
        JSON.stringify(parsed)
      );
    } else {
      const value = { [getPrefix()]: new Date() };
      StorageService.setSessionStorageItem(
        sessionStorageKey,
        JSON.stringify(value)
      );
    }
  };

  const getTimestamp = () => {
    const timestamps = StorageService.getSessionStorageItem(sessionStorageKey);
    if (timestamps) {
      const parsed = parseTimestamps(timestamps);
      return parsed[getPrefix()];
    }

    return null;
  };

  const removeTimestamp = () => {
    const timestamps = StorageService.getSessionStorageItem(sessionStorageKey);
    if (timestamps) {
      const parsed = parseTimestamps(timestamps);
      delete parsed[getPrefix()];
      StorageService.setSessionStorageItem(
        sessionStorageKey,
        JSON.stringify(parsed)
      );
    }
  };

  return {
    setTimestamp,
    getTimestamp,
    removeTimestamp
  };
};

export default useRecalculateButtonBlockTimestamp;
