// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { first, isNil, max, min, throttle } from "lodash";
import { MutableRefObject, useMemo } from "react";
import { IntlShape, useIntl } from "react-intl";

import getDefaultConfiguration from "./defaultChartConfiguration";

import IBushingLineChartDataSet from "features/detailpage/features/bushingsoffline/models/IBushingLineChartDataSet";
import IBushingReferencedDataSet from "features/detailpage/features/bushingsoffline/models/IBushingReferenceDataSet";
import ISize from "features/detailpage/features/bushingsoffline/models/ISize";

import {
  ChartAPI,
  ChartConfiguration,
  GridLineOptionsWithAxis,
  RegionOptions
} from "c3";
import Axis from "features/detailpage/features/bushingsoffline/models/Axis";
import BushingOfflineChartService from "features/detailpage/features/bushingsoffline/services/BushingOfflineChartService";
import getExtremeDates from "features/detailpage/features/bushingsoffline/utils/getExtremeDates";
import moment from "moment";

interface IUseBushingsOfflineChartConfiguration {
  series: IBushingLineChartDataSet[];
  size?: ISize;
  powerFactorReference?: IBushingReferencedDataSet;
  capacitanceReference?: IBushingReferencedDataSet;
  installDate: string;
  chartRef: MutableRefObject<ChartAPI>;
}
const useBushingsOfflineChartConfiguration = ({
  series,
  size,
  powerFactorReference,
  capacitanceReference,
  installDate,
  chartRef
}: IUseBushingsOfflineChartConfiguration) => {
  const intl = useIntl();
  return useMemo(() => {
    const configuration = getDefaultConfiguration(intl);
    const { firstDate, lastDate } = getExtremeDates(series);
    const powerFactorSeries = first(series.filter((s) => s.axis === Axis.Left));
    const capacitanceSeries = first(
      series.filter((s) => s.axis === Axis.Right)
    );

    setDataConfig(configuration, series);
    setReferences(configuration, powerFactorReference, capacitanceReference);
    setEmptyLabel(configuration, intl);
    setSize(configuration, size);
    setMaxMinAxisYValue(configuration, powerFactorSeries, powerFactorReference);
    setMaxMinAxisY2Value(
      configuration,
      capacitanceSeries,
      capacitanceReference
    );
    setAxisXLine(
      configuration,
      firstDate,
      lastDate,
      installDate,
      "install-date-line"
    );

    setXAxisRegion(
      configuration,
      firstDate,
      lastDate,
      installDate,
      "install-date-region"
    );

    onChartResize(chartRef, configuration);
    onChartRerendered(chartRef, configuration);

    return configuration;
  }, [
    intl,
    series,
    powerFactorReference,
    capacitanceReference,
    size,
    installDate,
    chartRef
  ]);
};

export default useBushingsOfflineChartConfiguration;

// #region Functions

function setDataConfig(
  configuration: ChartConfiguration,
  series: IBushingLineChartDataSet[]
) {
  const { xs, columns, axes, types, colors } =
    BushingOfflineChartService.getChartConfigurationSeriesData(series);

  configuration.data.xs = xs;
  configuration.data.columns = columns;
  configuration.data.axes = axes;
  configuration.data.types = types;
  configuration.data.colors = colors;
}

function setReferences(
  configuration: ChartConfiguration,
  powerFactorReference?: IBushingReferencedDataSet,
  capacitanceReference?: IBushingReferencedDataSet
) {
  const lines: GridLineOptionsWithAxis[] = [];

  if (!isNil(powerFactorReference?.value)) {
    lines.push({
      value: powerFactorReference.value,
      class: "power-factor-threshold",
      axis: "y"
    });
  }

  if (!isNil(capacitanceReference?.value)) {
    lines.push({
      value: capacitanceReference.value,
      class: "capacitance-threshold",
      axis: "y2"
    });
  }

  configuration.grid.y.lines = lines;
}

function setEmptyLabel(configuration: ChartConfiguration, intl: IntlShape) {
  const emptyLabel = intl.formatMessage({
    id: "detail_page.widgets.analytics.linechart.nodata",
    defaultMessage: "NO DATA AVAILABLE"
  });
  configuration.data.empty.label.text = emptyLabel;
}

function setSize(configuration: ChartConfiguration, size?: ISize) {
  if (size) {
    configuration.size = size;
  }
}

function setMaxMinAxisYValue(
  configuration: ChartConfiguration,
  powerFactorSeries: IBushingLineChartDataSet,
  powerFactorReference?: IBushingReferencedDataSet
) {
  if (!isNil(powerFactorReference)) {
    let maxValue = powerFactorReference.value;

    if (
      powerFactorSeries &&
      powerFactorSeries.values &&
      powerFactorSeries.values.length > 0
    ) {
      const seriesMaxValue = max(powerFactorSeries.values.map((s) => s.value));

      if (seriesMaxValue > maxValue) {
        maxValue = seriesMaxValue;
      }
    }
    configuration.axis.y.max = maxValue;
  }
}

function setMaxMinAxisY2Value(
  configuration: ChartConfiguration,
  capacitanceSeries: IBushingLineChartDataSet,
  capacitanceReference?: IBushingReferencedDataSet
) {
  if (!isNil(capacitanceReference)) {
    const maxValue = capacitanceReference.value;
    const minValue = capacitanceReference.value;

    if (
      capacitanceSeries &&
      capacitanceSeries.values &&
      capacitanceSeries.values.length > 0
    ) {
      const seriesMaxValue = max(capacitanceSeries.values.map((s) => s.value));
      const seriesMinValue = min(capacitanceSeries.values.map((s) => s.value));

      if (maxValue > seriesMaxValue) {
        configuration.axis.y2.max = maxValue;
      }

      if (minValue < seriesMinValue) {
        configuration.axis.y2.min = minValue;
      }
    }
  }
}

function setAxisXLine(
  configuration: ChartConfiguration,
  firstDate: Date,
  lastDate: Date,
  timestamp?: string,
  className?: string,
  label?: string,
  position?: "start" | "end" | "middle"
) {
  const dateValue = new Date(timestamp);
  const isDateSameOrBefore =
    moment(dateValue).isBefore(lastDate) ||
    dateValue.toLocaleDateString() === lastDate.toLocaleDateString();
  const isDateSameOrAfter =
    moment(dateValue).isAfter(firstDate) ||
    dateValue.toLocaleDateString() === firstDate.toLocaleDateString();
  let lines: GridLineOptionsWithAxis[] = [];

  if (timestamp && isDateSameOrBefore && isDateSameOrAfter) {
    lines.push({
      value: dateValue,
      text: label,
      position,
      class: className
    });
  }

  if (timestamp && !configuration.grid.x) {
    configuration.grid.x = {};
    configuration.grid.x.lines = lines;
  }
}

function setXAxisRegion(
  configuration: ChartConfiguration,
  oldestDate: Date,
  youngestDate: Date,
  timestamp?: string,
  className?: string
) {
  const endDate = new Date(timestamp);
  const isDateSameOrBefore =
    moment(endDate).isBefore(youngestDate) ||
    endDate.toLocaleDateString() === youngestDate.toLocaleDateString();
  const isDateSameOrAfter =
    moment(endDate).isAfter(oldestDate) ||
    endDate.toLocaleDateString() === oldestDate.toLocaleDateString();
  let regions: RegionOptions[] = [];

  if (endDate && isDateSameOrBefore && isDateSameOrAfter) {
    regions.push({
      start: null,
      end: endDate,
      class: className
    });
  }

  if (timestamp && !configuration.regions) {
    configuration.regions = [...regions];
  }
}

function onChartResize(
  chartRef: MutableRefObject<ChartAPI>,
  configuration: ChartConfiguration
) {
  configuration.onresize = () => {
    const { regions } = chartRef.current;
    const regionsResult = regions();
    chartRef.current.regions.remove({
      classes: ["install-date-region"],
      duration: 0
    });
    chartRef.current.regions.add(regionsResult);
  };
}

function onChartRerendered(
  chartRef: MutableRefObject<ChartAPI>,
  configuration: ChartConfiguration
) {
  configuration.onrendered = throttle(
    () => {
      if (chartRef.current?.regions) {
        const { regions } = chartRef.current;
        const regionsResult = regions();
        chartRef.current.regions.remove({
          classes: ["install-date-region"],
          duration: 0
        });
        chartRef.current.regions.add(regionsResult);
      }
    },
    500,
    { leading: true, trailing: false }
  );
}

// #endregion
