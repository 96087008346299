// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Processing from "components/common/Processing";
import styled from "styled-components";

interface IProcessingModalProps {
  className?: string;
  processingClassName?: string;
}

const ProcessingModal = ({
  className,
  processingClassName
}: IProcessingModalProps) => {
  return (
    <div className={className}>
      <Processing className={processingClassName} />
    </div>
  );
};

const StyledProcessingModal = styled(ProcessingModal)`
  &.processing-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3000;
  }

  &.processing-screen.dark {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &.processing-screen.light {
    background-color: rgba(255, 255, 255, 0.7);
  }

  &.processing-screen.fixed {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &.processing-screen.absolute {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

export default ({
  className = "processing-screen fixed dark",
  processingClassName = "spinner large light"
}: IProcessingModalProps) => (
  <StyledProcessingModal {...{ className, processingClassName }} />
);
