// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import ParameterSources from "models/ParameterSources";
import React from "react";
import { IntlShape, useIntl } from "react-intl";

export interface IParameterNameProps {
  nameId: string;
  modelId: string;
  implementationId: string;
  parameterSource?: ParameterSources;
}

const ParameterName = ({
  nameId,
  modelId,
  implementationId,
  parameterSource
}: IParameterNameProps) => {
  const intl = useIntl();
  const formattedValue = ParameterService.format(
    nameId,
    modelId,
    implementationId,
    intl,
    parameterSource
  );
  return <span>{formattedValue}</span>;
};

export default ParameterName;

export class ParameterService {
  private static readonly parameterIntlPrefix = "parameter";
  private static readonly derivativeParameterIntlPrefix =
    "derivative-parameter";

  static format(
    nameId: string,
    modelId: string,
    implementationId: string,
    intl: IntlShape,
    parameterSource?: ParameterSources
  ): string {
    if (parameterSource === "DerivativeParameter")
      return intl.formatMessage({
        id: `${modelId}.${this.derivativeParameterIntlPrefix}.${nameId}`,
        defaultMessage: intl.formatMessage({
          id: `${implementationId}.${this.derivativeParameterIntlPrefix}.${nameId}`,
          defaultMessage: nameId
        })
      });
    else
      return intl.formatMessage({
        id: `${modelId}.${this.parameterIntlPrefix}.${nameId}`,
        defaultMessage: intl.formatMessage({
          id: `${implementationId}.${this.parameterIntlPrefix}.${nameId}`,
          defaultMessage: nameId
        })
      });
  }
}
