// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Popover } from "antd";
import React, { useMemo } from "react";

import { IRow } from "common/datagrid/DataGrid";
import ColumnFactorsTooltip from "./ColumnFactorsTooltip";
import isClickable from "../utils/isClickable";
import IParameterInfo from "../../../models/IParameterInfo";
import IScoreFactor from "../../../models/IScoreFactor";

const ColumnFactors = (factors: IScoreFactor[], row: IRow<IParameterInfo>) => {
  const clickable = useMemo(() => isClickable(row), [row]);

  return (
    <div className={`column factors ${!clickable && "non-clickable"}`}>
      {factors && (
        <Popover content={<ColumnFactorsTooltip factors={factors} />}>
          <div className="factors">
            {factors
              .filter((f, i) => i < 3)
              .map((f, i) => (i < 2 ? f.TranslatedName : "..."))
              .join(", ")}
          </div>
        </Popover>
      )}
    </div>
  );
};

export default ColumnFactors;
