// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { shouldPolyfill } from "@formatjs/intl-getcanonicallocales/should-polyfill";

const polyfillGetCanonicalLocales = (): Promise<any> => {
  if (!shouldPolyfill()) return Promise.resolve();
  return import("@formatjs/intl-getcanonicallocales/polyfill");
};

export default polyfillGetCanonicalLocales;
