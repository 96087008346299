// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import ITier from "../models/ITier";

const tiers: ITier[] = [
  {
    id: "tier1",
    minSize: 2,
    maxSize: 100,
    r: 14
  },
  {
    id: "tier2",

    minSize: 101,
    maxSize: 1000,
    r: 17
  },
  {
    id: "tier3",
    minSize: 1001,
    maxSize: 10000,
    r: 21
  },
  {
    id: "tier4",
    minSize: 10001,
    r: 24
  }
];

export default tiers;
