// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import moment from "moment";
import React from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";

import {
  DateDifferenceService,
  Formats as DateDifferenceFormats
} from "common/formatters/DateDifference";
import TooltipWrapper from "common/tooltipwrapper/TooltipWrapper";
import IIssue from "features/detailpage/features/issues/models/IIssue";
import IssueChangesTooltip from "./IssueChangesTooltip";

export enum Formats {
  Date,
  Diff
}

interface IIssueCreatedDateProps {
  className?: string;
  format?: Formats;
  issue: IIssue;
}

const IssueCreatedDate = ({
  className,
  format,
  issue
}: IIssueCreatedDateProps) => {
  const intl = useIntl();
  const dateFormat =
    format !== undefined && format != null ? format : Formats.Diff;

  return (
    <div className={className}>
      <TooltipWrapper Tooltip={() => <IssueChangesTooltip issue={issue} />}>
        {dateFormat === Formats.Diff && (
          <FormattedMessage
            defaultMessage="{diff} ago"
            id="detail_page.issues_list.created_date"
            values={{
              diff: DateDifferenceService.format(
                issue.CreateDate,
                intl,
                DateDifferenceFormats.Long
              )
            }}
          />
        )}
        {dateFormat === Formats.Date && (
          <FormattedDate value={moment(issue.CreateDate).toDate()} />
        )}
      </TooltipWrapper>
    </div>
  );
};

export default IssueCreatedDate;
