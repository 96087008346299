// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { svgHeight, svgWidth } from "./../components/DuvalPentagon";

interface IPoint {
  x: number;
  y: number;
}

interface IDuvalPentagonConvertedCoordinates {
  point: IPoint;
}

const createDuvalPentagonConvertedCoordinates = ({
  point
}: IDuvalPentagonConvertedCoordinates): IPoint => {
  const margin = 45;
  const w = (svgWidth - 2 * margin) / 80;
  const centerX = svgHeight / 2;
  const centerY = svgWidth / 2;

  return {
    x: centerX + point.x * w + 10,
    y: centerY - point.y * w
  };
};

export default createDuvalPentagonConvertedCoordinates;
