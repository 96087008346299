// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Col, Row } from "antd";
import Data from "core/data/models/Data";
import LifecycleStatus from "models/LifecycleStatus";
import { IntlShape } from "react-intl";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import acknowledge from "../../../actions/acknowledge";
import adjustExpectedRange from "../../../actions/adjustExpectedRange";
import IThresholdCorrection from "../../../models/IThresholdCorrection";
import loadWear from "../actions/loadWear";
import removeWear from "../actions/removeWear";
import IWearData from "../models/IWearData";
import getWearSelector from "../selectors/getWearSelector";
import ContactWearChart from "./ContactWearChart";
import ContactWearParameters from "./ContactWearParameters";

interface IWearTabActions {
  adjustExpectedRange: (
    intl: IntlShape,
    parameterName: string,
    success?: (correction: IThresholdCorrection) => void,
    error?: () => void
  ) => void;
  acknowledge: (
    intl: IntlShape,
    parameterName: string,
    success?: (correction: IThresholdCorrection) => void,
    error?: () => void
  ) => void;
  loadWear: () => void;
  removeWear: () => void;
}

interface IWearTabState {
  wear: Data<IWearData>;
}

interface IWearTabOwnProps {
  assetId: string;
  lifecycleStatus: LifecycleStatus;
}

interface IWearTabProps
  extends IWearTabActions,
    IWearTabState,
    IWearTabOwnProps {}

const WearTab = ({
  acknowledge,
  adjustExpectedRange,
  assetId,
  loadWear,
  removeWear,
  wear,
  lifecycleStatus
}: IWearTabProps) => {
  return (
    <Row>
      <Col span={10}>
        <ContactWearParameters
          acknowledge={acknowledge}
          adjustExpectedRange={adjustExpectedRange}
          assetId={assetId}
          lifecycleStatus={lifecycleStatus}
          loadWear={loadWear}
          removeWear={removeWear}
          wear={wear}
        />
      </Col>
      <Col span={14}>
        <ContactWearChart assetId={assetId} />
      </Col>
    </Row>
  );
};

export default connect(
  (state: IState, { assetId }: IWearTabOwnProps): IWearTabState => ({
    wear: getWearSelector(state)(assetId)
  }),
  (dispatch: AppDispatch, { assetId }: IWearTabOwnProps): IWearTabActions => ({
    adjustExpectedRange: (
      intl: IntlShape,
      parameterName: string,
      success?: (correction: IThresholdCorrection) => void,
      error?: () => void
    ) => adjustExpectedRange({ intl, assetId, parameterName, success, error }),
    acknowledge: (
      intl: IntlShape,
      parameterName: string,
      success?: (correction: IThresholdCorrection) => void,
      error?: () => void
    ) => acknowledge({ intl, assetId, parameterName, success, error }),
    loadWear: () => dispatch(loadWear(assetId)),
    removeWear: () => dispatch(removeWear(assetId))
  })
)(WearTab);
