// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";

import useSetCanBeSaved from "../hooks/useSetCanBeSaved";

import ConfigurationBox, {
  IConfigurationBoxProps
} from "../components/ConfigurationBox";

const ConfigurationBoxContainer = ({
  handleOnAllChecked,
  ...ownProps
}: IConfigurationBoxProps) => {
  const { setCanBeSaved } = useSetCanBeSaved();
  const handleOnTreeInputChange = React.useCallback(
    (checkedKeys: string[]) => {
      setCanBeSaved(true);
    },
    [setCanBeSaved]
  );

  const onAllChecked = React.useCallback(
    (checked: boolean) => {
      handleOnAllChecked(checked);
      setCanBeSaved(true);
    },
    [handleOnAllChecked, setCanBeSaved]
  );

  const handleAllKeysCheckedChanged = React.useCallback(
    (allKeysChecked: boolean) => {
      if (allKeysChecked === false) allKeysChecked = undefined;
      handleOnAllChecked(allKeysChecked);
    },
    [handleOnAllChecked]
  );

  return (
    <ConfigurationBox
      handleOnAllChecked={onAllChecked}
      handleOnTreeInputChange={handleOnTreeInputChange}
      handleAllKeysCheckedChanged={handleAllKeysCheckedChanged}
      {...ownProps}
    />
  );
};

export default ConfigurationBoxContainer;
