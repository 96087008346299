// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { isEmpty, isString } from "lodash";
import React from "react";
import {
  FormattedMessage,
  injectIntl,
  IntlShape,
  MessageDescriptor
} from "react-intl";

import { IMessage } from "common/form/models/IMessage";

import "./TextField.less";

export interface ITextFieldHtmlProps {
  title?: string;
}

export interface ITextFieldProps extends ITextFieldHtmlProps {
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  label?: MessageDescriptor;
  maxLength?: number;
  messages?: IMessage[];
  multiline?: boolean;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  onInit?: (value: string) => void;
  placeholder?: string | MessageDescriptor;
  required?: boolean;
  type?: string;
}

export interface ITextFieldIntlProps extends ITextFieldProps {
  intl: IntlShape;
}

export interface ITextFieldState {
  value: string;
}

class TextField extends React.Component<ITextFieldIntlProps, ITextFieldState> {
  constructor(props: ITextFieldIntlProps) {
    super(props);

    this.state = {
      value: props.defaultValue
    };
  }

  componentDidMount() {
    const { defaultValue, onInit } = this.props;
    if (onInit) onInit(defaultValue);
  }

  render() {
    const {
      className,
      label,
      messages,
      required,
      title,
      disabled,
      defaultValue
    } = this.props;

    const maxLength = this.getMaxLength();
    const multiline = this.getMultiline();
    const numberOfErrors = this.getNumberOfErrors();
    const placeholder = this.getPlaceholder();
    const type = this.getType();
    const valueLength = this.getValueLength();

    return (
      <div
        className={`
          text-field-control
          ${className}
          ${numberOfErrors ? "error" : ""}
        `}
      >
        {label && <Label label={label} required={required} />}
        {!multiline && (
          <input
            defaultValue={defaultValue}
            maxLength={maxLength}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            placeholder={placeholder}
            type={type}
            title={title}
            disabled={disabled}
          />
        )}
        {multiline && (
          <textarea
          defaultValue={defaultValue}
            maxLength={maxLength}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            placeholder={placeholder}
            disabled={disabled}
          />
        )}
        {(messages || maxLength) && (
          <Summary
            currentLength={valueLength}
            maxLength={maxLength}
            messages={messages}
          />
        )}
      </div>
    );
  }

  private getMaxLength = () =>
    this.getMultiline() || this.getType() === "text"
      ? this.props.maxLength
      : undefined;

  private getMultiline = () => this.props.multiline || false;

  private getNumberOfErrors = () =>
    this.props.messages
      ? this.props.messages.filter((m) => m.type === "error").length
      : 0;

  private getPlaceholder = (): string => {
    const { intl, placeholder } = this.props;
    if (isEmpty(placeholder)) return undefined;
    if (isString(placeholder)) return placeholder;
    return intl.formatMessage(placeholder);
  };

  private getType = () => this.props.type || "text";

  private getValueLength = () =>
    this.state.value ? this.state.value.length : 0;

  private handleBlur = (
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLTextAreaElement>
  ) => {
    const { onBlur } = this.props;
    if (onBlur) onBlur();
  };

  private handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = e.currentTarget.value;
    const { onChange } = this.props;
    this.setState({ value });
    if (onChange) onChange(value);
  };
}

interface ICounterProps {
  currentLength: number;
  maxLength: number;
}

const Counter = ({ currentLength, maxLength }: ICounterProps) => {
  return (
    <div className="text-field-counter">
      {`${currentLength} / ${maxLength}`}
    </div>
  );
};

interface ILabelProps {
  label?: MessageDescriptor;
  required?: boolean;
}

const Label = ({ label, required }: ILabelProps) => {
  return (
    <div className="control-label default-grey-label">
      <FormattedMessage {...label} />
      {required && <FormattedMessage defaultMessage="*" id="global.star" />}
    </div>
  );
};

interface IMessagesProps {
  messages: IMessage[];
}

const Messages = ({ messages }: IMessagesProps) => {
  return (
    <div className="text-field-messages">
      {messages &&
        messages.map((m, i) => (
          <div key={i}>
            <Icon name="error" />
            <span className="text">
              <FormattedMessage {...m.descriptor} />
            </span>
          </div>
        ))}
    </div>
  );
};

interface ISummaryProps {
  currentLength: number;
  maxLength: number;
  messages: IMessage[];
}

const Summary = ({ currentLength, maxLength, messages }: ISummaryProps) => {
  return (
    <div className="text-field-summary">
      {messages ? <Messages messages={messages} /> : <div />}
      {maxLength ? (
        <Counter currentLength={currentLength} maxLength={maxLength} />
      ) : (
        <div />
      )}
    </div>
  );
};

const TextFieldWithIntl = injectIntl(TextField);

export default TextFieldWithIntl;
