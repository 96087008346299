// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IAssetRiskConfigurationValue } from "common/AssetRiskMatrix";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IAjaxAction } from "reducers/Index";
import { Action } from "redux";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";
import IRiskMatrixClusters from "../models/IRiskMatrixClusters";
import IRiskMatrixConfiguration from "../models/IRiskMatrixConfiguration";
import RiskMatrixScope from "../models/RiskMatrixScope";
import getLoadClustersUrl from "../utils/getLoadClustersUrl";

export enum Actions {
  DetailPage_RiskMatrix_SetRiskMatrixConfiguration = "DetailPage_RiskMatrix_SetRiskMatrixConfiguration",
  DetailPage_RiskMatrix_SetModelConfig = "DetailPage_RiskMatrix_SetModelConfig",
  DetailPage_RiskMatrix_SetClusters = "DetailPage_RiskMatrix_SetClusters",
  DetailPage_RiskMatrix_SetIsComponent = "DetailPage_RiskMatrix_SetIsComponent"
}

// #region Actions creators

export const setRiskMatrixConfiguration = (
  request: JQuery.jqXHR,
  data?: IRiskMatrixConfiguration
): IAjaxAction<IRiskMatrixConfiguration> => {
  const action: IAjaxAction<IRiskMatrixConfiguration> = {
    type: Actions.DetailPage_RiskMatrix_SetRiskMatrixConfiguration,
    request,
    data
  };
  return action;
};

export const setModelConfig = (
  request: JQuery.jqXHR,
  data?: IAssetRiskConfigurationValue
): IAjaxAction<IAssetRiskConfigurationValue> => {
  const action: IAjaxAction<IAssetRiskConfigurationValue> = {
    type: Actions.DetailPage_RiskMatrix_SetModelConfig,
    request,
    data
  };
  return action;
};

export const setRiskMatrixClusters = (
  request: JQuery.jqXHR,
  data?: IRiskMatrixClusters
): IAjaxAction<IRiskMatrixClusters> => {
  const action: IAjaxAction<IRiskMatrixClusters> = {
    type: Actions.DetailPage_RiskMatrix_SetClusters,
    request,
    data
  };
  return action;
};

export interface ISetIsComponentAction extends Action<string> {
  isComponent: boolean;
}

export const setIsComponentAction = (
  isComponent: boolean
): ISetIsComponentAction => ({
  type: Actions.DetailPage_RiskMatrix_SetIsComponent,
  isComponent
});

// #endregion Actions creators

// #region Actions

export function loadRiskMatrixConfiguration(
  dispatch: AppDispatch
): Promise<IRiskMatrixConfiguration> {
  return new Promise((resolve, reject) => {
    const url = UrlService.getApiUrl(
      config.api.detailPage.widgets.RiskMatrix.RiskMatrixChart
        .riskMatrixConfigurationUrl
    );

    const request = EndpointService.getJson<IRiskMatrixConfiguration>(
      url,
      (request, data) => {
        dispatch(setRiskMatrixConfiguration(request, data));
        resolve(data);
      },
      (request) => {
        dispatch(setRiskMatrixConfiguration(request));
        reject();
      }
    );

    dispatch(setRiskMatrixConfiguration(request));
  });
}

export function loadAssetRiskConfig(
  dispatch: AppDispatch,
  modelId: string
): Promise<any> {
  return new Promise<void>((resolve, reject) => {
    const url = UrlService.getApiUrl(
      config.api.detailPage.widgets.RiskMatrix.RiskMatrixChart.modelConfigUrl,
      {
        modelId
      }
    );

    const request = EndpointService.getJson<IAssetRiskConfigurationValue>(
      url,
      (request, data) => {
        dispatch(setModelConfig(request, data));
        resolve();
      },
      (request) => {
        dispatch(setModelConfig(request));
        reject();
      }
    );

    dispatch(setModelConfig(request));
  });
}

export function loadClustersAction(
  dispatch: AppDispatch,
  scope: RiskMatrixScope,
  modelId: string,
  organization: string,
  region: string,
  location: string,
  numberOfClusters: number = 20
) {
  return new Promise<void>((resolve, reject) => {
    const url = getLoadClustersUrl(
      scope,
      modelId,
      organization,
      region,
      location,
      numberOfClusters
    );

    const request = EndpointService.getJson<IRiskMatrixClusters>(
      url,
      (request, data) => {
        dispatch(setRiskMatrixClusters(request, data));
        resolve();
      },
      (request) => {
        dispatch(setRiskMatrixClusters(request));
        reject();
      }
    );

    dispatch(setRiskMatrixClusters(request));
  });
}

// #endregion Helpers
