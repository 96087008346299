// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useMemo } from "react";

import { ISwitchItem } from "common/form/components/Switch";
import { DielectricDataSource } from "../../../models/DielectricDataSource";

const useSF6PressureChartDataSourceSwitch = () => {
  const items: ISwitchItem[] = useMemo(
    () => [
      {
        id: DielectricDataSource.CompensatedGasPressure,
        message: {
          defaultMessage: "Compensated Pressure",
          id:
            "detail_page.widgets.circuit_breaker.dielectric.data_source.compensated"
        }
      },
      {
        id: DielectricDataSource.NonCompensatedGasPressure,
        message: {
          defaultMessage: "Pressure",
          id:
            "detail_page.widgets.circuit_breaker.dielectric.data_source.non_compensated"
        }
      }
    ],
    []
  );

  return { items };
};

export default useSF6PressureChartDataSourceSwitch;
