// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { notifications } from "@pg/common/build/components/Notifications";
import DetailPageWidgetContext from "features/detailpage/contexts/DetailPageWidgetContext";
import AssetRibbon from "features/detailpage/features/AssetRibbon";
import Issues from "features/detailpage/features/issues/components/Issues";
import IssuesContainer from "features/detailpage/features/issues/containers/IssuesContainer";
import { PageModes } from "features/detailpage/models/PagesModes";
import DetalPageWidgetContextProvider from "features/detailpage/providers/DetailPageWidgetContextProvider";
import isKinectricsNetworkTransformerModel from "features/detailpage/utils/isKinectricsNetworkTransformerModel";
import React, { useContext } from "react";
import {
  FormattedMessage,
  injectIntl,
  IntlShape,
  MessageDescriptor
} from "react-intl";
import IAssetDetails from "../../../models/IAssetDetails";
import AssetImplementationId from "../models/AssetImplementationId";
import DetailPageWidgets from "./DetailPageWidgets";

interface IDetailPageContentContextProps {
  isParametersListWidgetVisible: boolean;
}

export interface IDetailPageContentOwnProps {
  assetDetails: IAssetDetails;
  allowToCollapse: boolean;
  assetId: string;
  sidePanelExpanded: boolean;
  rightPanelExpanded: boolean;
  expandRightPanel: () => void;
  collapseRightPanel: () => void;
}

export interface IDetailPageContentState {
  pageMode: PageModes;
}

type DetailPageContentProps = IDetailPageContentContextProps &
  IDetailPageContentOwnProps & {
    intl: IntlShape;
  };

class DetailPageContent extends React.PureComponent<
  DetailPageContentProps,
  IDetailPageContentState
> {
  private issueRef: Issues;
  private getIssuesRef = (): Issues => this.issueRef;

  constructor(props: DetailPageContentProps) {
    super(props);

    this.state = {
      pageMode: PageModes.Left4Right0
    };
  }

  public UNSAFE_componentWillMount() {
    this.initDetailPage();
  }

  public UNSAFE_componentWillReceiveProps(nextProps: DetailPageContentProps) {
    if (this.props.assetId !== nextProps.assetId) {
      this.initDetailPage();
    }
  }

  public render() {
    const { leftPanelClassName, rightPanelClassName } =
      this.getLeftRightPanelClassName();

    const implementationId = this.getModelImplementationId()?.toLowerCase();

    return (
      <div
        className={
          "detail-page-new" +
          (this.props.sidePanelExpanded ? " left-panel-expanded" : "")
        }
      >
        <AssetRibbon
          assetId={this.props.assetId}
          sidePanelExpanded={this.props.sidePanelExpanded}
        />
        <div className="panels-container">
          <div
            className={
              "panel-left " +
              leftPanelClassName +
              (this.props.isParametersListWidgetVisible
                ? " minWidthContent"
                : "")
            }
          >
            <DetailPageWidgets
              assetId={this.props.assetId}
              assetType={this.getAssetType()}
              lifecycleStatus={this.getLifecycleStatus()}
              analyticsAvailable={
                implementationId === AssetImplementationId.Analytics
              }
              dcTransformerAvailable={
                implementationId === AssetImplementationId.DcTransformer
              }
              kinectricsNTAvailable={isKinectricsNetworkTransformerModel(
                implementationId
              )}
              batteryAvailable={
                implementationId === AssetImplementationId.Battery
              }
              cbkAvailable={
                implementationId === AssetImplementationId.CircuitBreaker
              }
              modelId={this.getModelId()}
              implementationId={this.getModelImplementationId()}
              replacementScoreModelImplementationId={this.getReplacementScoreModelImplementationId()}
              expanded={this.isChartWidgetsExpanded()}
            />
          </div>
          {this.state.pageMode !== PageModes.Left4Right0 && (
            <div
              className={"panel-right " + rightPanelClassName}
              data-qa="detailpage-action-plan"
            >
              <div className="right-title-panel">
                <Icon
                  className={`
                    expand
                    ${
                      this.state.pageMode === PageModes.Left3Right1
                        ? "left"
                        : "right"
                    }
                  `}
                  dataQa={
                    this.state.pageMode === PageModes.Left3Right1
                      ? "left"
                      : "right"
                  }
                  name="chevron_left"
                  onClick={this.onExpandActionPlanClicked}
                  size="lg"
                />
                <span className="title right-title">
                  <FormattedMessage
                    id="detail_page.widgets.issues_list.title"
                    defaultMessage="Action Plan"
                  />
                </span>
              </div>
              <div className="content">
                <IssuesContainer
                  assetId={this.props.assetId}
                  lifecycleStatus={this.getLifecycleStatus()}
                  ref={(r: Issues) => {
                    this.issueRef = r;
                  }}
                  pageMode={this.state.pageMode}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  public componentDidUpdate(prevProps: DetailPageContentProps) {
    this.rightPanelExpandedDidUpdate(prevProps);
    if (
      prevProps.isParametersListWidgetVisible !==
      this.props.isParametersListWidgetVisible
    ) {
      this.setWidgetsVisibilityToState();
    }
  }

  private rightPanelExpandedDidUpdate(prevProps: DetailPageContentProps) {
    const { rightPanelExpanded } = this.props;
    const { pageMode } = this.state;
    if (
      rightPanelExpanded !== prevProps.rightPanelExpanded &&
      pageMode !== PageModes.Left4Right0
    ) {
      this.changePageMode();
    }
  }

  private onExpandActionPlanClicked = (
    e: React.MouseEvent<HTMLSpanElement>
  ) => {
    const { pageMode } = this.state;

    if (pageMode === PageModes.Left2Right2) {
      this.onPageModeLeft2Right2ExpandActionPlanClicked();
    } else if (pageMode === PageModes.Left3Right1) {
      this.onPageModeLeft3Right1ExpandActionPlanClicked();
    }
  };

  private onPageModeLeft2Right2ExpandActionPlanClicked() {
    const { allowToCollapse, collapseRightPanel } = this.props;
    if (allowToCollapse) {
      this.getIssuesRef().reset();
      collapseRightPanel();
    } else {
      this.showQuestionAboutUnsavedIssue();
    }
  }

  private onPageModeLeft3Right1ExpandActionPlanClicked() {
    const { expandRightPanel } = this.props;
    expandRightPanel();
  }

  private showQuestionAboutUnsavedIssue() {
    const { collapseRightPanel } = this.props;

    const message: MessageDescriptor = {
      defaultMessage:
        "Issue was not saved. Do you want to close the panel and discard changes?",
      id: "detail_page.sliding_panel.issues.unsaved_changes_question"
    };

    notifications.confirm({
      title: this.props.intl.formatMessage(message),
      okText: this.props.intl.formatMessage({
        id: "global.messages.yes",
        defaultMessage: "Yes"
      }),
      cancelText: this.props.intl.formatMessage({
        id: "global.messages.no",
        defaultMessage: "No"
      }),
      onOk: () => {
        this.getIssuesRef().reset();
        collapseRightPanel();
      }
    });
  }

  private getLeftRightPanelClassName(): {
    leftPanelClassName: string;
    rightPanelClassName: string;
  } {
    switch (this.state.pageMode) {
      case PageModes.Left3Right1:
        return {
          leftPanelClassName: "panel-3",
          rightPanelClassName: "panel-1"
        };
      case PageModes.Left2Right2:
        return {
          leftPanelClassName: "panel-2",
          rightPanelClassName: "panel-2"
        };
      case PageModes.Left4Right0:
        return {
          leftPanelClassName: "panel-4",
          rightPanelClassName: "panel-0"
        };
      default:
        return {
          leftPanelClassName: "",
          rightPanelClassName: ""
        };
    }
  }

  private initDetailPage() {
    this.setWidgetsVisibilityToState(PageModes.Left3Right1);
  }

  private changePageMode() {
    const pageMode =
      this.state.pageMode === PageModes.Left2Right2
        ? PageModes.Left3Right1
        : PageModes.Left2Right2;

    this.setState({ pageMode });
  }

  private setWidgetsVisibilityToState(defaultPageMode = this.state.pageMode) {
    const { collapseRightPanel, isParametersListWidgetVisible } = this.props;
    const pageMode = isParametersListWidgetVisible
      ? PageModes.Left4Right0
      : this.getParentId() !== null
      ? PageModes.Left4Right0
      : this.state.pageMode === PageModes.Left4Right0
      ? PageModes.Left3Right1
      : defaultPageMode;

    if (pageMode === PageModes.Left4Right0) {
      collapseRightPanel();
    }

    this.setState({
      pageMode
    });
  }

  private getAssetType(): string {
    const { assetDetails } = this.props;

    let assetType = assetDetails?.NameplateWithModelInfo?.AssetType || null;

    if (
      assetType === undefined ||
      (assetType != null && assetType.trim() === "")
    )
      assetType = null;

    return assetType;
  }

  private isChartWidgetsExpanded(): boolean {
    return (
      (this.state.pageMode === PageModes.Left3Right1 &&
        !this.props.sidePanelExpanded &&
        !this.props.rightPanelExpanded) ||
      this.getParentId() !== null
    );
  }

  private getModelId = () => {
    const { assetDetails } = this.props;
    return assetDetails?.NameplateWithModelInfo?.ModelId || null;
  };

  private getModelImplementationId = () => {
    const { assetDetails } = this.props;
    return assetDetails?.NameplateWithModelInfo?.ModelImplementationId || null;
  };

  private getLifecycleStatus = () => {
    const { assetDetails } = this.props;
    return assetDetails?.NameplateWithModelInfo.LifecycleStatus || null;
  };

  private getParentId = () => {
    const { assetDetails } = this.props;
    return assetDetails?.NameplateWithModelInfo?.ParentId || null;
  };

  private getReplacementScoreModelImplementationId = () => {
    const { assetDetails } = this.props;
    return (
      assetDetails?.NameplateWithModelInfo
        ?.ReplacementScoreModelImplementationId || null
    );
  };
}

const DetailPageContentWithIntl = injectIntl(DetailPageContent);

const DetailPageContextConsumer = (props: IDetailPageContentOwnProps) => {
  const { isParametersListWidgetVisible } = useContext(DetailPageWidgetContext);
  return (
    <DetailPageContentWithIntl
      {...props}
      isParametersListWidgetVisible={isParametersListWidgetVisible}
    />
  );
};

export default (props: IDetailPageContentOwnProps) => (
  <DetalPageWidgetContextProvider>
    <DetailPageContextConsumer {...props} />
  </DetalPageWidgetContextProvider>
);
