// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Popover } from "antd";
import ICompanyHierarchyNode from "models/ICompanyHierarchyNode";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import getCompanyHierarchyName from "../../utils/getCompanyHierarchyName";
import CompanyHierarchyTooltip from "./CompanyHierarchyTooltip";

interface ICompanyConfigurationItemProps {
  companyHierarchyNodes: ICompanyHierarchyNode[];
  assetHierarchyCode: string;
}

const CompanyConfigurationItem = ({
  companyHierarchyNodes,
  assetHierarchyCode
}: ICompanyConfigurationItemProps) => {
  const companyHierarchyName = useMemo(() => {
    return getCompanyHierarchyName(companyHierarchyNodes, assetHierarchyCode);
  }, [companyHierarchyNodes, assetHierarchyCode]);

  return (
    <div className="labeled-row model-configuration">
      <span className="label">
        <FormattedMessage
          id={"asset.nameplate.company_hierarchy_name"}
          defaultMessage="Model Configuration"
        />
      </span>
      {companyHierarchyNodes ? (
        <Popover
          content={
            <CompanyHierarchyTooltip
              companyHierarchyNodes={companyHierarchyNodes}
              assetHierarchyCode={assetHierarchyCode}
            />
          }
        >
          <span className="value">{companyHierarchyName}</span>
        </Popover>
      ) : (
        <span className="value">{companyHierarchyName}</span>
      )}
    </div>
  );
};

export default CompanyConfigurationItem;
