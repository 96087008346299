// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import FilterPanel, {
  FilterPanelContextProvider,
  IItemComponentProps
} from "@pg/common/build/components/FilterPanel";
import Processing from "components/common/Processing";
import { useAppNavigate } from "core/app/components/RouterProvider";
import { getUserSelector } from "core/app/reducers/AppReducer";
import getTenantSelector from "core/selectors/getTenantSelector";
import { Children, ReactNode } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router";
import { useAppSelector } from "store";
import styled from "styled-components";
import {
  colorRiskHigh,
  colorRiskLow,
  colorRiskMedium
} from "styles/ColorVariables";
import useFilterBar from "../hooks/useFilterBar";
import ApiSelectedFilters from "../models/ISelectedFilters";
import UseFilter from "../models/UseFilter";
import translateOptionDisplayName from "../utils/translateOptionDisplayName";

interface IFilterBarOwnProps {
  children?: ReactNode;
  className?: string;
  namespace: string;
  onFilter?: (filters: ApiSelectedFilters) => void;
  useClearAll?: boolean;
  useSearch?: boolean;
  useAssetOrganizationWithRegionAndLocation?: UseFilter;
  useAssetCompanyHierarchy?: UseFilter;
  useAssetTypeWithSubType?: UseFilter;
  useAssetImportance?: UseFilter;
  useAssetImportanceRange?: UseFilter;
  useAssetRisk?: UseFilter;
  useAssetManufacturerWithModel?: UseFilter;
  useAssetVoltage?: UseFilter;
  useAssetAge?: UseFilter;
  useAssetStatus?: UseFilter;
  useDegradationScoreValueRange?: UseFilter;
  useIssueStatus?: UseFilter;
  useAlarmTypes?: UseFilter;
}

const FilterBar = ({
  children,
  className,
  namespace,
  onFilter,
  useClearAll,
  useSearch,
  useAssetOrganizationWithRegionAndLocation,
  useAssetCompanyHierarchy,
  useAssetTypeWithSubType,
  useAssetImportance,
  useAssetImportanceRange,
  useAssetRisk,
  useAssetManufacturerWithModel,
  useAssetVoltage,
  useAssetAge,
  useAssetStatus,
  useDegradationScoreValueRange,
  useIssueStatus,
  useAlarmTypes
}: IFilterBarOwnProps) => {
  const navigate = useAppNavigate();
  const location = useLocation();
  const intl = useIntl();
  const tenant = useAppSelector(getTenantSelector);
  const user = useAppSelector(getUserSelector);
  const { formatPlaceholder, getFilter, getHidden, handleFilter } =
    useFilterBar(onFilter);

  return (
    <FilterPanelContextProvider navigate={navigate} location={location}>
      <FilterPanel
        className={className}
        columnSearchSpans={{
          xs: 3,
          sm: 3,
          md: 3,
          lg: 4,
          xl: 4,
          xxl: 4
        }}
        columnSelectSpans={{
          xs: 3,
          sm: 3,
          md: 3,
          lg: 2,
          xl: 2,
          xxl: 2
        }}
        getFilter={getFilter}
        onFilter={handleFilter}
        shadow
        SpinnerComponent={() => <Processing className="spinner small light" />}
        useClearAll={useClearAll}
        usePresets={{
          namespace: `${tenant}-${user?.data?.ObjectIdentifier}-${namespace}`,
          restoreOnLoad: !!location.state?.filterBar?.restoreOnLoad
        }}
        useSearch={
          useSearch && {
            displayName: intl.formatMessage({
              defaultMessage: "Search",
              id: "filter_panel.filters.search"
            }),
            placeholder: intl.formatMessage({
              defaultMessage: "Search",
              id: "filter_panel.filters.search.placeholder"
            })
          }
        }
        useAssetOrganizationWithRegionAndLocation={
          useAssetOrganizationWithRegionAndLocation && {
            hidden: getHidden(useAssetOrganizationWithRegionAndLocation),
            displayName: intl.formatMessage({
              defaultMessage: "Organization",
              id: "filter_panel.filters.asset_organization_with_region"
            }),
            placeholder: (items) =>
              formatPlaceholder(
                {
                  defaultMessage: "All",
                  id: "filter_panel.filters.asset_organization_with_region.placeholder"
                },
                items
              )
          }
        }
        useCompanyHierarchy={
          useAssetCompanyHierarchy && {
            hidden: getHidden(useAssetCompanyHierarchy),
            displayName: intl.formatMessage({
              defaultMessage: "Company Hierarchy",
              id: "filter_panel.filters.asset_company_hierarchy"
            }),
            placeholder: (items) =>
              formatPlaceholder(
                {
                  defaultMessage: "All",
                  id: "filter_panel.filters.asset_company_hierarchy.placeholder"
                },
                items
              )
          }
        }
        useAssetTypeWithSubType={
          useAssetTypeWithSubType && {
            hidden: getHidden(useAssetTypeWithSubType),
            displayName: intl.formatMessage({
              defaultMessage: "Asset Type",
              id: "filter_panel.filters.asset_type"
            }),
            placeholder: (items) =>
              formatPlaceholder(
                {
                  defaultMessage: "All",
                  id: "filter_panel.filters.asset_type.placeholder"
                },
                items
              )
          }
        }
        useAssetImportance={
          useAssetImportance && {
            hidden: getHidden(useAssetImportance),
            displayName: intl.formatMessage({
              defaultMessage: "Importance",
              id: "filter_panel.filters.asset_importance"
            }),
            placeholder: (items) =>
              formatPlaceholder(
                {
                  defaultMessage: "All",
                  id: "filter_panel.filters.asset_importance.placeholder"
                },
                items
              )
          }
        }
        useAssetImportanceRange={
          useAssetImportanceRange && {
            hidden: getHidden(useAssetImportanceRange),
            displayName: intl.formatMessage({
              defaultMessage: "Importance",
              id: "filter_panel.filters.asset_importance"
            })
          }
        }
        useAssetRisk={
          useAssetRisk && {
            hidden: getHidden(useAssetRisk),
            displayName: intl.formatMessage({
              defaultMessage: "Risk",
              id: "filter_panel.filters.asset_risk"
            }),
            placeholder: (items) =>
              formatPlaceholder(
                {
                  defaultMessage: "All",
                  id: "filter_panel.filters.asset_risk.placeholder"
                },
                items
              ),
            ItemComponent: AssetRiskItem
          }
        }
        useAssetManufacturerWithModel={
          useAssetManufacturerWithModel && {
            hidden: getHidden(useAssetManufacturerWithModel),
            displayName: intl.formatMessage({
              defaultMessage: "Manufacturer",
              id: "filter_panel.filters.asset_manufacturer_model"
            }),
            placeholder: (items) =>
              formatPlaceholder(
                {
                  defaultMessage: "All",
                  id: "filter_panel.filters.asset_manufacturer_model.placeholder"
                },
                items
              )
          }
        }
        useAssetVoltage={
          useAssetVoltage && {
            hidden: getHidden(useAssetVoltage),
            displayName: intl.formatMessage({
              defaultMessage: "Voltage Class",
              id: "filter_panel.filters.asset_voltage"
            }),
            placeholder: (items) =>
              formatPlaceholder(
                {
                  defaultMessage: "All",
                  id: "filter_panel.filters.asset_voltage.placeholder"
                },
                items
              )
          }
        }
        useAssetAge={
          useAssetAge && {
            hidden: getHidden(useAssetAge),
            displayName: intl.formatMessage({
              defaultMessage: "Age",
              id: "filter_panel.filters.asset_age"
            }),
            placeholder: (items) =>
              formatPlaceholder(
                {
                  defaultMessage: "All",
                  id: "filter_panel.filters.asset_age.placeholder"
                },
                items
              )
          }
        }
        useDegradationScoreValueRange={
          useDegradationScoreValueRange && {
            hidden: getHidden(useDegradationScoreValueRange),
            displayName: intl.formatMessage({
              defaultMessage: "Condition",
              id: "filter_panel.filters.degradation_score"
            })
          }
        }
        useAssetStatus={
          useAssetStatus && {
            hidden: getHidden(useAssetStatus),
            displayName: intl.formatMessage({
              defaultMessage: "Asset Status",
              id: "filter_panel.filters.asset_status"
            }),
            placeholder: (items) =>
              formatPlaceholder(
                {
                  defaultMessage: "All",
                  id: "filter_panel.filters.asset_status.placeholder"
                },
                items
              )
          }
        }
        useIssueStatus={
          useIssueStatus && {
            hidden: getHidden(useIssueStatus),
            displayName: intl.formatMessage({
              defaultMessage: "Issue Status",
              id: "filter_panel.filters.issue_status"
            }),
            placeholder: (items) =>
              formatPlaceholder(
                {
                  defaultMessage: "All",
                  id: "filter_panel.filters.issue_status.placeholder"
                },
                items
              )
          }
        }
        useAlarmTypes={
          useAlarmTypes && {
            hidden: getHidden(useAlarmTypes),
            displayName: intl.formatMessage({
              defaultMessage: "Alarm Type",
              id: "filter_panel.filters.alarm_type"
            }),
            placeholder: (items) =>
              formatPlaceholder(
                {
                  defaultMessage: "All",
                  id: "filter_panel.filters.alarm_type.placeholder"
                },
                items
              )
          }
        }
        variant="light"
        translations={{
          cancelButton: intl.formatMessage({
            defaultMessage: "Cancel",
            id: "filter_panel.filters.select.cancel_button"
          }),
          clearSelection: intl.formatMessage({
            defaultMessage: "Clear Filter",
            id: "filter_panel.clear_filter"
          }),
          gettingFilterFailed: intl.formatMessage({
            defaultMessage: "Getting filter data failed",
            id: "filter_panel.getting_filter_data.failed"
          }),
          gettingFilterTryAgain: intl.formatMessage({
            defaultMessage: "Try again",
            id: "filter_panel.getting_filter_data.try_again"
          }),
          noOptionsSelected: intl.formatMessage({
            defaultMessage: "No Filters Applied",
            id: "filter_panel.no_filters_applied"
          }),
          okButton: intl.formatMessage({
            defaultMessage: "Submit",
            id: "filter_panel.filters.select.ok_button"
          }),
          selectedItems: (numberOfSelectedItems: number) =>
            intl.formatMessage(
              {
                defaultMessage:
                  "{numberOfSelectedItems, plural, zero {No selected items} one {Selected # item} other {Selected # items}}",
                id: "filter_panel.filters.select.tooltip.selected_items"
              },
              { numberOfSelectedItems }
            ),
          showMore: intl.formatMessage({
            defaultMessage: "Show more",
            id: "filter_panel.filters.select.show_more"
          }),
          unknownValue: intl.formatMessage({
            defaultMessage: "Unknown",
            id: "filter_panel.filters.select.unknown"
          }),
          filterOptionDisplayName: (
            filterName,
            optionId,
            optionDisplayName,
            level
          ) =>
            translateOptionDisplayName(
              filterName,
              optionId,
              optionDisplayName,
              level,
              intl
            ),
          applyOnClickAway: intl.formatMessage({
            defaultMessage: "Apply filter on click away",
            id: "filter_panel.filters.select.apply_on_click_away"
          }),
          applyOnClickAwayDescription: intl.formatMessage({
            defaultMessage:
              "Confirm filter selection changes when clicking outside of the open combo box",
            id: "filter_panel.filters.select.apply_on_click_away.description"
          }),
          presetLabel: intl.formatMessage({
            defaultMessage: "Filter Preset",
            id: "filter_panel.presets.preset"
          }),
          presetCustom: intl.formatMessage({
            defaultMessage: "Custom",
            id: "filter_panel.presets.custom"
          }),
          noPresets: intl.formatMessage({
            defaultMessage: "No presets",
            id: "filter_panel.presets.no_data"
          }),
          addPreset: intl.formatMessage({
            defaultMessage: "Add New",
            id: "filter_panel.presets.add"
          }),
          clearAll: intl.formatMessage({
            defaultMessage: "Clear filters",
            id: "filter_panel.clear_filters"
          }),
          presetNamePlaceholder: intl.formatMessage({
            defaultMessage: "Preset name",
            id: "filter_panel.preset_name"
          }),
          presetSaveTooltip: intl.formatMessage({
            defaultMessage: "Update Preset with current filter selection",
            id: "filter_panel.preset_save_tooltip"
          }),
          loading: intl.formatMessage({
            defaultMessage: "Loading...",
            id: "filter_panel.filters.select.loading"
          })
        }}
      />
      {Children.toArray(children)}
    </FilterPanelContextProvider>
  );
};

const AssetRiskItem = ({ id, children }: IItemComponentProps) => (
  <span
    style={{
      color:
        id === "Low"
          ? colorRiskLow
          : id === "Medium"
          ? colorRiskMedium
          : id === "High"
          ? colorRiskHigh
          : "inherit"
    }}
  >
    {children}
  </span>
);

export default styled(FilterBar)`
  &.filter-panel {
    z-index: 1001;
  }
`;
