// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import moment from "moment";
import * as React from "react";
import { FormattedMessage, FormattedDate } from "react-intl";

import ToggleInputContainer from "common/form/containers/ToggleInputContainer";
import TooltipWrapper, { Themes } from "common/tooltipwrapper/TooltipWrapper";
import IWorkOrder from "features/detailpage/models/IWorkOrder";
import { actionsModalFormName } from "./ActionsModal";

export const getWorkOrderInputName = (workOrder: IWorkOrder) =>
  `selected-wo-${workOrder.WorkOrderIdentifier.WorkOrderDistrictCode}-${workOrder.WorkOrderIdentifier.WorkOrderNumber}`;

interface IActionsModalWorkOrderProps {
  defaultSelected: boolean;
  workOrder: IWorkOrder;
}

const ActionsModalWorkOrder = ({
  defaultSelected,
  workOrder
}: IActionsModalWorkOrderProps) => {
  const maxLength = 200;

  const longSummary = workOrder.Summary;

  const shortSummary =
    longSummary.length > maxLength
      ? `${longSummary.substr(0, maxLength)} ...`
      : longSummary;

  return (
    <div className="work-order">
      <ToggleInputContainer
        className="toggle"
        defaultValue={defaultSelected}
        formName={actionsModalFormName}
        inputName={getWorkOrderInputName(workOrder)}
      />
      <div className="summary">
        {shortSummary === longSummary && longSummary}
        {shortSummary !== longSummary && (
          <TooltipWrapper
            Tooltip={() => (
              <div className="action-description-tooltip">{longSummary}</div>
            )}
            theme={Themes.Dark}
          >
            <div>{shortSummary}</div>
          </TooltipWrapper>
        )}
      </div>
      <div className="details">
        <div className="status">
          <span className="default-grey-label details-label">
            <FormattedMessage
              defaultMessage="Status:"
              id="detail_page.issues.actions.modal.new_request.form.status"
            />
          </span>
          <FormattedMessage
            defaultMessage={workOrder.Status}
            id={`issue.action.status.${workOrder.Status}`}
          />
        </div>
        <div className="scheduled-date">
          <span className="default-grey-label details-label">
            <FormattedMessage
              defaultMessage="Scheduled date:"
              id="detail_page.issues.actions.modal.new_request.form.scheduled_date"
            />
          </span>
          {workOrder.DueDate ? (
            <FormattedDate value={moment(workOrder.DueDate).local().toDate()} />
          ) : (
            <span data-qa="scheduled-date-dash">-</span>
          )}
        </div>
        <div className="order-number">
          <span className="default-grey-label details-label">
            <FormattedMessage
              defaultMessage="Number:"
              id="detail_page.issues.actions.modal.new_request.form.order_number.label"
            />
          </span>
          <span>
            <a
              href={workOrder.ExternalLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FormattedMessage
                defaultMessage="{districtCode}/{workOrderNumber}"
                id="detail_page.issues.actions.modal.new_request.form.order_number"
                values={{
                  districtCode:
                    workOrder.WorkOrderIdentifier.WorkOrderDistrictCode,
                  workOrderNumber: workOrder.WorkOrderIdentifier.WorkOrderNumber
                }}
              />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ActionsModalWorkOrder;
