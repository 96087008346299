// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";
import {
  useFeature,
  VisualizationKeys
} from "core/app/components/FeatureToggle";
import { LayoutRoute } from "core/app/components/RouterProvider";
import AgingTab from "features/detailpage/features/aging/components/AgingTab";
import AuxiliariesTab from "features/detailpage/features/AuxiliariesTab";
import DielectricSF6Tab from "features/detailpage/features/dielectric/components/DielectricSF6Tab";
import DielectricTab from "features/detailpage/features/dielectric/components/DielectricTab";
import LogTab from "features/detailpage/features/LogTab";
import MechanicalTab from "features/detailpage/features/MechanicalTab";
import OperationsTab from "features/detailpage/features/OperationsTab";
import WearTab from "features/detailpage/features/WearTab";
import LifecycleStatus from "models/LifecycleStatus";
import { useMemo } from "react";
import { useIntl } from "react-intl";

interface IUseCircuitBreakerRoutesProps {
  assetId: string;
  implementationId: string;
  lifecycleStatus: LifecycleStatus;
  modelId: string;
}

const useCircuitBreakerRoutes = ({
  assetId,
  implementationId,
  lifecycleStatus,
  modelId
}: IUseCircuitBreakerRoutesProps) => {
  const { isAvailable } = useFeature();
  const intl = useIntl();
  const model = useMemo(
    () => ({ modelId, implementationId }),
    [implementationId, modelId]
  );
  const searchParams = useMemo(() => new SearchParams({ assetId }), [assetId]);

  const routes = useMemo<LayoutRoute[]>(() => {
    return [
      isAvailable(VisualizationKeys.AssetDetailPage_Tabs_Parameters_Aging)
        ? {
            id: "Assets-DetailPage-Parameters-Aging",
            route: "Aging/*",
            displayName: intl.formatMessage({
              defaultMessage: "Aging",
              id: "detail_page.widgets.circuit_breaker.aging.tab_title"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/Aging",
              search: searchParams.toString()
            },
            element: <AgingTab assetId={assetId} />
          }
        : undefined,
      isAvailable(VisualizationKeys.AssetDetailPage_Tabs_Parameters_Dielectric)
        ? {
            id: "Assets-DetailPage-Parameters-Dielectric",
            route: "Dielectric/*",
            displayName: intl.formatMessage({
              defaultMessage: "Dielectric",
              id: "detail_page.widgets.circuit_breaker.dielectric.tab_title"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/Dielectric",
              search: searchParams.toString()
            },
            element: <DielectricTab assetId={assetId} model={model} />
          }
        : undefined,
      isAvailable(
        VisualizationKeys.AssetDetailPage_Tabs_Parameters_Dielectric_Parameters
      )
        ? {
            id: "Assets-DetailPage-Parameters-DielectricParameters",
            route: "DielectricParameters/*",
            displayName: intl.formatMessage({
              defaultMessage: "Dielectric",
              id: "detail_page.widgets.circuit_breaker.dielectric_parameters.tab_title"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/DielectricParameters",
              search: searchParams.toString()
            },
            element: (
              <DielectricSF6Tab
                assetId={assetId}
                lifecycleStatus={lifecycleStatus}
                model={model}
              />
            )
          }
        : undefined,
      isAvailable(VisualizationKeys.AssetDetailPage_Tabs_Parameters_Mechanical)
        ? {
            id: "Assets-DetailPage-Parameters-Mechanical",
            route: "Mechanical/*",
            displayName: intl.formatMessage({
              defaultMessage: "Mechanical",
              id: "detail_page.widgets.circuit_breaker.mechanical.tab_title"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/Mechanical",
              search: searchParams.toString()
            },
            element: (
              <MechanicalTab
                assetId={assetId}
                lifecycleStatus={lifecycleStatus}
              />
            )
          }
        : undefined,
      isAvailable(VisualizationKeys.AssetDetailPage_Tabs_Parameters_Wear)
        ? {
            id: "Assets-DetailPage-Parameters-Wear",
            route: "Wear/*",
            displayName: intl.formatMessage({
              defaultMessage: "Wear",
              id: "detail_page.widgets.circuit_breaker.wear.tab_title"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/Wear",
              search: searchParams.toString()
            },
            element: (
              <WearTab assetId={assetId} lifecycleStatus={lifecycleStatus} />
            )
          }
        : undefined,
      isAvailable(VisualizationKeys.AssetDetailPage_Tabs_Parameters_Auxiliaries)
        ? {
            id: "Assets-DetailPage-Parameters-Auxiliaries",
            route: "Auxiliaries/*",
            displayName: intl.formatMessage({
              defaultMessage: "Auxiliaries",
              id: "detail_page.widgets.circuit_breaker.auxiliaries.tab_title"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/Auxiliaries",
              search: searchParams.toString()
            },
            element: (
              <AuxiliariesTab
                assetId={assetId}
                lifecycleStatus={lifecycleStatus}
              />
            )
          }
        : undefined,
      isAvailable(VisualizationKeys.AssetDetailPage_Tabs_Parameters_Operations)
        ? {
            id: "Assets-DetailPage-Parameters-Operations",
            route: "Operations/*",
            displayName: intl.formatMessage({
              defaultMessage: "Operations",
              id: "detail_page.widgets.circuit_breaker.operations.tab_title"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/Operations",
              search: searchParams.toString()
            },
            element: <OperationsTab assetId={assetId} />
          }
        : undefined,
      isAvailable(VisualizationKeys.AssetDetailPage_Tabs_Parameters_Log)
        ? {
            id: "Assets-DetailPage-Parameters-Log",
            route: "Log/*",
            displayName: intl.formatMessage({
              defaultMessage: "Log",
              id: "detail_page.widgets.circuit_breaker.log.tab_title"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/Log",
              search: searchParams.toString()
            },
            element: <LogTab assetId={assetId} />
          }
        : undefined
    ].filter(Boolean);
  }, [assetId, intl, isAvailable, lifecycleStatus, model, searchParams]);

  return routes;
};

export default useCircuitBreakerRoutes;
