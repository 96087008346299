// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { TooltipPlacement } from "antd/lib/tooltip/index";
import Popover from "common/popover/Popover";
import {
  loadImportanceScoreFamilyInfoAction,
  resetFamilyInfoAction
} from "features/detailpage/features/familyinfo/actions/FamilyInfoActions";
import FamilyInfoContainer from "features/detailpage/features/familyinfo/containers/FamilyInfoContainer";
import { useCallback } from "react";
import { useAppDispatch } from "store";

interface IFamilyInfoImportanceScoreIconProps {
  assetId: string;
  stopPropagation?: boolean;
  placement?: TooltipPlacement;
}

const ImportanceScoreFamilyIcon = ({
  assetId,
  stopPropagation,
  placement
}: IFamilyInfoImportanceScoreIconProps) => {
  const dispatch = useAppDispatch();

  const handleShow = useCallback(() => {
    dispatch(loadImportanceScoreFamilyInfoAction(assetId));
  }, [assetId, dispatch]);

  const handleHide = useCallback(() => {
    dispatch(resetFamilyInfoAction());
  }, [dispatch]);

  return (
    <Popover
      content={<FamilyInfoContainer assetId={assetId} />}
      onShow={handleShow}
      onHide={handleHide}
      overlayClassName="family-info-importance-popover family-info-popover"
      stopPropagation={stopPropagation}
      placement={placement}
    >
      <span>
        <Icon
          className="family-info-popover-icon"
          dataQa="header-importance-family-icon"
          name="speed"
          size="sm"
        />
      </span>
    </Popover>
  );
};

export default ImportanceScoreFamilyIcon;
