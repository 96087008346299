// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { IntlShape } from "react-intl";

import IPrognosticPoint from "common/AssetRiskMatrix/models/IPrognosticPoint";
import { DegradationScoreService } from "common/formatters/DegradationScore";

interface IPrognosticRiskMatrixTooltip {
  intl: IntlShape;
  prognostic: IPrognosticPoint;
}

const PrognosticRiskMatrixTooltip = ({
  intl,
  prognostic: { Date, Importance, Score }
}: IPrognosticRiskMatrixTooltip) => {
  return (
    <table className="c3-tooltip prognostic-point">
      <tr>
        <th colSpan={2}>
          {intl.formatMessage(
            {
              id: "asset_risk_chart.prognostic.label",
              defaultMessage: "Prognosis for {date}"
            },
            {
              date: intl.formatDate(Date)
            }
          )}
        </th>
      </tr>
      <tr className="c3-tooltip-name-importance">
        <td className="name">
          {intl.formatMessage({
            id: "asset_risk_chart.y_axis.label",
            defaultMessage: "Importance"
          })}
        </td>
        <td className="value">{intl.formatNumber(Importance)}</td>
      </tr>
      <tr className="c3-tooltip-name-score">
        <td className="name">
          {intl.formatMessage({
            id: "asset_risk_chart.x_axis.label",
            defaultMessage: "Condition"
          })}
        </td>
        <td className="value">{DegradationScoreService.format(Score, intl)}</td>
      </tr>
    </table>
  );
};

export default PrognosticRiskMatrixTooltip;
