// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useState } from "react";

import { SelectedFilters } from "common/FilterBar";
import useSlidePanel from "./useSlidePanel";

const useIssues = () => {
  const [filters, setFilters] = useState<SelectedFilters>();

  const handleFilter = (filters: SelectedFilters) => {
    setFilters(filters);
  };

  return {
    filters,
    handleFilter,
    ...useSlidePanel()
  };
};

export default useIssues;
