// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Route, Routes } from "react-router";
import ILayoutRoute from "../models/ILayoutRoute";
import Navigate from "./Navigate";
import RouteNotFound from "./RouteNotFound";

interface IRouterSwitchProps {
  defaultRoute: {
    from?: string;
    route: ILayoutRoute;
  };
  routes: ILayoutRoute[];
  redirects?: {
    path: string;
    to: string;
  }[];
}

const RouterSwitch = ({
  defaultRoute,
  redirects,
  routes
}: IRouterSwitchProps) => {
  return (
    <Routes>
      {routes.map((r, i) => (
        <Route key={i} element={r.element} path={r.route} />
      ))}
      {redirects?.map((r, i) => (
        <Route key={i} element={<Navigate to={r.to} replace />} path={r.path} />
      ))}
      {defaultRoute?.route.to && (
        <Route
          element={<Navigate to={defaultRoute.route.to} replace />}
          path={defaultRoute.from || "/*"}
        />
      )}
      <Route element={<RouteNotFound />} path="/*" />
    </Routes>
  );
};

export default RouterSwitch;
