// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import loadData from "core/data/actions/loadData";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";
import ChartNames from "../models/ChartNames";

const chartUrls: { [name: string]: string } = {
  RollingAverageOfVectorLength:
    config.api.detailPage.dcRollingAverageOfVectorLengthUrl,
  MeasuredApparentCapacitance:
    config.api.detailPage.dcMeasuredApparentCapacitanceUrl,
  OilTemperature: config.api.detailPage.dcOilTemperatureUrl,
  OLTCOilLevelAndTemperature:
    config.api.detailPage.dcOLTCOilLevelAndTemperatureUrl,
  CircleTemperature: config.api.detailPage.dcCircleTemperatureUrl,
  CircleBushing: config.api.detailPage.dcCircleBushingUrl,
  CircleOltcOilLevel: config.api.detailPage.dcCircleOltcOilLevelUrl,
  CoreEarthingCurrent: config.api.detailPage.dcCoreEarthingCurrentUrl,
  CircleCoreEarthingCurrent:
    config.api.detailPage.dcCircleCoreEarthingCurrentUrl
};

export const getDataKey = (assetId: string, chartName: ChartNames) =>
  `DetailPage_Parameters_DC-${assetId}-${chartName}`;

const loadChart = (assetId: string, chartName: ChartNames) => {
  const url = UrlService.getApiUrl(chartUrls[chartName], {
    assetId
  });

  return loadData({ key: getDataKey(assetId, chartName), url, method: "get" });
};

export default loadChart;
