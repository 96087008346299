// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Statuses } from "core/data/models/Data";
import { loadOperationsAction } from "features/detailpage/features/aging/actions/AgingOperationsActions";
import AgingOperations from "features/detailpage/features/aging/components/AgingOperations";
import useAgingOperations from "features/detailpage/features/aging/hooks/useAgingOperations";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useAppDispatch } from "store";

interface IAgingTotalOperationsContainerProps {
  assetId: string;
}

export default ({ assetId }: IAgingTotalOperationsContainerProps) => {
  const dispatch = useAppDispatch();
  const { agingOperations } = useAgingOperations();

  useEffect(() => {
    if (
      agingOperations.status !== Statuses.Succeeded ||
      isEmpty(agingOperations.data)
    ) {
      dispatch(loadOperationsAction(assetId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AgingOperations agingOperations={agingOperations} />;
};
