// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Action } from "redux";

import Data from "core/data/models/Data";
import { IAjaxAction } from "reducers/Index";
import { Actions } from "../actions/BushingVisualizationActions";
import BushingVisualizationReducer from "../models/BushingVisualizationReducer";
import IOnlineBushingData from "../models/IOnlineBushingData";
import IState from "../models/IState";

export const defaultState: IState = {
  onlineBushingData: new Data()
};

const reducer: BushingVisualizationReducer = (
  state = defaultState,
  action: Action
) => {
  switch (action.type) {
    case Actions.DetailPage_BushingVisualization_SetOnlineBushingData: {
      return {
        ...state,
        onlineBushingData: new Data(
          (action as IAjaxAction<IOnlineBushingData>).request,
          (action as IAjaxAction<IOnlineBushingData>).data
        )
      };
    }
    default:
      return state;
  }
};

export default reducer;
