// Copyright 2016-2023 Hitachi Energy. All rights reserved.

interface IDuvalPentagonLegendProps {
  type: number;
}

const createDuvalPentagonLegend = ({ type }: IDuvalPentagonLegendProps) => {
  let labels: { [name: string]: string } = {};
  switch (type) {
    case 1:
      labels["PD"] = "Corona partial discharges";
      labels["S"] = "Stray Gassing of mineral oil; Temp <200\u2103";
      labels["T1"] = "Thermal faults; Temp <300\u2103";
      labels["T2"] = "Thermal faults; Temp >300\u2103 & <700\u2103";
      labels["T3"] = "Thermal faults; Temp >700\u2103";
      labels["D1"] = "Electrical discharges of Low energy";
      labels["D2"] = "Electrical discharges of High energy";
      return labels;
    case 2:
      labels["PD"] = "Corona partial discharges";
      labels["S"] = "Stray Gassing of mineral oil; Temp <200\u2103";
      labels["O"] = "Overheating; Temp <250\u2103";
      labels["C"] = "Hot spots with carbonization of paper; Temp >300\u2103";
      labels["T3-H"] = "High temperature faults occurring in oil only";
      labels["D1"] = "Electrical discharges of Low energy";
      labels["D2"] = "Electrical discharges of High energy";
      return labels;
    case 3:
      labels["PD"] = "Corona partial discharges";
      labels["S"] = "Stray Gassing of mineral oil";
      labels["T1-O"] = "Low temperature faults without paper carbonization";
      labels["T1-C"] = "Low temperature faults with likely paper carbonization";
      labels["T2-C"] =
        "Medium temperature faults with likely paper carbonization";
      labels["T2-O"] = "Medium temperature faults without paper carbonization";
      labels["T3-C"] =
        "High temperature faults with likely paper carbonization";
      labels["T3-H"] = "High temperature faults without paper carbonization";
      labels["D1"] = "Electrical discharges of Low energy";
      labels["D2"] = "Electrical discharges of High energy";
      return labels;
    default:
      return labels;
  }
};

export default createDuvalPentagonLegend;
