// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";
import { IState } from "reducers/Index";

const getAllAssetTypesCheckedSelector = createSelector(
  (state: IState) => state.notifications.allAssetTypesChecked,
  allAssetTypesChecked => allAssetTypesChecked
);

export default getAllAssetTypesCheckedSelector;
