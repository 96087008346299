// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import loadData from "core/data/actions/loadData";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";
import dataKey from "../constants/configurationDataKey";

const loadConfigurationGridData = (
  modelId: string,
  configurationName: string
) => {
  const url = UrlService.getApiUrl(
    config.api.detailPage.modelConfigurationUrl,
    [
      {
        name: "modelId",
        value: modelId
      },
      {
        name: "configurationName",
        value: configurationName
      }
    ]
  );

  return loadData({ key: dataKey(modelId, configurationName), url });
};

export default loadConfigurationGridData;
