// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Button, Typography } from "antd";
import { IAssetRiskConfigurationValue } from "common/AssetRiskMatrix";
import Container from "common/Container";
import FormContainer from "common/form/containers/FormContainer";
import IForm from "common/form/models/IForm";
import RecalculateButton from "common/RecalculateButton";
import Message, { types as msgTypes } from "components/common/Message";
import ModalWindow from "components/common/modal/ModalWindow";
import Processing from "components/common/Processing";
import ProcessingModal from "components/common/ProcessingModal";
import { Statuses } from "core/data/models/Data";
import AssetRiskConfiguration from "features/dataintegration/AssetRiskConfiguration";
import ModelData from "features/dataintegration/ModelData";
import ModelParametersMapping from "features/dataintegration/ModelParametersMapping";
import { isNil } from "lodash";
import React, { createRef, RefObject } from "react";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import modalWindowStore from "store/common/modal/ModalWindowStore";
import DataIntegrationStore, {
  IConfigurableModel,
  IModelPossibleValues,
  ModelMode,
  ModelType
} from "store/dashboards/dataintegration/DataIntegrationStore";
import { IData } from "utils/WebService";
import "./ModelConfig.less";
import XlsxDownloader from "./XlsxDownloader";

const { Title } = Typography;
const modalId = "DATA_INTEGRATION_XLSX_DOWNLOAD_MODAL";

interface IModelConfigProps {
  intl: IntlShape;
}

interface IModelConfigState {
  modelPossibleValues: IData<IModelPossibleValues>;
  selectedModel: IConfigurableModel;
  sources: IData<string[]>;
}

class ModelConfig extends React.Component<
  IModelConfigProps,
  IModelConfigState
> {
  private formRef: RefObject<HTMLFormElement>;

  constructor(props: IModelConfigProps) {
    super(props);

    this.formRef = createRef<HTMLFormElement>();

    this.state = {
      selectedModel: null,
      modelPossibleValues: {
        status: null,
        data: null,
        message: null
      },
      sources: {
        status: null,
        data: null,
        message: null
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.getModelConfigComponent = this.getModelConfigComponent.bind(this);
  }

  public render() {
    let component: JSX.Element = null;

    if (
      (this.state.selectedModel &&
        this.state.selectedModel.ConfigurableModelConfig.status ===
          Statuses.Loading) ||
      this.state.modelPossibleValues.status === Statuses.Loading
    ) {
      component = ModelConfig.getLoadingComponent();
    } else if (
      this.state.selectedModel &&
      this.state.selectedModel.ConfigurableModelConfig.status ===
        Statuses.Succeeded &&
      this.state.modelPossibleValues.status === Statuses.Succeeded
    ) {
      component = this.getModelConfigComponent();
    } else if (
      (this.state.selectedModel &&
        this.state.selectedModel.ConfigurableModelConfig.status ===
          Statuses.Failed) ||
      this.state.modelPossibleValues.status === Statuses.Failed
    ) {
      component = ModelConfig.getErrorComponent();
    }

    return component;
  }

  public componentDidMount() {
    DataIntegrationStore.addChangeListener(this.handleChange);
    DataIntegrationStore.loadSources();
  }

  public componentWillUnmount() {
    DataIntegrationStore.removeChangeListener(this.handleChange);
  }

  public handleOpenModalClick(e: React.MouseEvent<HTMLInputElement>) {
    e.preventDefault();
    modalWindowStore.openModal(modalId);
  }

  private handleChange(): void {
    this.setState((prevState, props) => {
      return Object.assign({}, prevState, {
        modelPossibleValues: DataIntegrationStore.getModelPossibleValues(),
        selectedModel: DataIntegrationStore.getSelectedModel(),
        sources: DataIntegrationStore.getSources()
      });
    });
  }

  private static getLoadingComponent() {
    return (
      <div className="model-config">
        <Container>
          <Processing />
        </Container>
      </div>
    );
  }

  private getModelConfigComponent(): JSX.Element {
    const { intl } = this.props;
    const modelName = this.state.selectedModel.ModelId;
    const metaData =
      this.state.selectedModel.ConfigurableModelConfig.data.Metadata;
    const showButtons =
      this.state.selectedModel &&
      (this.state.selectedModel._Mode === ModelMode.Add ||
        this.state.selectedModel._Mode === ModelMode.Edit);
    const assetRiskCfg: IAssetRiskConfigurationValue = {
      LowToMediumRiskLineThreshold:
        this.state.selectedModel.ConfigurableModelConfig.data
          .LowToMediumRiskLineThreshold,
      MediumToHighRiskLineThreshold:
        this.state.selectedModel.ConfigurableModelConfig.data
          .MediumToHighRiskLineThreshold
    };

    return (
      <div className="model-config" key={this.state.selectedModel.ModelId}>
        <FormContainer
          name="model-data"
          showActionButtons={false}
          onSubmit={this.handleOnSubmit}
          formRef={this.formRef}
        >
          <div className="model-config-header">
            <div
              className="model-config-header-name"
              data-qa="model-config-header-name"
            >
              <Title level={4}>{modelName}</Title>
              <Title level={5}>
                {isNil(metaData) ||
                isNil(metaData.Revision) ||
                metaData.Revision === "" ? null : (
                  <FormattedMessage
                    id="data_integration.model_config.model_revision"
                    defaultMessage="Model revision: {version}"
                    values={{
                      version: metaData.Revision
                    }}
                  />
                )}
              </Title>
            </div>
            <div className="header-buttons" data-qa="header-buttons">
              {showButtons ? (
                <div>
                  <div className="button" data-qa="save-button">
                    <Button
                      type="primary"
                      disabled={
                        !DataIntegrationStore.isModelValid(
                          this.state.selectedModel
                        )
                      }
                      htmlType="submit"
                    >
                      {this.props.intl.formatMessage({
                        id: "data_integration.model_config.save",
                        defaultMessage: "Save"
                      })}
                    </Button>
                  </div>
                  <div className="button" data-qa="cancel-button">
                    <Button
                      type="default"
                      onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                        e.preventDefault();
                        DataIntegrationStore.cancelEditing();

                        if (this.formRef?.current?.getWrappedInstance)
                          this.formRef.current.getWrappedInstance().cancel();
                      }}
                    >
                      {this.props.intl.formatMessage({
                        id: "data_integration.model_config.cancel",
                        defaultMessage: "Cancel"
                      })}
                    </Button>
                  </div>
                </div>
              ) : null}
              {this.state.selectedModel._Mode !== ModelMode.Add ? (
                <div>
                  <div className="button" data-qa="recalculate-button">
                    <RecalculateButton
                      modelId={this.state.selectedModel.ModelId}
                      type="default"
                      disabled={
                        this.state.selectedModel._Collector.status ===
                          Statuses.Loading ||
                        this.state.selectedModel._Mode === ModelMode.Edit
                      }
                    />
                  </div>
                  <div className="button" data-qa="export-to-excel-button">
                    <Button
                      type="default"
                      onClick={this.handleOpenModalClick}
                      disabled={
                        this.state.selectedModel._Collector.status ===
                          Statuses.Loading ||
                        this.state.selectedModel._Mode === ModelMode.Edit
                      }
                    >
                      {this.props.intl.formatMessage({
                        id: "data_integration.model_config.generate_template",
                        defaultMessage: "Generate Template"
                      })}
                    </Button>
                  </div>

                  {this.state.selectedModel.ModelType !== ModelType.Standard ? (
                    <div className="button" data-qa="delete-button">
                      <Button
                        type="default"
                        onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                          e.preventDefault();
                          DataIntegrationStore.dropModel(intl);
                        }}
                      >
                        {this.props.intl.formatMessage({
                          id: "data_integration.model_config.delete",
                          defaultMessage: "Delete"
                        })}
                      </Button>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
          <div className="scroll-wrapper">
            {this.state.selectedModel.ModelType !== ModelType.Standard ? (
              <ModelData model={this.state.selectedModel} />
            ) : null}
            <AssetRiskConfiguration
              modelId={this.state.selectedModel.ModelId}
              thresholds={assetRiskCfg}
              mode={this.state.selectedModel._Mode}
              isThresholdEditable={this.state.selectedModel.ConfigurableModelConfig.data.IsThresholdEditable.valueOf()}
              onChange={(value: IAssetRiskConfigurationValue) => {
                DataIntegrationStore.setAssetRiskConfig(
                  value.LowToMediumRiskLineThreshold,
                  value.MediumToHighRiskLineThreshold
                );
              }}
            />
            <ModelParametersMapping
              modelPossibleValues={this.state.modelPossibleValues.data}
              parameters={
                this.state.selectedModel.ConfigurableModelConfig.data
                  .ConfigurableModelParameters
              }
              selectedModel={this.state.selectedModel}
              sources={this.state.sources.data}
            />
          </div>
          <div className="model-config-footer">
            <FormattedMessage
              id="data_integration.model_config.footer.params_count"
              defaultMessage="Parameters count: {value}"
              values={{
                value:
                  this.state.selectedModel.ConfigurableModelConfig.data
                    .ConfigurableModelParameters.length
              }}
            />
            <span />
          </div>
        </FormContainer>
        {this.state.selectedModel &&
        this.state.selectedModel._Update.status === Statuses.Loading ? (
          <ProcessingModal />
        ) : null}
        <ModalWindow
          modalId={modalId}
          modalTitle={this.props.intl.formatMessage({
            id: "data_integration.model_config.generate_template",
            defaultMessage: "Generate Template"
          })}
          className="modal-window-mixed xlsx-downloader-modal"
        >
          <XlsxDownloader
            modalId={modalId}
            modelId={this.state.selectedModel.ModelId}
          />
        </ModalWindow>
      </div>
    );
  }

  private static getErrorComponent() {
    return (
      <div className="model-config">
        <Container>
          <Message type={msgTypes.ERROR} messageId="Error" />
        </Container>
      </div>
    );
  }

  private handleOnSubmit = (form: IForm): void => {
    const { intl } = this.props;
    DataIntegrationStore.storeModel(intl);
  };
}

export default injectIntl(ModelConfig, { forwardRef: true });
