// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { ITrendChart } from "common/chart/components/TrendLineChart";
import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { dataKey } from "../actions/loadDielectricTimedPressure";

const getDielectricTimedPressureSelector = createSelector(
  (state: IState) => state.data[dataKey] as Data<ITrendChart>,
  (pressure) => pressure || new Data<ITrendChart>()
);

export default getDielectricTimedPressureSelector;
