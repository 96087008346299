// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import loadData from "core/data/actions/loadData";
import UrlService from "core/data/services/UrlService";

import { config } from "utils/AppConfig";

export const dataKey = "DetailPage_Dielectric_Moisture_Chart";

export const loadDielectricMoisture = (assetId: string) => {
  const url = UrlService.getApiUrl(
    config.api.detailPage.dielectricMoistureUrl,
    { assetId }
  );

  return loadData({ key: dataKey, url });
};

export default loadDielectricMoisture;
