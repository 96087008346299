// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import loadData from "../../../core/data/actions/loadData";
import UrlService from "../../../core/data/services/UrlService";
import { config } from "../../../utils/AppConfig";

export const dataKey = `DataConfiguration_Templates`;

const loadTemplates = () => {
  const url = UrlService.getApiUrl(config.api.settings.data.templatesInfo);

  return loadData({ key: dataKey, url, method: "get" });
};

export default loadTemplates;
