// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import AntdProviderContainer from "core/app/containers/AntdProviderContainer";
import IntlProviderContainer from "core/app/containers/IntlProviderContainer";
import { HashRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import NotificationsProvider from "./NotificationsProvider";
import SiteContent from "./SiteContent";
import YupLocaleProvider from "./YupLocaleProvider";

const Site = () => {
  return (
    <AntdProviderContainer>
      <IntlProviderContainer>
        <YupLocaleProvider>
          <NotificationsProvider>
            <HashRouter>
              <SiteContent>
                <AppRoutes />
              </SiteContent>
            </HashRouter>
          </NotificationsProvider>
        </YupLocaleProvider>
      </IntlProviderContainer>
    </AntdProviderContainer>
  );
};

export default Site;
