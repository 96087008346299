// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import IDielectricData from "../../../models/IDielectricData";
import { dataKey } from "../actions/loadDielectricMoisture";

const getDielectricMoistureSelector = createSelector(
  (state: IState) => state.data[dataKey] as Data<IDielectricData>,
  (moisture) => moisture || new Data<IDielectricData>()
);

export default getDielectricMoistureSelector;
