// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React, { useEffect } from "react";
import { isEqual } from "lodash";
import useProcessRogersRatioData from "features/detailpage/features/rogersratio/hooks/useProcessRogersRatioData";
import { RogersRatioDataFetched } from "features/detailpage/features/rogersratio/hooks/useProcessRogersRatioData";
import RogersRatioChart from "features/detailpage/features/rogersratio/components/RogersRatioChart";

type RogersRatioChartWrapperProps = {
  rogersRatioData: RogersRatioDataFetched;
  showOfflineOnly: boolean;
  setShowOfflineOnly: (x: boolean) => void;
};

const RogersRatioChartWrapper = ({
  rogersRatioData,
  showOfflineOnly,
  setShowOfflineOnly
}: RogersRatioChartWrapperProps) => {
  const { rogersRatioZones, tracePoints, lastDataSource } =
    useProcessRogersRatioData({
      rogersRatioData
    });

  useEffect(() => {
    if (isEqual(lastDataSource, "Online")) {
      setShowOfflineOnly(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastDataSource]);

  return (
    <>
      {rogersRatioZones.length > 0 ? (
        <RogersRatioChart
          rogersRatioData={rogersRatioData}
          rogersRatioZones={rogersRatioZones}
          tracePoints={tracePoints}
          showOfflineOnly={showOfflineOnly}
        />
      ) : (
        <div className="tile">
          <div className="tile-content empty" />
        </div>
      )}
    </>
  );
};

export default RogersRatioChartWrapper;
