// Copyright 2016-2023 Hitachi Energy. All rights reserved.

export enum TrendTypeEnum {
  Phase1 = "Phase1",
  Phase2 = "Phase2",
  Phase3 = "Phase3"
}

type TrendType = keyof typeof TrendTypeEnum;

export default TrendType;
