// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IChart, TimeFrames } from "@apm/widgets/build/widgets/ParameterCharts";
import { IntlShape } from "react-intl";
import { chartToGroupMapper } from "../constants/voltageStressSettings";
import ChartType from "../models/ChartType";
import IVoltageStressData from "../models/IVoltageStressData";
import mapToChart from "./mapToChart";

const getChart = (
  intl: IntlShape,
  chartType: ChartType,
  timeFrame: TimeFrames,
  data: IVoltageStressData
): Promise<IChart> => {
  return new Promise<IChart>((resolve) => {
    setTimeout(() => {
      const chart: IChart = mapToChart(
        intl,
        chartType,
        data.Charts[chartType],
        chartToGroupMapper[chartType]
      );

      resolve(chart);
    });
  });
};

export default getChart;
