// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import SearchParams from "@pg/common/build/models/SearchParams";
import { Typography } from "antd";
import PbiReport, { removeReportPrefix } from "common/powerbi/PbiReport";
import { useAppNavigate } from "core/app/components/RouterProvider";
import { models } from "powerbi-client";
import React, { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import { config } from "utils/AppConfig";
import "./Reports.less";

const { Text } = Typography;

const createFilter = (
  column: string,
  values: (string | number | boolean)[]
) => {
  const filter: models.IBasicFilter = {
    operator: "In",
    filterType: models.FilterType.Basic,
    values,
    $schema: null,
    target: {
      table: config.powerbi.reports.views.assetsView,
      column
    }
  };

  return filter;
};

const Reports = () => {
  const navigate = useAppNavigate();
  const location = useLocation();
  const { reportName } = useParams();

  const searchParams = useMemo(
    () => new SearchParams(location.search),
    [location.search]
  );

  const assetId = useMemo(() => searchParams.get("assetId"), [searchParams]);

  const filters = useMemo(() => {
    const filters: models.IBasicFilter[] = [];

    if (assetId) {
      const assetIdFilter = createFilter(
        config.powerbi.reports.columns.assetId,
        [assetId]
      );
      filters.push(assetIdFilter);
    } else {
      const organization = searchParams.get("organization");
      const region = searchParams.get("region");
      const location = searchParams.get("location");

      if (organization || region || location) {
        [
          {
            column: config.powerbi.reports.columns.organization,
            value: organization
          },
          {
            column: config.powerbi.reports.columns.region,
            value: region
          },
          {
            column: config.powerbi.reports.columns.location,
            value: location
          }
        ].forEach((x) => {
          const filter = createFilter(x.column, [x.value]);
          filters.push(filter);
        });
      }
    }

    return filters;
  }, [assetId, searchParams]);

  const handleBackClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      navigate(-1);
    },
    [navigate]
  );

  return (
    <div className="dashboard-reports">
      <div className="navbar shadow">
        <div className="back" onClick={handleBackClick}>
          <Icon name="chevron_left" />
        </div>
        <div className="divider" />
        <Text className="report-name">
          <FormattedMessage
            id={"reports." + reportName}
            defaultMessage={
              assetId
                ? removeReportPrefix(removeReportPrefix(reportName))
                : removeReportPrefix(reportName)
            }
          />
        </Text>
      </div>
      <div>
        <PbiReport reportName={reportName} filters={filters} />
      </div>
    </div>
  );
};

export default Reports;
