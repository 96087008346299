// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import AssetRiskTrendWidget from "@apm/widgets/build/widgets/AssetRiskTrend";
import { Typography } from "antd";
import {
  DateDifferenceService,
  Formats
} from "common/formatters/DateDifference";
import moment from "moment";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import IAssetRiskIndicators from "../models/IAssetRiskIndicators";
import IAssetRiskTrendItem from "../models/IAssetRiskTrendItem";

const { Title } = Typography;

interface IAssetRiskTrendProps {
  assetRiskIndicators: IAssetRiskIndicators;
  assetRiskTrend: IAssetRiskTrendItem[];
  className?: string;
}

const AssetRiskTrend = ({
  assetRiskIndicators,
  assetRiskTrend,
  className
}: IAssetRiskTrendProps) => {
  const intl = useIntl();
  const now = useMemo(() => moment(), []);

  const indicators = useMemo(() => {
    return {
      assetTypesMonitored: assetRiskIndicators.AssetTypesMonitored,
      assetsMonitored: assetRiskIndicators.AssetsMonitored,
      assetsNotMonitored: assetRiskIndicators.AssetsNotMonitored,
      lastExecutionDate: assetRiskIndicators.LastExecutionDate
        ? new Date(assetRiskIndicators.LastExecutionDate)
        : null,
      locationsCovered: assetRiskIndicators.LocationsCovered
    };
  }, [
    assetRiskIndicators.AssetTypesMonitored,
    assetRiskIndicators.AssetsMonitored,
    assetRiskIndicators.AssetsNotMonitored,
    assetRiskIndicators.LastExecutionDate,
    assetRiskIndicators.LocationsCovered
  ]);

  const trend = useMemo(() => {
    return assetRiskTrend.map((t) => ({
      calculationDate: new Date(t.CalculationDate),
      lowRiskAssetCount: t.LowRiskAssetCount,
      mediumRiskAssetCount: t.MediumRiskAssetCount,
      highRiskAssetCount: t.HighRiskAssetCount
    }));
  }, [assetRiskTrend]);

  return (
    <div className={className}>
      <Title className="risk-trend-title title" level={5}>
        <FormattedMessage
          id="homepage.asset_risk"
          defaultMessage="Asset risk"
        />
      </Title>
      <AssetRiskTrendWidget
        indicators={indicators}
        formatXTick={(date) =>
          intl.formatDate(date, {
            year: "numeric",
            month: "short"
          })
        }
        formatYTick={(value, unit) =>
          intl.formatMessage(
            {
              defaultMessage: "{value, number} {unit}",
              id: "main_dashboard.asset_risk.percent_value_with_unit"
            },
            {
              value,
              unit
            }
          )
        }
        formatIndicatorValue={(value) => {
          if (typeof value === "number") return intl.formatNumber(value);
          return DateDifferenceService.format(value, intl, Formats.Short, now);
        }}
        header={
          <Title level={4}>
            <FormattedMessage
              id="homepage.asset_risk"
              defaultMessage="Asset risk"
            />
          </Title>
        }
        translations={{
          assetTypes: intl.formatMessage({
            id: "homepage.asset_risk.trend.assets_types",
            defaultMessage: "asset types"
          }),
          assetsMonitored: intl.formatMessage({
            id: "homepage.asset_risk.trend.assets_monitored",
            defaultMessage: "assets monitored"
          }),
          assetsNotMonitored: intl.formatMessage({
            id: "homepage.asset_risk.trend.assets_not_monitored",
            defaultMessage: "assets not monitored"
          }),
          expandButtonTooltip: intl.formatMessage({
            id: "global.chart.expand",
            defaultMessage: "Expand"
          }),
          lastUpdated: intl.formatMessage({
            id: "homepage.asset_risk.trend.last_date_updated",
            defaultMessage: "last updated"
          }),
          lastUpdateTitle: (value) =>
            intl.formatMessage(
              {
                defaultMessage: "Last updated {diff} ago.",
                id: "asset-risk-execution-date-tooltip.diff"
              },
              {
                diff: DateDifferenceService.format(
                  value,
                  intl,
                  Formats.Long,
                  now
                )
              }
            ),
          lastUpdateDescription: intl.formatMessage({
            defaultMessage:
              "Time since latest update of condition and risk assessment (i.e. asset model run) on any monitored asset.",
            id: "asset-risk-execution-date-tooltip.message"
          }),
          locationsCovered: intl.formatMessage({
            id: "homepage.asset_risk.trend.locations_covered",
            defaultMessage: "locations covered"
          }),
          percent: "%",
          high: intl.formatMessage({
            defaultMessage: "High",
            id: "homepage.asset_risk.asset_risk.highRisk"
          }),
          medium: intl.formatMessage({
            defaultMessage: "Medium",
            id: "homepage.asset_risk.asset_risk.mediumRisk"
          }),
          low: intl.formatMessage({
            defaultMessage: "Low",
            id: "homepage.asset_risk.asset_risk.lowRisk"
          })
        }}
        trend={trend}
      />
    </div>
  );
};

export default styled(AssetRiskTrend)`
  .risk-trend-title {
    margin-bottom: -28px;
  }
`;
