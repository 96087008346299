// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React, { ReactNode } from "react";
import styled from "styled-components";
import { Typography } from "antd";

import { spacingMedium } from "styles/StyleVariables";

const { Text } = Typography;

interface IColumnProps {
  className?: string;
  children?: ReactNode;
}

const Column = ({ className, children }: IColumnProps) => {
  return (
    <div className={className}>
      <Text>{children}</Text>
    </div>
  );
};

const StyledColumn = styled(Column)`
  padding: ${spacingMedium};
`;

export default StyledColumn;
