// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import AssetImplementationId from "features/detailpage/models/AssetImplementationId";

const isKinectricsNetworkTransformerModel = (implementationId: string) => {
  return (
    implementationId?.toLowerCase() ===
      AssetImplementationId.KinectricsTransformerMineralOilIEC ||
    implementationId?.toLowerCase() ===
      AssetImplementationId.KinectricsTransformerSiliconeIEEE
  );
};

export default isKinectricsNetworkTransformerModel;
