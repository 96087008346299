// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Input, Button } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

import useParametersSearch from "../hooks/useParametersSearch";

const { Search } = Input;

interface IParametersSearchProps {
  onSearch?: (value: string) => void;
  onClear?: () => void;
  defaultSearch?: string;
}

const ParametersSearch = ({
  onSearch,
  onClear,
  defaultSearch
}: IParametersSearchProps) => {
  const {
    handleClearClick,
    handleSearch,
    handleSearchChange,
    internalSearch
  } = useParametersSearch(onSearch, onClear, defaultSearch);

  return (
    <div>
      <Search
        className="search"
        onChange={handleSearchChange}
        onSearch={handleSearch}
        value={internalSearch}
      />
      {!!internalSearch && (
        <Button className="clear" onClick={handleClearClick} type="link">
          <FormattedMessage
            defaultMessage="Clear"
            id="detail_page.parameters_tab.parameters_grid.clear"
          />
        </Button>
      )}
    </div>
  );
};

export default ParametersSearch;
