// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";

import Input, {
  IInputState,
  IInputActions,
  IInputOwnProps,
  IInputData
} from "./Input";
import Toggle, { IToggleProps } from "./Toggle";

export interface IToggleInputActions extends IInputActions {}

export interface IToggleInputData extends IInputData {
  guid: string;
}

export interface IToggleInputOwnProps
  extends IInputOwnProps<boolean>,
    IToggleProps {}

export interface IToggleInputProps
  extends IToggleInputActions,
    IToggleInputData,
    IToggleInputOwnProps {}

export interface IToggleInputState extends IInputState {}

class ToggleInput extends Input<IToggleInputProps, IToggleInputState, boolean> {
  render() {
    const { guid } = this.props;
    return (
      <div className="input-toggle">
        <Toggle
          key={guid}
          {...this.props}
          onInit={this.handleInit.bind(this)}
          onChange={this.handleChange.bind(this)}
        />
      </div>
    );
  }
}

export default ToggleInput;
