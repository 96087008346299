// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import loadData from "../../../core/data/actions/loadData";
import UrlService from "../../../core/data/services/UrlService";
import { config } from "../../../utils/AppConfig";

export const dataKey = `ReportsConfiguration_Templates`;

const loadReportsTemplates = () => {
  const url = UrlService.getApiUrl(config.api.powerbiembed.templates);

  return loadData({ key: dataKey, url, method: "get" });
};

export default loadReportsTemplates;
