// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import loadData from "core/data/actions/loadData";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";
import IParameter from "../models/IParameter";

export interface IParametersData {
  [parameterName: string]: IParameter;
}

export const getDataKey = (assetId: string) =>
  `ConfigurationTool_Details-${assetId}`;

const loadParameters = (assetId: string, parameterNames: string[]) => {
  const url = UrlService.getApiUrl(config.api.configurationTool.parametersUrl, {
    assetId
  });

  return loadData({
    key: getDataKey(assetId),
    url,
    content: parameterNames,
    method: "post"
  });
};

export default loadParameters;
