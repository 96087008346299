// Copyright 2016-2023 Hitachi Energy. All rights reserved.

function deepClone<T>(srcObj: T): T {
  if (srcObj === undefined || srcObj === null || typeof srcObj !== "object")
    return srcObj;
  const dstObj = srcObj.constructor();
  const keys = Object.keys(srcObj);
  for (const key of keys) {
    dstObj[key] = deepClone<any>((srcObj as any)[key]);
  }
  return dstObj;
}

Object.defineProperty(Object, "deepClone", {
  enumerable: false,
  configurable: true,
  writable: true,
  value: deepClone
});

export {};
