// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";
import { useAppSelector } from "store";
import DegradedToggleView from "../components/DegradedToggleView/DegradedToggleView";
import useSetAllDegradedChecked from "../hooks/useSetAllDegradedChecked";
import useSetCanBeSaved from "../hooks/useSetCanBeSaved";
import getAllDegradedCheckedSelector from "../selectors/getAllDegradedCheckedSelector";
import getDegradedToRedSelector from "../selectors/getDegradedToRedSelector";
import getDegradedToYellowSelector from "../selectors/getDegradedToYellowSelector";
import getImprovedToGreenSelector from "../selectors/getImprovedToGreenSelector";
import getImprovedToYellowSelector from "../selectors/getImprovedToYellowSelector";

const DegradedToggleViewContainer = () => {
  const notificationsEnabled = useAppSelector(getDegradedToRedSelector);
  const degradedToRed = useAppSelector(getDegradedToRedSelector);
  const degradedToYellow = useAppSelector(getDegradedToYellowSelector);
  const improvedToGreen = useAppSelector(getImprovedToGreenSelector);
  const improvedToYellow = useAppSelector(getImprovedToYellowSelector);
  const allDegradedChecked = useAppSelector(getAllDegradedCheckedSelector);

  const { setCanBeSaved } = useSetCanBeSaved();
  const { setAllDegradedChecked } = useSetAllDegradedChecked();
  const onDegradedToggleChanged = React.useCallback(() => {
    setCanBeSaved(true);
    setAllDegradedChecked(undefined);
  }, [setAllDegradedChecked, setCanBeSaved]);

  return (
    <DegradedToggleView
      notificationsEnabled={notificationsEnabled}
      degradedToRed={degradedToRed}
      degradedToYellow={degradedToYellow}
      improvedToGreen={improvedToGreen}
      improvedToYellow={improvedToYellow}
      allDegradedChecked={allDegradedChecked}
      onDegradedToggleChanged={onDegradedToggleChanged}
    />
  );
};

export default DegradedToggleViewContainer;
