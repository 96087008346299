// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import SpatialView, { IStation } from "@apm/widgets/build/widgets/SpatialView";
import { Typography } from "antd";
import { IMapProvider } from "core/app/reducers/settings/MapProviderReducer";
import Data from "core/data/models/Data";
import { LocationRisk } from "features/RiskMapDashboard";
import { isNil } from "lodash";
import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";

const { Text, Title } = Typography;

interface IMapViewProps {
  mapProvider: IMapProvider;
  stationRisk: Data<LocationRisk[]>;
  onStationClick: (station: IStation) => void;
  onClusterClick: (cluster: IStation[]) => void;
}

const MapView = ({
  onStationClick,
  onClusterClick,
  mapProvider,
  stationRisk
}: IMapViewProps) => {
  const intl = useIntl();

  const formatName = useCallback(
    (name: string, level: string) =>
      isNil(name) ? (
        <Text style={{ fontStyle: "italic" }} type="secondary">
          {intl.formatMessage({
            defaultMessage: "Unknown",
            id: level + ".unknown"
          })}
        </Text>
      ) : (
        name
      ),
    [intl]
  );

  return (
    <>
      <Title className="title" level={5}>
        <FormattedMessage
          id="homepage.station_risk"
          defaultMessage="Spatial View"
        />
      </Title>
      <div className="station-risk-map">
        <SpatialView
          config={{
            key: mapProvider.Key,
            type: mapProvider.Type.toString(),
            urlTemplate: mapProvider.MapProviderUriSchema
          }}
          onStationClick={onStationClick}
          onClusterClick={onClusterClick}
          showChangeIndicator
          stations={
            stationRisk?.data?.map((s) => ({
              id: `${s.Organization}${s.Region}${s.Station}`,
              organization: s.Organization,
              region: s.Region,
              location: s.Station,
              highRiskAssetCount: s.HighRiskAssetCount,
              mediumRiskAssetCount: s.MediumRiskAssetCount,
              lowRiskAssetCount: s.LowRiskAssetCount,
              unknownRiskAssetCount: s.UnknownRiskAssetCount,
              latitude: s.Latitude,
              longitude: s.Longitude,
              stateChanged: s.StateChanged
            })) || []
          }
          translations={{
            locations: (numberOfLocations) =>
              intl.formatMessage(
                {
                  id: "homepage.station_risk.map.cluster.title.multi_system",
                  defaultMessage:
                    "{ value, plural, zero {no locations} one {# location} other {# locations} }"
                },
                {
                  value: numberOfLocations
                }
              ),
            highAssets: (value) =>
              intl.formatMessage(
                {
                  id: "homepage.station_risk.map.pushpin_info.high",
                  defaultMessage: "{ value, number } high"
                },
                {
                  value
                }
              ),
            mediumAssets: (value) =>
              intl.formatMessage(
                {
                  id: "homepage.station_risk.map.pushpin_info.medium",
                  defaultMessage: "{ value, number } medium"
                },
                {
                  value
                }
              ),
            lowAssets: (value) =>
              intl.formatMessage(
                {
                  id: "homepage.station_risk.map.pushpin_info.low",
                  defaultMessage: "{ value, number } low"
                },
                {
                  value
                }
              ),
            unknownAssets: (value) =>
              intl.formatMessage(
                {
                  id: "homepage.station_risk.map.pushpin_info.unknown",
                  defaultMessage: "{ value, number } unknown"
                },
                {
                  value
                }
              ),
            name: (organization: string, region: string, location: string) => (
              <FormattedMessage
                defaultMessage="{organization} / {region} / {location}"
                id="homepage.station_risk.map.station_name"
                values={{
                  organization: formatName(organization, "organization"),
                  region: formatName(region, "region"),
                  location: formatName(location, "location")
                }}
              />
            )
          }}
        />
      </div>
    </>
  );
};

export default MapView;
