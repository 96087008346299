// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "antd";

import BeginningOfMessages from "./BeginningOfMessages";
import { config } from "utils/AppConfig";

const MessageWithContactInfo = () => {
  const handleOnButtonClicked = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      window.open(config.UI.supportContactUrl);
    },
    []
  );

  return (
    <div className="message-box">
      <div className="right app-event question azure">
        <div className="img">
          <Icon name="info" />
        </div>
        <div data-qa="noEmailWarning">
          <BeginningOfMessages />
          <FormattedMessage
            id="notifications.noemail.message.azure"
            defaultMessage="Please configure your profile or request for help."
          />
        </div>
        <div className="buttons">
          <Button type="default" onClick={handleOnButtonClicked}>
            <FormattedMessage id="global.messages.help" defaultMessage="Help" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MessageWithContactInfo;
