// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import loadData from "core/data/actions/loadData";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";
import dataKey from "../constants/sotDataKey";

const loadSOTData = (assetId: string) => {
  const url = UrlService.getApiUrl(
    config.api.detailPage.assetStandardOilParametersTrendValuesUrl,
    [
      {
        name: "assetId",
        value: assetId
      }
    ]
  );

  return loadData({ key: dataKey(assetId), url });
};

export default loadSOTData;
