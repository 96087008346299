// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { notifications } from "@pg/common/build/components/Notifications";
import { IColumnOrder } from "common/datagrid/models/IColumnOrder";
import { IEndpoint } from "common/datagrid/models/IEndpoint";
import EndpointService from "core/data/services/EndpointService";
import { saveAs } from "file-saver";
import { IntlShape } from "react-intl";
import { ContentTypes } from "./DataService";

interface IExportToStyleSheetFile {
  endpoint: IEndpoint;
  order: IColumnOrder[];
  intl: IntlShape;
  assetId: string;
  contentType: ContentTypes;
}

export default class DataExportService {
  static exportToExcel(config: IExportToStyleSheetFile): void {
    const { contentType, assetId, endpoint, order, intl } = config;
    const url = endpoint.url;

    const data = Object.assign({}, endpoint.content, {
      order
    });

    function onSuccess(xhr: JQuery.jqXHR, data: any) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });

      const extension = ".xlsx";

      saveAs(
        blob,
        assetId === null
          ? `${contentType}${extension}`
          : `${assetId}_${contentType}${extension}`
      );
    }

    function onError(xhr: JQueryXHR) {
      notifications.error({
        message: intl.formatMessage(
          {
            id: "data_integration.model_config.xlsx_downloader.download_error",
            defaultMessage: "Downloading {name} failed"
          },
          { name: contentType }
        )
      });
    }

    EndpointService.postBinary(url, onSuccess, onError, data);
  }

  static exportToCsv(config: IExportToStyleSheetFile): void {
    const { contentType, assetId, endpoint, order, intl } = config;

    const url = endpoint.url;

    const data = Object.assign({}, endpoint.content, {
      order
    });
    const extension = ".csv";
    function onSuccess(xhr: JQuery.jqXHR, data: any) {
      const blob = new Blob([data], {
        type: "application/csv"
      });
      saveAs(
        blob,
        assetId === null
          ? `${contentType}${extension}`
          : `${assetId}_${contentType}${extension}`
      );
    }

    function onError(xhr: JQueryXHR) {
      notifications.error({
        message: intl.formatMessage(
          {
            defaultMessage: "Downloading {name} failed",
            id: "data_grid.footer.export.download.error"
          },
          {
            name: contentType
          }
        )
      });
    }

    EndpointService.postBinary(url, onSuccess, onError, data);
  }
}
