// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import IssueDetails, {
  IIssueDetailsActions
} from "features/detailpage/features/issues/components/IssueDetails";
import { IntlShape } from "react-intl";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import modalWindowStore from "store/common/modal/ModalWindowStore";
import {
  createIssue,
  editIssue,
  removeNewIssue,
  switchIssueModeToEdit,
  switchIssueModeToView,
  updateActiveIssue
} from "../actions/IssuesActions";
import IIssueFormCreate from "../models/IIssueFormCreate";
import IIssueFormEdit from "../models/IIssueFormEdit";

export function mapStateToProps(state: IState): {} {
  return {};
}

export function mapDispatchToProps(
  dispatch: AppDispatch
): IIssueDetailsActions {
  return {
    createIssue: (
      intl: IntlShape,
      assetId: string,
      issue: IIssueFormCreate
    ): Promise<void> => createIssue(dispatch, intl, assetId, issue),
    editIssue: (
      intl: IntlShape,
      issueId: string,
      issue: IIssueFormEdit
    ): Promise<void> => editIssue(dispatch, intl, issueId, issue),
    openModal: (modalId: string) => {
      modalWindowStore.openModal(modalId);
    },
    removeNewIssue: () => removeNewIssue(dispatch),
    switchIssueModeToEdit: (issueId: string) =>
      switchIssueModeToEdit(dispatch, issueId),
    switchIssueModeToView: (issueId: string) =>
      switchIssueModeToView(dispatch, issueId),
    updateActiveIssue: (issueId: string) => updateActiveIssue(dispatch, issueId)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueDetails);
