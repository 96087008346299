// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import IReadings from "../models/IReadings";

const getLastYearReadings = (readings: IReadings) => {
  const yearReadings: IReadings = {};
  if (!readings || Object.keys(readings).length === 0) return yearReadings;

  const yearRange = new Date(
    new Date().setFullYear(new Date().getFullYear() - 1)
  );

  const values = Object.keys(readings || {})
    .filter((key) => {
      return new Date(key).getTime() > yearRange.getTime();
    })
    .reduce((previous: { [date: string]: number }, current) => {
      previous[current] = readings[current];
      return previous;
    }, {});
  return values;
};

export default getLastYearReadings;
