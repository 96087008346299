// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { dataKey } from "../actions/loadPressureParameters";
import IDielectricPressureParametersData from "../models/IDielectricPressureParametersData";

const getDielectricPressureSelector = createSelector(
  (state: IState) =>
    state.data[dataKey] as Data<IDielectricPressureParametersData>,
  (parameters) => parameters || new Data<IDielectricPressureParametersData>()
);

export default getDielectricPressureSelector;
