// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { getDataKey } from "../actions/loadChart";
import ChartNames from "../models/ChartNames";
import IDCChart from "../models/IDCChart";

const getChartSelector = createSelector(
  (state: IState) => (assetId: string, chartName: ChartNames) =>
    state.data[getDataKey(assetId, chartName)] as Data<IDCChart>,
  (charts) => charts
);

export default getChartSelector;
