// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IDuvalPoint } from "common/DuvalPentagon";
import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { dataKey } from "../actions/loadDuvalPentagons";

const getDuvalPentagonSelector = createSelector(
  (state: IState) => state.data[dataKey] as Data<IDuvalPoint[]>,
  (stationRisk) => stationRisk || new Data<IDuvalPoint[]>()
);

export default getDuvalPentagonSelector;
