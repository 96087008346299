// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "reducers/Index";

import findPrognosticPoints from "../utils/getPrognosticPoints";

const getRiskMatrixPrognosticSelector = (importance: number) =>
  createSelector(
    (state: IState) => state.detailPage.degradationScore,
    (degradationScore) => findPrognosticPoints(degradationScore, importance)
  );

export default getRiskMatrixPrognosticSelector;
