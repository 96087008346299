// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Reducer } from "redux/index";

import Data from "core/data/models/Data";
import { IAjaxAction } from "reducers/Index";
import { Actions } from "features/detailpage/features/messages/actions/MessagesActions";

export type MessageTypes =
  | "Alert"
  | "Warning"
  | "Informational"
  | "Error"
  | "Predictive"
  | "Verification"
  | "TimeFrame"
  | "Action"
  | "Diagnosis"
  | "Solution"
  | "Urgency"
  | "Unknown"
  | "DataQualityError";

export interface IMessages {
  Messages: IMessage[];
}

export interface IMessage {
  MessageType: MessageTypes;
  MessageContent: string;
  MessageSource: string;
  CreationDate: string;
}

export interface IState {
  messages: Data<IMessages>;
}

const messagesReducer: Reducer<IState> = (
  state = {
    messages: new Data()
  },
  action: IAjaxAction<IMessages>
) => {
  switch (action.type) {
    case Actions.DetailPage_Messages_SetMessages:
      state = Object.assign({}, state, {
        messages: new Data(action.request, action.data)
      } as IState);
      return state;
    default:
      return state;
  }
};

export default messagesReducer;
