// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Statuses } from "core/data/models/Data";
import { ScatterPlotParameters } from "../components/ScatterPlotWidget";
import { IScatterPlotData } from "../reducers/FamilyAnalyticsReducer";

const haveCommonElements = (
  dataX: IScatterPlotData,
  dataY: IScatterPlotData
) => {
  const commonElement = dataX.dataPoints.data.DataPoints.find((d) =>
    dataY.dataPoints.data.DataPoints.some(
      ({ AssetId }) => d.AssetId === AssetId
    )
  );
  return commonElement ? true : false;
};

const useScatterPlotWidget = (
  parameters: ScatterPlotParameters,
  scatterPlotYData: IScatterPlotData,
  scatterPlotXData: IScatterPlotData
) => {
  const getDataStatus = () => {
    if (
      parameters.dataTypeX === "LatestValueDate" &&
      parameters.dataTypeY === "LatestValueDate"
    )
      return Statuses.Failed;

    if (parameters.dataTypeX === "LatestValueDate") {
      return scatterPlotYData.dataPoints.status;
    }
    if (parameters.dataTypeY === "LatestValueDate") {
      return scatterPlotXData.dataPoints.status;
    }

    if (scatterPlotYData && scatterPlotXData) {
      if (
        scatterPlotYData.dataPoints.status === Statuses.Succeeded &&
        scatterPlotXData.dataPoints.status === Statuses.Succeeded
      ) {
        if (!haveCommonElements(scatterPlotXData, scatterPlotYData))
          return Statuses.NotFound;

        return Statuses.Succeeded;
      }
      if (
        scatterPlotYData.dataPoints.status === Statuses.Loading ||
        scatterPlotXData.dataPoints.status === Statuses.Loading
      ) {
        return Statuses.Loading;
      }
      if (
        scatterPlotYData.dataPoints.status === Statuses.NotFound ||
        scatterPlotXData.dataPoints.status === Statuses.NotFound
      ) {
        return Statuses.NotFound;
      }
      if (
        scatterPlotYData.dataPoints.status === Statuses.Failed ||
        scatterPlotXData.dataPoints.status === Statuses.Failed
      ) {
        return Statuses.Failed;
      }
    }
    return null;
  };
  return { getDataStatus };
};
export default useScatterPlotWidget;
