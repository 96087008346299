// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import {
  loadNotificationsSettingsAction,
  resetToDefaultStateAction
} from "../actions/NotificationsActions";
import Notifications from "../components/Notifications";
import getNotificationsModelInputSelector from "../selectors/getNotificationsModelInputSelector";

const NotificationsContainer = () => {
  const dispatch = useAppDispatch();
  const modelInput = useAppSelector(getNotificationsModelInputSelector);

  useEffect(() => {
    dispatch(loadNotificationsSettingsAction());
    return () => {
      dispatch(resetToDefaultStateAction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Notifications modelInput={modelInput} />;
};

export default NotificationsContainer;
