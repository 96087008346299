// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Value } from "@apm/widgets/build/components/Parameters";
import IParameter from "@apm/widgets/build/components/Parameters/models/IParameter";
import IParametersGroup from "@apm/widgets/build/components/Parameters/models/IParametersGroup";
import IParameterValue from "@apm/widgets/build/components/Parameters/models/IParameterValue";
import { isNil } from "lodash";
import { FormattedMessage, IntlShape } from "react-intl";
import mapToParameterOptions from "../../../../../utils/mapToParameterOptions";
import mapToThreshold from "../../../../../utils/mapToThreshold";
import mapToThresholdCorrection from "../../../../../utils/mapToThresholdCorrection";
import IDielectricPressureParameterPoles from "../models/IDielectricPressureParameterPoles";
import IDielectricPressureParameterPoleValue from "../models/IDielectricPressureParameterPoleValue";
import IDielectricPressureParametersData from "../models/IDielectricPressureParametersData";

export default class SF6ParametersMapper {
  constructor(private intl: IntlShape) {}

  public map({
    ColumnsNames,
    ColumnsSubtitles,
    Parameters
  }: IDielectricPressureParametersData): IParametersGroup {
    return {
      columns: ColumnsNames.map((name, i) =>
        name
          ? {
              block: (
                <>
                  <div data-qa="parameter-column-header">
                    <FormattedMessage
                      defaultMessage={name}
                      id={`detail_page.cbk.dielectric.parameters.${name}`}
                    />
                  </div>
                  {ColumnsSubtitles && ColumnsSubtitles[i] && (
                    <div data-qa="parameter-column-subheader">
                      <FormattedMessage
                        defaultMessage={ColumnsSubtitles[i]}
                        id={`detail_page.cbk.dielectric.parameters.${ColumnsSubtitles[i]}`}
                      />
                    </div>
                  )}
                </>
              ),
              inline: `${this.intl.formatMessage({
                defaultMessage: name,
                id: `detail_page.cbk.dielectric.parameters.${name}`
              })} ${
                ColumnsSubtitles &&
                ColumnsSubtitles[i] &&
                this.intl.formatMessage({
                  defaultMessage: ColumnsSubtitles[i],
                  id: `detail_page.cbk.dielectric.parameters.${ColumnsSubtitles[i]}`
                })
              }`
            }
          : null
      ),

      parameters: Parameters.map((p) => this.mapDielectricParameters(p))
    };
  }

  private mapDielectricParameters({
    Name,
    Poles,
    Unit
  }: IDielectricPressureParameterPoles): IParameter {
    return {
      id: Name,
      name: this.intl.formatMessage({
        id: `detail_page.cbk.dielectric.parameters.${Name}`,
        defaultMessage: Name
      }),
      unit:
        Name === "TimeToWarningLevel"
          ? Unit ||
            this.intl.formatMessage({
              id: "detail_page.dielectric.sf6_parameters.units.days",
              defaultMessage: "days"
            })
          : Unit,
      values: Poles.map((p) => this.mapPoles(p))
    };
  }

  private mapPoles(
    poleValue: IDielectricPressureParameterPoleValue
  ): IParameterValue {
    const externalId = poleValue?.ExternalId;
    const options = mapToParameterOptions(poleValue?.Options);
    const thresholds = poleValue?.Thresholds?.map(mapToThreshold);
    const thresholdCorrection = mapToThresholdCorrection(
      poleValue?.UserThresholdCorrection
    );

    const value: Value = !isNil(poleValue?.Value)
      ? poleValue?.DataType === "dot"
        ? {
            type: "dot",
            value: poleValue?.Value.toString().toLowerCase() as
              | "problem"
              | "caution"
              | "normal"
          }
        : Number(poleValue?.Value)
      : null;

    return {
      externalId,
      options,
      thresholdCorrection,
      thresholds,
      value,
      valueDate: poleValue?.ParameterDate
        ? new Date(poleValue?.ParameterDate)
        : undefined
    };
  }
}
