// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import OperationsParameters, {
  IGroup,
  IRowUpdated,
  IState as IOperationsParametersState
} from "@apm/widgets/build/widgets/OperationsParameters";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { poleDefaultColors } from "../utils/getParametersDefaultState";
import { formatTwoDigitDateTime } from "common/DateTime/utils/dateFormatters";

interface IParametersOwnProps {
  group: IGroup[];
  onChange?: (rowUpdated: IRowUpdated) => void;
  parametersDefaultState?: IOperationsParametersState;
}

type ParametersProps = IParametersOwnProps;

const Parameters = ({
  group,
  onChange,
  parametersDefaultState
}: ParametersProps) => {
  const intl = useIntl();
  const formatValue = useCallback(
    (value: number) => intl.formatNumber(value),
    [intl.formatNumber]
  );

  return (
    <OperationsParameters
      animated={false}
      groups={group}
      onChange={onChange}
      defaultState={parametersDefaultState}
      translations={{
        slope: intl.formatMessage({
          id: "detail_page.widgets.circuit_breaker.operations.slope",
          defaultMessage: "Slope"
        }),
        value: intl.formatMessage({
          id: "detail_page.widgets.circuit_breaker.operations.value",
          defaultMessage: "Value"
        }),
        label: intl.formatMessage({
          id: "detail_page.widgets.circuit_breaker.operations.label",
          defaultMessage: "Hide from chart"
        }),
        eventDateInfo: (eventDate: Date) => {
          const formattedDate = formatTwoDigitDateTime(intl, eventDate);
          return intl.formatMessage(
            {
              id: "detail_page.widgets.circuit_breaker.operations.event_date",
              defaultMessage: "Event date: {value}"
            },
            { value: formattedDate }
          );
        }
      }}
      formatValue={formatValue}
      defaultColors={poleDefaultColors}
    />
  );
};

export default Parameters;
