// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IScatterPlotPoint } from "../components/ScatterPlot";
import * as ss from "simple-statistics";
import moment from "moment";

export interface ICorrelationLineData {
  x: number;
  y: number;
}

export interface ICorrelationData {
  xMin: number;
  yMin: number;
  xMax: number;
  yMax: number;
  correlationLineData: ICorrelationLineData[];
  rSquared: number;
}

class CorrelationService {
  private static getYValue(m: number, b: number, x: number): number {
    return m * x + b;
  }

  private static normalizeData(points: IScatterPlotPoint[]): number[][] {
    return points.map((p) => {
      return [
        p.valueX instanceof Date ? moment(p.valueX).valueOf() : p.valueX,
        p.valueY instanceof Date ? moment(p.valueY).valueOf() : p.valueY
      ];
    });
  }

  static compute(points: IScatterPlotPoint[]): ICorrelationData {
    const coordinatesToCalc = this.normalizeData(points);

    const lr = ss.linearRegression(coordinatesToCalc);
    const m = lr.m;
    const b = lr.b;
    const regressionLine = ss.linearRegressionLine(lr);

    const rSquared = ss.rSquared(coordinatesToCalc, regressionLine);

    let minX = Math.min(...points.map((p) => p.valueX as number));
    let maxX = Math.max(...points.map((p) => p.valueX as number));

    let generatedData = Array<ICorrelationLineData>();
    const step = Math.abs((maxX - minX) / 200);
    for (let i = minX; i < maxX; i += step) {
      generatedData.push({
        x: i,
        y: this.getYValue(m, b, i)
      });
    }

    // filter trend-line points to stay in chart area
    const minY = Math.min(...points.map((p) => p.valueY as number));
    const maxY = Math.max(...points.map((p) => p.valueY as number));
    generatedData = generatedData.filter((p) => p.y >= minY && p.y <= maxY);
    minX = Math.min(...generatedData.map((p) => p.x));
    maxX = Math.max(...generatedData.map((p) => p.x));

    return {
      rSquared: rSquared,
      xMin: minX,
      yMin: this.getYValue(m, b, minX),
      xMax: maxX,
      yMax: this.getYValue(m, b, maxX),
      correlationLineData: generatedData
    };
  }
}

export default CorrelationService;
