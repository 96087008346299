// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useCallback } from "react";
import { useAppDispatch } from "store";

import { loadClustersAction } from "../actions/RiskMatrixActions";
import RiskMatrixScope from "../models/RiskMatrixScope";

const useLoadClusters = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (
      scope: RiskMatrixScope,
      modelId: string,
      organization: string,
      region: string,
      location: string
    ) => {
      return loadClustersAction(
        dispatch,
        scope,
        modelId,
        organization,
        region,
        location
      );
    },
    [dispatch]
  );
};

export default useLoadClusters;
