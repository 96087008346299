// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IAjaxAction } from "reducers/Index";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";
import IVoltageData from "../models/IVoltageData";

export enum Actions {
  DetailPage_BatterVoltage_SetVoltageData = "DetailPage_BushingVisualization_SetOnlineBushingData"
}

export const setVoltageDataAction = (
  request: JQueryXHR,
  data?: IVoltageData
): IAjaxAction<IVoltageData> => ({
  type: Actions.DetailPage_BatterVoltage_SetVoltageData,
  request,
  data
});

export const loadVoltageData = (
  dispatch: AppDispatch,
  assetId?: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const url = UrlService.getApiUrl(config.api.detailPage.batteryVoltageUrl, {
      assetId
    });

    const request = EndpointService.getJson<IVoltageData>(
      url,
      (request, data) => {
        dispatch(setVoltageDataAction(request, data));
        resolve();
      },
      (request) => {
        dispatch(setVoltageDataAction(request));
        reject();
      }
    );

    dispatch(setVoltageDataAction(request));
  });
};
