// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import loadData from "core/data/actions/loadData";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";

export const getDataKey = (assetId: string) =>
  `DetailPage_AssetDetails-${assetId}`;

const loadAssetDetails = (assetId: string) => {
  const url = UrlService.getApiUrl(config.api.detailPage.assetDetailsUrl, {
    assetId
  });

  return loadData({ key: getDataKey(assetId), url, method: "get" });
};

export default loadAssetDetails;
