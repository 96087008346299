// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IAjaxAction } from "reducers/Index";
import { Action } from "redux";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";
import ISimulationConfig from "../components/SimulationModal/models/ISimulationConfig";
import IDegradationScoreTrend from "../models/IDegradationScoreTrend";

export const Actions = {
  DetailPage_RiskSimulationSummary_SetConfig:
    "DetailPage_RiskSimulationSummary_SetConfig",
  DetailPage_RiskSimulationSummary_SetDegradationScore:
    "DetailPage_RiskSimulationSummary_SetDegradationScore",
  DetailPage_RiskSimulationSummary_SetSimulationModalVisible:
    "DetailPage_RiskSimulationSummary_SetSimulationModalVisible"
};

export const setSimulationConfigAction = (
  request: JQuery.jqXHR,
  data?: ISimulationConfig
): IAjaxAction<ISimulationConfig> => {
  const action: IAjaxAction<ISimulationConfig> = {
    type: Actions.DetailPage_RiskSimulationSummary_SetConfig,
    request,
    data
  };
  return action;
};

export const setRiskTrendAction = (
  request: JQuery.jqXHR,
  data?: IDegradationScoreTrend
): IAjaxAction<IDegradationScoreTrend> => ({
  type: Actions.DetailPage_RiskSimulationSummary_SetDegradationScore,
  request,
  data
});

export interface ISetSimulationModalVisible extends Action<string> {
  visible: boolean;
}

export const setSimulationModalVisibleAction = (
  visible: boolean
): ISetSimulationModalVisible => ({
  type: Actions.DetailPage_RiskSimulationSummary_SetSimulationModalVisible,
  visible
});

export const loadSimulationConfig = (dispatch: AppDispatch, assetId: string) =>
  new Promise((resolve, reject) => {
    const url = UrlService.getApiUrl(
      config.api.detailPage.simulation.configUrl,
      null,
      { assetId }
    );

    const request = EndpointService.getJson<ISimulationConfig>(
      url,
      (request, data) => {
        dispatch(setSimulationConfigAction(request, data));
        resolve(data);
      },
      (request) => {
        dispatch(setSimulationConfigAction(request));
        reject(request);
      }
    );

    dispatch(setSimulationConfigAction(request));
  });

export const loadDegradationScore = (
  dispatch: AppDispatch,
  assetId: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const url = UrlService.getApiUrl(
      config.api.detailPage.degradationScoreTrendUrl,
      [
        {
          name: "assetId",
          value: assetId
        }
      ]
    );

    const request = EndpointService.get<IDegradationScoreTrend>(
      url,
      (request, data) => {
        dispatch(setRiskTrendAction(request, data));
        resolve();
      },
      (request) => {
        dispatch(setRiskTrendAction(request));
        reject();
      }
    );

    dispatch(setRiskTrendAction(request));
  });
};
