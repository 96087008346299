// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import ISelectedFilterSelect from "common/FilterBar/models/ISelectedFilterSelect";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { useCallback, useEffect, useMemo, useState } from "react";
import { config } from "utils/AppConfig";
import IAssetWithIssues from "../models/AssetWithIssues";

interface IUseLocationsIssuesListOptions {
  location?: string;
  organization?: string;
  region?: string;
}

interface IRangeData {
  Total: number;
  RowData: IAssetWithIssues[];
}

const assetsWithIssuesInitialValue: IAssetWithIssues[] = [];
const dataChunkSize = 10;
const rowsTotalDefaultValue = 0;
const startDefaultValue = 0;

const useLocationsIssuesList = ({
  location,
  organization,
  region
}: IUseLocationsIssuesListOptions) => {
  const [loading, setLoading] = useState(false);
  const [rowsTotal, setRowsTotal] = useState(rowsTotalDefaultValue);
  const [start, setStart] = useState(startDefaultValue);

  const [assetsWithIssues, setAssetsWithIssues] = useState<IAssetWithIssues[]>(
    assetsWithIssuesInitialValue
  );

  const selectedFilters: ISelectedFilterSelect[] = useMemo(
    () => [
      {
        Id: "AssetOrganizationWithRegionAndLocation",
        Options: [
          {
            Id: organization || null,
            Options: [
              {
                Id: region || null,
                Options: [
                  {
                    Id: location || null
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        Id: "IssueStatus",
        Options: [
          {
            Id: "New"
          },
          {
            Id: "InProgress"
          },
          {
            Id: "Monitor"
          }
        ]
      }
    ],
    [organization, region, location]
  );

  const resetIssuesData = useCallback(() => {
    setAssetsWithIssues(assetsWithIssuesInitialValue);
    setRowsTotal(rowsTotalDefaultValue);
    setStart(startDefaultValue);
  }, [setAssetsWithIssues, setRowsTotal, setStart]);

  const updateIssuesData = useCallback(
    (data: IRangeData) => {
      setAssetsWithIssues((assets) => [...assets, ...data.RowData]);
      setRowsTotal(data.Total);
      setStart((start) => start + dataChunkSize);
    },
    [setAssetsWithIssues, setRowsTotal, setStart]
  );

  const loadIssuesData = useCallback(
    (start: number) => {
      if (loading) return;

      setLoading(true);

      const endpointParams = {
        Start: start,
        Size: dataChunkSize,
        Filters: selectedFilters
      };

      EndpointService.post<IRangeData>(
        UrlService.getApiUrl(config.api.locations.issuesUrl),
        (_, data) => {
          updateIssuesData(data);
          setLoading(false);
        },
        () => {
          setLoading(false);
        },
        endpointParams,
        "application/json; charset=utf-8"
      );
    },
    [loading, selectedFilters, updateIssuesData]
  );

  const handleNext = useCallback(() => {
    loadIssuesData(start);
  }, [loadIssuesData, start]);

  useEffect(() => {
    resetIssuesData();
    loadIssuesData(startDefaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, region, location]);

  return {
    assetsWithIssues,
    handleNext,
    loading,
    rowsTotal
  };
};

export default useLocationsIssuesList;
