// Copyright 2016-2023 Hitachi Energy. All rights reserved.

export const fontFamily = `"Open Sans", Helvetica, Arial, sans-serif, sans-serif`;

export const fontSizeXXSmall = "8px";
export const fontSizeXSmall = "10px";
export const fontSizeSmall = "12px";
export const fontSizeBase = "14px";
export const fontSizeMedium = "16px";
export const fontSizeLarge = "24px";
export const fontSizeXLarge = "32px";

export const iconFontSizeXXSmall = "8px";
export const iconFontSizeXSmall = "10px";
export const iconFontSizeSmall = "12px";
export const iconFontSizeBase = "14px";
export const iconFontSizeMedium = "16px";
export const iconFontSizeLarge = "20px";
export const iconFontSizeXLarge = "24px";
export const iconFontSizeXXLarge = "28px";
export const iconFontSizeXXXLarge = "32px";

export const spacingXSmall = "2px";
export const spacingSmall = "4px";
export const spacingBase = "6px";
export const spacingMedium = "8px";
export const spacingLarge = "12px";
export const spacingXLarge = "16px";
export const spacingXXLarge = "24px";
export const spacingXXXLarge = "48px";
export const spacingXXXXLarge = "72px";

export const screenSizeXs = "0px";
export const screenSizeSm = "1280px";
export const screenSizeMd = "1440px";
export const screenSizeLg = "1600px";
export const screenSizeXl = "1920px";
export const screenSizeXxl = "2560px";

export const defaultDrawerWidth = "600px";
