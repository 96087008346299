// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Map, Record } from "immutable";

import Input from "./Input";

const FormRecord = Record({
  hasChanged: false,
  inputs: Map<string, Input>(),
  valid: false
});

export default FormRecord;
