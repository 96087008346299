// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import {
  IGroup,
  IParameter
} from "@apm/widgets/build/widgets/OperationsParameters";
import IOperation from "../models/IOperation";
import IPoleParameter from "../models/IPoleParameter";

export default class OperationsChartCreator {
  create(
    operations: IOperation,
    valueTime: number,
    slopeTime: number
  ): Promise<IGroup[]> {
    return new Promise((resolve) => {
      const groups = [
        {
          id: "pole1",
          name: "Pole 1",
          date: operations?.Pole1?.EventDate,
          parameters: this.createParameters(
            operations?.Pole1?.Parameters,
            valueTime,
            slopeTime
          )
        },
        {
          id: "pole2",
          name: "Pole 2",
          date: operations?.Pole2?.EventDate,
          parameters: this.createParameters(
            operations?.Pole2?.Parameters,
            valueTime,
            slopeTime
          )
        },
        {
          id: "pole3",
          name: "Pole 3",
          date: operations?.Pole3?.EventDate,
          parameters: this.createParameters(
            operations?.Pole3?.Parameters,
            valueTime,
            slopeTime
          )
        }
      ];
      resolve(groups);
    });
  }

  private createParameters(
    poleParameters: IPoleParameter[],
    valueTime: number,
    slopeTime: number
  ): IParameter[] {
    const parameters: IParameter[] = [];
    poleParameters?.forEach((p) => {
      const value = p.Values[valueTime];
      const slopeValue = p.Values[slopeTime];
      const slope = this.calculateSlope(
        value,
        slopeValue,
        slopeTime,
        valueTime
      );

      parameters.push({
        id: p.Id,
        name: p.Id,
        slope,
        value
      });
    });

    return parameters;
  }

  private calculateSlope(
    value: number,
    slopeValue: number,
    slopeTime: number,
    valueTime: number
  ) {
    if (slopeValue !== undefined) {
      return (slopeValue - value) / (slopeTime - valueTime);
    }

    return null;
  }
}
