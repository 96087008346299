// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";
import { RouterLayout } from "core/app/components/RouterProvider";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import AgingContactWear from "../../containers/AgingContactWearContainer";
import "./AgingSubTabs.less";

interface IAgingSubTabsProps {
  assetId: string;
}

const AgingSubTabs = ({ assetId }: IAgingSubTabsProps) => {
  const intl = useIntl();
  const searchParams = useMemo(() => new SearchParams({ assetId }), [assetId]);

  return (
    <div className="aging-sub-tabs" data-qa="aging-inner-tabs">
      <RouterLayout
        routes={[
          {
            id: "Assets-DetailPage-Parameters-Aging-ContactWear",
            route: "ContactWear",
            displayName: intl.formatMessage({
              defaultMessage: "Contact Wear (I²t)",
              id: "detail_page.widgets.circuit_breaker.aging.contact_wear_chart.title"
            }),
            to: {
              pathname: "/Assets/DetailPage/Parameters/Aging/ContactWear",
              search: searchParams.toString()
            },
            element: <AgingContactWear assetId={assetId} />
          }
        ]}
      />
    </div>
  );
};

export default AgingSubTabs;
