// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";

import ParameterHistogramChart from "./ParameterHistogramChart";
import IParameterHistogram from "../models/IParameterHistogram";

import "./ParameterHistogramSucceeded.less";

interface IParameterHistogramSucceededProps {
  parameterHistogramData: IParameterHistogram[];
  title: string;
  unit: string;
}

const ParameterHistogramSucceeded = ({
  parameterHistogramData,
  title,
  unit
}: IParameterHistogramSucceededProps) => (
  <div className="parameter-histogram container">
    <div className="parameter-histogram-chart-title">{title}</div>
    <ParameterHistogramChart
      parameterHistogramData={parameterHistogramData}
      unit={unit}
    ></ParameterHistogramChart>
  </div>
);

export default ParameterHistogramSucceeded;
