// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { loadDuvalPentagons } from "../actions/loadDuvalPentagons";
import getDuvalPentagonSelector from "../selectors/getDuvalPentagonSelector";

const useDuvalPentagonTab = (assetId: string, dataSource: string) => {
  const dispatch = useAppDispatch();
  const duvalPoints = useAppSelector(getDuvalPentagonSelector);

  useEffect(() => {
    dispatch(loadDuvalPentagons(assetId, dataSource));
  }, [dispatch, assetId, dataSource]);

  return { duvalPoints };
};

export default useDuvalPentagonTab;
