// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { ChartAPI } from "c3";
import C3Chart from "common/chart/components/C3Chart";

import IBushingLineChartDataSet from "features/detailpage/features/bushingsoffline/models/IBushingLineChartDataSet";
import IBushingThresholdDataSet from "features/detailpage/features/bushingsoffline/models/IBushingReferenceDataSet";
import ISize from "features/detailpage/features/bushingsoffline/models/ISize";
import BushingOfflineChartHeader from "./BushingOffliceChartHeader";
import useBushingOfflineChartConfiguration from "./hooks/useBushingOfflineChartConfiguration";

import { useRef } from "react";
import "./BushingOfflineChart.less";

interface IBushingOfflineChartProps {
  series: IBushingLineChartDataSet[];
  id: string;
  chartTitle: string;
  manufacturer?: string;
  model?: string;
  installDate?: string;
  size?: ISize;
  onChartRendered?: (chartApi: ChartAPI, id: string) => void;
  powerFactorThreshold?: IBushingThresholdDataSet;
  capacitanceThreshold?: IBushingThresholdDataSet;
}

const BushingOfflineChart = ({
  series,
  id,
  chartTitle,
  manufacturer,
  model,
  installDate,
  size,
  onChartRendered,
  powerFactorThreshold,
  capacitanceThreshold
}: IBushingOfflineChartProps) => {
  const chartRef = useRef<ChartAPI>();
  const configuration = useBushingOfflineChartConfiguration({
    series,
    size,
    powerFactorReference: powerFactorThreshold,
    capacitanceReference: capacitanceThreshold,
    installDate,
    chartRef
  });
  const isEmpty =
    (series || []).filter((s) => s.values && s.values.length > 0).length === 0;

  return (
    <>
      <BushingOfflineChartHeader
        chartTitle={chartTitle}
        manufacturer={manufacturer}
        model={model}
        installDate={installDate}
      />
      <C3Chart
        className={isEmpty ? "disabled" : ""}
        onRendered={(chart) => {
          chartRef.current = chart;
          if (onChartRendered) onChartRendered(chart, id);
        }}
        configuration={configuration}
      />
    </>
  );
};

export default BushingOfflineChart;
