// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React, { Dispatch, useEffect, useMemo } from "react";
import { connect } from "react-redux";

import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import OperationsSucceded from "./OperationsSucceded";
import Data, { Statuses } from "core/data/models/Data";
import IOperation from "../models/IOperation";
import getOperationsSelector from "../selectors/getOperationsSelector";
import { IState } from "reducers/Index";
import loadOperations from "../actions/loadOperations";

interface IOperationsBodyDataProps {
  operations: Data<IOperation>;
}

interface IOperationsBodyActionsProps {
  loadOperations: (assetId: string, event: string) => void;
}

interface IOperationsBodyOwnProps {
  assetId: string;
  event: string;
}

type OperationsBodyProps = IOperationsBodyDataProps &
  IOperationsBodyOwnProps &
  IOperationsBodyActionsProps;

const OperationsBody = ({
  operations,
  loadOperations,
  assetId,
  event
}: OperationsBodyProps) => {
  const responseText = useMemo(
    () =>
      operations?.responseText
        ? JSON.parse(operations.responseText)
        : undefined,
    [operations]
  );

  useEffect(() => {
    loadOperations(assetId, event);
  }, [assetId, event, loadOperations]);

  return (
    <>
      {operations?.status === Statuses.Loading && <Processing />}
      {operations?.status === Statuses.Succeeded && operations?.data && (
        <OperationsSucceded operations={operations.data} />
      )}
      {operations?.status === Statuses.NotFound && <WidgetNoDataMessage />}
      {operations?.status === Statuses.Failed && (
        <WidgetErrorMessage
          messageId={`detail_page.widgets.circuit_breaker.operations.${
            responseText || "UNKNOWN_ERROR"
          }`}
          messageDefault={responseText}
        />
      )}
    </>
  );
};

const mapStateToProps = (
  state: IState,
  { assetId, event }: IOperationsBodyOwnProps
): IOperationsBodyDataProps => ({
  operations: getOperationsSelector(state)(assetId, event)
});

const mapDispatchToProps = (
  dispatch: Dispatch<any>
): IOperationsBodyActionsProps => ({
  loadOperations: (assetId: string, event: string) =>
    dispatch(loadOperations(assetId, event))
});

export default connect(mapStateToProps, mapDispatchToProps)(OperationsBody);
