// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import MessagesContainer from "features/detailpage/features/messages/containers/MessagesContainer";

interface IMessagesWidgetProps {
  assetId: string;
}

const MessagesWidget = ({ assetId }: IMessagesWidgetProps) => {
  return <MessagesContainer assetId={assetId} />;
};

export default MessagesWidget;
