// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";
import { Col, Row } from "antd";
import Cascader from "common/cascader/Cascader";
import EmptyState from "common/empty/EmptyState";
import { SelectedFilters } from "common/FilterBar";
import Toggle, { ToggleModes } from "common/form/components/Toggle";
import Message, { types as msgTypes } from "components/common/Message";
import Processing from "components/common/Processing";
import SectionName from "components/common/SectionName";
import { useAppNavigate } from "core/app/components/RouterProvider";
import Data, { Statuses } from "core/data/models/Data";
import { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router";
import useFamilyAnalyticsWidget from "../hooks/useFamilyAnalyticsWidget";
import {
  IScatterPlotData,
  IScatterPlotFilterData
} from "../reducers/FamilyAnalyticsReducer";
import { DataTypes } from "../services/ScatterPlotWidgetService";
import "./FamilyAnalyticsWidget.less";
import FamilyContentsWidget from "./FamilyContentsWidget";
import ScatterPlotWidget, { ScatterPlotParameters } from "./ScatterPlotWidget";

export interface IFamilyAnalyticsWidgetActions {
  loadScatterPlotYData: (scatterPlotParameters: ScatterPlotParameters) => void;
  loadScatterPlotXData: (scatterPlotParameters: ScatterPlotParameters) => void;
}

export interface IFamilyAnalyticsWidgetOwnProps {
  selectedFilters: SelectedFilters;
  showErrorMessage: boolean;
  errorCode: string | undefined;
  dataPointsLimit: number | undefined;
}

export interface IScatterPlotWidgetData {
  scatterPlotFilters: Data<IScatterPlotFilterData>;
  scatterPlotYData: IScatterPlotData;
  scatterPlotXData: IScatterPlotData;
}

export interface IFamilyAnalyticsWidgetProps
  extends IFamilyAnalyticsWidgetOwnProps,
    IScatterPlotWidgetData,
    IFamilyAnalyticsWidgetActions {}

const FamilyAnalyticsWidget = ({
  loadScatterPlotYData,
  loadScatterPlotXData,
  scatterPlotFilters,
  scatterPlotXData,
  scatterPlotYData,
  selectedFilters,
  showErrorMessage,
  errorCode,
  dataPointsLimit
}: IFamilyAnalyticsWidgetProps) => {
  const intl = useIntl();
  const navigate = useAppNavigate();
  const location = useLocation();
  const searchParams = useMemo(
    () => new SearchParams(location.search),
    [location.search]
  );

  const scatterPlotParameters = useFamilyAnalyticsWidget(
    searchParams.toObject() as ScatterPlotParameters,
    loadScatterPlotYData,
    loadScatterPlotXData,
    selectedFilters
  );

  const onSubFilterYChange = (value: string[]) => {
    const item = value[value.length - 1];
    const type = value[0];

    searchParams.set("dataTypeY", type);
    switch (type) {
      case "Parameter":
      case "DerivativeParameter":
      case "DegradationSubScore":
        searchParams.set("seriesNameY", item);
        break;
      default:
        searchParams.set("seriesNameY", type);
        break;
    }

    navigate({ search: searchParams.toString() }, { replace: true });
  };

  const onSubFilterXChange = (value: string[]) => {
    const item = value[value.length - 1];
    const type = value[0];

    searchParams.set("dataTypeX", type);
    switch (type) {
      case "Parameter":
      case "DerivativeParameter":
      case "DegradationSubScore":
        searchParams.set("seriesNameX", item);
        break;
      default:
        searchParams.set("seriesNameX", type);
        break;
    }

    navigate({ search: searchParams.toString() }, { replace: true });
  };

  const seriesNameExist = useCallback(
    (seriesName: string, dataType: "Parameter" | "DegradationSubScore") => {
      if (scatterPlotFilters.data) {
        return scatterPlotFilters.data[dataType].some((x) => x === seriesName);
      }

      return false;
    },
    [scatterPlotFilters.data]
  );

  const getDefaultValue = useCallback((dataType: string, series: string) => {
    const optionsWithSubOptions = [
      "Parameter",
      "DerivativeParameter",
      "DegradationSubScore"
    ];
    if (!optionsWithSubOptions.includes(dataType)) return [dataType];
    else return [dataType, series];
  }, []);

  const cascaderDefaultYValue = useMemo(() => {
    if (scatterPlotParameters.dataTypeY !== undefined) {
      return getDefaultValue(
        scatterPlotParameters.dataTypeY,
        scatterPlotParameters.seriesNameY
      );
    }
    return [];
  }, [
    getDefaultValue,
    scatterPlotParameters.dataTypeY,
    scatterPlotParameters.seriesNameY
  ]);

  const cascaderDefaultXValue = useMemo(() => {
    if (scatterPlotParameters.dataTypeX !== undefined) {
      return getDefaultValue(
        scatterPlotParameters.dataTypeX,
        scatterPlotParameters.seriesNameX
      );
    }
    return [];
  }, [
    getDefaultValue,
    scatterPlotParameters.dataTypeX,
    scatterPlotParameters.seriesNameX
  ]);

  const getCascaderOptions = useCallback(
    (options: IScatterPlotFilterData) => {
      const degradations = options.DegradationSubScore.map((k) => {
        return {
          value: k,
          label: k
        };
      });

      const params = options.Parameter.map((p) => {
        return {
          value: p,
          label: p
        };
      });

      const derivativeParams = options.DerivativeParameters.map((d) => {
        return {
          value: d,
          label: d
        };
      });

      return [
        {
          value: "Parameter",
          label: intl.formatMessage({
            defaultMessage: "Parameter",
            id: "family_analytics.filters.parameter"
          }),
          children: params
        },
        {
          value: "DerivativeParameter",
          label: intl.formatMessage({
            defaultMessage: "Derivative Parameter",
            id: "family_analytics.filters.derivative_parameter"
          }),
          children: derivativeParams
        },
        {
          value: "DegradationSubScore",
          label: intl.formatMessage({
            defaultMessage: "Condition Subscore",
            id: "family_analytics.filters.degradation_subscore"
          }),
          children: degradations
        },
        {
          value: "DegradationScore",
          label: intl.formatMessage({
            defaultMessage: "Condition",
            id: "family_analytics.filters.degradation_score"
          })
        },
        {
          value: "ReplacementScore",
          label: intl.formatMessage({
            defaultMessage: "Replacement score",
            id: "family_analytics.filters.replacement_score"
          })
        },
        {
          value: "MaintenancePriorityScore",
          label: intl.formatMessage({
            defaultMessage: "Maintenance Priority Score",
            id: "family_analytics.filters.maintenance_priority_score"
          })
        },
        {
          value: "LatestValueDate",
          label: intl.formatMessage({
            defaultMessage: "Latest value date",
            id: "family_analytics.filters.latest_value_date"
          })
        }
      ].filter((x) => !x.children || (!!x.children && x.children.length));
    },
    [intl]
  );

  const getShowCorrelationDefaultValue = (): boolean => {
    if (searchParams.has("showCorrelation")) {
      return searchParams.get("showCorrelation") === "1";
    }

    searchParams.set("showCorrelation", "0");
    navigate({ search: searchParams.toString() }, { replace: true });
    return false;
  };

  const options = useMemo(() => {
    return scatterPlotFilters &&
      scatterPlotFilters.status === Statuses.Succeeded
      ? getCascaderOptions(scatterPlotFilters.data)
      : [];
  }, [getCascaderOptions, scatterPlotFilters]);

  const onCorrelationToggleChanged = () => {
    const newValue = searchParams.get("showCorrelation") === "1" ? "0" : "1";
    searchParams.set("showCorrelation", newValue);
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  useEffect(() => {
    const setDefaultYAxisParameters = () => {
      if (!searchParams.has("dataTypeY")) {
        searchParams.set("dataTypeY", "DegradationScore");
        searchParams.set("seriesNameY", "DegradationScore");
        navigate({ search: searchParams.toString() }, { replace: true });
      } else if (searchParams.has("dataTypeY") && scatterPlotFilters.data) {
        const dataTypeY = searchParams.get("dataTypeY") as DataTypes;
        if (dataTypeY === "Parameter" || dataTypeY === "DegradationSubScore") {
          const seriesNameY = searchParams.get("seriesNameY");
          if (
            !scatterPlotFilters.data?.hasOwnProperty(dataTypeY) &&
            !seriesNameExist(seriesNameY, dataTypeY)
          ) {
            searchParams.set("dataTypeY", "DegradationScore");
            searchParams.set("seriesNameY", "DegradationScore");
            navigate({ search: searchParams.toString() }, { replace: true });
          }
        }
      }
    };

    const setDefaultXAxisParameters = () => {
      if (!searchParams.has("dataTypeX")) {
        searchParams.set("dataTypeX", "LatestValueDate");
        searchParams.set("seriesNameX", "LatestValueDate");
        navigate({ search: searchParams.toString() }, { replace: true });
      } else if (searchParams.has("dataTypeX") && scatterPlotFilters.data) {
        const dataTypeX = searchParams.get("dataTypeX") as DataTypes;
        if (dataTypeX === "Parameter" || dataTypeX === "DegradationSubScore") {
          const seriesNameX = searchParams.get("seriesNameX");
          if (
            !scatterPlotFilters.data?.hasOwnProperty(dataTypeX) &&
            !seriesNameExist(seriesNameX, dataTypeX)
          ) {
            searchParams.set("dataTypeX", "LatestValueDate");
            searchParams.set("seriesNameX", "LatestValueDate");
            navigate({ search: searchParams.toString() }, { replace: true });
          }
        }
      }
    };

    setDefaultYAxisParameters();
    setDefaultXAxisParameters();
  }, [navigate, searchParams, scatterPlotFilters.data, seriesNameExist]);

  return (
    <div className="family-analytics-widget-container">
      <div className="scatter-plot-widget-container">
        <Row gutter={24}>
          {scatterPlotFilters?.status === Statuses.Loading && <Processing />}
          {scatterPlotFilters?.status === Statuses.Succeeded &&
            !showErrorMessage && (
              <>
                <Col span={24} xl={19}>
                  <div className="header dropdown-container">
                    <SectionName
                      messageId="family_analytics.scatter_plot_filter.label"
                      messageDefault="Visualization"
                    />
                    <Cascader
                      options={options}
                      defaultValue={cascaderDefaultYValue}
                      placeholder={intl.formatMessage({
                        defaultMessage: "Select",
                        id: "family_analytics.scatter_plot_filter.select"
                      })}
                      onChange={onSubFilterYChange}
                      className="family-analytics-subfilter"
                      dropdownClassName="family-analytics-subfilter-popup"
                      showSearch={true}
                    />
                    <Cascader
                      options={options}
                      defaultValue={cascaderDefaultXValue}
                      placeholder={intl.formatMessage({
                        defaultMessage: "Select",
                        id: "family_analytics.scatter_plot_filter.select"
                      })}
                      onChange={onSubFilterXChange}
                      className="family-analytics-subfilter"
                      dropdownClassName="family-analytics-subfilter-popup"
                      showSearch={true}
                    />
                    <Toggle
                      defaultValue={getShowCorrelationDefaultValue()}
                      label={{
                        defaultMessage: "Correlation",
                        id: "family_analytics.filters.show_correlation_line"
                      }}
                      mode={ToggleModes.LabelOnRight}
                      onChange={onCorrelationToggleChanged}
                    />
                  </div>

                  <ScatterPlotWidget
                    scatterPlotYData={scatterPlotYData}
                    scatterPlotXData={scatterPlotXData}
                    parameters={scatterPlotParameters}
                  />
                </Col>
                <Col span={12} xl={5}>
                  <FamilyContentsWidget parameters={scatterPlotParameters} />
                </Col>
              </>
            )}
          {scatterPlotFilters?.status === Statuses.Succeeded &&
            showErrorMessage &&
            errorCode === "LATEST_VALUES_MAX_LIMIT_EXCEEDED" && (
              <EmptyState
                className="data-point-limit-exited"
                defaultMessage="The number of assets to show is more than {limit, number}. Select more filtering criteria."
                id="family_analytics.datapoint_limit_exided"
                iconName="speed"
                iconClassName="family-analytics-icon"
                values={{
                  limit: dataPointsLimit
                }}
              />
            )}
          {scatterPlotFilters?.status === Statuses.Succeeded &&
            showErrorMessage &&
            errorCode === "NON_INT_OR_NEGATIVE_VALUE" && (
              <EmptyState
                className="data-point-limit-exited"
                defaultMessage="Family analytics configuration issue."
                id="family_analytics.non_int_or_negative_value"
                iconName="speed"
                iconClassName="family-analytics-icon"
              />
            )}
          {scatterPlotFilters?.status === Statuses.Failed && (
            <Message
              type={msgTypes.ERROR}
              message={scatterPlotFilters.message}
            />
          )}
        </Row>
      </div>
    </div>
  );
};

export default FamilyAnalyticsWidget;
