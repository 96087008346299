// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { loadOnlineBushingData } from "../actions/BushingVisualizationActions";
import BushingVisualization from "../components/BushingVisualization";
import selectOnlineBushingData from "../selectors/selectOnlineBushingData";

interface IBushingVisualizationContainerProps {
  assetId: string;
}

const BushingVisualizationContainer: React.FC<
  IBushingVisualizationContainerProps
> = ({ assetId }) => {
  const dispatch = useAppDispatch();
  const onlineBushingData = useAppSelector(selectOnlineBushingData);
  const memoizedLoadOnlineBushingData = useCallback(
    () => loadOnlineBushingData(dispatch, assetId),
    [assetId, dispatch]
  );

  React.useEffect(() => {
    memoizedLoadOnlineBushingData();
  }, [memoizedLoadOnlineBushingData]);

  return <BushingVisualization onlineBushingData={onlineBushingData} />;
};

export default BushingVisualizationContainer;
