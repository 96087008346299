// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";
import PbiReport from "common/powerbi/PbiReport";
import { WidgetMessage } from "components/common/widget/Widget";
import { IBasicFilter } from "powerbi-models";
import { useMemo } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";

interface IPbiReportTabProps {
  className?: string;
}

const PbiReportTab = ({ className }: IPbiReportTabProps) => {
  const location = useLocation();
  const searchParams = useMemo(
    () => new SearchParams(location.search),
    [location.search]
  );
  const reportName = useMemo(() => searchParams.get("pbi_n"), [searchParams]);
  const filters = useMemo<IBasicFilter[]>(
    () => JSON.parse(searchParams.get("pbi_f")),
    [searchParams]
  );

  return reportName ? (
    <div className={`${className} pbiembed-wrapper`}>
      <PbiReport reportName={reportName} filters={filters} />
    </div>
  ) : (
    <div className="select-report-message">
      <WidgetMessage
        iconName="info"
        iconClassName="select-report"
        messageId="detail_page.widgets.reports.select_report"
        messageDefault="Please select report to display"
      />
    </div>
  );
};

export default styled(PbiReportTab)`
  .pbi-embedded-report {
    height: calc(100vh - 230px);
  }
`;
