// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import {
  IRowUpdated,
  IState
} from "@apm/widgets/build/widgets/OperationsParameters";
import { merge } from "lodash";

const mergeParametersWidgetState = (
  currentState: IState,
  { checked, color, groupId, parameterId }: IRowUpdated
): IState => {
  const newState = merge(currentState, {
    [groupId]: {
      [parameterId]: {
        color,
        checked
      }
    }
  });

  return newState;
};

export default mergeParametersWidgetState;
