// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Action, Reducer } from "redux/index";

import Data from "core/data/models/Data";
import {
  Actions,
  ScatterPlotSetDataAction
} from "features/familyanalytics/actions/FamilyAnalyticsActions";
import { IAjaxAction } from "reducers/Index";
import { IScatterPlotDataPoints } from "features/familyanalytics/components/ScatterPlot";

export interface IState {
  scatterPlotFilters: Data<IScatterPlotFilterData>;
  scatterPlotYData: IScatterPlotData;
  scatterPlotXData: IScatterPlotData;
}

export interface IScatterPlotData {
  dataPoints: Data<IScatterPlotDataPoints>;
  assetId: string;
  labelX: string;
  labelY: string;
}

export interface IScatterPlotFilterData {
  Parameter: string[];
  DegradationSubScore: string[];
  DerivativeParameters: string[];
  DegradationScore: string;
  ReplacementScore: string;
}

export const defaultState: IState = {
  scatterPlotFilters: new Data(),
  scatterPlotYData: {
    dataPoints: new Data(),
    assetId: undefined,
    labelX: undefined,
    labelY: undefined
  },
  scatterPlotXData: {
    dataPoints: new Data(),
    assetId: undefined,
    labelX: undefined,
    labelY: undefined
  }
};

const familyAnalyticsReducer: Reducer<IState> = (
  state = defaultState,
  action: Action | ScatterPlotSetDataAction
) => {
  switch (action.type) {
    case Actions.ScatterPlotWidget_SetYData:
      return {
        ...state,
        scatterPlotYData: {
          dataPoints: new Data(
            (action as ScatterPlotSetDataAction).request,
            (action as ScatterPlotSetDataAction).data
          ),
          assetId: (action as ScatterPlotSetDataAction).assetId,
          labelX: (action as ScatterPlotSetDataAction).labelX,
          labelY: (action as ScatterPlotSetDataAction).labelY
        }
      };
    case Actions.ScatterPlotWidget_SetXData:
      return {
        ...state,
        scatterPlotXData: {
          dataPoints: new Data(
            (action as ScatterPlotSetDataAction).request,
            (action as ScatterPlotSetDataAction).data
          ),
          assetId: (action as ScatterPlotSetDataAction).assetId,
          labelX: (action as ScatterPlotSetDataAction).labelX,
          labelY: (action as ScatterPlotSetDataAction).labelY
        }
      };
    case Actions.FamilyAnalytics_SetScatterPlotFilters:
      return {
        ...state,
        scatterPlotFilters: new Data(
          (action as IAjaxAction<IScatterPlotFilterData>).request,
          (action as IAjaxAction<IScatterPlotFilterData>).data
        )
      };
    default:
      return state;
  }
};

export default familyAnalyticsReducer;
