// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { loadVoltageData } from "../actions/BatteryVoltageActions";
import BatteryVoltage from "../components/BatteryVoltage";
import batteryVoltageSelector from "../selectors/batteryVoltageSelector";

interface IBatteryVoltageContainerProps {
  assetId: string;
}

const BatteryVoltageContainer = ({
  assetId
}: IBatteryVoltageContainerProps) => {
  const dispatch = useAppDispatch();
  const voltageData = useAppSelector(batteryVoltageSelector);
  const memoizedLoadVoltageData = useCallback(
    () => loadVoltageData(dispatch, assetId),
    [assetId, dispatch]
  );

  useEffect(() => {
    memoizedLoadVoltageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <BatteryVoltage voltageData={voltageData} />;
};

export default BatteryVoltageContainer;
