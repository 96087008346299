// Copyright 2016-2023 Hitachi Energy. All rights reserved.

export enum ThresholdTypeEnum {
  Warning = "Warning",
  Alert = "Alert",
  High = "High",
  VeryHigh = "VeryHigh"
}

type ThresholdType = keyof typeof ThresholdTypeEnum;

export default ThresholdType;
