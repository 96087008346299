// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import ParameterCharts from "@apm/widgets/build/widgets/ParameterCharts";
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import loadSOTData from "../actions/loadSOTData";
import removeSOTData from "../actions/removeSOTData";
import useSOT from "../hooks/useSOT";
import ISOTDataModel from "../models/ISOTData";
import getSOTDataSelector from "../selectors/getSOTDataSelector";
import getLegend from "../utils/getLegend";
import getSotType from "../utils/getSotType";
import { formatDateTime } from "common/DateTime/utils/dateFormatters";

interface ISOTActions {
  loadSOTData: (assetId: string) => void;
  removeSOTData: (assetId: string) => void;
}

interface ISOTData {
  sotData: Data<ISOTDataModel>;
}

interface ISOTOwnProps {
  assetId: string;
  dataSources: string[];
  defaultDataSource?: string;
}

interface ISOTProps extends ISOTActions, ISOTData, ISOTOwnProps {}

const SOT = ({
  assetId,
  sotData,
  dataSources,
  defaultDataSource,
  loadSOTData,
  removeSOTData
}: ISOTProps) => {
  const intl = useIntl();

  const { oils, getSeriesColor, sotType } = useSOT(
    assetId,
    loadSOTData,
    removeSOTData,
    sotData?.data?.Charts,
    sotData?.data?.OilStandard
  );

  const dataSourceConfig = useMemo(
    () => ({
      dataSources: dataSources,
      defaultDataSource: defaultDataSource,
      showLabel: true,
    }),
    [dataSources, defaultDataSource]
  );

  const formatYTick = useCallback(
    (value: number, unit: string) => {
      let currentUnit: string;

      switch (unit) {
        case "%1":
        case "%2":
        case "%3":
          currentUnit = "%";
          break;
        default:
          currentUnit = unit != null ? unit : "";
          break;
      }

      return intl.formatMessage(
        {
          defaultMessage: "{value} {unit}",
          id: "detail_page.widgets.analytics.transformers.sot.value_unit"
        },
        {
          value: value,
          unit: currentUnit
        }
      );
    },
    [intl]
  );

  return (
    <>
      {sotData?.status === Statuses.Loading && <Processing />}
      {sotData?.status === Statuses.Succeeded && (
        <ParameterCharts
          chartGroups={[
            {
              name: "oils",
              charts: oils
            }
          ]}
          dataSourceConfig={dataSourceConfig}
          getLegend={getLegend(sotData.data.OilStandard, intl)}
          getSeriesColor={getSeriesColor}
          formatTooltipTitle={(date) => formatDateTime(intl, date)}
          formatXTick={(date) => intl.formatDate(date)}
          formatYTick={formatYTick}
          translations={{
            series: (seriesName) =>
              intl.formatMessage({
                defaultMessage: seriesName,
                id: `detail_page.widgets.analytics.transformers.StandardOilTests.${getSotType(
                  sotType
                )}.${seriesName}`
              }),
            empty: intl.formatMessage({
              defaultMessage: "No data available",
              id: "global.chart.no_data_available"
            })
          }}
        />
      )}
      {sotData?.status === Statuses.NotFound && (
        <div>
          <WidgetNoDataMessage />
        </div>
      )}
      {sotData?.status === Statuses.Failed && (
        <div>
          <WidgetErrorMessage
            messageId="global.empty"
            messageDefault={sotData?.message}
          />
        </div>
      )}
    </>
  );
};

export default connect(
  (state: IState, ownProps: ISOTOwnProps): ISOTData => ({
    sotData: getSOTDataSelector(state)(ownProps.assetId)
  }),
  (dispatch: AppDispatch): ISOTActions => ({
    loadSOTData: (assetId: string) => dispatch(loadSOTData(assetId)),
    removeSOTData: (assetId: string) => dispatch(removeSOTData(assetId))
  })
)(SOT);
