// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import loadData from "core/data/actions/loadData";
import removeData from "core/data/actions/removeData";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";

export const dataKey = "AssetRiskDashboard_TrendChart";

export const loadTrendChart = () => {
  const url = UrlService.getApiUrl(
    config.api.assetRiskDashboard.assetRiskTrend
  );

  const content: { filters: any[] } = {
    filters: []
  };

  return loadData({ key: dataKey, url, content, method: "post" });
};

export const removeTrendChart = () => removeData(dataKey);
