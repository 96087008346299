// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import SectionName from "components/common/SectionName";
import TrendChart from "features/assetriskdashboard/models/TrendChart";
import * as React from "react";

type AssetRiskDashboardWrapperProps = {
  trendChartData: TrendChart;
  children: React.ReactNode;
};

const AssetRiskDashboardWrapper = ({
  children,
  trendChartData
}: AssetRiskDashboardWrapperProps) => (
  <div className="asset-risk-dashboard">
    <div className="container">
      <div className="bootstrap-row asset-risk-dashboard__row">
        <div className="col-24 col-lg-20 asset-risk-dashboard__header">
          <SectionName
            messageDefault="Asset risk / {numberOfElements, number} items"
            messageId="asset_risk_dashboard.header.asset_risk"
            messageValues={{
              numberOfElements: trendChartData && trendChartData.length
            }}
          />
        </div>
      </div>
      {children}
    </div>
  </div>
);

export default AssetRiskDashboardWrapper;
