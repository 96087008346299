// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getActionsSelector = createSelector(
  (state: IState) => state.issues.actions,
  actions => actions
);

export default getActionsSelector;
