// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";
import Data from "../../../../../core/data/models/Data";
import { IState } from "../../../../../reducers/Index";
import { getDataKey } from "../actions/loadWear";
import IWearData from "../models/IWearData";

const getWearSelector = createSelector(
  (state: IState) => (assetId: string) =>
    state.data[getDataKey(assetId)] as Data<IWearData>,
  (wear) => wear
);

export default getWearSelector;
