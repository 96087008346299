// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { Actions as DGAOperationsActions } from "features/detailpage/features/ltc/actions/DgaOperationsActions";
import { Actions as HistorySwitchingActions } from "features/detailpage/features/ltc/actions/HistorySwitchingActions";
import { Actions as LTCOperationsActions } from "features/detailpage/features/ltc/actions/LTCOperationsActions";
import IDGAOperations from "features/detailpage/features/ltc/models/IDGAOperations";
import { IHistorySwitchingOperationsData } from "features/detailpage/features/ltc/models/IHistorySwitichingOperations";
import ILTCOperations from "features/detailpage/features/ltc/models/ILTCOperations";
import { round } from "lodash";
import { IAjaxAction, IState as IRootState } from "reducers/Index";
import { Reducer } from "redux";
import { createSelector } from "reselect";
import { useAppSelector } from "store";

export interface IState {
  ltc: {
    ltcOperations: Data<ILTCOperations>;
    historySwitching: Data<IHistorySwitchingOperationsData>;
    dgaOperations: Data<IDGAOperations>;
  };
}

export const defaultState: IState = {
  ltc: {
    ltcOperations: new Data(),
    historySwitching: new Data(),
    dgaOperations: new Data()
  }
};

const reducer: Reducer<IState> = (
  state = defaultState,
  action:
    | IAjaxAction<ILTCOperations>
    | IAjaxAction<IDGAOperations>
    | IAjaxAction<IHistorySwitchingOperationsData>
) => {
  switch (action.type) {
    case LTCOperationsActions.DetailPage_Ltc_SetOperations:
      return {
        ...state,
        ltc: {
          ...state.ltc,
          ltcOperations: new Data(
            (action as IAjaxAction<ILTCOperations>).request,
            (action as IAjaxAction<ILTCOperations>).data
          )
        }
      };
    case HistorySwitchingActions.DetailPage_Ltc_SetHistorySwitchingOperations:
      return {
        ...state,
        ltc: {
          ...state.ltc,
          historySwitching: new Data(
            (action as IAjaxAction<IHistorySwitchingOperationsData>).request,
            (action as IAjaxAction<IHistorySwitchingOperationsData>).data
          )
        }
      };
    case DGAOperationsActions.DetailPage_Ltc_SetDga:
      return {
        ...state,
        ltc: {
          ...state.ltc,
          dgaOperations: new Data(
            (action as IAjaxAction<IDGAOperations>).request,
            (action as IAjaxAction<IDGAOperations>).data
          )
        }
      };
    default:
      return state;
  }
};

export default reducer;

// #region Selectors

export const getLtcOperationsSelector = createSelector(
  (state: IRootState) => state.detailPage.ltc.ltcOperations,
  (ltcOperations) => ltcOperations
);

export const getDgaOperationsSelector = createSelector(
  (state: IRootState) => state.detailPage.ltc.dgaOperations,
  (dgaOperations) => dgaOperations
);

export const getSwitchingOperationsSelector = createSelector(
  (state: IRootState) => state.detailPage.ltc.historySwitching,
  (historySwitching) => historySwitching
);

const getCumulativeOperationCountSelector = createSelector(
  ({
    detailPage: {
      ltc: {
        ltcOperations: { data }
      }
    }
  }: IRootState) => {
    if (data && data.CumulativeOperationCount) {
      return data.CumulativeOperationCount;
    }

    return null;
  },
  (cumulativeOperationCount) => cumulativeOperationCount
);

const getMaximumOperationCountSelector = createSelector(
  ({
    detailPage: {
      ltc: {
        ltcOperations: { data }
      }
    }
  }: IRootState) => {
    if (data && data.MaximumOperationCount) {
      return data.MaximumOperationCount;
    }

    return null;
  },
  (maximumOperationCount) => maximumOperationCount
);

export const getLtcPercentageUsedSelector = createSelector(
  [getCumulativeOperationCountSelector, getMaximumOperationCountSelector],
  (cumulative, maximum) => {
    if (cumulative !== null && maximum !== null) {
      return round((cumulative / maximum) * 100);
    }
    return null;
  }
);

// #endregion

// #hooks

export const useLTCOperations = () => {
  const ltcOperations = useAppSelector(getLtcOperationsSelector);
  const percentageUsed = useAppSelector(getLtcPercentageUsedSelector);

  return {
    ltcOperations,
    percentageUsed
  };
};

export const useDGAOperations = () => {
  const dgaOperations = useAppSelector(getDgaOperationsSelector);

  return { dgaOperations };
};

export const useHistorySwitchingOperations = () => {
  const historySwitching = useAppSelector(getSwitchingOperationsSelector);

  return { historySwitching };
};

//
