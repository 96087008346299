// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { MessageDescriptor, injectIntl, IntlShape } from "react-intl";
import { Tree as AntdTree } from "antd";
import { TreeProps } from "antd/lib/tree";

import "./Tree.less";
export interface ITreeNode {
  id: string;
  message: MessageDescriptor;
  selected?: boolean;
  options?: ITreeNode[];
}

interface ITreeNodesProps {
  items: ITreeNode[];
}

export interface ITreeProps extends ITreeNodesProps, TreeProps {
  expandedKeys?: string[];
  defaultValue?: string[];
  onChange?: (value: string[]) => void;
  onAllKeysCheckedChanged?: (checkedAll: boolean) => void;
  onInit?: (value: string[]) => void;
}

export interface ITreeIntlProps extends ITreeProps {
  intl: IntlShape;
}

export interface ITreeState {
  expandedKeys: string[];
  checkedKeys: string[];
  allKeysChecked: boolean;
}

class Tree extends React.Component<ITreeIntlProps, ITreeState> {
  private allOptionsCount: number;

  constructor(props: ITreeIntlProps) {
    super(props);
    this.setAllOptionsCount();

    this.state = {
      expandedKeys: props.expandedKeys,
      checkedKeys: props.defaultValue,
      allKeysChecked: this.allOptionsCount <= props.defaultValue.length
    };
  }

  componentDidMount() {
    const { onInit, onAllKeysCheckedChanged } = this.props;
    const { checkedKeys } = this.state;
    if (onInit) onInit(checkedKeys);
    if (onAllKeysCheckedChanged)
      onAllKeysCheckedChanged(this.state.allKeysChecked);
  }

  render() {
    const { onInit, items, ...antdProps } = this.props;
    const { checkedKeys, expandedKeys } = this.state;
    return (
      <AntdTree
        checkable
        expandedKeys={expandedKeys}
        checkedKeys={checkedKeys}
        onCheck={this.handleChecked}
        {...antdProps}
      >
        {this.treeNodes(items)}
      </AntdTree>
    );
  }

  componentDidUpdate(prevProps: ITreeProps) {
    if (prevProps.items !== this.props.items) {
      this.setAllOptionsCount();
    }
  }

  private treeNodes = (items: ITreeNode[], level = 0) => {
    const { intl } = this.props;
    return items.map((item) => {
      if (item.options) {
        return (
          <AntdTree.TreeNode
            title={intl.formatMessage(item.message)}
            key={item.id}
            data-qa={`node node-${level} ${item.message.id}`}
          >
            {this.treeNodes(item.options, level + 1)}
          </AntdTree.TreeNode>
        );
      } else {
        return (
          <AntdTree.TreeNode
            title={intl.formatMessage(item.message)}
            key={item.id}
            data-qa={`node node-${level} ${item.message.id}`}
          />
        );
      }
    });
  };

  private handleChecked = (checkedKeys: string[]) => {
    const { onChange } = this.props;
    if (onChange) onChange(checkedKeys);

    this.setAllKeysCheckedIfChanged(checkedKeys);
    this.setState({
      checkedKeys
    });
  };

  private setAllOptionsCount() {
    const { items } = this.props;
    this.allOptionsCount = this.getAllItemsCount(items);
  }

  private getAllItemsCount = (items: ITreeNode[]) => {
    let count = 0;
    items.forEach((o) => {
      count++;
      if (o.options) {
        count += this.getAllItemsCount(o.options);
      }
    });
    return count;
  };

  private setAllKeysCheckedIfChanged(checkedKeys: string[]) {
    const { allKeysChecked } = this.state;
    const { onAllKeysCheckedChanged } = this.props;
    if (checkedKeys.length === this.allOptionsCount && !allKeysChecked) {
      this.setState({ allKeysChecked: true });
      if (onAllKeysCheckedChanged) onAllKeysCheckedChanged(true);
    } else if (checkedKeys.length < this.allOptionsCount && allKeysChecked) {
      this.setState({ allKeysChecked: false });
      if (onAllKeysCheckedChanged) onAllKeysCheckedChanged(false);
    }
  }
}

export default injectIntl(Tree);
