// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import FieldTypes from "../../../models/FieldTypes";
import IConfiguration from "../models/IConfiguration";
import assetSubTypes from "./assetSubTypes";
import manufacturers from "./manufacturers";

export const nameplateConfiguration: IConfiguration = {
  forms: [
    {
      formKey: "nameplate",
      enterDataWithCurrentDate: true,
      sections: [
        {
          span: { xs: 24, sm: 12, lg: 8 },
          sectionList: [
            {
              sectionName: {
                defaultMessage: "Title",
                id: "configuration_tool.tab.nameplate.section.title"
              },
              sectionType: "list",
              span: { xs: 24, sm: 12, lg: 8 },
              fields: [
                {
                  fieldName: {
                    id: "asset.nameplate.asset_id",
                    defaultMessage: "Asset ID"
                  },
                  fieldKey: "AssetId",
                  fieldType: FieldTypes.NameplateField,
                  dataType: "String",
                  inputType: "String",
                  disabledInEditMode: true,
                  rules: [
                    { required: true, whitespace: true },
                    {
                      type: "string",
                      max: 128
                    }
                  ]
                }
              ]
            },
            {
              sectionName: {
                defaultMessage: "Classification",
                id: "configuration_tool.tab.nameplate.section.classification"
              },
              sectionType: "list",
              span: { xs: 24, sm: 12, lg: 8 },
              fields: [
                {
                  fieldName: {
                    id: "asset.nameplate.name",
                    defaultMessage: "Name"
                  },
                  fieldKey: "Name",
                  fieldType: FieldTypes.NameplateField,
                  dataType: "String",
                  inputType: "String",
                  rules: [{ required: true, whitespace: true }]
                },
                {
                  fieldName: {
                    id: "asset.nameplate.description",
                    defaultMessage: "Description"
                  },
                  fieldKey: "Description",
                  fieldType: FieldTypes.NameplateField,
                  dataType: "String",
                  inputType: "String",
                  rules: [{ required: true, whitespace: true }]
                },
                {
                  fieldName: {
                    id: "asset.nameplate.asset_type",
                    defaultMessage: "Asset Type"
                  },
                  fieldKey: "AssetType",
                  fieldType: FieldTypes.NameplateField,
                  dataType: "String",
                  inputType: "String",
                  rules: [{ required: true, whitespace: true }],
                  disabled: true
                },
                {
                  fieldName: {
                    id: "asset.nameplate.assetsubtype",
                    defaultMessage: "Asset Subtype"
                  },
                  fieldKey: "AssetSubType",
                  fieldType: FieldTypes.NameplateField,
                  dataType: "String",
                  inputType: "list",
                  listValues: assetSubTypes,
                  rules: [{ required: true, whitespace: true }]
                }
              ]
            },
            {
              sectionName: {
                defaultMessage: "Age",
                id: "configuration_tool.tab.nameplate.section.age"
              },
              sectionType: "list",
              span: { xs: 24, sm: 12, lg: 8 },
              fields: [
                {
                  fieldName: {
                    id: "asset.nameplate.customer_expected_life",
                    defaultMessage: "Customer Expected Life"
                  },
                  fieldKey: "CustomerExpectedLife",
                  fieldType: FieldTypes.NameplateField,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [
                    {
                      type: "number",
                      min: 0
                    }
                  ],
                  unit: "Years"
                },
                {
                  fieldName: {
                    id: "asset.nameplate.manufacturer_expected_life",
                    defaultMessage: "Manufacturer Expected Life"
                  },
                  fieldKey: "ManufacturerExpectedLife",
                  fieldType: FieldTypes.NameplateField,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [
                    {
                      type: "number",
                      min: 0
                    }
                  ],
                  unit: "Years"
                },
                {
                  fieldName: {
                    id: "asset.nameplate.install_date",
                    defaultMessage: "Install Date"
                  },
                  fieldKey: "InstallDate",
                  fieldType: FieldTypes.NameplateField,
                  dataType: "DateTime",
                  inputType: "DateTime",
                  rules: [{ required: true }],
                  disableFutureDates: true
                },
                {
                  fieldName: {
                    id: "asset.nameplate.manufactured_date",
                    defaultMessage: "Manufactured Date"
                  },
                  fieldKey: "ManufacturedDate",
                  fieldType: FieldTypes.NameplateField,
                  dataType: "DateTime",
                  inputType: "DateTime",
                  rules: [{ required: true }],
                  disableFutureDates: true
                },
                {
                  fieldName: {
                    id: "asset.nameplate.nameplate_reference_date",
                    defaultMessage: "Nameplate Reference Date"
                  },
                  fieldKey: "NameplateReferenceDate",
                  fieldType: FieldTypes.NameplateField,
                  dataType: "DateTime",
                  inputType: "DateTime",
                  rules: [{ required: true }],
                  disableFutureDates: true
                }
              ]
            }
          ]
        },
        {
          span: { xs: 24, sm: 12, lg: 8 },
          sectionList: [
            {
              sectionName: {
                defaultMessage: "Localization",
                id: "configuration_tool.tab.nameplate.section.localization"
              },
              sectionType: "list",
              span: { xs: 24, sm: 12, lg: 8 },
              fields: [
                {
                  fieldName: {
                    id: "asset.nameplate.region",
                    defaultMessage: "Region"
                  },
                  fieldKey: "Region",
                  fieldType: FieldTypes.NameplateField,
                  dataType: "String",
                  inputType: "String",
                  rules: [{ whitespace: true }]
                },
                {
                  fieldName: {
                    id: "asset.nameplate.organization",
                    defaultMessage: "Organization"
                  },
                  fieldKey: "Organization",
                  fieldType: FieldTypes.NameplateField,
                  dataType: "String",
                  inputType: "String",
                  rules: [{ required: true, whitespace: true }]
                },
                {
                  fieldName: {
                    id: "asset.nameplate.location",
                    defaultMessage: "Location"
                  },
                  fieldKey: "Location",
                  fieldType: FieldTypes.NameplateField,
                  dataType: "String",
                  inputType: "String",
                  rules: [{ required: true, whitespace: true }]
                },
                {
                  fieldName: {
                    id: "asset.nameplate.facility",
                    defaultMessage: "Facility"
                  },
                  fieldKey: "Facility",
                  fieldType: FieldTypes.NameplateField,
                  dataType: "String",
                  inputType: "String",
                  rules: [{ whitespace: true }]
                },
                {
                  fieldName: {
                    id: "asset.nameplate.unit",
                    defaultMessage: "Unit"
                  },
                  fieldKey: "Unit",
                  fieldType: FieldTypes.NameplateField,
                  dataType: "String",
                  inputType: "String",
                  rules: [{ whitespace: true }]
                },
                {
                  fieldName: {
                    id: "asset.nameplate.longitude",
                    defaultMessage: "Longitude"
                  },
                  fieldKey: "Longitude",
                  fieldType: FieldTypes.NameplateField,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ required: true }]
                },
                {
                  fieldName: {
                    id: "asset.nameplate.latitude",
                    defaultMessage: "Latitude"
                  },
                  fieldKey: "Latitude",
                  fieldType: FieldTypes.NameplateField,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [{ required: true }]
                }
              ]
            },
            {
              sectionName: {
                defaultMessage: "Status",
                id: "asset.nameplate.status"
              },
              sectionType: "list",
              span: { xs: 24, sm: 12, lg: 8 },
              fields: [
                {
                  fieldName: {
                    id: "asset.nameplate.importance",
                    defaultMessage: "Importance"
                  },
                  fieldKey: "Importance",
                  fieldType: FieldTypes.NameplateField,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [
                    { required: true },
                    {
                      type: "number",
                      min: 0,
                      max: 100
                    }
                  ],
                  tooltip: {
                    id: "configuration_tool.tab.nameplate.importance.tooltip",
                    defaultMessage: "Expected range 0-100"
                  }
                },
                {
                  fieldName: {
                    id: "asset.nameplate.status",
                    defaultMessage: "Status"
                  },
                  fieldKey: "Status",
                  fieldType: FieldTypes.NameplateField,
                  dataType: "String",
                  inputType: "autoComplete"
                }
              ]
            }
          ]
        },
        {
          span: { xs: 24, sm: 12, lg: 8 },
          sectionList: [
            {
              sectionName: {
                defaultMessage: "Specification",
                id: "configuration_tool.tab.nameplate.section.specification"
              },
              sectionType: "list",
              span: { xs: 24, sm: 12, lg: 8 },
              fields: [
                {
                  fieldName: {
                    id: "asset.nameplate.system",
                    defaultMessage: "System"
                  },
                  fieldKey: "System",
                  fieldType: FieldTypes.NameplateField,
                  dataType: "String",
                  inputType: "String",
                  rules: [{ required: true, whitespace: true }],
                  tooltip: {
                    id: "configuration_tool.tab.nameplate.system.tooltip",
                    defaultMessage: "Text field"
                  }
                },
                {
                  fieldName: {
                    id: "asset.nameplate.voltage",
                    defaultMessage: "Asset Voltage"
                  },
                  fieldKey: "Voltage",
                  fieldType: FieldTypes.NameplateField,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [
                    { required: true },
                    {
                      type: "number",
                      min: 0
                    }
                  ],
                  unit: "kV"
                },
                {
                  fieldName: {
                    id: "asset.nameplate.rated_power",
                    defaultMessage: "Rated Power"
                  },
                  fieldKey: "RatedPower",
                  fieldType: FieldTypes.NameplateField,
                  dataType: "Decimal",
                  inputType: "Decimal",
                  rules: [
                    { required: true },
                    {
                      type: "number",
                      min: 0
                    }
                  ],
                  unit: "MVA"
                },
                {
                  fieldName: {
                    id: "asset.nameplate.manufacturer",
                    defaultMessage: "Manufacturer"
                  },
                  fieldKey: "Manufacturer",
                  fieldType: FieldTypes.NameplateField,
                  dataType: "String",
                  inputType: "list",
                  rules: [{ required: true, whitespace: true }],
                  listValues: manufacturers
                },
                {
                  fieldName: {
                    id: "asset.nameplate.serial_number",
                    defaultMessage: "Serial Number"
                  },
                  fieldKey: "SerialNumber",
                  fieldType: FieldTypes.NameplateField,
                  dataType: "String",
                  inputType: "String",
                  rules: [
                    { required: true, whitespace: true },
                    {
                      type: "string",
                      max: 50
                    }
                  ]
                }
              ]
            },
            {
              sectionName: {
                defaultMessage: "Custom",
                id: "configuration_tool.tab.nameplate.section.custom"
              },
              sectionType: "attributes"
            }
          ]
        }
      ]
    }
  ]
};
