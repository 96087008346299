// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Reducer } from "redux/index";

import { Actions } from "core/app/actions/MapProviderActions";
import Data from "core/data/models/Data";
import { IAjaxAction } from "reducers/Index";

export interface IMapProvider {
  Type: "Bing" | "OpenStreetMaps" | "Google";
  Key: string;
  MapProviderUriSchema: string;
}

export interface IState {
  mapProvider: Data<IMapProvider>;
}

const mapProviderReducer: Reducer<IState> = (
  state: IState,
  action: IAjaxAction<IMapProvider>
) => {
  switch (action.type) {
    case Actions.MapProvider_SetMapProvider:
      state = Object.assign({}, state, {
        mapProvider: new Data(action.request, action.data)
      } as IState);
      return state;
    default:
      return state;
  }
};

export default mapProviderReducer;
