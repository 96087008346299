// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FormattedMessage } from "react-intl";

interface IConfigurationBoxHeaderProps {
  headerName: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

const ConfigurationBoxHeader = ({
  headerName,
  checked,
  disabled,
  onChange
}: IConfigurationBoxHeaderProps) => {
  const handleChange = (e: CheckboxChangeEvent) => {
    if (onChange) onChange(e.target.checked);
  };

  return (
    <div className="header">
      <span className="header-checkbox">
        <Checkbox
          checked={checked}
          disabled={disabled}
          onChange={handleChange}
        ></Checkbox>
        <FormattedMessage id="notifications.all" defaultMessage="All" />
      </span>
      <span className="header-name">
        <span>{headerName}</span>
        <span>*</span>
      </span>
    </div>
  );
};

export default ConfigurationBoxHeader;
