// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { IAction } from "features/issues/reducers/IssuesReducer";

import ActionsList from "./ActionsList";

interface IActionsSucceededProps {
  actions: IAction[];
}

const ActionsSucceeded = ({ actions }: IActionsSucceededProps) => {
  const intl = useIntl();

  return (
    <div className="actions-list">
      <h4 data-qa="issues-action-panel-actionslabel">
        <FormattedMessage
          defaultMessage="Actions ({numberOfActions})"
          id="issues_page.slide_panel.actions.header"
          values={{
            numberOfActions: intl.formatNumber(actions.length || 0)
          }}
        />
      </h4>
      <ActionsList actions={actions} />
    </div>
  );
};

export default ActionsSucceeded;
