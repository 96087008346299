// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";

// containers
import RogersRatioContainer from "features/detailpage/features/rogersratio/containers/RogersRatioContainer";

interface IRogersRatioTabProps {
  assetId: string;
}

const RogersRatioTab: React.FC<IRogersRatioTabProps> = ({ assetId }) => {
  return <RogersRatioContainer assetId={assetId} />;
};

export default RogersRatioTab;
