// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { PbiEmbeddedReportFilter } from "common/powerbi/PbiEmbeddedReport";
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import ILinkedReport from "core/app/models/ILinkedReport";
import Data, { Statuses } from "core/data/models/Data";
import { ReportsModes } from "features/detailpage/features/reports/models/ReportsModes";
import IReport from "features/detailpage/models/IReport";
import { isEmpty } from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import onClickOutsideHOC, {
  HandleClickOutside,
  InjectedOnClickOutProps
} from "react-onclickoutside";
import ScrollArea from "react-scrollbar";
import buildAssetFilter from "../utils/buildAssetFilter";
import removeReportPrefix from "../utils/removeReportPrefix";
import "./Reports.less";

export interface IReportsStateProps {
  pbiReports?: Data<IReport[]>;
  linkedReports?: Data<ILinkedReport[]>;
}

export interface IReportsActions {
  loadPbiReports?: (assetType: string) => void;
  loadLinkedReports?: (assetId: string) => void;
}
interface IReportsProps extends IReportsStateProps, IReportsActions {
  Header: () => JSX.Element;
  emptyReportsTranslationId?: string;
  emptyReportsDefaultText?: string;
  showDropdownChevron?: boolean;
  assetId?: string;
  assetType?: string;
  reportsMode?: ReportsModes;
  onPbiReportClicked?: (
    report: IReport,
    filters?: PbiEmbeddedReportFilter[]
  ) => void;
  loadImmediately?: boolean;
}

interface IReportsState {
  showReports: boolean;
}

export class Reports
  extends React.PureComponent<
    IReportsProps & InjectedOnClickOutProps,
    IReportsState
  >
  implements HandleClickOutside<any>
{
  static defaultProps: IReportsProps = {
    Header: null,
    showDropdownChevron: true,
    emptyReportsTranslationId: "widget.messages.no_data",
    emptyReportsDefaultText: "No data available"
  };

  state: IReportsState = {
    showReports: false
  };

  public render(): JSX.Element {
    return this.getReportsComponent();
  }

  public componentDidUpdate(
    prevProps: IReportsProps & InjectedOnClickOutProps,
    prevState: IReportsState
  ) {
    if (
      this.state.showReports &&
      prevState.showReports !== this.state.showReports
    ) {
      this.loadReports();
    }
  }

  public handleClickOutside() {
    this.setState({ showReports: false });
  }

  private loadReports() {
    const { reportsMode } = this.props;

    switch (reportsMode) {
      case ReportsModes.EmbeddedReports:
        this.loadPbiReports();
        break;
      case ReportsModes.ExternalReports:
        this.loadLinkedReports();
        break;
      default:
        this.loadPbiReports();
        this.loadLinkedReports();
    }
  }

  private loadPbiReports(): void {
    const { pbiReports, loadPbiReports, assetType } = this.props;
    if (
      pbiReports.status !== Statuses.Succeeded &&
      loadPbiReports &&
      assetType
    ) {
      loadPbiReports(assetType);
    }
  }

  private loadLinkedReports(): void {
    const { linkedReports, loadLinkedReports, assetId } = this.props;
    if (
      linkedReports.status !== Statuses.Succeeded &&
      loadLinkedReports &&
      assetId
    ) {
      loadLinkedReports(assetId);
    }
  }

  private getReportsComponent(): JSX.Element {
    const { showReports } = this.state;
    return (
      <div className="reports-drop-down reports" data-qa="reports-tile">
        {this.getHeaderComponent()}
        {showReports && (
          <div className="list-container">
            <div className="report-list" data-qa="reports-list-tile">
              <ScrollArea horizontal={false} smoothScrolling={true}>
                {this.getReportsByReportsMode()}
              </ScrollArea>
            </div>
          </div>
        )}
      </div>
    );
  }

  private getReportsByReportsMode(): JSX.Element {
    const { reportsMode, pbiReports, linkedReports } = this.props;

    switch (reportsMode) {
      case ReportsModes.EmbeddedReports:
        return (
          <>
            {pbiReports?.status === Statuses.Loading && (
              <Processing className="spinner small dark" />
            )}
            {pbiReports?.status === Statuses.Succeeded &&
              this.getPbiReportsComponent()}
            {pbiReports?.status === Statuses.Failed && <WidgetErrorMessage />}
            {pbiReports?.status === Statuses.NotFound && (
              <WidgetNoDataMessage />
            )}
          </>
        );
      case ReportsModes.ExternalReports:
        return (
          <>
            {linkedReports?.status === Statuses.Loading && (
              <Processing className="spinner small dark" />
            )}
            {linkedReports?.status === Statuses.Succeeded &&
              this.getLinkedReportsComponent()}
            {linkedReports?.status === Statuses.Failed && (
              <WidgetErrorMessage />
            )}
            {linkedReports?.status === Statuses.NotFound && (
              <WidgetNoDataMessage />
            )}
          </>
        );
      default:
        return (
          <>
            {(linkedReports?.status === Statuses.Loading ||
              pbiReports?.status === Statuses.Loading) && (
              <Processing className="spinner small dark" />
            )}
            {linkedReports?.status === Statuses.Succeeded &&
              pbiReports?.status === Statuses.Succeeded && (
                <>
                  {this.getPbiReportsComponent()}
                  {this.getLinkedReportsComponent()}
                </>
              )}
            {(linkedReports?.status === Statuses.Failed ||
              pbiReports?.status === Statuses.Failed) && <WidgetErrorMessage />}
            {(linkedReports?.status === Statuses.NotFound ||
              pbiReports?.status === Statuses.NotFound) && (
              <WidgetNoDataMessage />
            )}
          </>
        );
    }
  }

  private getHeaderComponent(): JSX.Element {
    const { Header } = this.props;
    return (
      <div className="drop-down" onClick={this.handleHeaderClick}>
        <div className="reports-title navbar-item-label enabled">
          <Header />
          {this.props.showDropdownChevron && (
            <Icon
              className={this.state.showReports ? "top" : ""}
              dataQa="expand-icon"
              name="arrow_drop_down"
            />
          )}
        </div>
      </div>
    );
  }

  private getPbiReportsComponent(): JSX.Element {
    const {
      pbiReports: { data }
    } = this.props;
    if (!isEmpty(data)) {
      return (
        <div className="pbi-reports" data-qa="pbi-reports">
          <ul>{this.getPbiReportsList(data)}</ul>
        </div>
      );
    } else {
      return this.getEmptyReportsComponent();
    }
  }

  private getPbiReportsList(data: IReport[]): JSX.Element[] {
    return data.map((r) => (
      <li
        key={r.Id}
        data-qa="report"
        className="pbi-report-item"
        onClick={this.onPbiReportItemClicked.bind(this, r)}
      >
        <FormattedMessage
          id={"reports." + r.Name}
          defaultMessage={removeReportPrefix(r.Name)}
        />
      </li>
    ));
  }

  private getLinkedReportsComponent(): JSX.Element {
    const {
      linkedReports: { data, status }
    } = this.props;
    if (!isEmpty(data)) {
      return (
        <div className="linked-reports" data-qa="linked-reports">
          {status === Statuses.Loading && <Processing />}
          {status === Statuses.Succeeded && (
            <ul>{this.getLinkedReportsList(data)}</ul>
          )}
        </div>
      );
    } else {
      return this.getEmptyReportsComponent();
    }
  }

  private getEmptyReportsComponent(): JSX.Element {
    const { emptyReportsTranslationId, emptyReportsDefaultText } = this.props;
    return (
      <div className="empty-reports">
        <FormattedMessage
          id={emptyReportsTranslationId}
          defaultMessage={emptyReportsDefaultText}
        />
      </div>
    );
  }

  private getLinkedReportsList(data: ILinkedReport[]): JSX.Element[] {
    return data.map((r) => (
      <li key={r.DisplayName} data-qa="report">
        <div
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            e.stopPropagation();
            this.toggleShowReports();
            window.open(r.Url, "_blank");
          }}
        >
          {r.DisplayName}
        </div>
      </li>
    ));
  }

  private handleHeaderClick = () => {
    this.toggleShowReports();
  };

  private toggleShowReports = (): void => {
    this.setState({ showReports: !this.state.showReports });
  };

  private onPbiReportItemClicked = (report: IReport): void => {
    this.toggleShowReports();

    const { onPbiReportClicked, assetId } = this.props;
    const filters = buildAssetFilter(assetId);

    if (onPbiReportClicked) {
      onPbiReportClicked(report, filters);
    }
  };
}

export default onClickOutsideHOC(Reports);
