// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import {
  Parameter,
  ParametersGroup
} from "@apm/widgets/build/components/Parameters";
import IParameterValue from "@apm/widgets/build/components/Parameters/models/IParameterValue";
import Value from "@apm/widgets/build/components/Parameters/models/Value";
import { isNil } from "lodash";
import { FormattedMessage, IntlShape } from "react-intl";
import mapToParameterOptions from "../../../utils/mapToParameterOptions";
import mapToThreshold from "../../../utils/mapToThreshold";
import mapToThresholdCorrection from "../../../utils/mapToThresholdCorrection";
import IWearData from "../models/IWearData";
import IWearParameter from "../models/IWearParameter";
import IWearParameterValue from "../models/IWearParameterValue";

export default class WearParametersMapper {
  constructor(private intl: IntlShape) {}

  public map({ ColumnsNames, Parameters }: IWearData): ParametersGroup {
    return {
      columns: ColumnsNames.map((cn) =>
        cn
          ? {
              block: (
                <div data-qa="parameter-column-header">
                  <FormattedMessage
                    defaultMessage={cn}
                    id={`detail_page.cbk.wear.parameters.${cn}`}
                  />
                </div>
              ),
              inline: this.intl.formatMessage({
                id: `detail_page.cbk.wear.parameters.${cn}`,
                defaultMessage: cn
              })
            }
          : undefined
      ),
      parameters: Parameters.map((p) => this.mapParameters(p))
    };
  }

  private mapParameters({ Name, Unit, Values }: IWearParameter): Parameter {
    return {
      id: Name,
      name: this.intl.formatMessage({
        id: `detail_page.cbk.wear.parameters.${Name}`,
        defaultMessage: Name
      }),
      unit: Unit,
      values: Values.map((p) => this.mapValues(p))
    };
  }

  private mapValues(parameterValue: IWearParameterValue): IParameterValue {
    const externalId = parameterValue?.ExternalId;
    const options = mapToParameterOptions(parameterValue?.Options);
    const thresholds = parameterValue?.Thresholds?.map(mapToThreshold);
    const thresholdCorrection = mapToThresholdCorrection(
      parameterValue?.UserThresholdCorrection
    );

    const value: Value = !isNil(parameterValue?.Value)
      ? parameterValue?.DataType === "Dot"
        ? {
            type: "dot",
            value: parameterValue?.Value.toString().toLowerCase() as
              | "problem"
              | "caution"
              | "normal"
          }
        : parameterValue?.DataType === "Date"
        ? this.intl.formatDate(parameterValue?.Value as string)
        : Number(parameterValue?.Value)
      : null;

    return {
      externalId,
      options,
      thresholdCorrection,
      thresholds,
      value,
      valueDate: parameterValue?.ParameterDate
        ? new Date(parameterValue?.ParameterDate)
        : undefined
    };
  }
}
