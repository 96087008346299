// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { isEmpty } from "lodash";
import { useEffect } from "react";
import { connect } from "react-redux";

import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import BushingOfflineCharts from "features/detailpage/features/bushingsoffline/components/BushingOfflineTab/BushingOfflineCharts";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import { loadTransformerParametersAction } from "../../actions/BushingOfflineActions";
import IBushingOfflineDataResult from "../../models/IBushingOfflineDataResult";
import getTransformerParametersSelector from "../../selectors/getTransformerParametersSelector";
import "./BushingOfflineTab.less";

export interface IBushingOfflineTabDataProps {
  transformerParameters: Data<IBushingOfflineDataResult[]>;
}

export interface IBushingOfflineTabActionsProps {
  loadTransformerParameters: (assetId: string) => void;
}

export interface IBushingOfflineTabDataOwnProps {
  assetId: string;
}

type BushingOfflineComponentProps = IBushingOfflineTabDataOwnProps &
  IBushingOfflineTabActionsProps &
  IBushingOfflineTabDataProps;

const BushingOfflineComponent = ({
  assetId,
  transformerParameters,
  loadTransformerParameters
}: BushingOfflineComponentProps) => {
  useEffect(() => {
    loadTransformerParameters(assetId);
  }, [assetId, loadTransformerParameters]);

  switch (transformerParameters?.status) {
    case Statuses.Loading:
      return <Processing />;
    case Statuses.Succeeded:
      return isEmpty(transformerParameters?.data) ? (
        <WidgetNoDataMessage />
      ) : (
        <BushingOfflineComponentSucceded
          offlineBushingModelResult={transformerParameters?.data}
        />
      );
    case Statuses.Failed:
      return <WidgetErrorMessage />;
    default:
      return <WidgetNoDataMessage />;
  }
};

interface IBushingOfflineComponentProps {
  offlineBushingModelResult: IBushingOfflineDataResult[];
}

const BushingOfflineComponentSucceded = ({
  offlineBushingModelResult
}: IBushingOfflineComponentProps) => {
  return (
    <div className="bushing-offline" data-qa="bushing-offline">
      <BushingOfflineCharts
        offlineBushingModelResult={offlineBushingModelResult}
      />
    </div>
  );
};

const mapStateToProps = (
  state: IState,
  { assetId }: IBushingOfflineTabDataOwnProps
): IBushingOfflineTabDataProps => ({
  transformerParameters: getTransformerParametersSelector(state)
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): IBushingOfflineTabActionsProps => ({
  loadTransformerParameters: (assetId: string) =>
    dispatch(loadTransformerParametersAction(assetId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BushingOfflineComponent);
