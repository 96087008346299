// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";
import { FormattedMessage, FormattedDate } from "react-intl";
import { isEmpty } from "lodash";

import Data, { Statuses } from "core/data/models/Data";
import Processing from "components/common/Processing";
import IDGAOperations from "features/detailpage/features/ltc/models/IDGAOperations";
import IOperations from "features/detailpage/features/ltc/models/IOperations";
import {
  WidgetNoDataMessage,
  WidgetErrorMessage
} from "components/common/widget/Widget";
import ParameterName from "common/formatters/ParameterName";

import "./DGAOperations.less";

interface IDGAOperationsRowProps {
  name: string;
  previousValue: number;
  currentValue: number;
  unit: string;
  modelId: string;
  implementationId: string;
}

const DGAOperationsRow = ({
  name,
  previousValue,
  currentValue,
  unit,
  modelId,
  implementationId
}: IDGAOperationsRowProps) => (
  <div className="grid-row grid-row__ltc-dga-row">
    <div className="grid-cell name">
      <ParameterName nameId={name} modelId={modelId} implementationId={implementationId} />
    </div>
    <div className="grid-cell value">
      {previousValue !== null && (
        <FormattedMessage
          id="detail_page.widgets.analytics.transformers.ltc.dga_operations_value"
          defaultMessage="{value} {unit}"
          values={{
            value: previousValue,
            unit
          }}
        />
      )}
    </div>
    <div className="grid-cell value">
      {currentValue !== null && (
        <FormattedMessage
          id="detail_page.widgets.analytics.transformers.ltc.dga_operations_value"
          defaultMessage="{value} {unit}"
          values={{
            value: currentValue,
            unit
          }}
        />
      )}
    </div>
  </div>
);

interface IDGAOperationsComponentProps {
  dgaOperations: IDGAOperations;
  modelId: string;
  implementationId: string;
}

const DGAOperationsComponent = ({
  dgaOperations: { CurrentDate, Operations, PreviousDate },
  modelId,
  implementationId
}: IDGAOperationsComponentProps) => (
  <div className="grid-custom dga-operations" data-qa="dga-operations">
    <div className="grid-header">
      <div className="grid-row">
        <div className="grid-cell name dga-operations-header__title">
          <FormattedMessage
            id="detail_page.widgets.analytics.transformers.ltc.dga_operations"
            defaultMessage="DGA"
          />
        </div>
        <div className="grid-cell value dga-operations-header__date-header">
          <div>
            <FormattedMessage
              id="detail_page.widgets.analytics.transformers.ltc.dga_operations_previous"
              defaultMessage="Previous"
            />
          </div>
          {!isEmpty(PreviousDate) && (
            <div>
              {"("}
              <FormattedDate value={PreviousDate} />
              {")"}
            </div>
          )}
        </div>
        <div className="grid-cell value dga-operations-header__date-header">
          <div>
            <FormattedMessage
              id="detail_page.widgets.analytics.transformers.ltc.dga_operations_current"
              defaultMessage="Current"
            />
          </div>
          <div>
            {"("}
            <FormattedDate value={CurrentDate} />
            {")"}
          </div>
        </div>
      </div>
    </div>
    <div className="grid-body">
      {!isEmpty(Operations) &&
        Operations.map((operation: IOperations, index: number) => (
          <DGAOperationsRow
            key={`${operation.Name}_${index}`}
            name={operation.Name}
            currentValue={operation.CurrentValue}
            previousValue={operation.PreviousValue}
            unit={operation.Unit}
            modelId={modelId}
            implementationId={implementationId}
          />
        ))}
    </div>
  </div>
);

interface IDGAOperationsStateProps {
  dgaOperations: Data<IDGAOperations>;
}

interface IDGAOperationsProps extends IDGAOperationsStateProps {
  modelId: string;
  implementationId: string;
}

export default ({ dgaOperations, modelId, implementationId }: IDGAOperationsProps) => {
  switch (dgaOperations.status) {
    case Statuses.Loading:
      return <Processing className="spinner small dark" />;
    case Statuses.Succeeded:
      return isEmpty(dgaOperations.data) ? (
        <WidgetNoDataMessage />
      ) : (
        <DGAOperationsComponent
          dgaOperations={dgaOperations.data}
          modelId={modelId}
          implementationId={implementationId}
        />
      );
    case Statuses.NotFound:
      return <WidgetNoDataMessage />;
    case Statuses.Failed:
      return <WidgetErrorMessage />;
    default:
      return (
        <div className="tile">
          <div className="tile-content empty" />
        </div>
      );
  }
};
