// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import SF6Chart from "@apm/widgets/build/widgets/SF6Chart";
import { ITrendChart } from "common/chart/components/TrendLineChart";
import { useIntl } from "react-intl";
import { IModelDetails } from "../../DielectricTab/DielectricTab";
import useMoistureChart from "../hooks/useMoistureChart";

interface IMoistureChartProps {
  title?: string;
  trendChart: ITrendChart;
  showWidgetCard?: boolean;
  height?: number;
  model: IModelDetails;
}

const MoistureChart = ({
  title,
  trendChart,
  showWidgetCard,
  height,
  model
}: IMoistureChartProps) => {
  const intl = useIntl();

  const {
    series,
    regions,
    lines,
    formatXTick,
    formatTooltipTitle,
    emptyTranslation
  } = useMoistureChart({ trendChart, model });

  return (
    <SF6Chart
      title={title}
      series={series}
      regions={regions}
      lines={lines}
      formatXTick={formatXTick}
      formatTooltipTitle={formatTooltipTitle}
      adjustYSizeToThresholds={true}
      showWidgetCard={showWidgetCard}
      height={height}
      translations={{
        adjustToThresholds: intl.formatMessage({
          defaultMessage: "Adjust to thresholds",
          id: "detail_page.adjust_to_thresholds"
        }),
        empty: emptyTranslation
      }}
    />
  );
};

export default MoistureChart;
