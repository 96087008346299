// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { find, first } from "lodash";
import React from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import onClickOutsideHOC, {
  HandleClickOutside,
  InjectedOnClickOutProps
} from "react-onclickoutside";

import { IMessage } from "common/form/models/IMessage";

import "./Select.less";

export enum Themes {
  Primary,
  Light
}

export interface ISelectOption {
  Component?: React.ComponentType;
  hidden?: boolean;
  label?: MessageDescriptor;
  value: any;
}

export interface ISelectProps {
  className?: string;
  selectOptionClassName?: string;
  defaultValue?: any;
  disabled?: boolean;
  label?: MessageDescriptor;
  required?: boolean;
  messages?: IMessage[];
  onBlur?: () => void;
  onChange?: (value: any) => void;
  onInit?: (value: any) => void;
  options: ISelectOption[];
  theme?: Themes;
}

export interface ISelectState {
  selectedOption: ISelectOption;
  showOptions: boolean;
  top: number;
}

interface ILabeledSelectOptionProps {
  className?: string;
  active?: boolean;
  label: MessageDescriptor;
}

const LabeledSelectOption = ({
  label,
  className,
  active
}: ILabeledSelectOptionProps) => (
  <div
    className={`
    option
    ${active ? "active" : ""}
    ${className}`}
  >
    <FormattedMessage {...label} />
  </div>
);

interface ISelectOptionProps {
  option: ISelectOption;
  active?: boolean;
  labelClassName?: string;
  onClick?: (e: React.MouseEvent<any>, value: any) => void;
}

const SelectOption = ({
  option,
  onClick,
  labelClassName,
  active
}: ISelectOptionProps) => {
  if (option) {
    const { value, label, Component } = option;
    return (
      <div
        className="select-option"
        data-qa={option.value}
        onClick={(e) => {
          if (onClick) onClick(e, value);
        }}
      >
        {Component && <Component />}
        {!Component && (
          <LabeledSelectOption
            label={label}
            className={labelClassName}
            active={active}
          />
        )}
      </div>
    );
  }

  return null;
};

interface ISelectOptionsProps {
  onClick: (e: React.MouseEvent<any>, value: any) => void;
  options: ISelectOption[];
  selectedOption: ISelectOption;
  width: number;
  labelClassName?: string;
}

const SelectOptions = ({
  labelClassName,
  onClick,
  options,
  selectedOption,
  width
}: ISelectOptionsProps) => (
  <div className="select-options" style={{ width }}>
    {options
      .filter((option) => !option.hidden)
      .map((option) => {
        const active =
          selectedOption.value && option.value === selectedOption.value;

        return (
          <SelectOption
            key={option.value}
            option={option}
            onClick={onClick}
            active={active}
            labelClassName={labelClassName}
          />
        );
      })}
  </div>
);

interface ILabelProps {
  label?: MessageDescriptor;
  required?: boolean;
}

const Label = ({ label, required }: ILabelProps) => {
  return (
    <div className="control-label default-grey-label">
      <FormattedMessage {...label} />
      {required && <FormattedMessage defaultMessage="*" id="global.star" />}
    </div>
  );
};

interface ISelectMessagesProps {
  messages: IMessage[];
}

const Messages = ({ messages }: ISelectMessagesProps) => {
  return (
    <div className="select-messages">
      {messages &&
        messages.map((m, i) => (
          <div key={i}>
            <Icon name="error" />
            <span className="text">
              <FormattedMessage {...m.descriptor} />
            </span>
          </div>
        ))}
    </div>
  );
};

class Select
  extends React.PureComponent<
    ISelectProps & InjectedOnClickOutProps,
    ISelectState
  >
  implements HandleClickOutside<any>
{
  private selectContainerRef = React.createRef<HTMLDivElement>();
  private selectOptionsWrapperRef = React.createRef<HTMLDivElement>();

  constructor(props: ISelectProps & InjectedOnClickOutProps) {
    super(props);

    this.state = {
      selectedOption: this.getSelectedOption(props.defaultValue),
      showOptions: false,
      top: undefined
    };
  }

  public render() {
    const {
      options,
      label,
      required,
      disabled,
      className,
      selectOptionClassName,
      theme,
      messages
    } = this.props;
    const { selectedOption, showOptions, top } = this.state;

    const numberOfErrors = this.getNumberOfErrors();

    const themeClassName =
      theme === Themes.Primary
        ? "primary"
        : theme === Themes.Light
        ? "light"
        : "primary";

    return (
      <div
        className={`
          select-control
          ${themeClassName}
          ${className}
          ${disabled ? "disabled" : ""}
          ${numberOfErrors ? "error" : ""}
        `}
      >
        {label && <Label label={label} required={required} />}
        <div className="select-body-wrapper">
          <div
            className="select-container"
            {...this.getSelectProps()}
            ref={this.selectContainerRef}
          >
            <div className="selected-option">
              <SelectOption option={selectedOption} />
            </div>
            <div className="expand-arrow">
              <Icon name="expand_more" size="sm" />
            </div>
          </div>
          <div
            className="select-options-wrapper"
            ref={this.selectOptionsWrapperRef}
            style={{ top }}
          >
            {this.selectContainerRef && showOptions && (
              <SelectOptions
                options={options}
                selectedOption={selectedOption}
                onClick={this.handleOnOptionClick}
                labelClassName={selectOptionClassName}
                width={
                  this.selectContainerRef.current.getBoundingClientRect().width
                }
              />
            )}
          </div>
        </div>
        <Messages messages={messages} />
      </div>
    );
  }

  public componentDidMount() {
    const { defaultValue, onInit } = this.props;
    if (onInit) onInit(defaultValue);
  }

  public componentDidUpdate(
    prevProps: ISelectProps & InjectedOnClickOutProps,
    prevState: ISelectState
  ) {
    if (
      prevState.showOptions !== this.state.showOptions &&
      this.state.showOptions
    ) {
      const top = this.calculateSelectOptionsPosition();
      if (top !== this.state.top) {
        this.setState({ top });
      }
    }
    if (prevProps.defaultValue !== this.props.defaultValue)
      this.setState({
        selectedOption: this.getSelectedOption(this.props.defaultValue)
      });
  }

  public handleClickOutside() {
    const {
      selectedOption: { value },
      showOptions
    } = this.state;
    const { onBlur, onChange } = this.props;

    if (showOptions) {
      this.setState((prevState) =>
        Object.assign({}, prevState, { showOptions: false })
      );

      if (this.requiredValueIsEmpty(value)) {
        if (onChange) onChange(value);
      } else if (onBlur) {
        onBlur();
      }
    }
  }

  private getSelectProps(): {
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
    tabIndex?: number;
  } {
    const { disabled } = this.props;
    return !disabled
      ? {
          tabIndex: 0,
          onClick: this.handleSelectClick,
          onKeyDown: this.handleKeyPressed
        }
      : {};
  }

  private handleOnOptionClick = (e: React.MouseEvent<any>, value: any) => {
    e.stopPropagation();
    this.toggleShowOptions();
    this.setSelectedOption(value);
  };

  private setSelectedOption(value: any) {
    if (
      this.selectedOptionHasChanged(value) ||
      this.requiredValueIsEmpty(value)
    ) {
      const { onChange } = this.props;
      const selectedOption = this.getSelectedOption(value);
      if (onChange) onChange(value);

      this.setState({
        selectedOption
      });
    }
  }

  private handleSelectClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    this.toggleShowOptions();
  };

  private handleKeyPressed = ({ key }: React.KeyboardEvent<HTMLDivElement>) => {
    switch (key) {
      case "Enter":
        this.onEnterPressed();
        break;
    }
  };

  private onEnterPressed() {
    this.toggleShowOptions();
  }

  private toggleShowOptions() {
    this.setState((prevState) =>
      Object.assign({}, prevState, {
        showOptions: !this.state.showOptions,
        top: undefined
      })
    );
  }

  private calculateSelectOptionsPosition() {
    const boundingRectSelectContainer =
      this.selectContainerRef.current.getBoundingClientRect();
    const boudingRectOptionsWrapper =
      this.selectOptionsWrapperRef.current.getBoundingClientRect();
    if (boudingRectOptionsWrapper.bottom > window.innerHeight) {
      return -boudingRectOptionsWrapper.height;
    } else {
      return boundingRectSelectContainer.height;
    }
  }

  private selectedOptionHasChanged(value: any): boolean {
    return this.state.selectedOption.value !== value;
  }

  private requiredValueIsEmpty(value: any): boolean {
    const { required } = this.props;
    return required && (value === null || typeof value === typeof undefined);
  }

  private getSelectedOption(value: any): ISelectOption {
    const { options } = this.props;
    if (value) {
      const selectedOption = find(options, (x) => x.value === value);
      return selectedOption ? selectedOption : first(options);
    }

    return first(options);
  }

  private getNumberOfErrors = () =>
    this.props.messages
      ? this.props.messages.filter((m) => m.type === "error").length
      : 0;
}

export default onClickOutsideHOC(Select);
