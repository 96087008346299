// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as c3 from "c3";
import * as React from "react";

import Guid from "core/guid/Guid";
import IConfigurationExtensions from "../models/IConfigurationExtensions";
import BarChartService from "../services/BarChartService";

require("c3/c3.min.css");

export interface IC3ChartProps {
  className?: string;
  configuration: c3.ChartConfiguration;
  extensions?: IConfigurationExtensions;
  onRendered?: (chart: c3.ChartAPI, container: HTMLDivElement) => void;
  style?: React.CSSProperties;
  chartRef?: React.MutableRefObject<c3.ChartAPI>;
}

export default class C3Chart extends React.Component<IC3ChartProps> {
  constructor(props: IC3ChartProps) {
    super(props);

    this.containerId = `chart-${Guid.getUniqGuid()}`;
  }

  componentDidMount(): void {
    this.generateChart(this.containerId);
  }

  componentDidUpdate(prevProps: IC3ChartProps): void {
    if (prevProps.configuration !== this.props.configuration) {
      this.destroyChart();
      this.generateChart(this.containerId);
    }
  }

  componentWillUnmount(): void {
    this.destroyChart();
  }

  render() {
    const { className, configuration, style } = this.props;
    if (!configuration) {
      console.error("C3 chart configuration is not defined.");
      return null;
    }

    configuration.bindto = "#" + this.containerId;

    return (
      <div
        className={`${className} c3-chart`}
        id={this.containerId}
        ref={this.containerRef}
        style={style}
      />
    );
  }

  private containerId: string;
  private containerRef: React.RefObject<HTMLDivElement> = React.createRef();
  private chart: c3.ChartAPI | null = null;
  private timeout: number | null = null;

  private generateChart(containerId: string, numberOfTry: number = 0): void {
    const { applyExtensions } = this;
    const { configuration, extensions, onRendered } = this.props;
    if (!configuration) return;

    if (!numberOfTry) numberOfTry = 1;

    try {
      this.chart = c3.generate(configuration);
      if (this.chart && extensions)
        applyExtensions(containerId, configuration, extensions);
      if (this.chart && this.containerRef.current && onRendered)
        onRendered(this.chart, this.containerRef.current);
    } catch (e) {
      if (numberOfTry <= 3) {
        this.setTimeout(() => {
          this.generateChart(containerId, numberOfTry + 1);
        }, numberOfTry);
      } else {
        console.error("Error when generating C3 chart", e);
      }
    }
  }

  private applyExtensions(
    containerId: string,
    configuration: c3.ChartConfiguration,
    extensions: IConfigurationExtensions
  ) {
    const { addClassNameToBar } = BarChartService;

    if (
      configuration.data.type === "bar" &&
      extensions &&
      extensions.data &&
      extensions.data.class
    ) {
      addClassNameToBar("#" + containerId, extensions.data.class);
    }
  }

  private destroyChart(): void {
    this.clearTimeout();

    try {
      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }
    } catch (e) {
      console.error("Error when destroying C3 chart", e);
    }
  }

  private clearTimeout(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  private setTimeout(callback: () => void, numberOfTry: number) {
    this.clearTimeout();
    this.timeout = window.setTimeout(() => {
      this.clearTimeout();
      callback();
    }, 200 * numberOfTry);
  }
}
