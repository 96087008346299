// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { connect } from "react-redux";

import Authorization, {
  IAuthorizationData
} from "core/app/components/auth/Authorization";
import { IState } from "reducers/Index";

function mapStateToProps(state: IState): IAuthorizationData {
  return {
    user: state.app.user && state.app.user.data
  };
}

function mapDispatchToProps(): {} {
  return {};
}

const AuthorizationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Authorization);

export default AuthorizationContainer;
