// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IStation } from "@apm/widgets/build/widgets/SpatialView";
import SearchParams from "@pg/common/build/models/SearchParams";
import { SelectedFilters } from "common/FilterBar";
import { routes } from "core/app/components/AppRoutes";
import { useAppNavigate } from "core/app/components/RouterProvider";
import getMapProviderSelector from "core/selectors/getMapProviderSelector";
import { isNil } from "lodash";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useAppDispatch, useAppSelector } from "store";
import {
  loadStationRisk,
  removeStationRisk
} from "../actions/StationRiskActions";
import getStationRiskSelector from "../selectors/getStationRiskSelector";

const formatParam = (value: string | null) =>
  isNil(value) ? null : `"${value}"`;

const useRiskMapDashboard = () => {
  const dispatch = useAppDispatch();
  const [filters, setFilters] = useState<SelectedFilters>();
  const stationRisk = useAppSelector(getStationRiskSelector);
  const mapProvider = useAppSelector(getMapProviderSelector);

  const navigate = useAppNavigate();
  const location = useLocation();

  useEffect(() => {
    if (filters) dispatch(loadStationRisk(filters));

    return () => {
      dispatch(removeStationRisk());
    };
  }, [dispatch, filters]);

  const handleFilter = (filters: SelectedFilters) => {
    setFilters(filters);
  };

  const handleSingleMarkerClick = (station: IStation) => {
    const search = new SearchParams(location.search);
    search.set(
      "f_ao",
      `[${formatParam(station.organization)},${formatParam(
        station.region
      )},${formatParam(station.location)}]`
    );
    search.append("f_as", `[${formatParam("InService")}]`);
    navigate({
      pathname: routes.assets.pathname,
      search: search.toString()
    });
  };

  const handleClusterMarkerClick = (cluster: IStation[]) => {
    const search = new SearchParams(location.search);
    search.delete("f_ao");
    search.delete("f_as");
    cluster.forEach(
      (s) =>
        search.append(
          "f_ao",
          `[${formatParam(s.organization)},${formatParam(
            s.region
          )},${formatParam(s.location)}]`
        ),
      search.append("f_as", `[${formatParam("InService")}]`)
    );
    navigate({
      pathname: routes.assets.pathname,
      search: search.toString()
    });
  };

  return {
    stationRisk,
    mapProvider,
    handleFilter,
    handleSingleMarkerClick,
    handleClusterMarkerClick
  };
};

export default useRiskMapDashboard;
