// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import IPointBase from "./IPointBase";

export default class StackedPoint implements IPointBase {
  x: number;
  yLow: number;
  yMedium: number;
  yHigh: number;
}
