// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IChart } from "@apm/widgets/build/widgets/ParameterCharts";
import { IntlShape } from "react-intl";
import ChartTitle from "../components/ChartTitle";
import { chartUnit } from "../constants/voltageStressSettings";
import ChartGroup from "../models/ChartGroup";
import ChartType from "../models/ChartType";
import IVoltageStressChart from "../models/IVoltageStressChart";
import { TrendTypeEnum } from "../models/TrendType";
import getLastYearReadings from "./getLastYearReadings";
import mapToRegions from "./mapToRegions";

const mapToChart = (
  intl: IntlShape,
  chartType: ChartType,
  chartData: IVoltageStressChart,
  chartGroup: ChartGroup
): IChart => {
  const regions = mapToRegions(intl, chartData?.Thresholds, chartGroup);

  return {
    name: chartType,
    displayName: (
      <ChartTitle chartType={chartType} isModalTitle={false}></ChartTitle>
    ),
    modalDisplayName: (
      <ChartTitle chartType={chartType} isModalTitle={true}></ChartTitle>
    ),
    values: {
      history: {
        regions: regions,
        series: [
          {
            name: TrendTypeEnum.Phase1,
            values: chartData?.Trends.Phase1,
            unit: chartUnit[chartType]
          },
          {
            name: TrendTypeEnum.Phase2,
            values: chartData?.Trends.Phase2,
            unit: chartUnit[chartType]
          },
          {
            name: TrendTypeEnum.Phase3,
            values: chartData?.Trends.Phase3,
            unit: chartUnit[chartType]
          }
        ]
      },
      year: {
        regions: regions,
        series: [
          {
            name: TrendTypeEnum.Phase1,
            values: getLastYearReadings(chartData?.Trends.Phase1),
            unit: chartUnit[chartType]
          },
          {
            name: TrendTypeEnum.Phase2,
            values: getLastYearReadings(chartData?.Trends.Phase2),
            unit: chartUnit[chartType]
          },
          {
            name: TrendTypeEnum.Phase3,
            values: getLastYearReadings(chartData?.Trends.Phase3),
            unit: chartUnit[chartType]
          }
        ]
      }
    }
  };
};

export default mapToChart;
