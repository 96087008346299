// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";

import "common/equation/Equation.less";

interface IEquationProps {
  left: string | JSX.Element;
  right: string | JSX.Element;
  multiplier?: string | JSX.Element;
}

const Equation = ({ left, multiplier, right }: IEquationProps) => (
  <span className="equation">
    <span>{left}</span>
    <span> = </span>
    <span>
      {multiplier ? multiplier : null}
      {right}
    </span>
  </span>
);

export default Equation;
