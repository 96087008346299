// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Switch from "common/form/components/Switch";
import { memo, useMemo, useState } from "react";
import IDegradationScoreTrend from "../../../models/IDegradationScoreTrend";
import PrognosticChart, { IPrognosticChartProps } from "../../PrognosticChart";
import PrognosticGrid from "../../PrognosticGrid";
import RiskSimulationSummary, {
  IRiskSimulationSummaryProps
} from "../../RiskSimulationSummary";
import SimulationModal from "../../SimulationModal";
import usePrognosticGridRows from "../hooks/usePrognosticGridRows";
import usePrognosticTrendSeries from "../hooks/usePrognosticTrendSeries";
import "./PrognosticWidget.less";

interface IPrognosticWidgetProps
  extends IPrognosticChartProps,
    IRiskSimulationSummaryProps {
  degradationScoreTrend: IDegradationScoreTrend;
}

const PrognosticWidget = memo(
  ({
    expanded,
    degradationScoreTrend,
    modelId,
    implementationId,
    lifecycleStatus,
    assetId
  }: IPrognosticWidgetProps) => {
    const className = ["prognostic-widget", expanded ? "expanded" : ""].join(
      " "
    );
    const [switchId, setSwitchId] = useState<string>("trend");
    const switchItems = useMemo(
      () => [
        {
          id: "trend",
          message: {
            defaultMessage: "Trend",
            id: "detail_page.risk_trend.switch.trend_label"
          }
        },
        {
          id: "grid",
          message: {
            defaultMessage: "Grid",
            id: "detail_page.risk_trend.switch.grid_label"
          }
        }
      ],
      []
    );

    const rowsInfo = usePrognosticGridRows({
      degradationScoreTrend,
      implementationId,
      modelId,
      switchId
    });
    const trendInfo = usePrognosticTrendSeries({
      degradationScoreTrend,
      implementationId,
      modelId
    });

    return (
      <div className={className}>
        <RiskSimulationSummary
          assetId={assetId}
          modelId={modelId}
          implementationId={implementationId}
          lifecycleStatus={lifecycleStatus}
        >
          <Switch
            className="prognostic-switch"
            defaultSelected={switchId}
            items={switchItems}
            onChange={setSwitchId}
          />
        </RiskSimulationSummary>
        {switchId === "trend" && (
          <div className="prognostic-widget__chart">
            <PrognosticChart
              expanded={expanded}
              simulationSeries={trendInfo.simulationSeries}
              prognosisSeries={trendInfo.prognosisSeries}
              series={trendInfo.series}
              ready={trendInfo.ready}
            />
          </div>
        )}
        {switchId === "grid" && (
          <div className="prognostic-widget__grid">
            <PrognosticGrid
              rows={rowsInfo?.rows}
              rowsReady={rowsInfo?.rowsReady}
              modelId={modelId}
              assetId={assetId}
            />
          </div>
        )}
        <SimulationModal
          modelId={modelId}
          implementationId={implementationId}
          assetId={assetId}
          degradationScoreTrend={degradationScoreTrend}
        />
      </div>
    );
  }
);

export default PrognosticWidget;
