// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { notifications } from "@pg/common/build/components/Notifications";
import EndpointService from "core/data/services/EndpointService";
import UrlService, { IUrlParams } from "core/data/services/UrlService";
import Guid from "core/guid/Guid";
import IIssue from "features/detailpage/features/issues/models/IIssue";
import IIssueFormCreate from "features/detailpage/features/issues/models/IIssueFormCreate";
import IIssueFormEdit from "features/detailpage/features/issues/models/IIssueFormEdit";
import { IssueStatuses } from "features/detailpage/features/issues/models/IssueStatuses";
import IComment from "features/detailpage/models/IComment";
import ICommentForm from "features/detailpage/models/ICommentForm";
import { IntlShape } from "react-intl";
import { IAjaxAction } from "reducers/Index";
import { Action } from "redux";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";

export const Actions = {
  DetailPage_Issues_AddActiveIssueComment:
    "DetailPage_Issues_AddActiveIssueComment",
  DetailPage_Issues_CreateNewIssue: "DetailPage_Issues_CreateNewIssue",
  DetailPage_Issues_RemoveNewIssue: "DetailPage_Issues_RemoveNewIssue",
  DetailPage_Issues_SaveIssue: "DetailPage_Issues_SaveIssue",
  DetailPage_Issues_SetActiveIssues: "DetailPage_Issues_SetActiveIssues",
  DetailPage_Issues_SetClosedIssues: "DetailPage_Issues_SetClosedIssues",
  DetailPage_Issues_SetActiveIssueCommentCreateRequest:
    "DetailPage_Issues_SetActiveIssueCommentCreateRequest",
  DetailPage_Issues_SetNewIssueCreateRequest:
    "DetailPage_Issues_SetNewIssueCreateRequest",
  DetailPage_Issues_SetActiveIssueEditRequest:
    "DetailPage_Issues_SetActiveIssueEditRequest",
  DetailPage_Issues_SetActiveIssueEditStatusRequest:
    "DetailPage_Issues_SetActiveIssueEditStatusRequest",
  DetailPage_Issues_ChangeActiveIssueStatus:
    "DetailPage_Issues_ChangeActiveIssueStatus",
  DetailPage_Issues_SetStatuses: "DetailPage_Issues_SetStatuses",
  DetailPage_Issues_SetUrgencies: "DetailPage_Issues_SetUrgencies",
  DetailPage_Issues_SwitchIssueModeToEdit:
    "DetailPage_Issues_switchIssueModeToEdit",
  DetailPage_Issues_SwitchIssueModeToView:
    "DetailPage_Issues_SwitchIssueModeToView",
  DetailPage_Issues_UpdateIssue: "DetailPage_Issues_UpdateIssue",
  DetailPage_Issues_Start_Processing: "DetailPage_Issues_Start_Processing",
  DetailPage_Issues_End_Processing: "DetailPage_Issues_End_Processing"
};

export interface IAddActiveIssueCommentAction extends IAjaxAction<IComment> {
  issueId: string;
}

export interface ICreateNewIssueAction extends Action<string> {
  assetId: string;
  issueId: string;
  username: string;
}

export interface ISetActiveIssueCommentCreateRequestAction
  extends IAjaxAction<IComment> {
  issueId: string;
}

export interface ISetActiveIssueEditRequestAction extends IAjaxAction<IIssue> {
  issueId: string;
}

export interface ISetActiveIssueEditStatusRequestAction
  extends IAjaxAction<void> {
  issueId: string;
}

export interface IChangeActiveIssueStatusAction extends Action<string> {
  issueId: string;
  status: IssueStatuses;
}

export interface ISwitchIssueModeToEditAction extends Action<string> {
  issueId: string;
}

export interface ISwitchIssueModeToViewAction extends Action<string> {
  issueId: string;
}

export interface IUpdateActiveIssueAction extends Action<string> {
  issue: IIssue;
}

export const addActiveIssueCommentAction = (
  issueId: string,
  request: JQuery.jqXHR,
  data?: IComment
): IAddActiveIssueCommentAction => {
  const action: IAddActiveIssueCommentAction = {
    type: Actions.DetailPage_Issues_AddActiveIssueComment,
    issueId,
    request,
    data
  };
  return action;
};

export const createNewIssueAction = (
  assetId: string,
  issueId: string,
  username: string
): ICreateNewIssueAction => {
  const action: ICreateNewIssueAction = {
    type: Actions.DetailPage_Issues_CreateNewIssue,
    assetId,
    issueId,
    username
  };
  return action;
};

export const removeNewIssueAction = (): Action<string> => {
  const action: Action<string> = {
    type: Actions.DetailPage_Issues_RemoveNewIssue
  };
  return action;
};

export const saveIssueAction = (
  request: JQuery.jqXHR,
  data?: IIssue
): IAjaxAction<IIssue> => {
  const action: IAjaxAction<IIssue> = {
    type: Actions.DetailPage_Issues_SaveIssue,
    request,
    data
  };
  return action;
};

export const setActiveIssuesAction = (
  request: JQuery.jqXHR,
  data?: IIssue[]
): IAjaxAction<IIssue[]> => {
  const action: IAjaxAction<IIssue[]> = {
    type: Actions.DetailPage_Issues_SetActiveIssues,
    request,
    data
  };
  return action;
};

export const setClosedIssuesAction = (
  request: JQuery.jqXHR,
  data?: IIssue[]
): IAjaxAction<IIssue[]> => {
  const action: IAjaxAction<IIssue[]> = {
    type: Actions.DetailPage_Issues_SetClosedIssues,
    request,
    data
  };
  return action;
};

export const setActiveIssueCommentCreateRequestAction = (
  issueId: string,
  request: JQuery.jqXHR,
  data?: IComment
): ISetActiveIssueCommentCreateRequestAction => {
  const action: ISetActiveIssueCommentCreateRequestAction = {
    type: Actions.DetailPage_Issues_SetActiveIssueCommentCreateRequest,
    issueId,
    request,
    data
  };
  return action;
};

export const setNewIssueCreateRequestAction = (
  request: JQuery.jqXHR,
  data?: IIssue
): IAjaxAction<IIssue> => {
  const action: IAjaxAction<IIssue> = {
    type: Actions.DetailPage_Issues_SetNewIssueCreateRequest,
    request,
    data
  };
  return action;
};

export const setActiveIssueEditRequestAction = (
  issueId: string,
  request: JQuery.jqXHR,
  data?: any
): ISetActiveIssueEditRequestAction => {
  const action: ISetActiveIssueEditRequestAction = {
    type: Actions.DetailPage_Issues_SetActiveIssueEditRequest,
    issueId,
    request,
    data
  };
  return action;
};

export const setActiveIssueEditStatusRequestAction = (
  issueId: string,
  request: JQuery.jqXHR
): ISetActiveIssueEditStatusRequestAction => {
  const action: ISetActiveIssueEditStatusRequestAction = {
    type: Actions.DetailPage_Issues_SetActiveIssueEditStatusRequest,
    issueId,
    request,
    data: null
  };
  return action;
};

export const changeActiveIssueStatusAction = (
  issueId: string,
  status: IssueStatuses
): IChangeActiveIssueStatusAction => {
  const action: IChangeActiveIssueStatusAction = {
    type: Actions.DetailPage_Issues_ChangeActiveIssueStatus,
    issueId,
    status
  };

  return action;
};

export const setStatusesAction = (
  request: JQuery.jqXHR,
  data?: IssueStatuses[]
): IAjaxAction<IssueStatuses[]> => {
  const action: IAjaxAction<IssueStatuses[]> = {
    type: Actions.DetailPage_Issues_SetStatuses,
    request,
    data
  };
  return action;
};

export const setUrgenciesAction = (
  request: JQuery.jqXHR,
  data?: string[]
): IAjaxAction<string[]> => {
  const action: IAjaxAction<string[]> = {
    type: Actions.DetailPage_Issues_SetUrgencies,
    request,
    data
  };
  return action;
};

export const switchIssueModeToEditAction = (
  issueId: string
): ISwitchIssueModeToEditAction => {
  const action: ISwitchIssueModeToEditAction = {
    type: Actions.DetailPage_Issues_SwitchIssueModeToEdit,
    issueId
  };
  return action;
};

export const switchIssueModeToViewAction = (
  issueId: string
): ISwitchIssueModeToViewAction => {
  const action: ISwitchIssueModeToViewAction = {
    type: Actions.DetailPage_Issues_SwitchIssueModeToView,
    issueId
  };
  return action;
};

export const updateActiveIssueAction = (
  issue: IIssue
): IUpdateActiveIssueAction => ({
  type: Actions.DetailPage_Issues_UpdateIssue,
  issue
});

export const createComment = (
  dispatch: AppDispatch,
  issueId: string,
  comment: ICommentForm
) =>
  new Promise<void>((resolve, reject) => {
    const url = UrlService.getApiUrl(
      config.api.detailPage.issues.createCommentUrl,
      {
        issueId
      }
    );

    const request = EndpointService.postJson<IComment>(
      url,
      (request, data) => {
        dispatch(
          setActiveIssueCommentCreateRequestAction(issueId, request, data)
        );
        dispatch(addActiveIssueCommentAction(issueId, request, data));
        resolve();
      },
      (request) => {
        dispatch(setActiveIssueCommentCreateRequestAction(issueId, request));
        reject();
      },
      comment.Comment
    );

    dispatch(setActiveIssueCommentCreateRequestAction(issueId, request));
  });

export const createNewIssue = (
  dispatch: AppDispatch,
  assetId: string,
  username: string
): string => {
  const issueId = `new-${Guid.getUniqGuid()}`;
  dispatch(createNewIssueAction(assetId, issueId, username));
  return issueId;
};

export const removeNewIssue = (dispatch: AppDispatch) => {
  dispatch(removeNewIssueAction());
};

export const createIssue = (
  dispatch: AppDispatch,
  intl: IntlShape,
  assetId: string,
  issue: IIssueFormCreate
) =>
  new Promise<void>((resolve, reject) => {
    const url = UrlService.getApiUrl(
      config.api.detailPage.issues.createIssueUrl,
      {
        assetId
      }
    );

    const request = EndpointService.postJson<IIssue>(
      url,
      (request, data) => {
        dispatch(setNewIssueCreateRequestAction(request, data));
        dispatch(saveIssueAction(request, data));
        resolve();
      },
      (request) => {
        dispatch(setNewIssueCreateRequestAction(request));
        if (request.status === 400) {
          notifications.error({
            message: intl.formatMessage({
              id: `detail_page.issues.actions.create_issue.failed.${request.responseJSON}`,
              defaultMessage: `Issue adding failed. Response text: ${request.responseJSON}.`
            })
          });
        } else {
          notifications.error({
            message: intl.formatMessage({
              id: "detail_page.issues.actions.create_issue.failed",
              defaultMessage: "Issue adding failed."
            })
          });
        }

        reject();
      },
      issue
    );

    dispatch(setNewIssueCreateRequestAction(request));
  });

export const editIssue = (
  dispatch: AppDispatch,
  intl: IntlShape,
  issueId: string,
  issue: IIssueFormEdit
) =>
  new Promise<void>((resolve, reject) => {
    const url = UrlService.getApiUrl(
      config.api.detailPage.issues.editIssueUrl,
      {
        issueId
      }
    );

    const request = EndpointService.putJson<IIssue>(
      url,
      (request, data) => {
        dispatch(setActiveIssueEditRequestAction(issueId, request, data));
        dispatch(saveIssueAction(request, data));
        resolve();
      },
      (request) => {
        dispatch(setActiveIssueEditRequestAction(issueId, request));
        if (request.status === 400) {
          notifications.error({
            message: intl.formatMessage({
              id: `detail_page.issues.actions.edit_issue.failed.${request.responseJSON}`,
              defaultMessage: `Issue editing failed. Response text: ${request.responseJSON}.`
            })
          });
        } else {
          notifications.error({
            message: intl.formatMessage({
              id: "detail_page.issues.actions.edit_issue.failed",
              defaultMessage: "Issue editing failed."
            })
          });
        }

        reject();
      },
      null,
      issue
    );

    dispatch(setActiveIssueEditRequestAction(issueId, request));
  });

export const editIssueStatus = (
  dispatch: AppDispatch,
  intl: IntlShape,
  issueId: string,
  status: IssueStatuses
): Promise<void> =>
  new Promise((resolve, reject) => {
    const url = UrlService.getApiUrl(
      config.api.detailPage.issues.editIssueStatusUrl,
      {
        issueId
      }
    );

    const request = EndpointService.put(
      url,
      (request) => {
        dispatch(setActiveIssueEditStatusRequestAction(issueId, request));
        dispatch(changeActiveIssueStatusAction(issueId, status));

        if (status === "Closed") {
          notifications.success({
            message: intl.formatMessage({
              id: "detail_page.issues.actions.edit_issue_status.issue_closed",
              defaultMessage: "Issue was closed."
            })
          });
        }

        resolve();
      },
      (request) => {
        dispatch(setActiveIssueEditStatusRequestAction(issueId, request));
        if (request.status === 400) {
          notifications.error({
            message: intl.formatMessage({
              id: `detail_page.issues.actions.edit_issue_status.failed.${request.responseJSON}`,
              defaultMessage: `Issue status editing failed. Response text: ${request.responseJSON}.`
            })
          });
        } else {
          notifications.error({
            message: intl.formatMessage({
              id: "detail_page.issues.actions.edit_issue_status.failed",
              defaultMessage: "Issue status editing failed."
            })
          });
        }

        reject();
      },
      null,
      status,
      "application/json; charset=utf-8"
    );

    dispatch(setActiveIssueEditStatusRequestAction(issueId, request));
  });

export const loadActiveIssues = (dispatch: AppDispatch, assetId: string) => {
  const activeStatuses: IssueStatuses[] = ["InProgress", "Monitor", "New"];
  const url = UrlService.getApiUrl(
    config.api.detailPage.issues.issuesUrl,
    {
      assetId
    },
    {
      status: activeStatuses
    }
  );

  const request = EndpointService.getJson<IIssue[]>(
    url,
    (request, data) => {
      dispatch(setActiveIssuesAction(request, data));
    },
    (request) => {
      dispatch(setActiveIssuesAction(request));
    }
  );

  dispatch(setActiveIssuesAction(request));
};

export const loadClosedIssues = (
  dispatch: AppDispatch,
  assetId: string,
  limit?: number
) => {
  const closeStatus: IssueStatuses = "Closed";
  const search: IUrlParams = {
    status: closeStatus
  };

  if (limit) search["take"] = limit.toString();

  const url = UrlService.getApiUrl(
    config.api.detailPage.issues.issuesUrl,
    {
      assetId
    },
    search
  );

  const request = EndpointService.getJson<IIssue[]>(
    url,
    (request, data) => {
      dispatch(setClosedIssuesAction(request, data));
    },
    (request) => {
      dispatch(setClosedIssuesAction(request));
    }
  );

  dispatch(setClosedIssuesAction(request));
};

export const loadStatuses = (dispatch: AppDispatch) => {
  const url = UrlService.getApiUrl(config.api.detailPage.issues.statusesUrl);

  const request = EndpointService.getJson<IssueStatuses[]>(
    url,
    (request, data) => {
      dispatch(setStatusesAction(request, data));
    },
    (request) => {
      dispatch(setStatusesAction(request));
    }
  );

  dispatch(setStatusesAction(request));
};

export const loadUrgencies = (dispatch: AppDispatch) => {
  const url = UrlService.getApiUrl(config.api.detailPage.issues.urgenciesUrl);

  const request = EndpointService.getJson<string[]>(
    url,
    (request, data) => {
      dispatch(setUrgenciesAction(request, data));
    },
    (request) => {
      dispatch(setUrgenciesAction(request));
    }
  );

  dispatch(setUrgenciesAction(request));
};

export const switchIssueModeToEdit = (
  dispatch: AppDispatch,
  issueId: string
) => {
  dispatch(switchIssueModeToEditAction(issueId));
};

export const switchIssueModeToView = (
  dispatch: AppDispatch,
  issueId: string
) => {
  dispatch(switchIssueModeToViewAction(issueId));
};

export const updateActiveIssue = (
  dispatch: AppDispatch,
  issueId: string
): Promise<void> =>
  new Promise((resolve, reject) => {
    const url = UrlService.getApiUrl(config.api.detailPage.issues.getIssueUrl, [
      { name: "issueId", value: issueId }
    ]);

    EndpointService.getJson<IIssue>(
      url,
      (request, data) => {
        dispatch(updateActiveIssueAction(data));
        resolve();
      },
      (request) => {
        reject();
      }
    );
  });

export const startProcessingIssues = (): Action<string> => ({
  type: Actions.DetailPage_Issues_Start_Processing
});

export const endProcessingIssues = (): Action<string> => ({
  type: Actions.DetailPage_Issues_End_Processing
});
