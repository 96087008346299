// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";

import SlidePanel from "common/slidepanel/SlidePanel";

import useIssuesSlidePanel, {
  IssueSlidePanelMode,
  ISlidePanelData
} from "../hooks/useIssuesSlidePanel";

interface IIssueSlidePanelProps {
  slidePanelRef: React.MutableRefObject<SlidePanel>;
  issueSlidePanelMode: IssueSlidePanelMode;
  slidePanelData: ISlidePanelData;
}

const IssuesSlidePanel = ({
  slidePanelRef,
  issueSlidePanelMode,
  slidePanelData
}: IIssueSlidePanelProps) => {
  const { ContentComponent, PanelComponent } = useIssuesSlidePanel(
    issueSlidePanelMode,
    slidePanelData
  );
  return (
    <SlidePanel
      PanelTitle={PanelComponent}
      ref={(ref: any) => {
        slidePanelRef.current = ref;
      }}
    >
      {ContentComponent && <ContentComponent />}
    </SlidePanel>
  );
};

export default IssuesSlidePanel;
