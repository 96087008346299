// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { dataKey } from "../actions/loadAssetRiskTrend";
import IAssetRiskTrendItem from "../models/IAssetRiskTrendItem";

const getAssetRiskTrendSelector = createSelector(
  (state: IState) => state.data[dataKey] as Data<IAssetRiskTrendItem[]>,
  (assetRiskTrend) => assetRiskTrend
);

export default getAssetRiskTrendSelector;
