// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IDataEndpoint } from "common/datagrid/DataGrid";
import ISelectedFilterSelect from "common/FilterBar/models/ISelectedFilterSelect";
import UrlService from "core/data/services/UrlService";
import { useMemo } from "react";
import { config } from "utils/AppConfig";
import useLocationsAssetGridColumnsConfig from "./useLocationsAssetGridColumnsConfig";

interface IUseLocationsAssetGridOptions {
  location?: string;
  organization?: string;
  region?: string;
}

const useLocationsAssetGrid = ({
  organization,
  region,
  location
}: IUseLocationsAssetGridOptions) => {
  const selectedFilters: ISelectedFilterSelect[] = useMemo(
    () => [
      {
        Id: "AssetOrganizationWithRegionAndLocation",
        Options: [
          {
            Id: organization || null,
            Options: [
              {
                Id: region || null,
                Options: [
                  {
                    Id: location || null
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        Id: "AssetStatus",
        Options: [
          {
            Id: "InService"
          }
        ]
      }
    ],
    [organization, region, location]
  );
  const columns = useLocationsAssetGridColumnsConfig();

  const dataEndpoint: IDataEndpoint = useMemo(
    () => ({
      url: UrlService.getApiUrl(config.api.locations.assetsUrl),
      type: "POST",
      content: {
        filters: selectedFilters
      }
    }),
    [selectedFilters]
  );

  return {
    dataEndpoint,
    columns
  };
};

export default useLocationsAssetGrid;
