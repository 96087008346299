// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";

import useRiskMatrixChartWrapper from "../hooks/useRiskMatrixChartWrapper";
import { Statuses } from "core/data/models/Data";

import { WidgetErrorMessage } from "components/common/widget/Widget";

import Processing from "components/common/Processing";
import RiskMatrixChart, { IRiskMatrixChartProps } from "./RiskMatrixChart";

interface IRiskMatrixChartWrapperProps extends IRiskMatrixChartProps {}

const RiskMatrixChartWrapper = ({
  assetId,
  assetDetails,
  grayscale
}: IRiskMatrixChartWrapperProps) => {
  const {
    assetRiskConfiguration,
    hasStatus,
    errorMessage
  } = useRiskMatrixChartWrapper();

  if (assetRiskConfiguration) {
    if (hasStatus(Statuses.Loading)) {
      return <Processing />;
    } else if (hasStatus(Statuses.Succeeded)) {
      return (
        <RiskMatrixChart
          assetDetails={assetDetails}
          assetId={assetId}
          grayscale={grayscale}
        />
      );
    } else if (hasStatus(Statuses.Failed)) {
      return (
        <WidgetErrorMessage
          messageId="global.empty"
          messageDefault={errorMessage}
          messageValues={errorMessage}
        />
      );
    }
  }

  return (
    <RiskMatrixChart
      assetDetails={assetDetails}
      assetId={assetId}
      grayscale={grayscale}
    />
  );
};

export default RiskMatrixChartWrapper;
