// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { ISubscore } from "@apm/widgets/build/widgets/ConditionTrend";
import { isNil } from "lodash";
import { useIntl } from "react-intl";

import SubScoreFamilyIcon from "./SubScoreFamilyIcon";

import { SubscoreService } from "common/formatters/SubscoreName";

interface IListActionComponentProps extends ISubscore {
  isInFamily?: boolean;
  assetId: string;
  modelId: string;
  implementationId: string;
  lifecycleStatus: string;
}

const ListActionComponent = ({
  isInFamily,
  id,
  assetId,
  modelId,
  implementationId,
  value,
  lifecycleStatus
}: IListActionComponentProps) => {
  const intl = useIntl();
  return isInFamily && !isNil(value) && lifecycleStatus === "InService" ? (
    <SubScoreFamilyIcon
      assetId={assetId}
      subScoreName={id}
      subScoreTranslatedName={SubscoreService.format(
        id,
        modelId,
        implementationId,
        intl
      )}
      level="1"
    />
  ) : null;
};

export default ListActionComponent;
