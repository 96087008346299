// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IAjaxAction } from "reducers/Index";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";
import { IAgingContactWear } from "../models/IAgingContactWear";

export enum Actions {
  DetailPage_Aging_SetContactWear = "DetailPage_Aging_SetContactWear"
}

export const setContactWearAction = (
  request: JQuery.jqXHR,
  data?: IAgingContactWear
): IAjaxAction<IAgingContactWear> => ({
  request,
  data,
  type: Actions.DetailPage_Aging_SetContactWear
});

export const loadContactWearAction =
  (assetId: string) => (dispatch: AppDispatch) =>
    fetchContactWear(dispatch, assetId);

function fetchContactWear(
  dispatch: AppDispatch,
  assetId: string
): Promise<void> {
  return new Promise((resolve, reject) => {
    const url = UrlService.getApiUrl(
      config.api.detailPage.agingContactWearUrl,
      [
        {
          name: "assetId",
          value: assetId
        }
      ]
    );
    const request = EndpointService.getJson<IAgingContactWear>(
      url,
      (request, data) => {
        dispatch(setContactWearAction(request, data));
        resolve();
      },
      (request) => {
        dispatch(setContactWearAction(request));
        reject();
      }
    );

    dispatch(setContactWearAction(request));
  });
}
