// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";
import { IState } from "reducers/Index";

const getAdSupportContactUrlSelector = createSelector(
  (state: IState) => state.app.environment.data.AdSupportContactUrl,
  adSupportContactUrl => adSupportContactUrl
);

export default getAdSupportContactUrlSelector;
