// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";
import { FormattedMessage } from "react-intl";

import ToggleInputContainer from "common/form/containers/ToggleInputContainer";
import TooltipWrapper, { Themes } from "common/tooltipwrapper/TooltipWrapper";
import IWorkRequest from "features/detailpage/models/IWorkRequest";
import IWorkRequestForm from "features/detailpage/models/IWorkRequestForm";
import { actionsModalFormName } from "./ActionsModal";

interface IActionsModalWorkRequestProps {
  defaultSelected: boolean;
  workRequest: IWorkRequest | IWorkRequestForm;
}

const ActionsModalWorkRequest = ({
  defaultSelected,
  workRequest
}: IActionsModalWorkRequestProps) => {
  const maxLength = 200;

  let longSummary: string;
  if (workRequest.hasOwnProperty("Summary")) {
    longSummary = (workRequest as IWorkRequest).Summary || "";
  } else {
    const condition = (workRequest as IWorkRequestForm).Condition || "";
    const recommendation =
      (workRequest as IWorkRequestForm).Recommendation || "";
    longSummary = `${condition} ${recommendation}`;
    longSummary = longSummary.trim();
  }

  const shortSummary =
    longSummary.length > maxLength
      ? `${longSummary.substr(0, maxLength)} ...`
      : longSummary;

  return (
    <div className="work-request">
      <ToggleInputContainer
        className="toggle"
        defaultValue={defaultSelected}
        formName={actionsModalFormName}
        inputName="selected-wr"
      />
      <div className="summary">
        {shortSummary === longSummary && longSummary}
        {shortSummary !== longSummary && (
          <TooltipWrapper
            Tooltip={() => (
              <div className="action-description-tooltip">{longSummary}</div>
            )}
            theme={Themes.Dark}
          >
            <div>{shortSummary}</div>
          </TooltipWrapper>
        )}
      </div>
      <div className="details">
        <div className="status">
          <span className="default-grey-label details-label">
            <FormattedMessage
              defaultMessage="Status:"
              id="detail_page.issues.actions.modal.new_request.form.status"
            />
          </span>
          <span>-</span>
        </div>
        <div className="scheduled-date">
          <span className="default-grey-label details-label">
            <FormattedMessage
              defaultMessage="Scheduled date:"
              id="detail_page.issues.actions.modal.new_request.form.scheduled_date"
            />
          </span>
          <span data-qa="scheduled-date-dash">-</span>
        </div>
        <div className="order-number">
          <span className="default-grey-label details-label">
            <FormattedMessage
              defaultMessage="Number:"
              id="detail_page.issues.actions.modal.new_request.form.order_number.label"
            />
          </span>
          <span data-qa="order-number-dash">-</span>
        </div>
      </div>
    </div>
  );
};

export default ActionsModalWorkRequest;
