// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import loadData from "core/data/actions/loadData";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";
import ChartNames from "../models/ChartNames";

export const getDataKey = (assetId: string, chartName: ChartNames) =>
  `DetailPage_Parameters_DC-${assetId}-${chartName}`;

const loadMeasurements = (
  assetId: string,
  parameters: string[],
  referenceLines: string[],
  chartName: ChartNames
) => {
  const url = UrlService.getApiUrl(config.api.detailPage.dcMeasurementsUrl);

  return loadData({
    key: getDataKey(assetId, chartName),
    url,
    content: {
      AssetId: assetId,
      Parameters: {
        DataSeries: parameters,
        ReferenceLines: referenceLines
      }
    },
    method: "post"
  });
};

export default loadMeasurements;
