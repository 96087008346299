// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useMemo, useState } from "react";

import { IDataEndpoint } from "common/datagrid/DataGrid";
import { SelectedFilters } from "common/FilterBar";
import { UserRoles } from "core/app/components/auth/Authorization";
import { IUser } from "core/app/reducers/settings/UserReducer";
import AuthorizationService from "core/app/services/AuthorizationService";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";
import useIssuesGridActionConfig from "./useIssueGridActionsConfig";
import useIssuesGridColumnsConfig, {
  IOnMaintenancePriorityClickOptions,
  IOnNumberOfActionsClickOptions
} from "./useIssuesGridColumnsConfig";

interface IUseGridOptions {
  filters: SelectedFilters;
  onMaintenancePriorityClick?: (
    options: IOnMaintenancePriorityClickOptions
  ) => void;
  onNumberOfActionsClick?: (options: IOnNumberOfActionsClickOptions) => void;
  user: IUser;
}

const useIssueGrid = ({
  filters,
  onMaintenancePriorityClick,
  onNumberOfActionsClick,
  user
}: IUseGridOptions) => {
  const [rowsTotal, setRowsTotal] = useState<number>();
  const columns = useIssuesGridColumnsConfig({
    onMaintenancePriorityClick,
    onNumberOfActionsClick
  });

  const isReadOnlyMode = useMemo(() => {
    return !AuthorizationService.isAuthorized(user, [
      UserRoles.Administrator,
      UserRoles.Engineer,
      UserRoles.LimitedEngineer
    ]);
  }, [user]);
  const defaultActions = useIssuesGridActionConfig();
  const actions = useMemo(
    () => (isReadOnlyMode ? [] : defaultActions),
    [defaultActions, isReadOnlyMode]
  );
  const dataEndpoint: IDataEndpoint = useMemo(
    () => ({
      url: UrlService.getApiUrl(config.api.watchlist.issuesRangeUrl),
      type: "POST",
      content: {
        search: filters.search,
        filters: filters.selects
      },
      onDataLoaded: (total) => {
        setRowsTotal(total);
      }
    }),
    [filters]
  );

  return {
    dataEndpoint,
    rowsTotal,
    actions,
    columns
  };
};

export default useIssueGrid;
