// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useMemo } from "react";
import { useIntl } from "react-intl";

import HistorySwitchingChartService from "features/detailpage/features/ltc/services/HistorySwitchingChartService";
import IHistorySwitchingOperations, {
  IHistorySwitchingOperationsData
} from "features/detailpage/features/ltc/models/IHistorySwitichingOperations";

interface IUseHistorySwitchingChartConfigurationPayload {
  historySwitching: IHistorySwitchingOperationsData;
}

const useHistorySwitchingChartConfiguration = ({
  historySwitching
}: IUseHistorySwitchingChartConfigurationPayload) => {
  const intl = useIntl();
  const historySwitchingEntries = Object.entries(historySwitching);
  const historyDates: Date[] & string[] = [];
  const historyData: number[] = [];

  for (const [keys, values] of historySwitchingEntries) {
    historyDates.push(new Date(keys));
    historyData.push(values);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const historySwitchingData: IHistorySwitchingOperations = {
    historyDates,
    historyData
  };

  const configuration = useMemo(
    () =>
      HistorySwitchingChartService.getChartConfiguration(
        historySwitchingData,
        intl
      ),
    [historySwitchingData, intl]
  );

  return {
    configuration
  };
};

export default useHistorySwitchingChartConfiguration;
