// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";

import "./NoDataMessage.less";

interface INoDataMessageProps {
  className?: string;
  message?: MessageDescriptor;
  withBorder?: boolean;
}

const NoDataMessage = ({
  className,
  message,
  withBorder = false
}: INoDataMessageProps) => {
  const computedMessage = message || {
    defaultMessage: "No data",
    id: "common.no_data_message"
  };

  return (
    <div
      className={`
        common-no-data-message
        ${className}
        ${withBorder && "with-border"}
      `}
    >
      <FormattedMessage {...computedMessage} />
    </div>
  );
};

export default NoDataMessage;
