// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import loadData from "core/data/actions/loadData";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";
import dataKey from "../constants/voltageStressDataKey";
import ChartGroup from "../models/ChartGroup";

const loadVoltageStressData = (assetId: string, chartGroup: ChartGroup) => {
  const url = UrlService.getApiUrl(
    config.api.detailPage.voltageStressVisualizationsUrl,
    [
      { name: "assetId", value: assetId },
      { name: "chartGroup", value: chartGroup }
    ]
  );
  return loadData({ key: dataKey(assetId), url });
};

export default loadVoltageStressData;
