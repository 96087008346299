// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Button } from "antd";
import React, { ReactNode, RefObject } from "react";
import {
  FormattedMessage,
  injectIntl,
  IntlShape,
  MessageDescriptor
} from "react-intl";

import FormModel from "../models/Form";

import "./Form.less";

export interface IFormActions {
  createForm: () => void;
  resetForm: () => void;
}

export interface IFormData {
  form: FormModel;
}

export interface IFormOwnProps {
  buttonCancelLabel?: MessageDescriptor;
  buttonSubmitLabel?: MessageDescriptor;
  className?: string;
  disabled?: boolean;
  name: string;
  onChange?: (form: FormModel) => void;
  onSubmit?: (form: FormModel) => void;
  onCancel?: () => void;
  showActionButtons?: boolean;
  submitButtonDataQa?: string;
  formRef?: RefObject<HTMLFormElement>;
}

export interface IFormProps extends IFormActions, IFormData, IFormOwnProps {
  children: ReactNode;
  intl: IntlShape;
}

export class Form extends React.Component<IFormProps> {
  constructor(props: IFormProps) {
    super(props);
    props.createForm();
  }

  componentDidUpdate(prevProps: IFormProps) {
    const nextProps = this.props;

    if (prevProps.form !== nextProps.form && nextProps.onChange)
      nextProps.onChange(nextProps.form);
  }

  render() {
    const { handleCancel, handleSubmit } = this;
    const {
      buttonCancelLabel,
      buttonSubmitLabel,
      children,
      className,
      disabled,
      form,
      intl,
      showActionButtons,
      submitButtonDataQa,
      formRef
    } = this.props;

    const submitButtonValue = buttonSubmitLabel
      ? intl.formatMessage(buttonSubmitLabel)
      : intl.formatMessage({
          defaultMessage: "Submit",
          id: "form.buttons.submit"
        });

    return form ? (
      <form
        className={`common-form ${className}`}
        onSubmit={handleSubmit}
        ref={formRef}
      >
        {React.Children.toArray(children)}
        {showActionButtons !== false && (
          <Button
            data-qa={submitButtonDataQa}
            disabled={!form.valid || disabled}
            htmlType="submit"
            type="primary"
          >
            {submitButtonValue}
          </Button>
        )}
        {showActionButtons !== false && (
          <Button
            type="link"
            className="dark"
            disabled={disabled}
            onClick={handleCancel}
          >
            {buttonCancelLabel ? (
              <FormattedMessage {...buttonCancelLabel} />
            ) : (
              <FormattedMessage
                defaultMessage="Cancel"
                id="form.buttons.cancel"
              />
            )}
          </Button>
        )}
      </form>
    ) : null;
  }

  public submit = () => {
    const { form, onSubmit } = this.props;
    if (onSubmit) onSubmit(form);
  };

  public cancel = () => {
    const { onCancel, resetForm } = this.props;
    resetForm();
    if (onCancel) onCancel();
  };

  private handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    this.submit();
  };

  private handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    this.cancel();
  };
}

const FormWithIntl = injectIntl(Form);

export default FormWithIntl;
