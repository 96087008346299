// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import AnalyticsChartLegend from "features/detailpage/components/analytics/AnalyticsChartLegend";
import BushingOfflineChartStaticLegend from "features/detailpage/features/bushingsoffline/components/BushingOfflineChartLegend/BushingOfflineChartStaticLegend";
import BushingOfflineChartService from "features/detailpage/features/bushingsoffline/services/BushingOfflineChartService";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import "./BushingOfflineChartLegend.less";

export interface IBushingOfflineChartLegendProps {
  series: string;
  focusPlotSeries?: (series: string, plot: string) => void;
  revertPlotSeries?: (series: string) => void;
  togglePlotSeries?: (series: string, plot: string) => void;
  showBushingInstallDate?: boolean;
}

const BushingOfflineChartLegend = ({
  series,
  focusPlotSeries,
  revertPlotSeries,
  togglePlotSeries,
  showBushingInstallDate
}: IBushingOfflineChartLegendProps) => {
  const intl = useIntl();
  const settings = useMemo(
    () => BushingOfflineChartService.getBushingChartsSettings(),
    []
  );
  const installDateLegendItem = useMemo(() => "InstallDate", []);
  let staticLegendItems: string[] = useMemo(
    () =>
      showBushingInstallDate
        ? [...settings.References, installDateLegendItem]
        : [...settings.References],
    [installDateLegendItem, settings.References, showBushingInstallDate]
  );

  return (
    <div className="bushing-offline-legend">
      <AnalyticsChartLegend
        plots={BushingOfflineChartService.getPlots(intl)}
        series={series}
        onFocus={focusPlotSeries}
        onRevert={revertPlotSeries}
        onToggle={togglePlotSeries}
      />
      <BushingOfflineChartStaticLegend items={staticLegendItems} />
    </div>
  );
};

export default BushingOfflineChartLegend;
