// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";

import Switch from "common/form/components/Switch";

import useSF6PressureChartDataSourceSwitch from "../hooks/useSF6PressureChartDataSourceSwitch";

interface ISF6PressureChartDataSourceSwitchProps {
  defaultSelected?: string;
  onChange?: (id: string) => void;
}

const SF6PressureChartDataSourceSwitch = ({
  defaultSelected,
  onChange
}: ISF6PressureChartDataSourceSwitchProps) => {
  const { items } = useSF6PressureChartDataSourceSwitch();
  return (
    <div className="dielectric-switch">
      <Switch
        defaultSelected={defaultSelected || items[0].id}
        items={items}
        onChange={onChange}
      />
    </div>
  );
};

export default SF6PressureChartDataSourceSwitch;
