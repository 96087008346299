// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { notifications } from "@pg/common/build/components/Notifications";
import UrlService from "core/data/services/UrlService";
import { IntlShape } from "react-intl";
import { config } from "utils/AppConfig";
import EndpointService from "../../../core/data/services/EndpointService";
import IThresholdCorrection from "../models/IThresholdCorrection";

interface IAcknowledgeParams {
  assetId: string;
  error?: () => void;
  intl: IntlShape;
  parameterName: string;
  success?: (correction: IThresholdCorrection) => void;
  url?: string;
}

const acknowledge = ({
  assetId,
  error,
  intl,
  parameterName,
  success,
  url
}: IAcknowledgeParams) => {
  const acknowledgeUrl =
    url ||
    UrlService.getApiUrl(config.api.detailPage.parametersAcknowledgeUrl, {
      assetId,
      parameterName
    });

  EndpointService.postJson<IThresholdCorrection>(
    acknowledgeUrl,
    (request, data) => {
      if (success) success(data);
    },
    (request) => {
      if (request.status === 400 && request.responseText) {
        const parsedResponseText = JSON.parse(request.responseText);
        notifications.error({
          message: intl.formatMessage({
            id: `detail_page.acknowledge.failed.${parsedResponseText}`,
            defaultMessage: `Cannot acknowledge ${parsedResponseText}`
          })
        });
      } else {
        notifications.error({
          message: intl.formatMessage({
            id: "detail_page.acknowledge.failed",
            defaultMessage: "Cannot acknowledge"
          })
        });
      }

      if (error) error();
    }
  );
};

export default acknowledge;
