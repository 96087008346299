// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import React from "react";
import { FormattedNumber } from "react-intl";
import styled from "styled-components";

import { colorPrimary } from "styles/ColorVariables";

interface IColumnChangeProps {
  className?: string;
  relativeChange: number;
}

const ColumnChange = ({ className, relativeChange }: IColumnChangeProps) => {
  return (
    <div className={`${className} grid-cell-content asset-risk-change-column`}>
      <FormattedNumber
        value={Math.abs(relativeChange)}
        minimumFractionDigits={1}
        maximumFractionDigits={1}
      />
      {relativeChange >= 0 ? (
        <Icon name="arrow_upward" size="sm" />
      ) : (
        <Icon name="arrow_downward" size="sm" />
      )}
    </div>
  );
};

const StyledColumnChange = styled(ColumnChange)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  span.icon {
    color: ${colorPrimary};
  }
`;

export default StyledColumnChange;
