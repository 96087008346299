// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import {
  colorGray80,
  colorStatusRed,
  colorTeal100
} from "@pg/common/build/styles/ColorVariables";
import { DatePicker, Form, Tabs } from "antd";
import { isNil } from "lodash";
import { TypedValue } from "models/ITypedValue";
import moment from "moment";
import { Tab } from "rc-tabs/lib/interface";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { spacingLarge, spacingXLarge } from "styles/StyleVariables";
import AssetModalContext from "../contexts/AssetModalContext";
import FormContext from "../contexts/FormContext";
import { IFormItem } from "../hooks/useForms";
import IFieldData from "../models/IFieldData";
import IFormValues from "../models/IFormValues";
import { ITabInfo } from "../models/ITabInfo";
import disableFutureDates from "../utils/disableFutureDates";
import CustomSections from "./CustomSections";

interface ITabbedInspectionProps {
  className?: string;
  formInfo: IFormItem;
}
interface ISectionStatus {
  name: string;
  modified: boolean;
  invalid: boolean;
}

interface ITabStatus {
  name: string;
  modified: boolean;
  invalid: boolean;
  sectionsInfo: ISectionStatus[];
}

const TabbedInspection = ({ className, formInfo }: ITabbedInspectionProps) => {
  const {
    validateMessages,
    parametersRestorePoint,
    registerForm,
    changeInspectionField,
    changeInspectionDateForFields,
    changeTabsInfo,
    isReadOnlyMode
  } = useContext(AssetModalContext);

  const intl = useIntl();
  const form = Form.useForm<IFormValues>(formInfo?.form)[0];
  const [tabsStatus, setTabsStatus] = useState<ITabStatus[]>([]);

  const tabs: ITabInfo[] = useMemo(
    () =>
      formInfo.tabs ||
      formInfo.formConfiguration.subTabs.map((t) => ({
        tabId: t.tabName.id.toString(),
        invalid: false,
        touched: false
      })),
    [formInfo.formConfiguration.subTabs, formInfo.tabs]
  );

  const fieldsInfo = useMemo(() => {
    const dataTypes: { [name: string]: TypedValue } = {};
    const fieldKeys: string[] = [];
    const tabsStatus: ITabStatus[] = [];

    formInfo.formConfiguration.subTabs.forEach((subTab) => {
      const sectionStatus: ISectionStatus[] = [
        { name: `${subTab.tabName.id}_main`, modified: false, invalid: false }
      ];
      subTab.sections.forEach((sectionElement) => {
        if (sectionElement.sectionType === "table") {
          sectionStatus.push({
            name: sectionElement.sectionName.id,
            modified: false,
            invalid: false
          });
        }
        if (sectionElement.fields)
          sectionElement.fields.forEach((field) => {
            dataTypes[field.fieldKey] = field.dataType;
            fieldKeys.push(field.fieldKey);
          });
        if (sectionElement.sectionList)
          sectionElement.sectionList.forEach((section) => {
            if (sectionElement.sectionType === "table") {
              sectionStatus.push({
                name: sectionElement.sectionName.id,
                modified: false,
                invalid: false
              });
            }
            section.fields.forEach((field) => {
              dataTypes[field.fieldKey] = field.dataType;
              fieldKeys.push(field.fieldKey);
            });
          });
      });
      const tabInfo = tabs?.filter((tab) => tab.tabId === subTab.tabName.id)[0];
      tabsStatus.push({
        name: subTab.tabName.id,
        invalid: tabInfo?.invalid,
        modified: tabInfo?.touched,
        sectionsInfo: sectionStatus
      });

      setTabsStatus(tabsStatus);
    });

    return { dataTypes, fieldKeys };
  }, [formInfo.formConfiguration.subTabs, tabs]);

  useEffect(() => {
    registerForm(formInfo.formConfiguration.formKey, form, tabs);
  }, [form, formInfo.formConfiguration.formKey, registerForm, tabs]);

  const [activeTab, setActiveTab] = useState(
    formInfo.formConfiguration.subTabs[0].tabName.id.toString()
  );
  const [inspectionDate, setInspectionDate] = useState<Date>();

  useEffect(
    () => {
      if (formInfo.invalid) {
        form.validateFields();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleTabChange = useCallback((activeKey: string) => {
    setActiveTab(activeKey);
  }, []);

  const inspectionDateKey = useMemo(() => {
    return `DateOfInspection_${formInfo.formConfiguration.formKey}`;
  }, [formInfo.formConfiguration.formKey]);

  const fieldIsRequired = useCallback(
    (fieldName: string): boolean => {
      return (
        !isNil(parametersRestorePoint) &&
        !isNil(parametersRestorePoint[fieldName]?.Value)
      );
    },
    [parametersRestorePoint]
  );

  const handleSectionInfo = useCallback(
    (sectionName: string, modified: boolean, invalid: boolean) => {
      const newTabsStatus = tabsStatus.map((tab) => {
        if (tab.name === activeTab) {
          const sections = tab.sectionsInfo.map((section) =>
            section.name === sectionName
              ? { name: section.name, modified: modified, invalid: invalid }
              : section
          );
          const tabModified = sections.some((section) => section.modified);
          const tabInvalid = sections.some((section) => section.invalid);
          changeTabsInfo(formInfo.formConfiguration.formKey, [
            {
              tabId: activeTab,
              invalid: tabInvalid,
              touched: tabModified
            }
          ]);
          return {
            name: tab.name,
            invalid: tabInvalid,
            modified: tabModified,
            sectionsInfo: sections
          };
        } else return tab;
      });

      setTabsStatus(newTabsStatus);
    },
    [activeTab, changeTabsInfo, formInfo.formConfiguration.formKey, tabsStatus]
  );

  const handleParameterChange = useCallback(
    (
      fieldName: string,
      fieldValue: string | number | boolean | Date,
      fieldType: TypedValue
    ) => {
      changeInspectionField(
        fieldName,
        fieldType === "Bool" && !isNil(fieldValue)
          ? fieldValue === "true"
          : fieldValue,
        fieldType,
        inspectionDate
      );
    },
    [changeInspectionField, inspectionDate]
  );

  const handleFieldsChange = useCallback(
    (changedFields: IFieldData[], allFields: IFieldData[]) => {
      changedFields.forEach((field) => {
        if (field.touched) {
          if (field.name[0] === inspectionDateKey) {
            setInspectionDate(field.value as Date);

            changeInspectionDateForFields(
              field.value as Date,
              fieldsInfo.fieldKeys
            );
          } else {
            handleParameterChange(
              field.name[0],
              field.value,
              fieldsInfo.dataTypes[field.name[0]]
            );
            const fieldsWithoutInspectionDateKey = allFields.filter(
              ({ name }) => name[0] !== inspectionDateKey
            );
            const valid = fieldsWithoutInspectionDateKey.some(
              (field) => field.touched
            );
            handleSectionInfo(`${activeTab}_main`, true, !valid);
          }
        }
      });
    },
    [
      inspectionDateKey,
      changeInspectionDateForFields,
      fieldsInfo.fieldKeys,
      fieldsInfo.dataTypes,
      handleParameterChange,
      handleSectionInfo,
      activeTab
    ]
  );

  const activeTabSections = useMemo(
    () => (
      <CustomSections
        configuration={
          formInfo.formConfiguration.subTabs.find(
            (t) => t.tabName.id === activeTab
          ).sections
        }
        onParameterChange={handleParameterChange}
        onSectionInfoChange={handleSectionInfo}
        fieldIsRequired={fieldIsRequired}
      />
    ),
    [
      activeTab,
      fieldIsRequired,
      formInfo.formConfiguration.subTabs,
      handleParameterChange,
      handleSectionInfo
    ]
  );

  const formatDataQa = (name: string) => {
    return name.toLocaleLowerCase().replace(/\s/g, "-");
  };

  const subTabs = useMemo(
    () => (
      <Tabs
        defaultActiveKey={formInfo.formConfiguration.subTabs[0].tabName.id.toString()}
        onChange={(key: string) => handleTabChange(key)}
        items={formInfo.formConfiguration.subTabs.map<Tab>((t) => ({
          key: String(t.tabName.id),
          label: (
            <span
              data-qa={`${formatDataQa(
                t.tabName.defaultMessage.toString()
              )}-tab`}
            >
              {intl.formatMessage({ ...t.tabName })}
              {formInfo?.tabs?.find((tab) => tab.tabId === t.tabName.id)
                ?.invalid ? (
                <Icon className="tab-invalid-icon" name="circle" size="xs" />
              ) : formInfo?.tabs?.find((tab) => tab.tabId === t.tabName.id)
                  ?.touched ? (
                <Icon className="tab-touched-icon" name="circle" size="xs" />
              ) : (
                ""
              )}
            </span>
          ),
          children: t.tabName.id === activeTab ? activeTabSections : null
        }))}
      />
    ),
    [
      formInfo.formConfiguration.subTabs,
      formInfo?.tabs,
      handleTabChange,
      activeTabSections,
      intl,
      activeTab
    ]
  );

  return (
    <div className={className}>
      <Form
        form={form}
        layout="horizontal"
        colon={false}
        requiredMark={false}
        validateMessages={validateMessages}
        onFieldsChange={handleFieldsChange}
        key={formInfo.formConfiguration.formKey}
      >
        <FormContext.Provider value={form}>
          <Form.Item
            label={intl.formatMessage({
              id: "configuration_tool.label.date_of_inspection",
              defaultMessage: "Date of inspection"
            })}
            className="date-of-inspection"
            name={inspectionDateKey}
            rules={[{ required: true }]}
          >
            <DatePicker
              disabledDate={disableFutureDates}
              format={moment.defaultFormat}
              data-qa="InspectionDate"
              disabled={isReadOnlyMode}
            />
          </Form.Item>
          {subTabs}
        </FormContext.Provider>
      </Form>
    </div>
  );
};
const StyledTabbedInspection = styled(TabbedInspection)`
  .tab-touched-icon {
    margin-left: ${spacingXLarge};
    color: ${colorTeal100};
  }

  .tab-invalid-icon {
    margin: auto ${spacingLarge};
    color: ${colorStatusRed};
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      span {
        color: ${colorGray80};
      }

      .tab-touched-icon {
        margin-left: ${spacingXLarge};
        color: ${colorTeal100};
      }

      .tab-invalid-icon {
        margin: auto ${spacingLarge};
        color: ${colorStatusRed};
      }
    }
  }
`;

export default StyledTabbedInspection;
