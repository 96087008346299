// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";
import { IColumnConfig, IRow, IRowData } from "common/datagrid/DataGrid";
import { routes } from "core/app/components/AppRoutes";
import { useAppNavigate } from "core/app/components/RouterProvider";
import { useCallback } from "react";
import { IDataPoint } from "../components/ScatterPlot";
import { IScatterPlotData } from "../reducers/FamilyAnalyticsReducer";

const useFamilyContents = (
  scatterPlotXData: IScatterPlotData,
  scatterPlotYData: IScatterPlotData
) => {
  const navigate = useAppNavigate();

  let dataPoints: IDataPoint[];
  if (
    scatterPlotXData.dataPoints.data !== null &&
    scatterPlotYData.dataPoints.data === null
  ) {
    dataPoints = filterDataPoints(scatterPlotXData);
  } else if (
    scatterPlotXData.dataPoints.data === null &&
    scatterPlotYData.dataPoints.data !== null
  ) {
    dataPoints = filterDataPoints(scatterPlotYData);
  } else if (
    scatterPlotXData.dataPoints.data !== null &&
    scatterPlotYData.dataPoints.data !== null
  ) {
    dataPoints = filterCommonDataPoints(scatterPlotYData, scatterPlotXData);
  } else {
    dataPoints = [];
  }

  const data: IRowData[] = [];
  dataPoints.map((p) => data.push({ AssetId: p.AssetId }));

  const handleRowClick = useCallback(
    (row: IRow) => {
      const searchParams = new SearchParams({ assetId: row.data["AssetId"] });

      navigate({
        pathname: routes.detailPage.pathname,
        search: searchParams.toString()
      });
    },
    [navigate]
  );

  const columns: IColumnConfig[] = [
    {
      frozen: true,
      id: "AssetId",
      message: {
        defaultMessage: "Asset Id",
        id: "comparisons.grid.columns.asset_id"
      },
      weight: 0
    }
  ];

  return {
    columns,
    dataPoints,
    data,
    handleRowClick
  };
};

const filterDataPoints = (dataPoints: IScatterPlotData): IDataPoint[] => {
  return dataPoints.dataPoints.data.DataPoints.filter(
    (d) => d.Date != null && d.Value != null
  );
};

const filterCommonDataPoints = (
  pointsY: IScatterPlotData,
  pointsX: IScatterPlotData
): IDataPoint[] => {
  const mergedPoints = pointsY.dataPoints.data.DataPoints.map((yPoint) => {
    const haveEqualAssetId = (xPoint: IDataPoint) =>
      xPoint.AssetId === yPoint.AssetId;
    const xPoint = pointsX.dataPoints.data.DataPoints.find(haveEqualAssetId);
    return {
      AssetId: yPoint.AssetId,
      valueX: xPoint ? +xPoint.Value : null,
      valueY: yPoint ? +yPoint.Value : null,
      Date: "",
      Risk: yPoint ? yPoint.Risk ?? "" : "",
      Value: xPoint ? +xPoint.Value : +yPoint.Value
    };
  });
  return mergedPoints.filter((p) => p.valueX != null && p.valueY != null);
};
export default useFamilyContents;
