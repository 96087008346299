// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";

import C3Chart from "common/chart/components/C3Chart";
import IParameterHistogram from "../models/IParameterHistogram";
import useChartConfiguration from "../hooks/useChartConfiguration";

import "./ParameterHistogramChart.less";

interface IParameterHistogramChartProps {
  parameterHistogramData: IParameterHistogram[];
  unit: string;
}

const ParameterHistogramChart = ({
  parameterHistogramData,
  unit
}: IParameterHistogramChartProps) => {
  const configuration = useChartConfiguration(parameterHistogramData, unit);

  return (
    <div className="parameter-histogram-chart">
      <C3Chart configuration={configuration}></C3Chart>
    </div>
  );
};

export default ParameterHistogramChart;
