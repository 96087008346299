// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React, { useMemo } from "react";
import Popover from "antd/lib/popover";

import { IRow } from "common/datagrid/DataGrid";
import isClickable from "../utils/isClickable";
import IParameterInfo from "../../../models/IParameterInfo";

import useIsEllipsisActive from "../hooks/useIsEllipsisActive";

const ColumnName = (translatedName: string, row: IRow<IParameterInfo>) => {
  const { divRef, isEllipsisActive } = useIsEllipsisActive();
  const clickable = useMemo(() => isClickable(row), [row]);
  const content = useMemo(
    () => (
      <div
        ref={divRef}
        className={`column name ${clickable ? "" : "non-clickable"}`}
        data-qa={row.data.Name}
      >
        {translatedName}
      </div>
    ),
    [clickable, row.data.Name, translatedName, divRef]
  );

  return isEllipsisActive ? (
    <Popover content={<>{translatedName}</>} destroyTooltipOnHide={true}>
      {content}
    </Popover>
  ) : (
    content
  );
};

export default ColumnName;
