// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { IState as IOperationsParametersWidgetState } from "@apm/widgets/build/widgets/OperationsParameters";
import { merge } from "lodash";

const convertParametersFromArray = (value: string[][]) => {
  if (value) {
    const result: IOperationsParametersWidgetState = {};

    value.forEach((v) => {
      const [group, parameter, color] = v;
      merge(result, {
        [group]: {
          [parameter]: {
            color,
            checked: true
          }
        }
      });
    });

    return result;
  }

  return null;
};

export default convertParametersFromArray;
