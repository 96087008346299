// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Typography } from "antd";
import {
  DateDifferenceService,
  Formats
} from "common/formatters/DateDifference";
import Popover from "common/popover/Popover";
import AssetRiskLastUpdatedTooltipExtended from "common/tooltips/AssetRiskExecutionDateTooltipExtended";
import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Label from "./Label";
import "./LastUpdate.less";

const { Text } = Typography;

interface ILastUpdateProps {
  degradationScoreDate: string;
  degradationScoreExecutionDate: string;
  latestParameterDate: string;
}

const LastUpdate = ({
  degradationScoreDate,
  degradationScoreExecutionDate,
  latestParameterDate
}: ILastUpdateProps) => {
  const intl = useIntl();

  const dateToCalculate = useMemo(() => {
    return (
      degradationScoreExecutionDate ??
      degradationScoreDate ??
      latestParameterDate
    );
  }, [
    degradationScoreExecutionDate,
    degradationScoreDate,
    latestParameterDate
  ]);

  const lastUpdatedBefore = useMemo(() => {
    return DateDifferenceService.format(dateToCalculate, intl, Formats.Long);
  }, [dateToCalculate, intl]);

  return (
    <div className="asset-last-update asset-ribbon-section">
      <div
        className="asset-last-update__label"
        data-qa="header-last-update-label"
      >
        <Label>
          <FormattedMessage
            id="asset_detail_page.header.last_update"
            defaultMessage="Last update"
          />
        </Label>
      </div>
      <div
        className="asset-last-update__value"
        data-qa="header-last-update-value"
      >
        <Popover
          trigger="hover"
          placement="bottom"
          content={
            <AssetRiskLastUpdatedTooltipExtended
              lastUpdatedBefore={lastUpdatedBefore}
              conditionCalculationDate={degradationScoreExecutionDate}
              conditionDate={degradationScoreDate}
              latestParameterDate={latestParameterDate}
            />
          }
        >
          <Text>
            <FormattedMessage
              defaultMessage="{value} ago"
              id="asset_detail_page.header.last_update.value"
              values={{
                value: lastUpdatedBefore
              }}
            />
          </Text>
        </Popover>
      </div>
    </div>
  );
};

export default LastUpdate;
