// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useIntl } from "react-intl";

import IBushingOfflineStaticLegendItem from "features/detailpage/features/bushingsoffline/models/IBushingOfflineStaticLegendItem";
import BushingOfflineChartService from "features/detailpage/features/bushingsoffline/services/BushingOfflineChartService";
import { useMemo } from "react";
import "./BushingOfflineChartStaticLegend.less";

export interface IBushingOfflineChartStaticLegendProps {
  items?: string[];
}

const BushingOfflineChartStaticLegend = ({
  items
}: IBushingOfflineChartStaticLegendProps) => {
  const intl = useIntl();
  const legendItems: IBushingOfflineStaticLegendItem[] = useMemo(
    () => BushingOfflineChartService.getLegendItems(items, intl),
    [intl, items]
  );

  return (
    <div className="bushing-static-legend">
      {legendItems.map((item, index) => (
        <div className="bushing-static-legend__item" key={index}>
          <span className="bushing-static-legend__item-box">
            <span
              className={`bushing-static-legend__item-box--dotted ${item.className}`}
            />
          </span>
          <span className="bushing-static-legend__item-text">{item.name}</span>
        </div>
      ))}
    </div>
  );
};

export default BushingOfflineChartStaticLegend;
