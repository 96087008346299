// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { notifications } from "@pg/common/build/components/Notifications";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, MessageDescriptor, useIntl } from "react-intl";
import useRecalculateButtonBlockTimestamps from "./useRecalculateButtonBlockTimestamp";
import useTriggerModel from "./useTriggerModel";

const isCalculatingBlockTimeout = 30 * 1000;

interface IUseRecalculateButtonOptions {
  modelId: string;
  assetId?: string;
}

const useRecalculateButton = ({
  assetId,
  modelId
}: IUseRecalculateButtonOptions) => {
  const intl = useIntl();
  const { getTimestamp, removeTimestamp, setTimestamp } =
    useRecalculateButtonBlockTimestamps({ assetId, modelId });
  const { triggerModel } = useTriggerModel();

  const timeoutId = useRef<number>();

  const timeoutValue = useMemo(() => {
    const timestamp = getTimestamp();
    if (timestamp) {
      const timeoutValue =
        isCalculatingBlockTimeout -
        (new Date().getTime() - new Date(timestamp).getTime());

      return timeoutValue;
    }

    return -1;
  }, [getTimestamp]);

  const [isRecalculating, setIsRecalculating] = useState<boolean>(
    timeoutValue > -1 ? true : false
  );

  const Label = useMemo(
    () =>
      isRecalculating
        ? () => (
            <FormattedMessage
              id="model_trigger.calculating"
              defaultMessage="Calculating..."
            />
          )
        : () => (
            <FormattedMessage
              id="model_trigger.calculate"
              defaultMessage="Calculate"
            />
          ),
    [isRecalculating]
  );

  const questionMessage: MessageDescriptor = useMemo(() => {
    if (assetId) {
      return {
        id: "model_trigger.question.asset",
        defaultMessage:
          "Do you want to calculate the current score for this asset? Completing your request might take a moment depending on the size of your asset fleet and other processes that are now in progress."
      };
    }
    return {
      id: "model_trigger.question.model",
      defaultMessage:
        "Do you want to calculate current scores for assets that reference this performance model? Completing your request might take a moment depending on the size of your asset fleet and other processes that are now in progress."
    };
  }, [assetId]);

  const stopCalculating = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    setIsRecalculating(false);
    removeTimestamp();
  }, [removeTimestamp]);

  const triggerRecalculate = useCallback(async () => {
    try {
      setIsRecalculating(true);
      setTimestamp();
      timeoutId.current = window.setTimeout(
        stopCalculating,
        isCalculatingBlockTimeout
      );

      await triggerModel(modelId, assetId);
    } catch (e) {
      notifications.error({
        message: intl.formatMessage({
          id: "model_trigger.error",
          defaultMessage: "Cannot perform the calculation."
        })
      });
    }
  }, [assetId, intl, modelId, setTimestamp, stopCalculating, triggerModel]);

  const handleClick = useCallback(() => {
    notifications.confirm({
      title: intl.formatMessage({
        defaultMessage: questionMessage.defaultMessage.toString(),
        id: questionMessage.id.toString()
      }),
      onOk: () => {
        triggerRecalculate();
      }
    });
  }, [
    intl,
    questionMessage.defaultMessage,
    questionMessage.id,
    triggerRecalculate
  ]);

  useEffect(() => {
    if (timeoutValue > -1) {
      setIsRecalculating(true);
      timeoutId.current = window.setTimeout(stopCalculating, timeoutValue);
    } else {
      removeTimestamp();
    }

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [removeTimestamp, stopCalculating, timeoutValue]);

  return { isRecalculating, Label, handleClick };
};

export default useRecalculateButton;
