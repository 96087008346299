// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { loadVersion } from "core/app/actions/VersionActions";
import VersionProvider, {
  IVersionProviderActions
} from "core/app/components/VersionProvider";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";

function mapStateToProps(state: IState): {} {
  return {};
}

function mapDispatchToProps(dispatch: AppDispatch): IVersionProviderActions {
  return {
    loadVersion: () => {
      loadVersion(dispatch);
    }
  };
}

const VersionProviderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VersionProvider);

export default VersionProviderContainer;
