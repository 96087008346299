// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Action, Reducer } from "redux";
import keyRiskChangesReducer, {
  defaultState as keyRiskChangesDefaultState,
  IState as IKeyRiskChangesState
} from "../keyriskchanges/reducers/KeyRiskChangesReducer";
import openIssuesReducer, {
  defaultState as openIssuesDefaultState,
  IOpenIssuesState
} from "../openissues/reducers/OpenIssuesReducer";

export interface IState extends IOpenIssuesState, IKeyRiskChangesState {}

const defaultState: IState = {
  ...openIssuesDefaultState,
  ...keyRiskChangesDefaultState
};

const mainDashboardReducer: Reducer<IState> = (
  state: IState = defaultState,
  action: Action
) => {
  state = openIssuesReducer(state, action) as IState;
  state = keyRiskChangesReducer(state, action) as IState;

  return state;
};

export default mainDashboardReducer;
