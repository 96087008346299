// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { DuvalPoint } from "../../../components/analytics/DuvalTriangle/DuvalTriangle";
import IDuvalRegion from "../../../components/analytics/DuvalTriangle/models/IDuvalRegion";

const type4Default: IDuvalRegion[] = [
  {
    Name: "O",
    Color: "#57C676",
    LabelCenter: new DuvalPoint(0, 35, 65),
    LabelXOffset: 0,
    LabelYOffset: -8,
    Points: [
      new DuvalPoint(0, 70, 30),
      new DuvalPoint(9, 61, 30),
      new DuvalPoint(9, 0, 91),
      new DuvalPoint(0, 0, 100)
    ]
  },
  {
    Name: "ND",
    Color: "#83D3AF",
    LabelCenter: new DuvalPoint(35, 0, 65),
    LabelXOffset: 10,
    LabelYOffset: 25,
    Points: [
      new DuvalPoint(9, 45, 46),
      new DuvalPoint(54, 0, 46),
      new DuvalPoint(9, 0, 91)
    ]
  },
  {
    Name: "PD",
    Color: "#4386FB",
    LabelCenter: new DuvalPoint(90, 10, 0),
    LabelXOffset: 2,
    LabelYOffset: 0,
    Points: [
      new DuvalPoint(98, 2, 0),
      new DuvalPoint(97, 2, 1),
      new DuvalPoint(84, 15, 1),
      new DuvalPoint(85, 15, 0)
    ]
  },
  {
    Name: "S",
    Color: "#9AEEE1",
    LabelCenter: new DuvalPoint(75, 25, 0),
    LabelXOffset: -20,
    LabelYOffset: 10,
    Points: [
      new DuvalPoint(54, 0, 46),
      new DuvalPoint(100, 0, 0),
      new DuvalPoint(98, 2, 0),
      new DuvalPoint(97, 2, 1),
      new DuvalPoint(84, 15, 1),
      new DuvalPoint(85, 15, 0),
      new DuvalPoint(64, 36, 0),
      new DuvalPoint(40, 36, 24),
      new DuvalPoint(15, 61, 24),
      new DuvalPoint(15, 55, 30),
      new DuvalPoint(9, 61, 30),
      new DuvalPoint(9, 45, 46)
    ]
  },
  {
    Name: "C",
    Color: "#54B3AA",
    LabelCenter: new DuvalPoint(30, 70, 0),
    LabelXOffset: -30,
    LabelYOffset: 0,
    Points: [
      new DuvalPoint(64, 36, 0),
      new DuvalPoint(40, 36, 24),
      new DuvalPoint(15, 61, 24),
      new DuvalPoint(15, 55, 30),
      new DuvalPoint(0, 70, 30),
      new DuvalPoint(0, 100, 0)
    ]
  }
];

export default type4Default;
