// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import {
  colorRiskHigh,
  colorRiskLow,
  colorRiskMedium,
  colorRiskUnknown
} from "@pg/common/build/styles/ColorVariables";
import AssetRisks from "models/AssetRisks";
import IAssetRiskCountsTree from "models/IAssetRiskCountsTree";

const createJellyBenasValues = (
  assetRiskCounts: IAssetRiskCountsTree,
  id: string[],
  onItemClick: (assetRisk: AssetRisks, id: string[], value: number) => void
) => [
  {
    name: "High",
    color: colorRiskHigh,
    value: assetRiskCounts.HighRiskCount,
    onClick: (assetRisk: AssetRisks, value: number) =>
      onItemClick(assetRisk, id, value)
  },
  {
    name: "Medium",
    color: colorRiskMedium,
    value: assetRiskCounts.MediumRiskCount,
    onClick: (assetRisk: AssetRisks, value: number) =>
      onItemClick(assetRisk, id, value)
  },
  {
    name: "Low",
    color: colorRiskLow,
    value: assetRiskCounts.LowRiskCount,
    onClick: (assetRisk: AssetRisks, value: number) =>
      onItemClick(assetRisk, id, value)
  },
  {
    name: "Unknown",
    color: colorRiskUnknown,
    value: assetRiskCounts.UnknownRiskCount,
    onClick: (assetRisk: AssetRisks, value: number) =>
      onItemClick(assetRisk, id, value)
  }
];

export default createJellyBenasValues;
