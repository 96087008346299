// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";
import { isEmpty } from "lodash";

import ISubfactorScore from "features/detailpage/features/subfactorsgrid/models/ISubfactorScore";
import Data, { Statuses } from "core/data/models/Data";

import Processing from "components/common/Processing";
import {
  WidgetNoDataMessage,
  WidgetErrorMessage,
  WidgetEmpty
} from "components/common/widget/Widget";

import SubfactorsGridHeader from "./SubfactorsGridHeader";
import SubfactorsGridBody from "./SubfactorsGridBody";
import SubfactorsGridFooter from "./SubfactorsGridFooter";

import "./SubfactorsGrid.less";

export interface ISubfactorsGridActions {
  loadAssetSubfactors: (assetId: string) => Promise<void>;
}

export interface ISubfactorsGridStateProps {
  assetSubfactors: Data<Array<ISubfactorScore>>;
  previousDate: string;
  currentDate: string;
  assetSubfactorsCount: number;
}

export interface ISubfactorsProps
  extends ISubfactorsGridActions,
    ISubfactorsGridStateProps {
  assetId: string;
  modelId: string;
  implementationId: string;
}

interface IGridProps {
  assetSubfactors: Array<ISubfactorScore>;
  assetSubfactorsCount: number;
  modelId: string;
  implementationId: string;
  previousDate: string;
  currentDate: string;
}

const Grid = ({
  assetSubfactors,
  assetSubfactorsCount,
  modelId,
  implementationId,
  previousDate,
  currentDate
}: IGridProps) => {
  return (
    <>
      <div className="grid-custom subfactors-grid">
        <SubfactorsGridHeader
          previousDate={previousDate}
          currentDate={currentDate}
        />
        <SubfactorsGridBody subfactors={assetSubfactors} modelId={modelId} implementationId={implementationId} />
      </div>
      <SubfactorsGridFooter subfactorsCount={assetSubfactorsCount} />
    </>
  );
};

interface ISubfactorsGridProps {
  assetSubfactors: Data<Array<ISubfactorScore>>;
  assetSubfactorsCount: number;
  modelId: string;
  implementationId: string;
  previousDate: string;
  currentDate: string;
}

const SubfactorsGrid = ({
  assetSubfactors,
  assetSubfactorsCount,
  modelId,
  implementationId,
  previousDate,
  currentDate
}: ISubfactorsGridProps) => {
  switch (assetSubfactors.status) {
    case Statuses.Loading:
      return <Processing />;
    case Statuses.Succeeded:
      return !isEmpty(assetSubfactors.data) ? (
        <Grid
          assetSubfactors={assetSubfactors.data}
          assetSubfactorsCount={assetSubfactorsCount}
          modelId={modelId}
          implementationId={implementationId}
          previousDate={previousDate}
          currentDate={currentDate}
        />
      ) : (
        <WidgetNoDataMessage />
      );
    case Statuses.Failed:
      return (
        <WidgetErrorMessage
          messageId="global.empty"
          messageDefault={assetSubfactors.message}
          messageValues={{ message: assetSubfactors.message }}
        />
      );
    default:
      return <WidgetEmpty />;
  }
};

const SubfactorsList = ({
  assetSubfactors,
  assetSubfactorsCount,
  assetId,
  modelId,
  implementationId,
  previousDate,
  currentDate,
  loadAssetSubfactors
}: ISubfactorsProps) => {
  useLoadAssetSubfactors(loadAssetSubfactors, assetId, assetSubfactors);

  return (
    <div className="subfactors-list" data-qa="subfactors">
      <SubfactorsGrid
        assetSubfactors={assetSubfactors}
        assetSubfactorsCount={assetSubfactorsCount}
        modelId={modelId}
        implementationId={implementationId}
        previousDate={previousDate}
        currentDate={currentDate}
      />
    </div>
  );
};

export default SubfactorsList;

// #region Hooks

function useLoadAssetSubfactors(
  loadAssetSubfactors: (assetId: string) => Promise<void>,
  assetId: string,
  assetSubfactors: Data<Array<ISubfactorScore>>
) {
  React.useEffect(() => {
    if (
      assetSubfactors.status !== Statuses.Succeeded ||
      isEmpty(assetSubfactors.data)
    ) {
      loadAssetSubfactors(assetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);
}

// #endregion
