// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { debounce } from "lodash";
import React from "react";
import ResizeObserver from "resize-observer-polyfill";

import "./ResizeWrapper.less";

type ResizeProps = {
  children: React.ReactNode;
  className?: string;
} & Partial<DefaultProps>;

type ResizeState = {
  parentWidth: number;
  parentHeight: number;
};

type DefaultProps = Readonly<typeof resizeWrapperDefaultProps>;

const resizeWrapperDefaultProps = {
  className: ""
};

class ResizeWrapper extends React.Component<ResizeProps, ResizeState> {
  static defaultProps = resizeWrapperDefaultProps;

  constructor(props: ResizeProps) {
    super(props);

    this.state = {
      parentWidth: undefined,
      parentHeight: undefined
    };
  }

  componentDidMount() {
    this.observer.observe(this.resizeWrapper.current.parentElement);
  }

  componentWillUnmount() {
    this.observer.unobserve(this.resizeWrapper.current.parentElement);
  }

  private updateDimensions = () => {
    const { parentWidth, parentHeight } = this.state;

    if (
      this.resizeWrapper &&
      this.resizeWrapper.current &&
      this.resizeWrapper.current.parentElement &&
      (this.resizeWrapper.current.parentElement.clientWidth !== parentWidth ||
        this.resizeWrapper.current.parentElement.clientHeight !== parentHeight)
    ) {
      this.setState({
        parentWidth: this.resizeWrapper.current.parentElement.clientWidth,
        parentHeight: this.resizeWrapper.current.parentElement.clientHeight
      });
    }
  };

  render() {
    const { parentHeight, parentWidth } = this.state;
    const { children, className } = this.props;

    const key =
      parentHeight !== undefined && parentWidth !== undefined
        ? `${parentHeight}_${parentWidth}`
        : undefined;

    return (
      <div
        key={key || "-"}
        className={`resize-wrapper ${className}`}
        ref={this.resizeWrapper}
      >
        {key ? children : null}
      </div>
    );
  }

  private observer = new ResizeObserver(() => {
    this.debouncedUpdateDimensions();
  });
  private resizeWrapper = React.createRef<HTMLDivElement>();
  private debouncedUpdateDimensions = debounce(this.updateDimensions, 500);
}

export default ResizeWrapper;
