// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import {
  colorsChartSeries,
  IState as IWidgetState
} from "@apm/widgets/build/widgets/OperationsParameters";
import IPoleDefaultColors from "@apm/widgets/build/widgets/OperationsParameters/models/IPoleDefaultColors";
const color = colorsChartSeries;
const getParametersDefaultState = (): IWidgetState => {
  return {
    pole1: {
      ph1: {
        checked: true,
        color: color[0]
      },
      ph2: {},
      ph3: {},
      tr: {
        checked: true,
        color: color[0]
      },
      cc: {
        checked: true,
        color: color[0]
      },
      tc1: {
        checked: true,
        color: color[0]
      },
      tc2: {
        checked: true,
        color: color[0]
      },
      swa: {
        checked: true,
        color: color[0]
      },
      swb: {
        checked: true,
        color: color[0]
      }
    },
    pole2: {
      ph1: {
        checked: true,
        color: color[1]
      },
      ph2: {},
      ph3: {},
      tr: {
        checked: true,
        color: color[1]
      },
      cc: {
        checked: true,
        color: color[1]
      },
      tc1: {
        checked: true,
        color: color[1]
      },
      tc2: {
        checked: true,
        color: color[1]
      },
      swa: {
        checked: true,
        color: color[1]
      },
      swb: {
        checked: true,
        color: color[1]
      }
    },
    pole3: {
      ph1: {
        checked: true,
        color: color[3]
      },
      ph2: {},
      ph3: {},
      tr: {
        checked: true,
        color: color[3]
      },
      cc: {
        checked: true,
        color: color[3]
      },
      tc1: {
        checked: true,
        color: color[3]
      },
      tc2: {
        checked: true,
        color: color[3]
      },
      swa: {
        checked: true,
        color: color[3]
      },
      swb: {
        checked: true,
        color: color[3]
      }
    }
  };
};

const poleDefaultColors: IPoleDefaultColors = {
  pole1: colorsChartSeries[0],
  pole2: colorsChartSeries[1],
  pole3: colorsChartSeries[3]
};

export { getParametersDefaultState, poleDefaultColors };
