// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { colorStatusOrange } from "@pg/common/build/styles/ColorVariables";
import styled from "styled-components";

interface IReplaceableAssetsProps {
  className?: string;
  value: number;
}

const ReplaceableAssets = ({ className, value }: IReplaceableAssetsProps) =>
  value && <Icon className={className} name="recycling" size="md" />;

export default styled(ReplaceableAssets)`
  color: ${colorStatusOrange};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
