// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Risk from "common/columns/Risk";
import { IColumnConfig, IRow, IRowData } from "common/datagrid/DataGrid";
import * as React from "react";
import { useMemo } from "react";
import AssetRisks from "../components/AssetRisks";
import ItemsCount from "../components/ItemsCount";
import Location from "../components/Location";
import MaintenancePriority from "../components/MaintenancePriority";
import ReplaceableAssets from "../components/ReplaceableAssets";
import LocationsPanelContentSwitchOption from "../models/LocationsPanelContentSwitchOption";

interface IUseLocationsGridColumnsConfigOptions {
  handleLocationPanelOpen: (
    selectedRow: IRow<IRowData>,
    selectedContent: LocationsPanelContentSwitchOption
  ) => void;
}
const useLocationsGridColumnsConfig = ({
  handleLocationPanelOpen
}: IUseLocationsGridColumnsConfigOptions) => {
  const columns: IColumnConfig[] = useMemo(
    () => [
      {
        component: (_, row) => (
          <Location
            location={row.data["Location"]}
            region={row.data["Region"]}
            organization={row.data["Organization"]}
          />
        ),
        id: "Location",
        message: {
          defaultMessage: "Location",
          id: "locations_page.grid.columns.location"
        },
        weight: 4
      },
      {
        component: (value) => <Risk risk={value} />,
        id: "AssetRisk",
        message: {
          defaultMessage: "Risk",
          id: "locations_page.grid.columns.risk"
        }
      },
      {
        component: (value, row) => (
          <ItemsCount
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              handleLocationPanelOpen(row, "Assets");
            }}
            value={value}
          />
        ),
        id: "AssetCount",
        message: {
          defaultMessage: "Number of Assets",
          id: "locations_page.grid.columns.number_of_assets"
        },
        weight: 2
      },
      {
        component: (value, row) => (
          <ItemsCount
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              handleLocationPanelOpen(row, "Issues");
            }}
            value={value}
          />
        ),
        id: "IssueCount",
        message: {
          defaultMessage: "Number of Issues",
          id: "locations_page.grid.columns.number_of_issues"
        },
        weight: 2
      },
      {
        component: (value) => <MaintenancePriority value={value} />,
        id: "MaintenancePriority",
        message: {
          defaultMessage: "MP Score",
          id: "locations_page.grid.columns.maintenance_priority"
        },
        weight: 2
      },
      {
        component: (value) => <ReplaceableAssets value={value} />,
        id: "HasReplaceableAssets",
        message: {
          defaultMessage: "Replaceable Assets",
          id: "locations_page.grid.columns.replaceable_assets"
        },
        weight: 1.5
      },
      {
        component: (_, row) => <AssetRisks row={row} />,
        id: "AssetRiskDistribution",
        sortable: false,
        message: {
          defaultMessage: "Asset Risk Distribution",
          id: "locations_page.grid.columns.asset_risk_distribution"
        },
        weight: 4
      }
    ],
    [handleLocationPanelOpen]
  );

  return columns;
};

export default useLocationsGridColumnsConfig;
