// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import "common/slidepanel/SlidePanel.less";
import { Children, Component, ReactNode } from "react";
import ScrollArea from "react-scrollbar";

enum Statuses {
  Opened,
  Closing,
  Closed
}

export interface ISlidePanelProps {
  children: ReactNode;
  contentClassName?: string;
  PanelTitle?: () => JSX.Element;
}

export interface ISlidePanelState {
  status: Statuses;
}

export default class SlidePanel extends Component<
  ISlidePanelProps,
  ISlidePanelState
> {
  static defaultProps = {
    contentClassName: "col-6"
  };

  constructor(props: ISlidePanelProps) {
    super(props);

    this.state = {
      status: Statuses.Closed
    };
  }

  open = () => {
    this.setState((prevState, props) =>
      Object.assign({}, prevState, {
        status: Statuses.Opened
      } as ISlidePanelState)
    );
  };

  render() {
    const { handleCloseIconClick, handleContentClick, handleSlidePanelClick } =
      this;
    const { children, contentClassName, PanelTitle } = this.props;
    const { status } = this.state;

    const childrenArray = Children.toArray(children);

    return (
      <div
        className={`slide-panel ${
          status === Statuses.Opened
            ? "opened"
            : status === Statuses.Closing
            ? "closing"
            : "closed"
        }`}
      >
        <div className="container" onClick={handleSlidePanelClick}>
          <div className="bootstrap-row">
            <div
              className={`content ${contentClassName}`}
              onClick={handleContentClick}
            >
              <div className="header">
                <div className="title">{PanelTitle && <PanelTitle />}</div>
                <Icon
                  name="chevron_right"
                  onClick={handleCloseIconClick}
                  size="lg"
                  dataQa="slide-panel-close"
                />
              </div>
              <div className="main">
                <ScrollArea
                  horizontal={false}
                  focusableTabIndex={null}
                  smoothScrolling={true}
                  speed={0.8}
                >
                  {childrenArray}
                </ScrollArea>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private close = () => {
    this.setState((prevState, props) =>
      Object.assign({}, prevState, {
        status: Statuses.Closing
      } as ISlidePanelState)
    );

    // Ms param value is related to animation time.
    // See SlidePanel.less file.
    setTimeout(() => {
      this.setState((prevState, props) =>
        Object.assign({}, prevState, {
          status: Statuses.Closed
        } as ISlidePanelState)
      );
    }, 200);
  };

  private handleCloseIconClick = () => {
    const { close } = this;
    close();
  };

  private handleContentClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  private handleSlidePanelClick = () => {
    const { close } = this;
    close();
  };
}
