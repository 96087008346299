// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useEffect, useRef, useState } from "react";

const useIsEllipsisActive = () => {
  const [isEllipsisActive, setIsEllipsisActive] = useState<boolean>(false);
  const divRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (
      divRef.current &&
      divRef.current.offsetWidth < divRef.current.scrollWidth
    ) {
      setIsEllipsisActive(true);
    } else {
      setIsEllipsisActive(false);
    }
  }, []);

  return { isEllipsisActive, divRef };
};

export default useIsEllipsisActive;
