// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import IDuvalTabConfiguration from "features/detailpage/components/analytics/DuvalTriangle/models/IDuvalTabConfiguration";
import isKinectricsNetworkTransformerModel from "features/detailpage/utils/isKinectricsNetworkTransformerModel";
import isKinectricsNetworkTransformerSiliconeModel from "features/detailpage/utils/isKinectricsNetworkTransformerSiliconeModel";
import { useMemo } from "react";
import type1Default from "../constants/type1Default";
import type3Default from "../constants/type3Default";
import type4Default from "../constants/type4Default";
import type5Default from "../constants/type5Default";

const useDuvalTabs = (implementationId: string) => {
  const isKinectricsModel = useMemo<boolean>(() => {
    return isKinectricsNetworkTransformerModel(implementationId.toLowerCase());
  }, [implementationId]);

  const isKinectricsSiliconeModel = useMemo<boolean>(() => {
    return isKinectricsNetworkTransformerSiliconeModel(
      implementationId.toLowerCase()
    );
  }, [implementationId]);

  const showPentagons = useMemo<boolean>(() => {
    return !isKinectricsModel;
  }, [isKinectricsModel]);

  const duvalConfiguration = useMemo<IDuvalTabConfiguration>(() => {
    return {
      type1: isKinectricsSiliconeModel ? [] : type1Default,
      type3: isKinectricsSiliconeModel ? type3Default : [],
      type4: isKinectricsSiliconeModel ? [] : type4Default,
      type5: isKinectricsModel ? [] : type5Default
    };
  }, [isKinectricsModel, isKinectricsSiliconeModel]);

  return { duvalConfiguration, showPentagons };
};

export default useDuvalTabs;
